import React, { useCallback, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { RootState } from '~/modules';
import { setMobileMenuOpen } from '~/modules/header';

interface IProps {
  customerTypeName?: string;
  handleSignout: () => void;
  children: React.ReactNode;
}

const MobileGnb = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 60%;
  min-width: 32rem;
  height: 100%;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  transition: 0.3s ease-out;
  z-index: 99;
  touch-action: none;
  display: flex;
  flex-direction: column;

  &.open {
    left: 0;
    transition: 0.3s ease;
  }
`;

const Link = styled(RouterLink)`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  min-height: 50px;

  &.btn {
    justify-content: center;
  }
`;

const XplayzLink = styled.a`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  min-height: 50px;

  &.btn {
    justify-content: center;
  }
`;

const MobileGnbTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 16px;

  a {
    width: auto;
    display: flex;
    align-items: center;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15.5px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const MobileGnbLogoImage = styled.img`
  width: 70px;

  + b {
    color: #000;
    font-size: 12px;
    padding-left: 4px;
  }
`;

const MobileGnbCenter = styled.ul`
  position: relative;
  top: 20px;
  padding: 0 16px;
`;

const MobileGnbDepth = styled.div`
  border-top: 1px solid #ebebeb;
  padding: 1.5rem 0 1rem;

  span {
    padding: 1rem 0;
    font-size: 12px;
    color: #aaaaaa;
  }
`;

const MobileGnbBottom = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 1rem 1.6rem;
  border-top: 1px solid #ebebeb;
  text-align: center;
  position: relative;
  margin-top: auto;

  ul > a > li {
    width: 100%;
    text-align: center;
  }

  button {
    margin-bottom: 1rem;
  }
`;

const MobileGnbBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 98;
  cursor: pointer;
  display: none;
  touch-action: none;

  &.open {
    display: block;
    transition: 0.3s ease-in;
  }
`;

export function MobileGnbHome() {
  return (
    <MobileGnbCenter>
      <Link to="/">
        <li>
          <FormattedMessage id="gnb.home.index" />
        </li>
      </Link>
      <Link to="/home-start">
        <li>
          <FormattedMessage id="gnb.home.start" />
        </li>
      </Link>
      <Link to="/home-resource">
        <li>
          <FormattedMessage id="gnb.home.resource" />
        </li>
      </Link>
      <XplayzLink
        href="https://xplayz.zenaad.com"
        target="_blank"
        rel="noreferrer"
      >
        <li>xPlayz</li>
      </XplayzLink>
      <XplayzLink
        href="https://www.zenaplay.com"
        target="_blank"
        rel="noreferrer"
      >
        <li>ZenaPlay</li>
      </XplayzLink>
      <XplayzLink
        href="https://mcn.zenaad.com"
        target="_blank"
        rel="noreferrer"
      >
        <li>MCN</li>
      </XplayzLink>
    </MobileGnbCenter>
  );
}

export function MobileGnbSdk() {
  return (
    <MobileGnbCenter>
      <Link to="/home-sdk">
        <li>
          <FormattedMessage id="gnb.sdkAndSupport.sdk" />
        </li>
      </Link>
      <Link to="/home-support">
        <li>
          <FormattedMessage id="gnb.sdkAndSupport.support" />
        </li>
      </Link>
    </MobileGnbCenter>
  );
}

export function MobileGnbGame() {
  return (
    <MobileGnbCenter>
      <Link to="/home-game">
        <li>
          <FormattedMessage id="gnb.app.game" />
        </li>
      </Link>
    </MobileGnbCenter>
  );
}

export function MobileGnbPartner() {
  return (
    <MobileGnbCenter>
      <Link to="/home-mediation">
        <li>
          <FormattedMessage id="gnb.partner.mediation" />
        </li>
      </Link>
      <Link to="/home-publisher">
        <li>
          <FormattedMessage id="gnb.partner.publisher" />
        </li>
      </Link>
    </MobileGnbCenter>
  );
}

export function MobileGnbEmpty() {
  return <MobileGnbCenter />;
}

export function MobileGnbAd() {
  return (
    <MobileGnbCenter>
      <Link to="/ad">
        <li>
          <FormattedMessage id="gnb.ad.home" />
        </li>
      </Link>
      <Link to="/ad-campaign">
        <li>
          <FormattedMessage id="gnb.ad.campaign" />
        </li>
      </Link>
      <Link to="/ad-ad">
        <li>
          <FormattedMessage id="gnb.ad.ad" />
        </li>
      </Link>
      <MobileGnbDepth>
        <span>
          <FormattedMessage id="gnb.ad.report" />
        </span>
        <ul>
          <Link to="/ad-report-campaign">
            <li>
              <FormattedMessage id="gnb.ad.report.campaign" />
            </li>
          </Link>
          <Link to="/ad-report-ad">
            <li>
              <FormattedMessage id="gnb.ad.report.ad" />
            </li>
          </Link>
        </ul>
      </MobileGnbDepth>
    </MobileGnbCenter>
  );
}

export function MobileGnbPub() {
  return (
    <MobileGnbCenter>
      <Link to="/pub">
        <li>
          <FormattedMessage id="gnb.pub.home" />
        </li>
      </Link>
      <Link to="/pub-app">
        <li>
          <FormattedMessage id="gnb.pub.app" />
        </li>
      </Link>
      <Link to="/pub-inventory">
        <li>
          <FormattedMessage id="gnb.pub.inventory" />
        </li>
      </Link>
      <Link to="/pub-inventory">
        <li>
          <FormattedMessage id="gnb.pub.appReward" />
        </li>
      </Link>
      <MobileGnbDepth>
        <span>
          <FormattedMessage id="gnb.pub.report" />
        </span>
        <ul>
          <Link to="/pub-report-day">
            <li>
              <FormattedMessage id="gnb.pub.report.day" />
            </li>
          </Link>
          <Link to="/pub-report-app">
            <li>
              <FormattedMessage id="gnb.pub.report.app" />
            </li>
          </Link>
          <Link to="/pub-report-user">
            <li>
              <FormattedMessage id="gnb.pub.report.user" />
            </li>
          </Link>
        </ul>
      </MobileGnbDepth>
    </MobileGnbCenter>
  );
}

function HeaderMobile({ customerTypeName, handleSignout, children }: IProps) {
  // const { mobileMenuOpen, setMobileMenuOpen } = useContext(HeaderContext);

  const dispatch = useDispatch();
  const { user, mobileMenuOpen } = useSelector((state: RootState) => ({
    user: state.user.user,
    mobileMenuOpen: state.header.mobileMenuOpen,
  }));
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const listener = () => {
      dispatch(setMobileMenuOpen(false));
    };

    if (mobileMenuOpen) {
      window.addEventListener('click', listener);
    } else {
      window.removeEventListener('click', listener);
    }

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [dispatch, mobileMenuOpen]);

  const handleManageConsole = useCallback(() => {
    if (user) {
      if (user.Type === 'ADVERTISER') {
        history.push('/ad');
      } else {
        history.push('/pub');
      }
    }
  }, [history, user]);

  return (
    <>
      {/* 모바일 메뉴 */}
      <MobileGnb className={mobileMenuOpen ? 'open' : ''}>
        <MobileGnbTop>
          <a href="/">
            <MobileGnbLogoImage
              src={require('~/assets/images/header__logo.svg').default}
              width="100%"
              height="100%"
              alt="ZENAAD"
            />
            {customerTypeName && <b>{customerTypeName}</b>}
          </a>
          {user && (
            <span className="cursor__pointer" onClick={handleSignout}>
              <FormattedMessage id="gnb.signout" />
            </span>
          )}
        </MobileGnbTop>
        {children}
        <MobileGnbBottom>
          {user ? (
            <ul>
              <Link
                to={user.Type === 'ADVERTISER' ? '/ad-my-page' : '/pub-my-page'}
              >
                <li>
                  <FormattedMessage id="gnb.mypage" />
                </li>
              </Link>
              {location.pathname === '/' ||
              location.pathname.startsWith('/home') ? (
                <button className="btn" onClick={handleManageConsole}>
                  <div className="btn__txt">
                    <FormattedMessage id="gnb.manageConsole" />
                  </div>
                </button>
              ) : null}
              {/* {location.pathname === '/' && (
                <button className="btn" onClick={handleManageConsole}>
                  <div className="btn__txt">
                    <FormattedMessage id="gnb.manageConsole" />
                  </div>
                </button>
              )} */}
            </ul>
          ) : (
            <Link to="signin" className="btn">
              <FormattedMessage id="gnb.signin" />
            </Link>
          )}
        </MobileGnbBottom>
      </MobileGnb>

      {/* 모바일 메뉴 bg */}
      <MobileGnbBg
        className={mobileMenuOpen ? 'open' : ''}
        onClick={() => dispatch(setMobileMenuOpen(false))}
      />
    </>
  );
}

export default HeaderMobile;
