import React from 'react';

import { FormattedMessage } from 'react-intl';

import Hint from '~/components/common/hint';
import { OverlayTopWrap } from '~/components/elements/overlay';

interface IDetailTopProps {
  product: string;
  registTime: string;
  updateTime: string;
}

function DetailTop({ product, registTime, updateTime }: IDetailTopProps) {
  return (
    <OverlayTopWrap>
      <h4 className="overlay__title m_tit">
        <FormattedMessage id="pub.inven.detail" />
      </h4>
      <ul className="overlay__top--detail">
        <li>
          <h5 className="xs_tit">
            <div className="">
              <FormattedMessage id="pub.inven.invenType" />
            </div>
          </h5>
          <span className="m_txt">
            <FormattedMessage id={`pub.inven.${product.toLowerCase()}`} />
          </span>
          <Hint
            title={`hint.${product.toLowerCase()}`}
            content={`hint.${product.toLowerCase()}.desc`}
          />
        </li>
        <li>
          <h5 className=" xs_tit">
            <FormattedMessage id="common.registTime" />
          </h5>
          <span className="m_txt">{registTime}</span>
        </li>
        <li>
          <h5 className=" xs_tit">
            <FormattedMessage id="common.updateTime" />
          </h5>
          <span className="m_txt">{updateTime}</span>
        </li>
      </ul>
    </OverlayTopWrap>
  );
}

export default DetailTop;
