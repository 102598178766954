import { useEffect } from 'react';

function HideScrollBar() {
  useEffect(() => {
    if (!document.body) return;
    document.body.style.overflowY = 'hidden';

    return () => {
      if (!document.body) return;
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return null;
}

export default HideScrollBar;
