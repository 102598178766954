import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { emailPattern } from '~/libs/regexp';

import SignupAgree from './signup-agree';
import SignupForm from './signup-form';

function Signup() {
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [type, setType] = useState<string>('ADVERTISER');
  const [agree, setAgree] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = queryString.parse(location.search);
    const { email: queryEmail, token: queryToken } = query;
    if (email === undefined || token === undefined) {
      history.push('/');
    }

    if (!emailPattern.test(queryEmail as string)) {
      history.push('/');
    }

    setEmail(queryEmail as string);
    setToken(queryToken as string);
  }, [email, token, history, location.search]);

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
  };

  const handleChangeAgree = (value: boolean) => {
    setAgree(value);
  };

  const handleNext = () => {
    if (!agree) {
      window.alert(formatMessage({ id: 'sugnup.agree.notAgree' }));
      return;
    }

    setIsAgree(true);
  };

  return (
    <>
      {!isAgree && (
        <SignupAgree
          type={type}
          handleChangeType={handleChangeType}
          agree={agree}
          handleChangeAgree={handleChangeAgree}
          handleNext={handleNext}
        />
      )}

      {isAgree && <SignupForm email={email} token={token} type={type} />}
    </>
  );
}

export default Signup;
