import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IAssetCashProps {
  cash: number;
}

function AssetCash({ cash }: IAssetCashProps) {
  const { formatMessage } = useIntl();

  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="admypage.asset.cash" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputContentWrap>
        <span className="m_txt">
          <NumberFormat value={cash} displayType="text" thousandSeparator />
          <i className="unit xs_tit">KRW</i>
          <i className="icon-chevron-right" />
        </span>
        <button
          className="btn contentBtn"
          onClick={() => {
            window.alert(formatMessage({ id: 'alert.ready' }));
          }}
        >
          <FormattedMessage id="admypage.asset.payment" />
        </button>
      </FormInputContentWrap>
    </FormInput>
  );
}

export default AssetCash;
