import React from 'react';

import { useSelector } from 'react-redux';
import { Flip, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import FullLoader from '~/components/common/full-loader';
import { RootState } from '~/modules';
import { ConsoleStyle } from '~/theme/console-style';
import { GlobalStyle } from '~/theme/global-style';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  /* .toast-container */
  // width: 500px;

  .Toastify__close-button {
    min-height: auto;
    width: auto;
  }

  @media only screen and (min-width: 480px) {
    width: 500px;
  }

  @media only screen and (max-width: 599px) {
    .Toastify__toast-body {
      font-size: 14px;
    }
  }
`;

function Core() {
  const isFullLoader = useSelector(
    (state: RootState) => state.core.isFullLoader,
  );

  return (
    <>
      <GlobalStyle />
      <ConsoleStyle />

      <StyledToastContainer
        transition={Flip}
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
      />

      {isFullLoader && <FullLoader />}
    </>
  );
}

export default Core;
