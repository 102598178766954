import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  OverlayContentTop,
  OverlayTopCreate,
  OverlayTopWrap,
} from '~/components/elements/overlay';

interface ICreateTabProps {
  currentTab: number;
}

function CreateTab({ currentTab }: ICreateTabProps) {
  return (
    <OverlayContentTop className="create">
      <OverlayTopWrap>
        <h4 className="overlay__title m_tit">
          <FormattedMessage id="ad.campaign.create" />
        </h4>
        <OverlayTopCreate className="xs_tit">
          <ul>
            <li className={currentTab === 1 ? 'active' : ''}>
              <FormattedMessage id="ad.campaign" />
            </li>
            <li className={currentTab === 2 ? 'active' : ''}>
              <FormattedMessage id="ad.campaign.app" />
            </li>
          </ul>
        </OverlayTopCreate>
      </OverlayTopWrap>
    </OverlayContentTop>
  );
}

export default CreateTab;
