import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useHeaderScroll } from '~/hooks/use-header-scroll';
import { clearHeader } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { setUser } from '~/modules/user';

import HeaderMobile from './header-mobile';
import HeaderWeb from './header-web';

interface IProps {
  customerTypeName?: string;
  webGnb: JSX.Element;
  mobileGnb: JSX.Element;
}

function Header({ customerTypeName, webGnb, mobileGnb }: IProps) {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useHeaderScroll();

  const handleSignout = () => {
    if (window.confirm(f({ id: 'confirm.signout' }))) {
      dispatch(setUser(null));

      const { storageKey } = config;
      storage.remove(storageKey.accessToken);
      storage.remove(storageKey.user);

      clearHeader();

      history.push('/');
    }
  };

  return (
    <>
      <HeaderWeb
        customerTypeName={customerTypeName}
        handleSignout={handleSignout}
      >
        {webGnb}
      </HeaderWeb>
      <HeaderMobile
        customerTypeName={customerTypeName}
        handleSignout={handleSignout}
      >
        {mobileGnb}
      </HeaderMobile>
    </>
  );
}

export default Header;
