import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const alertify = require('alertifyjs/build/css/alertify.min.css');
const alertifyDefault = require('alertifyjs/build/css/themes/default.min.css');
const reactDatePicker = require('react-datepicker/dist/react-datepicker.css');

const icon = require('./icon.css');

export const GlobalStyle = createGlobalStyle<any>`
  ${reset}
  // @import url("https://use.typekit.net/hja8qtr.css");
  // @import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700&display=swap");
  @import ${alertify};
  @import ${alertifyDefault};
  @import ${reactDatePicker};
  @import ${icon};

  .alertify-notifier {
    font-size: 90%;
  }

  .ajs-primary, .ajs-buttons {
    button {
      width: inherit;
    }
  }

  /* 공통 */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  li {
    list-style: none;
  }
  
  a {
    color: rgba(0, 0, 0, 0.6);
    font-weight: initial;
    text-decoration: none;
  }

  a:not(.btn):hover {
    color: rgba(0, 0, 0, 0.8);
  }
  
  a + li:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  
  a.active {
    font-weight: 600;
    color: #1b1c23;
  }

  a > i.icon-save_alt {
    display: block;
  }
  
  b {
    font-weight: 600;
  }

  html,
  body {
    width: 100%;
  }

  @media screen and (min-width: 1156px) {
    html {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1155px) {
    html {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 599px) {
    html {
      font-size: 8px;
    }
  }

  body {
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: "Pretendard", "cairo", "Noto Sans KR", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    min-height: 100vh;
    min-width: 320px;
  }
  
  button {
    font-family: "Pretendard", "cairo", "Noto Sans KR", sans-serif;
  }
  
  button[type="button"] {
    min-height: inherit;
  }

  button:disabled {
    background-color: #ebebeb;
    color: rgb(189, 189, 189);
    cursor: default;
  }
  
  button:disabled:hover {
    background-color: #ebebeb;
    color: rgb(189, 189, 189);
    cursor: default;
  }
  
  section {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  section:first-child {
    overflow: visible;
  }

  legend {
    display: none;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    font-weight: 400;
    color: #ffffff;
    background-color: #3f38d6;
    font-size: 16px;
    transition: 0.3s;
  }
  
  .btn:hover {
    background-color: #0c0698;
    transition: 0.3s ease-in;
  }

  .contentBtn {
    width: auto;
    height: auto;
    min-height: auto;
    padding: 8px 18px;
    border: 1px solid #322ac7;
    border-radius: 2px;
  }

  @media screen and (max-width: 1159px) {
    .contentBtn {
      width: auto;
      min-height: auto;
      padding: 8px 12px;
    }
  }

  .btnLine {
    background-color: #fff;
    border: 1px solid #d1d5d9;
    bottom: 0;
    color: #808991;
    font-weight: 400;
    letter-spacing: -0.1px;
    box-shadow: 0px 2px 2px 0 rgba(216, 222, 241, 0.3);
  }
  
  .btnLine:hover {
    background-color: rgba(216, 222, 241, 0.3);
    transition: 0.3s ease-in;
  }
  
  .btnLine i {
    color: #808991;
  }
  
  .btnClear {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #aaa;
  }
  
  .btnClear:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transition: 0.3s ease-in;
  }
  
  .btnLight {
    background-color: #f3f6fd;
    color: #3f38d6;
    border: 1px solid rgba(63, 56, 214, 0.05) !important;
  }
  
  .btnLight:hover {
    background-color: #d7e2fc;
    transition: 0.3s ease-in;
  }
  
  .btn__group {
    display: flex;
  }
  
  .btn__group .btn {
    margin-left: 0;
  }
  
  .btn__group .btn:first-child {
    margin-right: 0.3rem;
  }

  .btn__txt {
    height: 17px;
    line-height: 18px;
  }
  
  /* 버튼 안 텍스트 leading 영역 없애기용 */
  i + .btn__txt {
    padding-left: 3px;
    height: 17px;
    line-height: 18px;
    white-space: nowrap;
    font-size: 16px; 
  }
  
  @media screen and (max-width: 1155px) {
    i + .btn__txt {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .text__left {
    text-align: left;
  }
  
  .text__right {
    text-align: right;
  }
  
  .text__center {
    text-align: center;
  }
  
  [class^="icon-chevron-"],
  [class*=" icon-chevron-"] {
    font-size: 16px !important;
  }
  
  .cursor__default {
    cursor: inherit;
  }

  .cursor__pointer {
    cursor: pointer;
  }
  
  .display__none {
    display: none !important;
  }
  
  .display__block {
    display: block;
  }
  /* end 공통 */

  /* 제목 폰트 */
  .l_tit {
    font-size: 2.4rem;
    font-weight: 400;
    color: #1b1c23;
    letter-spacing: -0.02em;
  }

  .m_tit {
    font-size: 1.8rem;
    font-weight: 300;
    color: #1b1c23;
  }

  .ms_tit {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1b1c23;
  }

  .s_tit {
    font-size: 0.9rem;
    font-weight: 600;
    color: #1b1c23;
  }

  .xs_tit {
    font-size: 0.8rem;
    font-weight: 600;
    color: #1b1c23;
  }
  /* end 제목 폰트 */

  /* 본문 폰트 */
  .xl_txt {
    font-size: 1.05rem;
    font-weight: 300;
    color: #1b1c23;
  }

  .l_txt {
    font-size: 0.9rem;
    font-weight: 300;
    color: #1b1c23;
  }

  .m_txt {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  .s_txt {
    font-size: 0.8rem;
    font-weight: 400;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
  }

  .xs_txt {
    font-size: 0.7rem;
    font-weight: 300;
    color: #1b1c23;
  }

  @media screen and (max-width: 599px) {
    /* 모바일 제목 폰트 */
    .l_tit {
      font-size: 3rem;
    }
  
    .m_tit {
      font-size: 2.625rem;
    }

    .ms_tit {
      font-size: 2rem;
    }
  
    .s_tit {
      font-size: 1.75rem;
    }
  
    .xs_tit {
      font-size: 1.75rem;
    }
    /* end 모바일 제목 폰트 */
  
    /* 모바일 본문 폰트 */
    .xl_txt {
      font-size: 2rem;
    }
  
    .l_txt {
      font-size: 1.75rem;
    }
  
    .m_txt {
      font-size: 1.75rem;
    }
  
    .s_txt {
      font-size: 1.75rem;
      font-weight: 400;
    }
  
    .xs_txt {
      font-size: 1.625rem;
    }
    /* end 모바일 본문 폰트 */
  }

  /* 모달창 */
  .modal__container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1999;
  }

  .modal__box {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    padding: 2.5rem;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    transform: translate(-50%, -50%);
    overflow: auto;
  }

  .modal__box.medium {
    width: 500px;
    height: auto;
  }

  .modal__box.small {
    width: 400px;
    height: auto;
  }

  .modal__box h4 {
    padding-bottom: 0.8rem;
  }

  .modal__box .modal__box--content {
    padding-bottom: 2.5rem;
  }

  .modal__box i {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #333333;
    cursor: pointer;
  }

  .modal__button {
    width: 100%;
    max-height: 50px;
    cursor: pointer;
  }

  @media screen and (max-width: 599px) {
    .modal__box.small {
      width: 80%;
      padding: 3rem;
    }

    .modal__box.mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: initial;
      bottom: -100%;
      transform: translate(-50%, 0);
      width: 100%;
      height: 40vh;
      padding: 3.5rem 2.5rem 2.5rem;
      border-radius: 1rem 1rem 0 0;
      min-height: 320px;
    }

    .modal__box.mobile.active{
      bottom: 0;
      transition:.3s ease;
    }

    .modal__box.mobile i {
      top: 2rem;
      right: 2rem;
    }
  }
  /* end 모달창 */

  form,
  input,
  textarea,
  select,
  button,
  option {
    width: 100%;
    height: 100%;
    font-size: 16px;
    letter-spacing: 0.5px;
    border: 0;
    background-color: transparent;
    color: #666;
    font-family: "Pretendard", "cairo", "Noto Sans KR", sans-serif;
  }

  button {
    min-height: 50px;
    background-color: #3f38d6;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 16px !important;
    cursor: pointer;
    line-height: 1.5;
  }

  button.small {
    width: auto;
    min-height: auto;
  }

  input,
  select {
    position: relative;
    height: 100%;
  }

  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-weight: 300;
  }

  /* 라디오버튼 */
  input[type="radio"],
  input[type="radio"]:checked {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  input[type="radio"] + label {
    position: relative;
    display: block;
    transform: translate(0);
    left: 0;
    padding: 13px;
    padding-left: 45px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 100%;
    box-sizing: border-box;
  }

  input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 5px solid #3f38d6;
    box-sizing: border-box;
  }

  /* end 라디오버튼 */

  /* 체크 박스 */
  input[type="checkbox"] {
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0 none;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  input[type="checkbox"] + label {
    position: relative;
    left: 0;
    width: 100%;
    height: 57px;
    cursor: pointer;
    line-height: 57px;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  input[type="checkbox"] + label i {
    position: absolute;
    top: 0px;
    left: 2px;
    font-size: 16px;
    color: #ffffff;
    display: none;
  }

  input[type="checkbox"]:checked + label i {
    display: block;
  }

  input[type="checkbox"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked + label:before {
    content: "";
    color: #fff;
    font-weight: 700;
    border: 1px solid #3f38d6;
    background: #3f38d6;
  }

  /* end 체크박스 */

  /* label floating */
  input:not([type="radio"]):not([type="checkbox"]),
  textarea {
    padding: 20px 15px 5px 15px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    input:not([type="radio"]):not([type="checkbox"]) + label, textarea + label { 
      top: -1px !important;
      transform: translate(0, 0.25rem);
      font-size: 12px;
      transition: 0.15s ease-in;
      opacity: 0.5;
    }
  }

  select {
    padding: 15px;
    padding-right: 40px !important;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 1;
  }

  input.input__search {
    height: 100%;
    padding-right: 40px !important;
  }

  select + i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: #3f38d6;
    z-index: 0;
  }

  select::-ms-expand {
    display: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:not([type="radio"]):not([type="checkbox"]):not(:placeholder-shown)
    + label,
  input:not([type="radio"]):not([type="checkbox"]):focus + label,
  textarea:not(:placeholder-shown) + label,
  textarea:focus + label {
    top: -1px;
    transform: translate(0, 0.25rem);
    font-size: 12px;
    transition: 0.15s ease-in;
    opacity: 0.5;
  }

  // input:read-only {
  //   padding: 0;
  // }
  
  // input:read-only + label {
  //   width: 100%;
  //   height: 50px;
  //   line-height: 50px;
  //   left: 0;
  //   top: -5px !important;
  //   padding-left: 15px;
  //   background-color: #d4d4d4;
  //   font-size: 16px !important;
  // }

  textarea + label {
    top: 20px;
  }
  /* end label floating */

  .form__input--40 {
    width: calc(40% - 0.5rem);
  }

  .form__input--50 {
    width: calc(50% - 0.5rem);
  }

  .form__input--60 {
    width: calc(60% - 0.5rem);
  }

  @media screen and (max-width: 599px) {
    form,
    input,
    textarea,
    select,
    button {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 399px) {
    form,
    input,
    textarea,
    select,
    button {
      font-size: 14px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  @media screen and (max-width: 599px) {
    .react-datepicker-wrapper {
      max-width: 100%;
    }
  
    .react-datepicker__input-container {
      width: 100%;
    }

    .react-datepicker__input-container input {
      max-width: 100%;
    }

    .react-datepicker {
      font-size: 1.4rem;
   }
  }

  .react-datepicker__portal {
    @media screen and (max-width: 599px) {    
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 3.5rem !important;
        line-height: 3.5rem !important;
      }
    }
   
    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: 2.52rem !important;
    }
  }

  @media screen and (max-width: 599px) {    
    .react-datepicker button[type="button"] {
       width: 10px;
       margin:0;
    }
  }
`;
