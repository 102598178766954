import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { accountAPI } from '~/api';
import {
  FormInput,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { defaultAxios } from '~/libs/axios';

interface IProps {
  title: string;
  required: boolean;
  matURL: string;
  folder: string;
  filename: string | null;
  handleFile: (file: any) => void;
}

function Upload({
  title,
  required,
  matURL,
  folder,
  filename,
  handleFile,
}: IProps) {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>('');

  const { formatMessage } = useIntl();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
    maxSize: 4194304,
    onDrop: (acceptedFiles) => {},
    onDropAccepted: async (acceptedFiles) => {
      const formData = new FormData();
      formData.append('folder', folder);
      acceptedFiles.forEach((f) => {
        formData.append('uploadFiles', f);
      });

      const { data } = await defaultAxios.post(
        accountAPI.s3getSignedUrl,
        formData,
      );

      const { urls } = data;
      const { uploadURL, filename: fname } = urls[0];

      const acceptedFile = acceptedFiles[0];
      setFile(acceptedFile);
      setPreview(URL.createObjectURL(acceptedFile));

      handleFile({
        uploadURL,
        file: acceptedFile,
        filename: fname,
      });
    },
    onDropRejected: (rejectedFiles) => {
      for (const item of rejectedFiles) {
        if (item.size > 4194304) {
          window.alert(formatMessage({ id: 'alert.fileSize' }, { size: 4 }));
          break;
        }
      }
    },
  });

  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          {title}
          {required && <i className="required" />}
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputField
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        {/* 
        {files.length > 0 &&
          files.map((file, index) => (
            <img key={index} src={file.preview} alt="" />
          ))} */}

        {preview !== '' ? (
          <img src={preview} alt="" />
        ) : (
          filename !== '' && (
            <img src={`${matURL}/${folder}/${filename}`} alt="" />
          )
        )}

        <input {...getInputProps()} />

        {isDragActive && file && <div className="preview--blur" />}

        {!file && !filename && (
          <div className="form__preview">
            <i className="icon-image" />
            <div className="btn__txt">
              {isDragActive
                ? 'Drop image here'
                : `Drag 'n' drop some files here, or click to select files`}
            </div>
          </div>
        )}

        {/* <div className="form__preview">
          <i className="icon-image" />
          <div className="btn__txt">
            {isDragActive
              ? 'Drop image here'
              : `Drag 'n' drop some files here, or click to select files`}
          </div>
        </div> */}
      </FormInputField>
      {/* {filesError === 1 && (
        <span className="warning__message s_txt">
          <FormattedMessage id="validate.required" />
        </span>
      )} */}
    </FormInput>
  );
}

export default Upload;
