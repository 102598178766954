import React from 'react';

import Footer from './footer';
import Header from './header';

interface IProps {
  customerTypeName?: string;
  webGnb: JSX.Element;
  mobileGnb: JSX.Element;
  mainClassName?: string;
  children: React.ReactNode;
}

function HomeLayout({
  customerTypeName,
  webGnb,
  mobileGnb,
  mainClassName,
  children,
}: IProps) {
  return (
    <>
      <Header
        customerTypeName={customerTypeName}
        webGnb={webGnb}
        mobileGnb={mobileGnb}
      />
      <main className={mainClassName}>{children}</main>
      <Footer />
    </>
  );
}

export default HomeLayout;
