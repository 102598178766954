import { version } from './helper';

const list = `api/${version.v1}/ad/campaign/`;

const listAll = `api/${version.v1}/ad/campaign/list-all`;

const get = `api/${version.v1}/ad/campaign/get`;

const add = `api/${version.v1}/ad/campaign/add`;

const modify = `api/${version.v1}/ad/campaign/modify`;

export default {
  list,
  listAll,
  get,
  add,
  modify,
};
