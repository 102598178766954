import styled from 'styled-components';

export const ListSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding: 6rem 0;
  width: 100%;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
  }

  h3 {
    flex: 1;
    margin-right: 1rem;
  }

  @media screen and (max-width: 599px) {
    display: inline-block;
    padding: 4rem 0;

    h3 {
      margin-bottom: 4rem;
    }
  }
`;

export const ListSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex: 2;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const ListSectionItemText = styled.div`
  position: relative;
  margin-bottom: 2rem;

  h4.s_tit {
    display: flex;
    overflow: hidden;
  }

  i {
    position: relative;
    left: 0.5rem;
    transform: translateY(400%);
    transition: 0.3s ease;
    color: #1b1c23;
  }
`;

export const ListSectionItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);

  &:hover {
    ${ListSectionItemText} {
      i {
        transform: translateY(0%);
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(n + 3) {
    padding-top: 5rem;
  }

  @media screen and (max-width: 599px) {
    width: calc(50% - 8px);

    &:nth-child(n + 3) {
      padding-top: 6rem;
    }
  }
`;

export const ListSectionItemImg = styled.div`
  position: relative;
  width: 100%;
  padding-top: 51%;
  background-color: #fafafa;
  border: 1px solid #ebebeb;
  margin-bottom: 1rem;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  > span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }
  }
`;

export const ListSectionItemFile = styled.div`
  margin-top: auto;
`;

export const ListSectionFile = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 60%;
  padding: 0.25rem 0;

  i {
    cursor: pointer;
    color: #1b1c23;
  }

  @media screen and (max-width: 599px) {
    max-width: 70%;
  }
`;

// .listSection__line {
//   width: 1156px;
//   height: 1px;
//   background-color: #ebebeb;
//   margin: 2rem auto;
// }

// @media screen and (max-width: 1155px) {
//   .listSection__line {
//     width: calc(100% - 48px);
//     margin: 2rem 24px;
//   }
// }
// @media screen and (max-width: 599px) {
//   .listSection__line {
//     width: calc(100% - 32px);
//     margin: 2rem 16px;
//   }
// }
export const ListSectionLine = styled.div`
  width: 1156px;
  height: 1px;
  background-color: #ebebeb;
  margin: 2rem auto;

  @media screen and (max-width: 1155px) {
    width: calc(100% - 48px);
    margin: 2rem 24px;
  }

  @media screen and (max-width: 599px) {
    width: calc(100% - 32px);
    margin: 2rem 16px;
  }
`;
