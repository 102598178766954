import React from 'react';

import { FormattedMessage } from 'react-intl';

import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { ICustomer } from '~/models/account/my-page';

import AddInfoMobile from './add-info01-mobile';

interface IAddInfoProps {
  customer: ICustomer;
}

function AddInfo({ customer }: IAddInfoProps) {
  const { Email, CountryCode, MobileNumber } = customer;

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="mypage.addinfo" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <AddInfoMobile
              email={Email}
              countryCode={CountryCode}
              mobileNumber={MobileNumber}
            />
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default AddInfo;
