import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Content } from '~/components/elements/content';
import {
  ListSection,
  ListSectionContainer,
  ListSectionItem,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements/list-section';

function ResourceSuccess() {
  return (
    <>
      <Content id="success">
        <ListSection>
          <h3 className="m_tit">
            <FormattedMessage id="resource.success" />
          </h3>
          <ListSectionContainer>
            <ListSectionItem>
              <ListSectionItemImg>
                <img src={require('~/assets/images/p3_6.png').default} alt="" />
              </ListSectionItemImg>
              <ListSectionItemText>
                <h4 className="s_tit">
                  <FormattedMessage id="resource.ready" />
                </h4>
                <p className="s_txt">
                  <FormattedMessage id="resource.update" />
                </p>
              </ListSectionItemText>
            </ListSectionItem>
          </ListSectionContainer>
        </ListSection>
      </Content>
      <Content />
    </>
  );
}

export default ResourceSuccess;
