import React from 'react';

import { CSVLink } from 'react-csv';

interface IReportAdExportProps {
  csv: any;
}

function ReportAdExport({ csv }: IReportAdExportProps) {
  if (!csv) {
    return null;
  }

  return (
    <CSVLink
      data={csv}
      filename="Advertiser-Ad.csv"
      className="btn contentBtn btnLine"
    >
      <i className="icon-save_alt" />
      <div className="btn__txt">Export</div>
    </CSVLink>
  );
}

export default ReportAdExport;
