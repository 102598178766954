import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import Hint from '~/components/common/hint';
import {
  OverlayContentBottom,
  OverlayItem,
  OverlayItemWrap,
} from '~/components/elements/overlay';
import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IPubMyRevenueDetailsItem } from '~/models/account/my-page';

const TableContainer = styled(DefaultTableContainer as any)`
  @media screen and (max-width: 599px) {
    margin-top: 0rem;
  }
`;

interface IRevenueDetailsListProps {
  list: IPubMyRevenueDetailsItem[];
}

interface IRevenueDetailsListItemProps {
  item: IPubMyRevenueDetailsItem;
}

function RevenueDetailsList({ list }: IRevenueDetailsListProps) {
  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItem>
          <TableContainer>
            <table className="xs_txt">
              <thead>
                <tr>
                  <th className="border__right">
                    <FormattedMessage id="pubmypage.revenueGive.revenueDetails.month" />
                  </th>
                  <th className="text__right">
                    <FormattedMessage id="pubmypage.revenueGive.revenueDetails.revenue" />
                    (KRW)
                    <Hint title="hint.revenue" content="hint.revenue.desc" />
                  </th>
                  <th className="text__right">
                    <FormattedMessage id="pubmypage.revenueGive.revenueDetails.eCPM" />
                    (KRW)
                    <Hint title="hint.ecpm" content="hint.ecpm.desc" />
                  </th>
                  <th className="text__right">
                    <FormattedMessage id="pubmypage.revenueGive.revenueDetails.impressions" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  list.map((item, index) => (
                    <RevenueDetailsItem key={index} item={item} />
                  ))}
              </tbody>
            </table>
          </TableContainer>
        </OverlayItem>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

function RevenueDetailsItem({ item }: IRevenueDetailsListItemProps) {
  const { RegistTime, Revenue, eCPM, Impressions } = item;

  return (
    <tr>
      <th className="border__right">{RegistTime}</th>
      <td className="text__right table__important">
        <NumberFormat value={Revenue} displayType="text" thousandSeparator />
      </td>
      <td className="text__right table__important">
        <NumberFormat value={eCPM} displayType="text" thousandSeparator />
      </td>
      <td className="text__right table__important">
        <NumberFormat
          value={Impressions}
          displayType="text"
          thousandSeparator
        />
      </td>
    </tr>
  );
}

export default RevenueDetailsList;
