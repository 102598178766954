import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Hint from '~/components/common/hint';
import { OverlayTopWrap } from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';

interface IDetailTopProps {
  product: string;
  billingType: string;
  status: string;
  registTime: string;
  updateTime: string;
  handleStart: () => Promise<void>;
  handleStop: () => Promise<void>;
}

const Status = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  padding: 2px 8px 0 10px;
  top: -2px;
  align-items: center;

  i {
    display: inline-block;
    position: relative;
    top: -2px;
    color: #808991 !important;
    vertical-align: middle;
    z-index: 1;
    font-size: 18px;

    &.icon-play_arrow {
      color: #3f38d6 !important;
    }
  }
`;

function DetailTop({
  product,
  billingType,
  status,
  registTime,
  updateTime,
  handleStart,
  handleStop,
}: IDetailTopProps) {
  return (
    <OverlayTopWrap>
      <h4 className="overlay__title m_tit">
        <FormattedMessage id="ad.ad.detail" />
      </h4>
      <ul className="overlay__top--detail">
        <li>
          <h5 className="xs_tit">
            <div className="">
              <FormattedMessage id="ad.ad.product" />
            </div>
          </h5>
          <span className="m_txt">
            <FormattedMessage id={`ad.ad.${product.toLowerCase()}`} />
          </span>
          <Hint
            title={`hint.${product.toLocaleLowerCase()}`}
            content={`hint.${product.toLocaleLowerCase()}.desc`}
          />
        </li>
        <li>
          <h5 className=" xs_tit">
            <FormattedMessage id="ad.ad.billingType" />
          </h5>
          <span className="m_txt">{billingType}</span>
          <Hint
            title={`hint.${billingType.toLocaleLowerCase()}`}
            content={`hint.${billingType.toLocaleLowerCase()}.desc`}
          />
        </li>
        <li>
          <h5 className=" xs_tit">
            <FormattedMessage id="common.registTime" />
          </h5>
          <span className="m_txt">{registTime}</span>
        </li>
        <li>
          <h5 className=" xs_tit">
            <FormattedMessage id="common.updateTime" />
          </h5>
          <span className="m_txt">{updateTime}</span>
        </li>
        <li>
          <h5 className="xs_tit">
            <FormattedMessage id="ad.ad.status" />
          </h5>
          {status ===
          adtier0.PROMOTION_STATUS[adtier0.PROMOTION_STATUS.INPROGRESS] ? (
            <Status className="btnLine" onClick={handleStop}>
              <span className="m_txt">
                <FormattedMessage id={`ad.ad.${status.toLowerCase()}`} />
              </span>
              <i className="icon-pause" />
            </Status>
          ) : (
            <Status className="btnLine" onClick={handleStart}>
              <span className="m_txt">
                <FormattedMessage id={`ad.ad.${status.toLowerCase()}`} />
              </span>
              <i className="icon-play_arrow" />
            </Status>
          )}
        </li>
      </ul>
    </OverlayTopWrap>
  );
}

export default DetailTop;
