import React, { useState } from 'react';

import { commonAPI } from '~/api';
import LineChart from '~/components/chart/line-chart';
import { defaultAxios } from '~/libs/axios';
import { dataURItoBlob } from '~/libs/utils';

interface IReportUserChartProps {
  chartData: any;
}

const options = {
  chartArea: { top: '10%', width: '95%', height: '75%' },
  curveType: 'none',
  colors: ['#62DEE9', '#3F38D6'],
  legend: { position: 'bottom', alignment: 'start', style: 'cursor: pointer' },
  pointSize: `8`,
  axisTitlesPosition: 'in',
  hAxis: {
    showTextEvery: '1',
    textPosition: 'out',
    textStyle: {
      fontSize: `14`,
      color: '#666666',
    },
  },
  vAxis: {
    textStyle: {
      fontSize: `14`,
      color: '#666666',
    },
    gridlines: {
      color: '#ebebeb',
    },
  },
};

function ReportUserChart({ chartData }: IReportUserChartProps) {
  const [imageURI, setImageURI] = useState<string>('');

  const handleDownload = async () => {
    const blob = dataURItoBlob(imageURI);
    const filename = 'user-report.png';

    const { data } = await defaultAxios.post(commonAPI.s3getSignedUrl);
    await defaultAxios.put(data.uploadURL, blob, {
      headers: {
        'Content-Type': 'image/png',
      },
    });

    const image = data.uploadURL.split('?')[0];

    saveAs(image, filename);
  };

  const handleImageURI = (uri: string) => {
    setImageURI(uri);
  };

  return (
    <>
      <button className="btn contentBtn btnLine" onClick={handleDownload}>
        <i className="icon-save_alt" />
        <div className="btn__txt">Download</div>
      </button>

      <LineChart
        chartData={chartData}
        options={options}
        handleImageURI={handleImageURI}
      />
    </>
  );
}

export default ReportUserChart;
