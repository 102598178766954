import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

// import { defaultAxios } from '~/libs/axios';

interface IProps {
  children: React.ReactNode;
}

function AppWrapper({ children }: IProps) {
  const location = useLocation();

  useEffect(() => {
    // const versionCheck = async () => {
    //   const { href } = window.location;
    //   const orgin = window.location.origin;

    //   const arrHref = href.split('/');
    //   const nowVersion = arrHref[3];

    //   const { data } = await defaultAxios.get(`${orgin}/version.json`);
    //   const newVersion = data.version;

    //   if (nowVersion !== newVersion) {
    //     window.alert('새로운 버전이 있습니다.');
    //     window.location.href = '/';
    //   }
    // };

    if (process.env.NODE_ENV !== 'development') {
      // versionCheck();
    }
  }, [location.pathname]);

  useEffect(() => {
    document.body.style.overflowY = 'auto';
    document.body.style.width = '';
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
}

export default AppWrapper;
