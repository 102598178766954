import React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import {
  Content,
  ContentSubContainer,
  ContentSubText,
} from '~/components/elements';

function SdkIntro() {
  return (
    <Content className="first">
      <ContentSubContainer>
        <ContentSubText>
          <span className="xs_tit">SDK</span>
          <h1 className="l_tit">
            <FormattedMessage id="sdk.title" />
          </h1>
          <h5 className="m_txt">
            <FormattedHTMLMessage id="sdk.titleDesc" />
          </h5>
        </ContentSubText>
        <img src={require('~/assets/images/p5_main.png').default} alt="" />
      </ContentSubContainer>
    </Content>
  );
}

export default SdkIntro;
