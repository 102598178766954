import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { ConsoleContent } from '~/components/elements/console-content';
import { StateContainer } from '~/components/elements/state';
import { IResAdHome } from '~/models/ad/home';

interface IProps {
  data: IResAdHome;
}

function HomeNetwork({ data: { state } }: IProps) {
  return (
    <>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="ad.home.network" />
        </h1>
        <StateContainer>
          <li>
            <p className="m_txt">
              <FormattedMessage id="ad.home.impression" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={state.Impressions}
                  displayType="text"
                  thousandSeparator
                />
              </b>
            </span>
          </li>
          <li>
            <p className="m_txt">
              <FormattedMessage id="ad.home.click" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={state.Clicks}
                  displayType="text"
                  thousandSeparator
                />
              </b>
            </span>
          </li>
          <li>
            <p className="m_txt">
              <FormattedMessage id="ad.home.ctr" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={state.CTR}
                  displayType="text"
                  thousandSeparator
                  suffix="%"
                />
              </b>
            </span>
          </li>
        </StateContainer>
      </ConsoleContent>
    </>
  );
}

export default HomeNetwork;
