/* tslint:disable */
/* eslint-disable */
const debug = (...message: any): void => {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_CLIENT_ENV === 'hwkim' ||
    process.env.REACT_APP_CLIENT_ENV === 'dev' ||
    process.env.REACT_APP_CLIENT_ENV === 'stage' ||
    process.env.REACT_APP_CLIENT_ENV === 'sample'
  ) {
    console.debug('[DEBUG]', ...message);
  }
};

const info = (...message: any): void => {
  console.info('[INFO]', ...message);
};

const warning = (...message: any): void => {
  console.warn('[WARN]', ...message);
};

const error = (...message: any): void => {
  console.error('[ERROR]', ...message);
};

export const logger = {
  debug,
  info,
  warning,
  error
};
