import React from 'react';

import { Helmet } from 'react-helmet';

import Start from '~/components/home/start';
import { MobileGnbHome } from '~/components/layout/header-mobile';
import { GnbHome } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeStartPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbHome />} mobileGnb={<MobileGnbHome />}>
        <Start />
      </Layout>
    </>
  );
}

export default HomeStartPage;
