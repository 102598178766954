import styled from 'styled-components';

import { FormInputContainer, FormInputField } from './form';

export const NoFloating = styled.div`
  input:not([type='radio']):not([type='checkbox']),
  textarea,
  select {
    padding: 10px;
    min-height: auto;
    resize: none;
  }

  input:not([type='radio']):not([type='checkbox']),
  textarea {
    + label {
      display: none;
    }
  }

  select {
    ~ label {
      display: none;
    }
  }

  ${FormInputContainer} {
    padding-bottom: 0;
  }

  ${FormInputField} {
    // height: 100%;
    min-height: 42px;
    border-color: #d1d5d9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;

    > i:not(.icon-calendar_today):not(.icon-x) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      padding: 10px;
      color: #3f38d6;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 599px) {
    ${FormInputField} {
      min-height: 38px !important;
    }

    flex: 1 0 auto;
  }
`;
