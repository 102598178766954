import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ErrorBoundary from '~/components/error/error-boundary';
import Core from '~/containers/base/core';
import { RootState } from '~/modules';
import {
  AdAdCreatePage,
  AdAdDetailPage,
  AdAdListPage,
  AdCampaignCreatePage,
  AdCampaignDetailPage,
  AdCampaignListPage,
  AdHomePage,
  AdMyPage,
  AdReportAdPage,
  AdReportCampaignPage,
  ChangePasswordPage,
  FailedAuthenticationPage,
  FindEmailPage,
  FindPasswordPage,
  HomeAppPage,
  HomeGamePage,
  HomeHelpPage,
  HomeMediationPage,
  HomePage,
  HomePublisherPage,
  HomeResourcePage,
  HomeSdkPage,
  HomeStartPage,
  PubAppCreatePage,
  PubAppDetailPage,
  PubAppListPage,
  PubHomePage,
  PubInventoryCreatePage,
  PubInventoryDetailPage,
  PubInventoryListPage,
  PubAppRewardListPage,
  PubAppRewardCreatePage,
  PubAppRewardDetailPage,
  PubMyPage,
  PubMyRevenueDocPage,
  PubMyRevenueDetailsPage,
  PubMyRevenueRequestPage,
  PubMyRevenueRequestListPage,
  PubReportAppPage,
  PubReportDayPage,
  PubReportUserPage,
  ResetPasswordPage,
  SigninPage,
  SignupEmailPage,
  SignupPage,
  SignupSuccessPage,
} from '~/pages';

import Error404 from './error/error-404';
import Error500 from './error/error-500';

function App() {
  const { userInitialized } = useSelector((state: RootState) => ({
    user: state.user.user,
    userInitialized: state.user.userInitialized,
  }));

  if (!userInitialized) {
    return null;
  }

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route path="/signin" component={SigninPage} />
          <Route path="/signup-email" component={SignupEmailPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/signup-success" component={SignupSuccessPage} />
          <Route path="/find-email" component={FindEmailPage} />
          <Route path="/find-password" component={FindPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route
            path="/failed-authentication"
            component={FailedAuthenticationPage}
          />

          <Route exact path="/" component={HomePage} />
          <Route path="/home-start" component={HomeStartPage} />
          <Route path="/home-resource" component={HomeResourcePage} />
          <Route path="/home-sdk" component={HomeSdkPage} />
          <Route path="/home-game" component={HomeGamePage} />
          <Route path="/home-app" component={HomeAppPage} />
          <Route path="/home-mediation" component={HomeMediationPage} />
          <Route path="/home-publisher" component={HomePublisherPage} />
          <Route path="/home-help" component={HomeHelpPage} />

          <Route path="/ad-my-page" component={AdMyPage} />

          <Route path="/pub-my-page" component={PubMyPage} />
          <Route
            path="/pub-my-page-revenue-details"
            component={PubMyRevenueDetailsPage}
          />
          <Route path="/pub-my-page-doc" component={PubMyRevenueDocPage} />
          <Route
            path="/pub-my-page-request"
            component={PubMyRevenueRequestPage}
          />
          <Route
            path="/pub-my-page-request-list"
            component={PubMyRevenueRequestListPage}
          />

          <Route path="/change-password" component={ChangePasswordPage} />

          <Route path="/ad" component={AdHomePage} />

          <Route
            exact
            path="/ad-campaign/:id"
            component={AdCampaignDetailPage}
          />
          <Route path="/ad-campaign" component={AdCampaignListPage} />
          <Route path="/ad-campaign-create" component={AdCampaignCreatePage} />

          <Route path="/ad-ad/:id" component={AdAdDetailPage} />
          <Route path="/ad-ad" component={AdAdListPage} />
          <Route path="/ad-ad-create" component={AdAdCreatePage} />

          <Route path="/ad-report-campaign" component={AdReportCampaignPage} />
          <Route path="/ad-report-ad" component={AdReportAdPage} />

          <Route path="/pub" component={PubHomePage} />

          <Route exact path="/pub-app/:id" component={PubAppDetailPage} />
          <Route path="/pub-app" component={PubAppListPage} />
          <Route path="/pub-app-create" component={PubAppCreatePage} />

          <Route
            exact
            path="/pub-inventory/:id"
            component={PubInventoryDetailPage}
          />
          <Route path="/pub-inventory" component={PubInventoryListPage} />
          <Route
            path="/pub-inventory-create"
            component={PubInventoryCreatePage}
          />

          <Route
            exact
            path="/pub-app-reward/:id"
            component={PubAppRewardDetailPage}
          />
          <Route path="/pub-app-reward" component={PubAppRewardListPage} />
          <Route
            path="/pub-app-reward-create"
            component={PubAppRewardCreatePage}
          />

          <Route path="/pub-report-day" component={PubReportDayPage} />
          <Route path="/pub-report-app" component={PubReportAppPage} />
          <Route path="/pub-report-user" component={PubReportUserPage} />

          <Route path="/error" component={Error500} />
          <Route component={Error404} />
        </Switch>
      </ErrorBoundary>

      <Core />
    </>
  );
}

export default App;
