import React from 'react';

import { Helmet } from 'react-helmet';

import Resource from '~/components/home/resource';
import { MobileGnbHome } from '~/components/layout/header-mobile';
import { GnbHome } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeResourcePage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbHome />} mobileGnb={<MobileGnbHome />}>
        <Resource />
      </Layout>
    </>
  );
}

export default HomeResourcePage;
