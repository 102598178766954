import React from 'react';

import { FormInput, FormInputField } from '~/components/elements/form';

interface IProps {
  formInputClass?: string;
  children: React.ReactNode;
  info?: string;
  error?: any;
}

function BasicInput({ formInputClass, children, info, error }: IProps) {
  return (
    <FormInput className={formInputClass || ''}>
      <FormInputField>{children}</FormInputField>
      {info && <span className="info__message s_txt">{info}</span>}
      {error && <span className="warning__message s_txt">{error.message}</span>}
    </FormInput>
  );
}

export default BasicInput;
