import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { accountAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { config } from '~/libs/config';
import { getPosibleSearchDate } from '~/libs/utils';
import {
  IResRevenueRequestList,
  IRevenueRequestListItem,
} from '~/models/account/my-page';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import {
  setSearchEndDate,
  setSearchSelectDay,
  setSearchStartDate,
} from '~/modules/user';

import RevenueRequestListFilter from './revenue-request-list01-filter';
import Tab from './tab';

function RevenueRequestList() {
  const [list, setList] = useState<IRevenueRequestListItem[] | null>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { searchSelectDay, searchStartDate, searchEndDate } = useSelector(
    (s: RootState) => s.user,
  );
  const history = useHistory();

  const [{ data, loading, error }, execute] = useAxios<IResRevenueRequestList>(
    {
      url: accountAPI.requestList,
      method: 'GET',
      params: {
        type: 1,
        startDate: searchStartDate,
        endDate: searchEndDate,
      },
    },
    { useCache: false },
  );

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.push('/pub-my-page');
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.list) {
        setList(data.list);
      }
    }
  }, [data]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!list) {
    return null;
  }

  const handleSearch = (day: number, sdate: any, edate: any) => {
    const valid = getPosibleSearchDate(sdate, edate, config.searchDay);
    if (!valid) {
      window.alert(
        formatMessage({ id: 'validate.searchDay' }, { days: config.searchDay }),
      );
      return;
    }

    dispatch(setSearchSelectDay(day));
    dispatch(setSearchStartDate(sdate));
    dispatch(setSearchEndDate(edate));

    execute({
      params: { type: 1, startDate: sdate, endDate: edate },
    });
  };

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="pubmypage.revenueGive" />
          </OverlayHeaderTitle>
        </OverlayHeader>

        <OverlayContent>
          <Tab currentTab={4} />

          <RevenueRequestListFilter
            searchSelectDay={searchSelectDay}
            searchStartDate={searchStartDate}
            searchEndDate={searchEndDate}
            handleSearch={handleSearch}
            list={list}
          />
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default RevenueRequestList;
