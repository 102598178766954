import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Switch, SwitchBtn, SwitchTxt } from '~/components/elements/switch';
import { IAdListItem } from '~/models/ad/ad';

interface IListTableItemProps {
  ad: IAdListItem;
  handleChangeStatus: (e: React.MouseEvent, proID: number) => Promise<void>;
  handleDetail: (proID: number) => void;
}

function ListTableItem({
  ad,
  handleChangeStatus,
  handleDetail,
}: IListTableItemProps) {
  return (
    <tr onClick={() => handleDetail(ad.ProID)}>
      <th className="text__center">
        {(ad.Status === 'FIRST' || ad.Status === 'WAITING') && (
          <i
            className="icon-play_arrow"
            onClick={(e: React.MouseEvent) => handleChangeStatus(e, ad.ProID)}
          />
        )}
        {ad.Status === 'INPROGRESS' && (
          <i
            className="icon-pause"
            onClick={(e: React.MouseEvent) => handleChangeStatus(e, ad.ProID)}
          />
        )}
        {ad.Status === 'COMPLETE' && '-'}
      </th>
      <th className="text__left table__important">
        <FormattedMessage id={`ad.ad.${ad.Status.toLowerCase()}`} />
      </th>
      <th className="text__left border__right table__title">
        <span>{ad.ProName}</span>
      </th>
      <td className="text__left">
        {ad.Platform === 'AOS' ? (
          <>
            <i className="icon-android" /> Android
          </>
        ) : (
          <>
            <i className="icon-appleinc" /> iOS
          </>
        )}
      </td>
      <td>
        <FormattedMessage id={`ad.ad.${ad.Product.toLowerCase()}`} />
      </td>
      <td>{ad.BillingType}</td>
      <td className="text__right table__important">
        {ad.Status === 'INPROGRESS' && ad.Winning === 1 && (
          <i className="icon-gavel" />
        )}
        <NumberFormat value={ad.AvgCost} displayType="text" thousandSeparator />
      </td>
      <th>
        <Switch
          className={ad.Status === 'INPROGRESS' ? '' : 'stop'}
          onClick={(e: React.MouseEvent) => handleChangeStatus(e, ad.ProID)}
        >
          <SwitchTxt className="switch__txt--start">Start</SwitchTxt>
          <SwitchTxt className="switch__txt--stop">Stop</SwitchTxt>
          <SwitchBtn>
            <i className="switch__btn--bar" />
          </SwitchBtn>
        </Switch>
      </th>
    </tr>
  );
}

export default ListTableItem;
