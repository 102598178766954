import styled from 'styled-components';

export const StateContainer = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  li {
    position: relative;
    height: auto;
    flex: 1;
    padding-right: 1rem;

    &:last-child {
      padding-right: 0;
    }
  }

  p {
    color: #6b88e5;
    line-height: 1;
  }

  span {
    &.krw {
      margin-left: 6px;
    }
  }

  .stats--detail {
    width: 100%;
    margin-top: 10px;
  }

  .stats--detail li {
    color: #808991;
    padding: 0;
    width: 104px;
    min-height: auto;
  }

  .stats--detail li span {
    display: inline-block;
    min-width: 40px;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;

    li {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      min-height: 47px;
    }

    // > li {
    //   flex-wrap: wrap;
    //   height: 100%;
    //   min-height: 85px;
    // }

    // .stats--detail li {
    //   margin-left: auto;
    // }

    .stats--detail li span:last-child {
      min-width: auto;
    }
  }
`;
