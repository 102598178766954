import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import ConfirmPassword from '~/components/common/confirm-password';
import {
  FormInput,
  FormInputContentWrap,
  FormInputField,
  FormInputGroup,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

interface IDefaultInfoNameProps {
  email: string;
  firstName: string;
  lastName: string;
}

interface IFormData {
  firstName: string;
  lastName: string;
}

function DefaultInfoName({
  email,
  firstName,
  lastName,
}: IDefaultInfoNameProps) {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, getValues, setValue, handleSubmit } =
    useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    setValue('firstName', firstName);
    setValue('lastName', lastName);
  }, [firstName, lastName, setValue]);

  const onSubmit = useCallback(async () => {
    setOpenConfirm(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    setOpenConfirm(false);

    const { firstName: fName, lastName: lName } = getValues();

    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post(accountAPI.changeName, {
        firstName: fName,
        lastName: lName,
      });

      dispatch(setIsFullLoader(false));

      const { error } = data;

      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert(formatMessage({ id: 'alert.modify' }));
        if (user) {
          const { storageKey } = config;

          const newUser = { ...user };
          newUser.FirstName = fName;
          newUser.LastName = lName;

          dispatch(setUser(newUser));

          storage.set(storageKey.user, newUser);
        }
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  }, [dispatch, formatMessage, getValues, user]);

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>기본 정보 - 이름 변경</legend>
          <FormInput>
            <FormInputTitleWrap>
              <FormInputTitleName className="m_txt">
                <FormattedMessage id="mypage.default.name" />
              </FormInputTitleName>
            </FormInputTitleWrap>
            <FormInputContentWrap>
              <FormInputGroup>
                <FormInputField className="form__input--50">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name*"
                    ref={register({
                      required: formatMessage({
                        id: 'validate.required',
                      }),
                    })}
                  />
                  <label htmlFor="firstName">First Name</label>
                </FormInputField>

                <FormInputField className="form__input--50">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name*"
                    ref={register({
                      required: formatMessage({
                        id: 'validate.required',
                      }),
                    })}
                  />
                  <label htmlFor="lastName">Last Name</label>
                </FormInputField>
              </FormInputGroup>
              <button className="btn contentBtn">
                <FormattedMessage id="common.modify" />
              </button>
            </FormInputContentWrap>
            {errors.firstName && (
              <span className="warning__message s_txt">
                {`First Name: ${errors.firstName.message}`}
              </span>
            )}
            {errors.lastName && (
              <span className="warning__message s_txt">
                {`Last Name: ${errors.lastName.message}`}
              </span>
            )}
          </FormInput>
        </fieldset>
      </form>

      {openConfirm && (
        <ConfirmPassword
          email={email}
          openModal={openConfirm}
          handleRequestClose={handleCloseConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default DefaultInfoName;
