import React from 'react';

import NumberFormat from 'react-number-format';

import { IReportByDailyApp, IReportByDailyMedia } from '~/models/pub/report';

import ReportDayListMedia from './report-day08-list-media';

interface IReportDayListAppProps {
  app: IReportByDailyApp;
  appID: string;
  medList: IReportByDailyMedia[] | null;
  handleClickApp: (value: string) => void;
}

function ReportDayListApp({
  app,
  appID,
  medList,
  handleClickApp,
}: IReportDayListAppProps) {
  const { AppID, AppName, Revenue, eCPM, Impressions, Installs, CTR } = app;

  return (
    <>
      <tr className="table__depth" onClick={() => handleClickApp(AppID)}>
        <th>
          <i className={appID === AppID ? 'depth__plus open' : 'depth__plus'} />
        </th>
        <th className="text__left border__right table__title">
          <span>{AppName}</span>
        </th>
        <td className="text__right table__important">
          <NumberFormat value={Revenue} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">
          <NumberFormat value={eCPM} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">
          <NumberFormat
            value={Impressions}
            displayType="text"
            thousandSeparator
          />
        </td>
        <td className="text__right">
          <NumberFormat value={Installs} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">{CTR}</td>
      </tr>
      {appID === AppID &&
        medList &&
        medList.map((med, index) => (
          <ReportDayListMedia key={index} med={med} />
        ))}
    </>
  );
}

export default ReportDayListApp;
