import React from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import My from '~/components/account/ad-my';
import { MobileGnbAd } from '~/components/layout/header-mobile';
import { GnbAd } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function AdMy() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout
        customerTypeName={intl.formatMessage({ id: 'logo.advertiser' })}
        webGnb={<GnbAd />}
        mobileGnb={<MobileGnbAd />}
        mainClassName="console"
      >
        <My />
      </Layout>
    </>
  );
}

export default AdMy;
