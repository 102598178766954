import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { adReportAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import {
  IReportCampaignListItem,
  IResReportCampaignList,
  IState,
} from '~/models/ad/report';
import { setIsFullLoader } from '~/modules/core';

import ReportCampaignState from './report-campaign01-state';
import ReportCampaignExport from './report-campaign02-export';
import ReportCampaignList from './report-campaign03-list';

function ReportCampiagn() {
  const [state, setState] = useState<IState>({
    impression: 0,
    click: 0,
    ctr: 0,
  });
  const [campaigns, setCampaigns] = useState<IReportCampaignListItem[] | null>(
    null,
  );
  const [csv, setCSV] = useState<any>(null);

  const [{ data, loading, error }] = useAxios<IResReportCampaignList>(
    {
      url: adReportAPI.campaign,
      method: 'GET',
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.campaigns) {
        let totalImpression = 0;
        let totalClick = 0;
        let avgCTR = 0;

        const csvData: any = [
          [
            formatMessage({ id: 'ad.report.campName' }),
            formatMessage({ id: 'ad.report.impression' }),
            formatMessage({ id: 'ad.report.click' }),
            formatMessage({ id: 'ad.report.ctrper' }),
            formatMessage({ id: 'ad.report.execute' }),
          ],
        ];

        for (const item of data.campaigns) {
          totalImpression += Number(item.Impression);
          totalClick += Number(item.Click);

          csvData.push([
            item.Name,
            item.Impression,
            item.Click,
            item.CTR,
            item.Execute,
          ]);
        }

        if (totalImpression > 0 && totalClick > 0) {
          avgCTR = (totalClick / totalImpression) * 100;
        }

        setState({
          impression: totalImpression,
          click: totalClick,
          ctr: avgCTR,
        });

        setCampaigns(data.campaigns);
        setCSV(csvData);
      }
    }
  }, [data, formatMessage]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="ad.report.campaign" />
        </h1>
        <ReportCampaignState state={state} />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        <ReportCampaignExport csv={csv} />
        <ReportCampaignList campaigns={campaigns} />
      </ConsoleContent>
    </>
  );
}

export default ReportCampiagn;
