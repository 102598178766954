import React, { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputContentWrap,
  FormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';

import Modal from './modal';

interface IPasswordConfirmProps {
  email: string;
  openModal: boolean;
  handleRequestClose: () => void;
  handleClose: () => void;
  handleConfirm: () => Promise<void>;
}

interface IFormData {
  pw: string;
}

function PasswordConfirm({
  email,
  openModal,
  handleRequestClose,
  handleClose,
  handleConfirm,
}: IPasswordConfirmProps) {
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, handleSubmit } = useForm<IFormData>();
  const { formatMessage } = useIntl();

  const { returnQuery } = usePrevLocation();

  const onSubmit = useCallback(
    async ({ pw }: IFormData) => {
      try {
        const { data } = await defaultAxios.post(accountAPI.confirmPw, {
          pw,
        });

        const { error } = data;

        if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          await handleConfirm();
        } else {
          window.alert(formatMessage({ id: `errors.${error}` }));
        }
      } catch (err) {
        setErrorData(err);
      }
    },
    [formatMessage, handleConfirm],
  );

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <Modal
      isOpen={openModal}
      sizeClassName="medium"
      isOverlayClose={false}
      isEscClose={false}
      handleRequestClose={handleRequestClose}
    >
      <div className="modal__box--content">
        <i className="icon-x" onClick={handleClose} />
        <h4 className="m_tit">
          <b>Confirm Password</b>
        </h4>
        <div className="overlay__item">
          <form onSubmit={handleSubmit(onSubmit)}>
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputField>
                    <span className="m_txt">{email}</span>
                  </FormInputField>
                </FormInput>

                <FormInput>
                  <FormInputContentWrap>
                    <FormInputField>
                      <input
                        type="password"
                        name="pw"
                        id="pw"
                        placeholder="Confirm password*"
                        autoFocus
                        ref={register({
                          required: formatMessage({
                            id: 'validate.required',
                          }),
                        })}
                      />
                      <label htmlFor="pw">
                        <FormattedMessage id="vcode.email" />*
                      </label>
                    </FormInputField>
                    <button className="btn contentBtn">Confirm</button>
                  </FormInputContentWrap>
                  {errors.pw && (
                    <span className="warning__message s_txt">
                      {errors.pw.message}
                    </span>
                  )}
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default PasswordConfirm;
