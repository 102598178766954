import styled from 'styled-components';

import { ContainerGap } from '~/components/elements/content';

export const ProfitContainer = styled(ContainerGap as any)`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 599px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProfitItem = styled.div`
  width: calc(33.33% - 13.33px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media screen and (max-width: 599px) {
    width: 100%;
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    padding: 0.5rem 0;
  }
`;

export const ProfitItemSymbol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem;
  background-color: #3f38d6;

  @media screen and (max-width: 599px) {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
  }

  i {
    color: #ffffff;
  }
`;
