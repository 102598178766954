import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Content } from '~/components/elements/content';

import { ProfitItem, ProfitItemSymbol } from './home03-elements';

const Wrapper = styled(Content as any)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const ProfitItemPartner = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 2rem;

  @media screen and (max-width: 1155px) {
    flex-wrap: wrap;
  }

  img {
    width: 6rem;
    height: auto;
    padding: 0.3rem 0;
    margin-right: 1rem;

    @media screen and (max-width: 599px) {
      width: 20%;
    }
  }
`;

const Profit3Img = styled.div`
  width: calc(33.33% * 2);
  height: 20.2rem;
  background: center right
    url(${require('~/assets/images/p1_partnerAd.png').default}) no-repeat;
  background-size: contain;
  display: block;

  @media screen and (max-width: 599px) {
    width: 100%;
    height: 30rem;
    background-position: center;
  }
`;

function HomeRevenue3() {
  return (
    <Wrapper>
      <ProfitItem>
        <ProfitItemSymbol>
          <i className="icon-broadcast" />
        </ProfitItemSymbol>
        <h5 className="s_tit">
          <FormattedMessage id="home.revenue.partner" />
        </h5>
        <p className="xl_txt">
          <FormattedMessage id="home.revenue.partnerDesc1" />
        </p>
        <p className="s_txt">
          <FormattedMessage id="home.revenue.partnerDesc2" />
        </p>
        <ProfitItemPartner>
          <img src={require('~/assets/images/mobvista.png').default} alt="" />
          <img
            src={require('~/assets/images/smartposting.png').default}
            alt=""
          />
          <img src={require('~/assets/images/ventumm.png').default} alt="" />
        </ProfitItemPartner>
      </ProfitItem>
      <Profit3Img
        data-aos="fade-left"
        data-aos-delay="50"
        data-aos-duration="1000"
      />
    </Wrapper>
  );
}

export default HomeRevenue3;
