import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { accountAPI } from '~/api';
import BasicInput from '~/components/base/basic-input';
import { Content } from '~/components/elements/content';
import { FormInputContainer } from '~/components/elements/form';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { pwPattern } from '~/libs/regexp';
import {
  IResResetPassword,
  IResVerifyEmail,
} from '~/models/account/find-password';
import { setIsFullLoader } from '~/modules/core';

import { Sign, SignContainer, SignLog } from '../elements';

interface IFormData {
  pw: string;
  pwConfirm: string;
}

function ResetPassword() {
  const [token, setToken] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<any>(null);

  const [
    { data: verifyData, loading: verifyLoading, error: verifyError },
    verifyEmail,
  ] = useAxios<IResVerifyEmail>(
    {
      url: accountAPI.verifyEmail,
      method: 'POST',
    },
    { useCache: false, manual: true },
  );

  const { register, errors, handleSubmit, getValues } = useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(verifyLoading));
  }, [dispatch, verifyLoading]);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const qsToken = String(qs.token || '');
    setToken(qsToken);
  }, [history, location.search]);

  useEffect(() => {
    if (token) {
      verifyEmail({
        data: {
          token,
        },
      });
    }
  }, [token, verifyEmail]);

  const onSubmit = async ({ pw }: IFormData) => {
    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post<IResResetPassword>(
        accountAPI.resetPassword,
        {
          token,
          pw,
        },
      );

      dispatch(setIsFullLoader(false));

      const { error } = data;
      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert('Success');
        history.push('/signin');
      } else {
        window.alert('Failed');
        history.push('/failed-authentication');
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (verifyError) {
    throw verifyError;
  }

  if (errorData) {
    throw errorData;
  }

  if (!verifyData) {
    return null;
  }

  if (
    token === '' ||
    verifyData.error !== adtier0.ERROR[adtier0.ERROR.SUCCESS]
  ) {
    return <Redirect to="/failed-authentication" />;
  }

  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>

          <h3 className="xl_txt">Reset Password</h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>Reset Password</legend>
              <FormInputContainer>
                <BasicInput
                  info={formatMessage({ id: 'signup.form.pwDesc' })}
                  error={errors.pw}
                >
                  <input
                    type="password"
                    name="pw"
                    id="pw"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      pattern: {
                        value: pwPattern,
                        message: formatMessage({ id: 'validate.pw' }),
                      },
                    })}
                  />
                  <label htmlFor="pw">New Password</label>
                </BasicInput>

                <BasicInput error={errors.pwConfirm}>
                  <input
                    type="password"
                    name="pwConfirm"
                    id="pwConfirm"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      validate: (value: string) =>
                        getValues().pw === value ||
                        formatMessage({ id: 'validate.pwNotMatch' }),
                    })}
                  />
                  <label htmlFor="pwConfirm">Confirm New Password</label>
                </BasicInput>
              </FormInputContainer>
              <button className="btn">Reset</button>
            </fieldset>
          </form>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default ResetPassword;
