import React from 'react';

import { Helmet } from 'react-helmet';

import FindPassword from '~/components/account/find-password';

function FindPasswordPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <FindPassword />
    </>
  );
}

export default FindPasswordPage;
