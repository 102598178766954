import { createGlobalStyle } from 'styled-components';

export const ConsoleStyle = createGlobalStyle<any>`
  main.console {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-top: 60px;
  }

  main.console h1 {
    padding-bottom: 5rem;
  }

  @media screen and (max-width: 1155px) {
    main.console {
      padding-top: 50px;
    }
  }

  .fileSelectBtn {
    display: none !important;
    width: 100px !important;
    height: auto !important;
    margin-top: 0.5rem;
    margin-left: auto;

    @media screen and (max-width: 599px) {
      display: flex !important;
    }
  }
`;
