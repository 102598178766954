import { version } from './helper';

const home = `api/${version.v1}/pub/`;

const activeAllMedia = `api/${version.v1}/pub/active-all-media`;

export default {
  home,
  activeAllMedia,
};
