import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Content,
  ListSection,
  ListSectionContainer,
  ListSectionItem as DefaultListSectionItem,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements';

const ListSectionItem = styled(DefaultListSectionItem as any)`
  p {
    &.l_txt {
      padding: 0.5rem 0 1.5rem 0;
      transition: 0.3s ease;
    }
  }

  @media screen and (max-width: 599px) {
    p {
      &.l_txt {
        min-height: 6rem;
        padding: 0.625rem 0 0rem 0;
      }
    }
  }
`;

function MediationContent() {
  return (
    <Content>
      <ListSection>
        <h3 className="m_tit">
          <FormattedMessage id="mediation.mediation" />
        </h3>
        <ListSectionContainer>
          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://smartposting.co.kr/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/smartposting.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="mediation.sp" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="mediation.sp.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="mediation.sp.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('https://www.mobvista.com/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/mobvista.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="mediation.ms" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="mediation.ms.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="mediation.ms.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('https://www.ventumm.com/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/ventumm.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="mediation.vt" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="mediation.vt.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="mediation.vt.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('https://www.ad-max.co.kr/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/admax.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="mediation.admax" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="mediation.admax.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="mediation.admax.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>
        </ListSectionContainer>
      </ListSection>
    </Content>
  );
}

export default MediationContent;
