import styled from 'styled-components';

export const FormInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;

  span {
    &.warning__message {
      color: #e91538;
      padding-top: 0.15rem;
      text-align: left;
      display: block;
      width: 100%;
    }

    &.info__message {
      font-size: 12px;
      color: #666666;
      word-break: break-all;
      padding-top: 0.15rem;
    }
  }

  &.noFloating {
    padding-bottom: 0;
  }

  @media screen and (max-width: 599px) {
    flex: 1 0 auto;
  }
`;

export const FormInput = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.form__input--50 {
    width: calc(50% - 10px);
  }

  &.video video {
    position: relative;
    max-width: 100%;
    display: block;
    z-index: 2; <== 수정
  }

  i {
    &.unit {
      padding-left: 6px;
      color: #1b1c23 !important;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 599px) {
    &.form__input--50 {
      width: calc(50% - 8px);
    }
  }

  @media screen and (max-width: 399px) {
    &.form__input--50 {
      width: 100%;
    }
  }
`;

export const FormInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > label {
    white-space: nowrap;
  }

  &.disable input[type='radio']:not(:checked) + label:before {
    background-color: #ebebeb;
  }
`;

export const FormInputTitleName = styled.span``;

export const FormInputTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;

  ${FormInputTitleName} {
    display: flex;
    align-items: center;
    color: #1b1c23 !important;
    white-space: nowrap;
  }

  i {
    &.icon-info {
      margin-left: auto;
    }

    &.required {
      align-self: flex-start;
      position: relative;
      width: 5px;
      height: 5px;
      display: block;
      background-color: #3f38d6;
      border-radius: 5px;
      margin-left: 3px;
    }
  }
`;

export const FormInputField = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #ebebeb;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;

  > span {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    background-color: #d4d4d4;
  }

  &.unit {
    input {
      text-align: right;
      padding-right: 50px !important;
    }
  }

  &.input__disabled {
    background-color: #d4d4d4 !important;
  }

  i.icon-x {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    border-radius: 16px;
    color: #1b1c23;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    z-index: 2;
    margin: 3px;
  }

  .preview--blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: block;
    z-index: 1;
  }

  .form__preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d4d4d4;
    padding: 1rem;
    flex-direction: column;

    .btn__txt {
      white-space: pre-wrap;
      font-size: 14px;
      text-align: center;
      height: auto;
    }
  }

  img {
    position: relative;
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 640px;
    min-height: 35px;
    margin: 0 auto;
    z-index: 2;
  }
`;

export const FormInputContentWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    width: 100%;
    display: flex;
    height: auto;

    i {
      &.icon-chevron-right {
        margin-left: auto;
        color: #d4d4d4;
      }
    }
  }

  button {
    justify-content: center;
    min-width: 80px;
    min-height: auto;
    margin-left: 1rem;
    margin-top: 0;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-end;

    button {
      margin-top: 1rem;
    }

    ${FormInputField} {
      :first-child {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const FormInputText = styled.div`
  max-height: 200px;
  padding: 20px;
  font-weight: 300;
  text-align: justify;
  overflow-y: scroll;
`;

export const FormTextArea = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FormInputRadio = styled.div``;
