import { ActionType, createAction, createReducer } from 'typesafe-actions';

// action type
const SET_MOBILE_MENU_OPEN = 'header/SET_MOBILE_MENU_OPEN';

// create action method
export const setMobileMenuOpen = createAction(SET_MOBILE_MENU_OPEN)<boolean>();

// action object type
const actions = { setMobileMenuOpen };
type HeaderAction = ActionType<typeof actions>;

// state
interface IHeaderState {
  mobileMenuOpen: boolean;
}

// initial state
const initialState: IHeaderState = {
  mobileMenuOpen: false,
};

const header = createReducer<IHeaderState, HeaderAction>(initialState, {
  [SET_MOBILE_MENU_OPEN]: (state, action) => ({
    ...state,
    mobileMenuOpen: action.payload,
  }),
});

export default header;
