import styled from 'styled-components';

export const FormInputVideo = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
`;

export const FormInputVideoThumb = styled.div`
  min-width: 0;
  max-width: max-content;

  img {
    width: calc(100% - 1rem);
    min-width: 60px;
    max-width: 128px;
    border-radius: 2px;
  }
`;

export const FormInputVideoInfo = styled.div`
  width: 100%;
  height: 100%;
  align-self: flex-start;
`;

export const FormInputVideoSlider = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
`;

export const FormInputVideoSliderWrap = styled.div`
  display: flex;

  div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    width: 100%;
  }
`;

const SliderArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    opacity: 0.5;
    transition: 0.3s ease;
  }

  &::hover i {
    opacity: 1;
    transition: 0.3s ease;
  }
`;

export const SliderArrowLeft = styled(SliderArrow as any)`
  left: 0px;
`;

export const SliderArrowRight = styled(SliderArrow as any)`
  right: 0;
`;
