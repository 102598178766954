import React from 'react';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IRevenueRequestListItem } from '~/models/account/my-page';

const TableContainer = styled(DefaultTableContainer as any)`
  margin-top: 5rem;

  @media screen and (max-width: 599px) {
    margin-top: 0rem;
  }
`;

interface IRevenueRequestListListProps {
  list: IRevenueRequestListItem[];
}

interface IRequestListListItemProps {
  item: IRevenueRequestListItem;
}

function RequestListList({ list }: IRevenueRequestListListProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="border__right">
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory.date" />
              <i className="icon-drop-down" />
            </th>
            <th>
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory.name" />
              <i className="icon-drop-down" />
            </th>
            <th>
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory.bank" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory.bankNum" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory.revenue" />
              <i className="icon-drop-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item, index) => (
              <RequestListListItem key={index} item={item} />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

function RequestListListItem({ item }: IRequestListListItemProps) {
  const { RequestTime, Name, Bank, Number: BankNumber, Num } = item;

  const time = moment(RequestTime).format('YYYY-MM-DD HH:mm:ss');
  const temp = time.split(' ');
  const date = temp[0];
  const hour = temp[1];

  return (
    <tr>
      <th className="border__right">
        {date}
        <span className="subText">{hour}</span>
      </th>
      <td>{Name}</td>
      <td>{Bank}</td>
      <td className="text__right">{BankNumber}</td>
      <td className="text__right table__important">
        <NumberFormat value={Num} displayType="text" thousandSeparator />
      </td>
    </tr>
  );
}

export default RequestListList;
