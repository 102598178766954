import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IRevenueDetailsProps {
  handleClickRevenueDetails: () => void;
}

function RevenueDetails({ handleClickRevenueDetails }: IRevenueDetailsProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="pubmypage.revenueGive.revenueHistory" />
        </FormInputTitleName>
        <FormInputContentWrap>
          <span className="m_txt">
            <i className="icon-chevron-right" />
          </span>
          <button
            className="btn contentBtn"
            onClick={handleClickRevenueDetails}
          >
            <FormattedMessage id="pubmypage.revenueGive.history" />
          </button>
        </FormInputContentWrap>
      </FormInputTitleWrap>
    </FormInput>
  );
}

export default RevenueDetails;
