import { version } from './helper';

const home = `api/${version.v1}/ad/`;

const stopAllPromotion = `api/${version.v1}/ad/stop-all-promotion`;

const startAllPromotion = `api/${version.v1}/ad/start-all-promotion`;

export default {
  home,
  stopAllPromotion,
  startAllPromotion,
};
