import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  OverlayContentTop,
  OverlayTitleKeyword,
  OverlayTitleWrap,
  OverlayTopCreate,
  OverlayTopWrap,
} from '~/components/elements/overlay';

interface ICreateTabPros {
  currentTab: number;
  product: string;
}

function CreateTab({ currentTab, product }: ICreateTabPros) {
  return (
    <OverlayContentTop className="create">
      <OverlayTopWrap>
        <OverlayTitleWrap>
          <h4 className="overlay__title m_tit">
            <FormattedMessage id="pub.inven.create" />
          </h4>
          {product && (
            <OverlayTitleKeyword>
              <FormattedMessage id={`pub.inven.${product.toLowerCase()}`} />
            </OverlayTitleKeyword>
          )}
        </OverlayTitleWrap>
        <OverlayTopCreate className="xs_tit">
          <ul>
            <li className={currentTab === 1 ? 'active' : ''}>
              <FormattedMessage id="pub.inven.invenType" />
            </li>
            <li className={currentTab === 2 ? 'active' : ''}>
              <FormattedMessage id="pub.inven" />
            </li>
            {product !== 'BANNER' && (
              <li className={currentTab === 3 ? 'active' : ''}>
                <FormattedMessage id="pub.inven.reward" />
              </li>
            )}
          </ul>
        </OverlayTopCreate>
      </OverlayTopWrap>
    </OverlayContentTop>
  );
}

export default CreateTab;
