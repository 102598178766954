import React from 'react';

import { Helmet } from 'react-helmet';

import Detail from '~/components/ad/ad/detail';

function CampaignDetailPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Detail />
    </>
  );
}

export default CampaignDetailPage;
