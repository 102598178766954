import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { pubInvenAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { IInvenCreate } from '~/models/pub/inventory';
import { setIsFullLoader } from '~/modules/core';

interface ICreateInventoryProps {
  createData: IInvenCreate;
  appID: string | undefined;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (obj: object) => void;
  handleReqError: (err: any) => void;
}

interface IFormData {
  reward: string;
  rewards: string;
}

function CreateReward({
  createData,
  appID,
  handleCurrentTab,
  handleCreateData,
  handleReqError,
}: ICreateInventoryProps) {
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const { register, handleSubmit, getValues, setValue } = useForm<IFormData>({
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setValue('reward', createData.reward);
    setValue('rewards', createData.rewards);

    if (createData.reward !== '') {
      setIsRequired(true);
    }
  }, [createData.reward, createData.rewards, setValue]);

  const handleChangeReward = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setIsRequired(false);
      setValue('rewards', '');
    } else {
      setIsRequired(true);
    }
  };

  const handlePrevTab = () => {
    const { reward, rewards } = getValues();
    handleCreateData({ reward, rewards });
    handleCurrentTab(2);
  };

  const onSubmit = async ({ reward, rewards }: IFormData) => {
    if (reward !== '') {
      if (rewards === '') {
        window.alert(f({ id: 'pub.inven.alertRewards' }));
        return;
      }
    }

    if (window.confirm(f({ id: 'confirm.add' }))) {
      const { name, product } = createData;

      dispatch(setIsFullLoader(true));

      try {
        const { data: addData } = await defaultAxios.post(pubInvenAPI.add, {
          appID,
          name,
          product,
          reward,
          rewards,
        });

        dispatch(setIsFullLoader(false));

        if (addData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          alert(f({ id: 'alert.add' }));
          history.push(`/pub-inventory?appID=${appID}`);
        } else {
          alert(f({ id: `errors.${addData.error}` }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        handleReqError(err);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>상세정보 - 보상</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="pub.inven.reward" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.inven.name" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="reward"
                        id="reward"
                        placeholder=""
                        ref={register}
                        onChange={handleChangeReward}
                      />
                      <label htmlFor="reward">
                        <FormattedMessage id="pub.inven.name" />
                      </label>
                    </FormInputField>
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.inven.rewardCount" />
                        {isRequired && <i className="required" />}
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="rewards"
                        id="rewards"
                        placeholder=""
                        ref={register}
                      />
                      <label htmlFor="rewards">
                        <FormattedMessage id="pub.inven.rewardCount" />
                      </label>
                    </FormInputField>
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button
                type="button"
                className="btn contentBtn btnClear"
                onClick={handlePrevTab}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button type="submit" className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.create" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateReward;
