import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import Modal from './modal';

interface IRecaptchaProps {
  openModal: boolean;
  handleRequestClose: () => void;
  handleClose: () => void;
  handleRecaptcha: (value: any) => Promise<void>;
}

const siteKey =
  process.env.NODE_ENV === 'development'
    ? '6LeC3scUAAAAANDoClo6ASCHNahEitGsREWwECvp'
    : process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

function Recaptcha({
  openModal,
  handleRequestClose,
  handleClose,
  handleRecaptcha,
}: IRecaptchaProps) {
  return (
    <Modal
      isOpen={openModal}
      sizeClassName="small"
      isOverlayClose={false}
      isEscClose={false}
      handleRequestClose={handleRequestClose}
    >
      <div className="modal__box--content">
        <i className="icon-x" onClick={handleClose} />
        <h4 className="m_tit">
          <b>Confirm Humanity</b>
        </h4>
        <p className="s_txt">
          Before we subscribe you, we need to confirm you are a human.
        </p>
        {openModal && (
          <ReCAPTCHA sitekey={siteKey} onChange={handleRecaptcha} />
        )}
      </div>
    </Modal>
  );
}

export default Recaptcha;
