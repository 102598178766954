import styled from 'styled-components';

export const SwitchBtn = styled.span`
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 34px;
  background-color: #fff;
  border: 1px solid #0c0698;

  .switch__btn--bar {
    width: 2px;
    height: 30%;
    border-radius: 2px;
    background-color: #3f38d6;
    display: block;
    padding: 0 !important;
  }
`;

export const Switch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
  height: 34px;
  margin: 0 auto;
  border: 1px solid #0c0698;
  overflow: hidden;

  ${SwitchBtn} {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  &.stop {
    border-color: #aaa;

    ${SwitchBtn} {
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;
      border-color: #aaaaaa;
    }

    .switch__btn--bar {
      background-color: #aaaaaa;
    }
  }
`;

export const SwitchTxt = styled.div`
  flex: 1;
  height: 100%;
  text-align: center;
  line-height: 34px;
  font-size: 13px;
  color: #ffffff;

  &.switch__txt--start {
    background-color: #3f38d6;
  }

  &.switch__txt--stop {
    color: #969696;
    background-color: #d4d4d4;
  }
`;
