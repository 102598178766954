import { version } from './helper';

const email = `api/${version.v1}/account/auth-mail`;

const signup = `api/${version.v1}/account/signup`;

const signin = `api/${version.v1}/account/signin`;

const signinCheck = `api/${version.v1}/account/signin-check`;

const findEmail = `api/${version.v1}/account/find-email`;

const findPassword = `api/${version.v1}/account/find-password`;

const resetPassword = `api/${version.v1}/account/reset-password`;

const verifyEmail = `api/${version.v1}/account/verify-email`;

const contact = `api/${version.v1}/account/contact`;

const recaptcha = `api/${version.v1}/account/recaptcha`;

const sendVCode = `api/${version.v1}/account/send-vcode`;

const confirmVCode = `api/${version.v1}/account/confirm-vcode`;

const confirmPw = `api/${version.v1}/account/confirm-pw`;

const adMyPage = `api/${version.v1}/account/ad-my-page`;

const pubMyPage = `api/${version.v1}/account/pub-my-page`;

const changePassword = `api/${version.v1}/account/change-password`;

const changeName = `api/${version.v1}/account/change-name`;

const changeCompany = `api/${version.v1}/account/change-company`;

const changeTimezone = `api/${version.v1}/account/change-timezone`;

const changeMobile = `api/${version.v1}/account/change-mobile`;

const getAccount = `api/${version.v1}/account/get-account`;

const modifyAccount = `api/${version.v1}/account/modify-account`;

const s3getSignedUrl = `api/${version.v1}/account/s3-get-signed-url`;

const getRevenue = `api/${version.v1}/account/get-revenue`;

const requestRevenueCheck = `api/${version.v1}/account/request-revenue-check`;

const requestRevenue = `api/${version.v1}/account/request-revenue`;

const requestList = `api/${version.v1}/account/request-list`;

const reveneDetails = `api/${version.v1}/account/revenue-details`;

export default {
  email,
  signup,
  signin,
  signinCheck,
  findEmail,
  findPassword,
  resetPassword,
  verifyEmail,
  contact,
  recaptcha,
  sendVCode,
  confirmVCode,
  confirmPw,
  adMyPage,
  pubMyPage,
  changePassword,
  changeName,
  changeCompany,
  changeTimezone,
  changeMobile,
  getAccount,
  modifyAccount,
  s3getSignedUrl,
  getRevenue,
  requestRevenueCheck,
  requestRevenue,
  requestList,
  reveneDetails,
};
