import moment from 'moment';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { IUser } from '~/models/user';

// action type
const SET_USER = 'user/SET_USER';
const SET_USER_INITIALIZED = 'user/SET_USER_INITIALIZED';
const SET_SEARCH_SELECT_DAY = 'user/SET_SEARCH_SELECT_DAY';
const SET_SEARCH_START_DATE = 'user/SET_SEARCH_START_DATE';
const SET_SEARCH_END_DATE = 'user/SET_SEARCH_END_DATE';

// create action method
export const setUser = createAction(SET_USER)<IUser | null>();
export const setUserInitialized = createAction(SET_USER_INITIALIZED)<boolean>();
export const setSearchSelectDay = createAction(SET_SEARCH_SELECT_DAY)<number>();
export const setSearchStartDate = createAction(SET_SEARCH_START_DATE)<Date>();
export const setSearchEndDate = createAction(SET_SEARCH_END_DATE)<Date>();

// action object type
const actions = {
  setUser,
  setUserInitialized,
  setSearchSelectDay,
  setSearchStartDate,
  setSearchEndDate,
};
type CoreAction = ActionType<typeof actions>;

// state
interface ICoreState {
  user: IUser | null;
  userInitialized: boolean;
  searchSelectDay: number;
  searchStartDate: Date;
  searchEndDate: Date;
}

// initial state
const initialState: ICoreState = {
  user: null,
  userInitialized: false,
  searchSelectDay: 7,
  searchStartDate: moment().subtract(6, 'days').toDate(),
  searchEndDate: moment().toDate(),
};

const user = createReducer<ICoreState, CoreAction>(initialState, {
  [SET_USER]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [SET_USER_INITIALIZED]: (state, action) => ({
    ...state,
    userInitialized: action.payload,
  }),
  [SET_SEARCH_SELECT_DAY]: (state, action) => ({
    ...state,
    searchSelectDay: action.payload,
  }),
  [SET_SEARCH_START_DATE]: (state, action) => ({
    ...state,
    searchStartDate: action.payload,
  }),
  [SET_SEARCH_END_DATE]: (state, action) => ({
    ...state,
    searchEndDate: action.payload,
  }),
});

export default user;
