import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import { pubInvenAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentBottom,
  OverlayContentTop,
  OverlayHeader,
  OverlayHeaderRight,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IResInvenDetail } from '~/models/pub/inventory';
import { IResBase } from '~/models/res-base';
import { setIsFullLoader } from '~/modules/core';
import { setErrorType } from '~/modules/error';

import DetailInvenType from './detail01-top';
import DetailInventory from './detail02-inventory';
import DetailReward from './detail03-reward';

interface IFormData {
  name: string;
  reward: string;
  rewards: string;
}

function Detail() {
  const [product, setProduct] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [reward, setReward] = useState<string>('');
  const [registTime, setRegistTime] = useState<string>('');
  const [updateTime, setUpdateTime] = useState<string>('');
  const [errorData, setErrorData] = useState<any>(null);

  const { id: medID } = useParams<any>();

  const { register, handleSubmit, setValue, errors } = useForm<IFormData>({
    submitFocusError: false,
  });

  const [{ data, loading, error }] = useAxios<IResInvenDetail>(
    {
      url: pubInvenAPI.get,
      method: 'POST',
      data: {
        medID,
      },
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const [query] = useQueryParams({
    appID: StringParam,
  });

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      const { inven } = data;
      if (!inven) {
        dispatch(setErrorType('NOT_FOUND'));
        return;
      }

      const { Product, Name, Reward, Rewards, RegistTime, UpdateTime } =
        data.inven;

      setProduct(Product);
      setName(Name);
      setReward(Reward);
      setRegistTime(moment(RegistTime).format('YYYY-MM-DD HH:mm'));
      setUpdateTime(moment(UpdateTime).format('YYYY-MM-DD HH:mm'));

      setValue('name', Name);
      setValue('reward', Reward);
      setValue('rewards', Rewards);
    }
  }, [data, dispatch, setValue]);

  const handleClose = () => {
    history.push(`/pub-inventory?appID=${query.appID}`);
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  const onSubmit = async (formData: IFormData) => {
    if (formData.reward !== '') {
      if (formData.rewards === '') {
        window.alert(formatMessage({ id: 'pub.inven.alertRewards' }));
        return;
      }
    }

    try {
      if (window.confirm(formatMessage({ id: 'confirm.modify' }))) {
        dispatch(setIsFullLoader(true));

        const { data: modifyData } = await defaultAxios.post<IResBase>(
          pubInvenAPI.modify,
          {
            medID,
            name: formData.name,
            reward: formData.reward,
            rewards: formData.rewards,
          },
        );

        dispatch(setIsFullLoader(false));

        if (modifyData.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.modify' }));
          handleClose();
        } else {
          window.alert(formatMessage({ id: `errors.${modifyData.error}` }));
        }
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data || !data.inven) {
    return null;
  }

  return (
    <main className="console">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>인벤토리 - 상세 정보</legend>
          <OverlayContainer>
            <OverlayHeader>
              <i className="icon-x" onClick={handleClose} />
              <OverlayHeaderTitle className="m_txt">{name}</OverlayHeaderTitle>
              <OverlayHeaderRight>
                <Link to={`/pub-app/${query.appID}?name=&pageNo=1`}>
                  <i className="icon-launchopen_in_new" />
                  <div className="btn__txt">
                    <FormattedMessage id="pub.inven.goApp" />
                  </div>
                </Link>
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.modify" />
                  </div>
                </button>
              </OverlayHeaderRight>
            </OverlayHeader>
            <OverlayContent>
              <OverlayContentTop>
                <DetailInvenType
                  product={product}
                  registTime={registTime}
                  updateTime={updateTime}
                />
              </OverlayContentTop>

              <OverlayContentBottom>
                <DetailInventory
                  register={register}
                  errors={errors}
                  medID={medID}
                />

                {product !== 'BANNER' && (
                  <DetailReward
                    register={register}
                    setValue={setValue}
                    reward={reward}
                  />
                )}
              </OverlayContentBottom>
            </OverlayContent>
          </OverlayContainer>
        </fieldset>
      </form>
    </main>
  );
}

export default Detail;
