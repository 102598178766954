import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { SearchContainer } from '~/components/elements/search';
import { IAppNameListItem } from '~/models/pub/app';

interface IListSearchProps {
  appID: string;
  apps: IAppNameListItem[] | null;
  handleChangeAppID: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleNewAppReward: () => void;
}

function ListSearch({
  appID,
  apps,
  handleChangeAppID,
  handleNewAppReward,
}: IListSearchProps) {
  return (
    <SearchContainer>
      <NoFloating>
        <FormInputContainer>
          <FormInput>
            <FormInputField>
              <select
                name="appName"
                id="appName"
                value={appID}
                onChange={handleChangeAppID}
              >
                {apps &&
                  apps.map((app: IAppNameListItem, index: number) => (
                    <option key={index} value={app.AppID}>
                      {app.Name}
                    </option>
                  ))}
              </select>
              <label htmlFor="appName">
                <FormattedMessage id="pub.app.appName" />
              </label>
              <i className="icon-chevron-down" />
            </FormInputField>
          </FormInput>
        </FormInputContainer>
      </NoFloating>
      <div className="btn contentBtn" onClick={handleNewAppReward}>
        <i className="icon-plus" />
        <div className="btn__txt">
          <FormattedMessage id="pub.appReward.newAppReward" />
        </div>
      </div>
    </SearchContainer>
  );
}

export default ListSearch;
