import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import Hint from '~/components/common/hint';
import { TableContainer } from '~/components/elements/table';
import { IReportByApp } from '~/models/pub/report';

interface IReportByAppListProps {
  apps: IReportByApp[];
}

interface IReportByAppListItemProps {
  app: IReportByApp;
}

function ReportAppList({ apps }: IReportByAppListProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="text__left border__right">
              <FormattedMessage id="pub.report.appName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.revenue" />
              (KRW)
              <Hint title="hint.revenue" content="hint.revenue.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.ecpm" />
              (KRW)
              <Hint title="hint.ecpm" content="hint.ecpm.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.impression" />
              <i className="icon-drop-down active" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.install" />
              <Hint title="hint.install" content="hint.install.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.ctr" />
              (%)
              <Hint title="hint.ctr" content="hint.ctr.desc" />
            </th>
          </tr>
        </thead>
        <tbody>
          {apps &&
            apps.map((app, index) => (
              <ReportByAppListItem key={index} app={app} />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

function ReportByAppListItem({ app }: IReportByAppListItemProps) {
  const { AppName, Revenue, eCPM, Impressions, Installs, CTR } = app;

  return (
    <tr>
      <th className="text__left border__right table__title">
        <span>{AppName}</span>
      </th>

      <td className="text__right table__important">
        <NumberFormat value={Revenue} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={eCPM} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat
          value={Impressions}
          displayType="text"
          thousandSeparator
        />
      </td>
      <td className="text__right">
        <NumberFormat value={Installs} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">{CTR}</td>
    </tr>
  );
}

export default ReportAppList;
