import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  ListSection,
  ListSectionContainer,
  ListSectionFile,
  ListSectionItem,
  ListSectionItemFile,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements/list-section';

function ResourceDoc2() {
  return (
    <ListSection>
      <h3 className="m_tit">
        <FormattedMessage id="resource.ad" />
      </h3>
      <ListSectionContainer>
        <ListSectionItem>
          <ListSectionItemImg>
            <img src={require('~/assets/images/p3_3.png').default} alt="" />
          </ListSectionItemImg>
          <ListSectionItemText>
            <h4 className="s_tit">
              <FormattedMessage id="resource.adGuide" />
            </h4>
            <p className="s_txt">
              <FormattedMessage id="resource.adGuideDesc" />
            </p>
          </ListSectionItemText>
          <ListSectionItemFile>
            <ListSectionFile>
              <h5 className="xs_tit">English</h5>
              <a
                href="https://resource.zenaad.com/Guide-Advertiser-en.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-save_alt" />
              </a>
            </ListSectionFile>
            <ListSectionFile>
              <h5 className="xs_tit">
                <FormattedMessage id="language.ko" />
              </h5>
              <a
                href="https://resource.zenaad.com/Guide-Advertiser-ko.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-save_alt" />
              </a>
            </ListSectionFile>
          </ListSectionItemFile>
        </ListSectionItem>
      </ListSectionContainer>
    </ListSection>
  );
}

export default ResourceDoc2;
