import styled from 'styled-components';

export const SubNav = styled.nav`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background-color: #ffffff;
  z-index: 8;
  box-sizing: border-box;

  a {
    padding: 0.5rem 1.5rem;
  }

  &.scroll {
    position: fixed;
    top: 60px;
    width: 100%;
    border-top: none;

    + section {
      margin-top: 60px;
    }
  }

  @media screen and (max-width: 1155px) {
    height: 50px;

    &.scroll {
      top: 50px;

      + section {
        margin-top: 50px;
      }
    }
  }

  @media screen and (max-width: 599px) {
    text-align: center;
    word-break: keep-all;

    a {
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 359px) {
    flex-direction: column;
    height: auto;

    a {
      width: 100%;
      padding: 1rem 1rem;
      text-align: left;
    }
  }
`;
