import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import ConfirmPassword from '~/components/common/confirm-password';
import {
  FormInput,
  FormInputContentWrap,
  FormInputField,
  FormInputGroup,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { Select, selectStyles } from '~/components/elements/select';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { callingCode as numberOptions } from '~/json/calling-code';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { numberPattern } from '~/libs/regexp';
import { storage } from '~/libs/storage';
import { ISelectOption } from '~/models/select-option';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

interface IAddInfoMobileProps {
  email: string;
  countryCode: string;
  mobileNumber: string;
}

interface IFormData {
  code: string;
  number: string;
}

function AddInfoMobile({
  email,
  countryCode,
  mobileNumber,
}: IAddInfoMobileProps) {
  const [code, setCode] = useState<ISelectOption | null>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, getValues, setValue, handleSubmit } =
    useForm<IFormData>({
      submitFocusError: false,
    });

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    register({ name: 'code', type: 'custom' });

    if (countryCode !== '') {
      const obj = numberOptions.find(
        (o: ISelectOption) => o.value === countryCode,
      );

      setValue('code', countryCode);
      if (!obj) {
        setCode(null);
      } else {
        setCode(obj);
      }
    } else {
      setValue('code', numberOptions[119].value);
      setCode(numberOptions[119]);
    }

    setValue('number', mobileNumber);
  }, [countryCode, formatMessage, mobileNumber, register, setValue]);

  const handleChangeCode = useCallback(
    (selected: ISelectOption) => {
      setValue('code', selected.value);
      setCode(selected);
    },
    [setValue],
  );

  const onSubmit = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    setOpenConfirm(false);

    const { code: codeNumber, number } = getValues();

    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post(accountAPI.changeMobile, {
        code: codeNumber,
        number,
      });

      dispatch(setIsFullLoader(false));

      const { error } = data;

      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert(formatMessage({ id: 'alert.modify' }));
        if (user) {
          const { storageKey } = config;

          const newUser = { ...user };
          newUser.CountryCode = codeNumber;
          newUser.MobileNumber = number;

          dispatch(setUser(user));

          storage.set(storageKey.user, newUser);
        }
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  }, [dispatch, formatMessage, getValues, user]);

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>기본 정보 - 이름 변경</legend>
          <FormInput>
            <FormInputTitleWrap>
              <FormInputTitleName className="m_txt">
                <FormattedMessage id="mypage.addinfo.mobile" />
              </FormInputTitleName>
            </FormInputTitleWrap>
            <FormInputContentWrap className="vertical">
              <FormInputGroup>
                <FormInputField>
                  <Select
                    name="code"
                    placeholder="Country code"
                    styles={selectStyles}
                    isClearable={false}
                    isSearchable
                    options={numberOptions}
                    value={code}
                    onChange={handleChangeCode}
                  />
                </FormInputField>

                <FormInputField>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    placeholder="Mobile phone number*"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      validate: {
                        onlynum: (value) =>
                          numberPattern.test(value) ||
                          formatMessage({ id: 'validate.onlyNum' }),
                      },
                    })}
                  />
                  <label htmlFor="number">Mobile phone number</label>
                </FormInputField>
                {errors.number && (
                  <span className="warning__message s_txt">
                    {`Mobile phone number: ${errors.number.message}`}
                  </span>
                )}
              </FormInputGroup>
              <button className="btn contentBtn">
                <FormattedMessage id="common.modify" />
              </button>
            </FormInputContentWrap>
          </FormInput>
        </fieldset>
      </form>

      {openConfirm && (
        <ConfirmPassword
          email={email}
          openModal={openConfirm}
          handleRequestClose={handleCloseConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default AddInfoMobile;
