import React, { useEffect } from 'react';

import { get } from 'lodash';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { RootState } from '~/modules';
import { setLanguage } from '~/modules/core';
import translations from '~/translations';

interface IIntlContainerProps {
  children: React.ReactNode;
}

function IntlContainer({ children }: IIntlContainerProps) {
  const language = useSelector((state: RootState) => state.core.language);
  const dispatch = useDispatch();

  useEffect(() => {
    const { storageKey } = config;
    const lang = storage.get(storageKey.language);

    if (lang) {
      dispatch(setLanguage(lang));
    } else if (navigator.language) {
      const defaultLanguage = navigator.language.split(/[-_]/)[0];
      if (defaultLanguage && defaultLanguage === 'ko') {
        dispatch(setLanguage('ko'));
      }
    }
  }, [dispatch]);

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={get(translations, language)}
    >
      {children}
    </IntlProvider>
  );
}

export default IntlContainer;
