import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ConsoleContent as DefaultConsoleContent } from '~/components/elements/console-content';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { SearchContainer as DefaultSearchContainer } from '~/components/elements/search';
import { ICampaignNameListItem } from '~/models/ad/campaign';

interface IProps {
  campID: number | undefined;
  campaigns: ICampaignNameListItem[] | null;
  showFilter: boolean;
  handleChangeCampID: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleNewAd: () => void;
  handleShowFilter: () => void;
}

const ConsoleContent = styled(DefaultConsoleContent as any)`
  padding-bottom: 0 !important;
`;

const SearchContainer = styled(DefaultSearchContainer as any)`
  i {
    &.icon-tune {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 42px;
      height: 42px;
      cursor: pointer;
      margin-left: 0.5rem;

      &.open {
        background-color: rgba(216, 222, 241, 0.3);
      }
    }
  }

  @media screen and (max-width: 599px) {
    i {
      &.icon-tune {
        min-width: 38px;
        height: 38px;
      }
    }
  }
`;

function ListSearch({
  campID,
  campaigns,
  showFilter,
  handleChangeCampID,
  handleNewAd,
  handleShowFilter,
}: IProps) {
  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="ad.ad.list" />
      </h1>
      <SearchContainer>
        <form>
          <fieldset>
            <legend />
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputField>
                    <select
                      name="campName"
                      id="campName"
                      value={campID}
                      onChange={handleChangeCampID}
                    >
                      <option value="0">== SELECT ==</option>
                      {campaigns &&
                        campaigns.map((camp: ICampaignNameListItem) => (
                          <option key={camp.CampID} value={camp.CampID}>
                            {camp.Name}
                          </option>
                        ))}
                    </select>
                    <label htmlFor="campName">
                      <FormattedMessage id="ad.campaign.campName" />
                    </label>
                    <i className="icon-chevron-down" />
                  </FormInputField>
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </fieldset>
        </form>
        <div className="btn contentBtn" onClick={handleNewAd}>
          <i className="icon-plus" />
          <div className="btn__txt">
            <FormattedMessage id="ad.ad.newPromotion" />
          </div>
        </div>
        <i
          className={
            showFilter ? 'icon-tune btnLine open' : 'icon-tune btnLine'
          }
          onClick={handleShowFilter}
        />
      </SearchContainer>
    </ConsoleContent>
  );
}

export default ListSearch;
