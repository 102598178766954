import axios from 'axios';
import { detect } from 'detect-browser';
import { saveAs } from 'file-saver';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';
import moment from 'moment';

export const prettyJson = (obj: any): string => {
  return JSON.stringify(obj, undefined, 2);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const fileToBase64 = (
  file: File,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const replaceBR = (str: string) => {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

export const supportsVideoType = (type: string, video: HTMLVideoElement) => {
  // Allow user to create shortcuts, i.e. just "webm"
  const formats = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
  };

  return video.canPlayType(get(formats, type));
};

export const pressedEnter =
  (fn: () => void) => (e: import('react').KeyboardEvent<HTMLInputElement>) => {
    // e.preventDefault();
    if (e.key === 'Enter') {
      fn();
    }
  };

export const escapeForUrl = (text: string): string => {
  return text
    .replace(
      /[^0-9a-zA-Zㄱ-힣.\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf -]/g,
      '',
    )
    .replace(/ /g, '-')
    .replace(/--+/g, '-');
};

export const getIsMobile = (): boolean => {
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    return true;
  }

  return false;
};

export const getBrowserName = (): string => {
  const browser = detect();
  if (browser) {
    return browser.name;
  }

  return '';
};

export const setInnerHtml = (html: string) => {
  return { __html: html };
};

export const getPosibleSearchDate = (
  start: Date,
  end: Date,
  searchDay: number,
): boolean => {
  const sdate = moment(start);
  const edate = moment(end);

  const duration = moment.duration(edate.diff(sdate));
  const count = duration.asDays();

  if (count < 0 || count > searchDay) {
    return false;
  }

  return true;
};

export const downloadFromURL = (fileurl: string, filename: string) => {
  axios({
    url: fileurl,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    // const url = window.URL.createObjectURL(new Blob([response.data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', filename);
    // document.body.appendChild(link);
    // link.click();
    saveAs(new Blob([response.data]), filename);
  });
};

export const dataURItoBlob = (dataURI: any) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};
