import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { accountAPI } from '~/api';
import BasicInput from '~/components/base/basic-input';
import BasicTextArea from '~/components/base/basic-textarea';
import Recaptcha from '~/components/common/recaptcha';
import { Content, FormInput, FormInputContainer } from '~/components/elements';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { emailPattern } from '~/libs/regexp';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';

interface IFormData {
  name: string;
  email: string;
  company: string;
  mobile: string;
  message: string;
}

const HelpContainer = styled.div`
  @media screen and (min-width: 600px) {
    ${FormInput} {
      width: calc(50% - 10px);
    }
  }
`;

// const siteKey =
//   process.env.NODE_ENV === 'development'
//     ? '6LeC3scUAAAAANDoClo6ASCHNahEitGsREWwECvp'
//     : process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

function HelpContent() {
  const [params, setParams] = useState<IFormData | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, handleSubmit, setValue, reset } =
    useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  const handleOpenModal = (value: boolean) => {
    setOpenModal(value);
  };

  const handleCloseRecaptcha = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (user) {
      setValue('name', `${user.FirstName} ${user.LastName}`);
      setValue('email', user.Email);
      setValue('company', user.Company);
    }
  }, [setValue, user]);

  const onSubmit = async ({
    name,
    email,
    company,
    mobile,
    message,
  }: IFormData) => {
    setParams({
      name,
      email,
      company,
      mobile,
      message,
    });

    handleOpenModal(true);
  };

  const handleReCAPTCHA = async (value: any): Promise<void> => {
    const token = value;

    try {
      const { data: reCaptchaData } = await defaultAxios.post(
        accountAPI.recaptcha,
        { token },
      );

      if (reCaptchaData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        handleOpenModal(false);

        try {
          dispatch(setIsFullLoader(true));

          const { data } = await defaultAxios.post<{ error: string }>(
            accountAPI.contact,
            params,
          );

          dispatch(setIsFullLoader(false));

          const { error } = data;

          if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
            window.alert(formatMessage({ id: 'help.send.success' }));
            reset();
            if (user) {
              setValue('name', `${user.FirstName} ${user.LastName}`);
              setValue('email', user.Email);
              setValue('company', user.Company);
            }
          } else {
            window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
          }
        } catch (err) {
          dispatch(setIsFullLoader(false));

          setErrorData(err);
        }
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <Content>
        <HelpContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>문의하기</legend>
              <FormInputContainer>
                <BasicInput error={errors.name}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="name">Name</label>
                </BasicInput>

                <BasicInput error={errors.email}>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      pattern: {
                        value: emailPattern,
                        message: formatMessage({ id: 'validate.email' }),
                      },
                    })}
                  />
                  <label htmlFor="email">Email</label>
                </BasicInput>

                <BasicInput error={errors.email}>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    placeholder="&nbsp;"
                    ref={register}
                  />
                  <label htmlFor="company">Company Name (optional)</label>
                </BasicInput>

                <BasicInput error={errors.email}>
                  <input
                    type="text"
                    name="mobile"
                    id="mobile"
                    placeholder="&nbsp;"
                    ref={register}
                  />
                  <label htmlFor="mobile">Moblie (optional)</label>
                </BasicInput>

                <BasicTextArea error={errors.email}>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="message">Message</label>
                </BasicTextArea>
              </FormInputContainer>
              <button className="btn">Submit</button>
            </fieldset>
          </form>
        </HelpContainer>
      </Content>

      {/* <Modal
        isOpen={openModal}
        sizeClassName="small"
        isOverlayClose={false}
        isEscClose={false}
        handleRequestClose={handleCloseRecaptcha}
      >
        <div className="modal__box--content">
          <i className="icon-x" onClick={() => handleOpenModal(false)} />
          <h4 className="m_tit">
            <b>Confirm Humanity</b>
          </h4>
          <p className="s_txt">
            Before we subscribe you, we need to confirm you are a human.
          </p>
          {openModal && (
            <ReCAPTCHA sitekey={siteKey} onChange={handleReCAPTCHA} />
          )}
        </div>
      </Modal> */}

      <Recaptcha
        openModal={openModal}
        handleRequestClose={handleCloseRecaptcha}
        handleClose={handleCloseRecaptcha}
        handleRecaptcha={handleReCAPTCHA}
      />
    </>
  );
}

export default HelpContent;
