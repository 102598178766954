import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import qs from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { pubAppAPI, pubInvenAPI } from '~/api';
import { ConsoleContent } from '~/components/elements/console-content';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IAppNameListItem, IResAppNameList } from '~/models/pub/app';
import { IInvenListItem, IResInvenList } from '~/models/pub/inventory';
import { IResBase } from '~/models/res-base';
import { setIsFullLoader } from '~/modules/core';

import ListSearch from './list01-search';
import ListTable from './list02-table';

function List() {
  const [appID, setAppID] = useState<string>('');
  const [apps, setApps] = useState<IAppNameListItem[] | null>(null);
  const [invens, setInvens] = useState<IInvenListItem[] | null>(null);
  const [errorData, setErrorData] = useState<any>(null);

  const [{ data: appData, loading: appLoading, error: appError }] =
    useAxios<IResAppNameList>(
      {
        url: pubAppAPI.listAll,
        method: 'GET',
      },
      { useCache: false },
    );

  const [
    { data: invenData, loading: invenLoading, error: invenError },
    execute,
  ] = useAxios<IResInvenList>(
    {
      url: pubInvenAPI.list,
      method: 'GET',
    },
    { useCache: false, manual: true },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (appLoading || invenLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [appLoading, dispatch, invenLoading]);

  useEffect(() => {
    if (appData) {
      if (appData.apps) {
        setApps(appData.apps);
      }
    }
  }, [appData]);

  useEffect(() => {
    const query = qs.parse(location.search);
    const qsAppID = String(query.appID || '');

    execute({
      params: {
        appID: qsAppID,
      },
    });
  }, [execute, location.search]);

  useEffect(() => {
    if (invenData) {
      if (invenData.invens) {
        setAppID(invenData.appID);
        setInvens(invenData.invens);
      }
    }
  }, [invenData]);

  const handleChangeAppID = (e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(`/pub-inventory?appID=${e.target.value}`);
  };

  const handleNewInventory = () => {
    if (!apps) {
      window.alert(formatMessage({ id: 'pub.inven.emptyApp' }));
      return;
    }

    if (apps.length === 0) {
      window.alert(formatMessage({ id: 'pub.inven.emptyApp' }));
      return;
    }

    history.push(`/pub-inventory-create?appID=${appID}`);
  };

  const handleDetail = (medID: string) => {
    history.push(`/pub-inventory/${medID}?appID=${appID}`);
  };

  const handleActiveMedia = async (
    e: React.MouseEvent,
    medID: string,
    active: number,
  ) => {
    e.stopPropagation();

    if (window.confirm(formatMessage({ id: 'confirm.execute' }))) {
      try {
        dispatch(setIsFullLoader(true));

        const { data: activeData } = await defaultAxios.post<IResBase>(
          pubInvenAPI.activeMedia,
          {
            medID,
            active,
          },
        );

        dispatch(setIsFullLoader(false));

        if (activeData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          alert(formatMessage({ id: 'alert.execute' }));
          if (invens) {
            const arr = invens.map((inven) =>
              inven.MedID === medID ? { ...inven, Active: active } : inven,
            );

            setInvens(arr);
          }
        } else {
          alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    }
  };

  if (appError) {
    if (appError.response) {
      if (appError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw appError;
  }

  if (invenError) {
    if (invenError.response) {
      if (invenError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw invenError;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="pub.inven.list" />
      </h1>

      <ListSearch
        appID={appID}
        apps={apps}
        handleChangeAppID={handleChangeAppID}
        handleNewInventory={handleNewInventory}
      />

      <ListTable
        invens={invens}
        handleDetail={handleDetail}
        handleActiveMedia={handleActiveMedia}
      />
    </ConsoleContent>
  );
}

export default List;
