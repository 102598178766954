import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { accountAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import {
  IPubMyRevenueDetailsItem,
  IResPubMyRevenueDetails,
} from '~/models/account/my-page';
import { setIsFullLoader } from '~/modules/core';

import RevenueDetailsList from './revenue-details01-list';
import Tab from './tab';

function History() {
  const [list, setList] = useState<IPubMyRevenueDetailsItem[] | null>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const [{ data, loading, error }] = useAxios<IResPubMyRevenueDetails>(
    {
      url: accountAPI.reveneDetails,
      method: 'GET',
    },
    { useCache: false },
  );

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.push('/pub-my-page');
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.list) {
        setList(data.list);
      }
    }
  }, [data]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!list) {
    return null;
  }

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="pubmypage.revenueGive" />
          </OverlayHeaderTitle>
        </OverlayHeader>

        <OverlayContent>
          <Tab currentTab={1} />

          <RevenueDetailsList list={list} />
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default History;
