import axios from 'axios';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';

import { storage } from '~/libs/storage';

import { config } from './config';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4001'
    : process.env.REACT_APP_API_ADDR;

const defaultAxios = axios.create({
  baseURL,
});

// defaultAxios.interceptors.request.use(
//   (config: any) => {
//     console.log(config);
//     return config;
//   },
//   (error: any) => {
//     // Do something with request error
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

defaultAxios.interceptors.response.use(
  (response: any) => {
    // logger.info(response);

    const { data } = response;
    const { newToken } = data;

    if (newToken) {
      // logger.info(`newTokem: ${newToken}`);

      const { headers, storageKey } = config;

      storage.set(storageKey.accessToken, newToken);

      defaultAxios.defaults.headers.common[headers.accessToken] = newToken;
    }

    return response;
  },
  (error: any) => Promise.reject(error),
);

const cache = new LRU({ max: 0 });

configure({ axios: defaultAxios, cache });

const clearHeader = () => {
  const { headers } = config;
  defaultAxios.defaults.headers.common[headers.accessToken] = '';
  defaultAxios.defaults.headers.common[headers.custID] = '';
  defaultAxios.defaults.headers.common[headers.sessionKey] = '';
  defaultAxios.defaults.headers.common[headers.timezoneOffset] = '';

  configure({ axios: defaultAxios, cache });
};

export { defaultAxios, clearHeader };
