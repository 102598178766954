import React from 'react';

import AppIntro from './app01-intro';
import AppContent from './app02-content';

function Game() {
  return (
    <>
      <AppIntro />
      <AppContent />
    </>
  );
}

export default Game;
