import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import {
  FormInput,
  FormInputContentWrap,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { Select, selectStyles } from '~/components/elements/select';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { timezone as timezoneOptions } from '~/json/timezone';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { ISelectOption } from '~/models/select-option';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

interface IDefaultInfoTimeZoneProps {
  timezoneName: string;
}

function DefaultInfoTimeZone({ timezoneName }: IDefaultInfoTimeZoneProps) {
  const [timezone, setTimezone] = useState<ISelectOption | null>(null);
  const [timezoneError, setTimezoneError] = useState<number>(0);
  const [errorData, setErrorData] = useState<any>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    const index = timezoneOptions.findIndex(
      (item) => item.value === timezoneName,
    );

    setTimezone(timezoneOptions[index]);
  }, [timezoneName]);

  const handleChangeTimezone = (selectedOption: ISelectOption) => {
    if (selectedOption === null) {
      setTimezone(null);
      setTimezoneError(1);
      return;
    }

    setTimezone(selectedOption);
    setTimezoneError(0);
  };

  const handleSubmit = async () => {
    if (!timezone) {
      setTimezoneError(1);
      return;
    }

    const name = timezone ? timezone.value : '';
    if (name === '') {
      setTimezoneError(1);
      return;
    }

    if (window.confirm(formatMessage({ id: 'confirm.modify' }))) {
      try {
        const { data } = await defaultAxios.post(accountAPI.changeTimezone, {
          timezone: name,
        });

        const { error } = data;
        if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.modify' }));
          const { row } = data;
          if (user) {
            const { storageKey } = config;

            const newUser = {
              ...user,
              TimezoneOffset: row.TimezoneOffset,
              Iso2: row.Iso2,
              ShortName: row.ShortName,
            };

            dispatch(setUser(user));

            storage.set(storageKey.user, newUser);
          }
        } else {
          window.alert(formatMessage({ id: `errors.${error}` }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    }
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="mypage.default.timezone" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputContentWrap>
        <FormInputField>
          <Select
            name="timezone"
            placeholder="&nbsp;Timezone Name"
            options={timezoneOptions}
            styles={selectStyles}
            isClearable
            isSearchable
            menuPosition="fixed"
            menuPlacement="bottom"
            menuPortalTarget={document.body}
            value={timezone}
            onChange={handleChangeTimezone}
          />
        </FormInputField>
        <button className="btn contentBtn" onClick={handleSubmit}>
          <FormattedMessage id="common.modify" />
        </button>
      </FormInputContentWrap>
      {timezoneError === 1 && (
        <span className="warning__message s_txt">
          <FormattedMessage id="validate.required" />
        </span>
      )}
    </FormInput>
  );
}

export default DefaultInfoTimeZone;
