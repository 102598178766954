import React from 'react';

import {
  FormInput,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IProps {
  title: string;
  children: React.ReactNode;
  hint?: JSX.Element;
}

function TitleStatic({ title, children, hint }: IProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">{title}</FormInputTitleName>
        {hint && hint}
      </FormInputTitleWrap>
      {children}
    </FormInput>
  );
}

export default TitleStatic;
