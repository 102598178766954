import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { Select, selectStyles } from '~/components/elements/select';
import { country as countryOptions } from '~/json/country';
import { ISelectOption } from '~/models/select-option';

interface IDetailAppDefaultProps {
  country: ISelectOption[] | null;
  countryError: number;
  campType: string;
  url: string;
  urlError: number;
  filter: string;
  handleCountry: (selectedOption: ISelectOption[]) => void;
  handleCampType: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrl: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function DetailAppDefault({
  country,
  countryError,
  campType,
  url,
  urlError,
  filter,
  handleCountry,
  handleCampType,
  handleUrl,
  handleFilter,
}: IDetailAppDefaultProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.app" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <form>
          <fieldset>
            <legend>캠페인생성 - 앱</legend>
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.country" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputField>
                    <Select
                      name="country"
                      placeholder={formatMessage({ id: 'ad.ad.country' })}
                      options={countryOptions}
                      styles={selectStyles}
                      isClearable
                      isSearchable
                      isMulti
                      value={country}
                      onChange={handleCountry}
                    />
                  </FormInputField>
                  {countryError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.campType" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputGroup className="disable">
                    <FormInputRadio className="form__input--50">
                      <input
                        type="radio"
                        name="campType"
                        id="android"
                        value="AOS"
                        checked={campType === 'AOS'}
                        onChange={handleCampType}
                        disabled
                      />
                      <label htmlFor="android">Android</label>
                    </FormInputRadio>

                    <FormInputRadio className="form__input--50">
                      <input
                        type="radio"
                        name="campType"
                        id="ios"
                        value="IOS"
                        checked={campType === 'IOS'}
                        onChange={handleCampType}
                        disabled
                      />
                      <label htmlFor="ios">iOS</label>
                    </FormInputRadio>
                  </FormInputGroup>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.targetUrl" />
                      <i className="required" />
                    </FormInputTitleName>
                    <CopyToClipboard
                      text={url}
                      onCopy={() =>
                        toast.success(formatMessage({ id: 'alert.clipboard' }))
                      }
                    >
                      <i className="icon-filter_none cursor__pointer" />
                    </CopyToClipboard>
                  </FormInputTitleWrap>
                  <FormInputField>
                    <textarea
                      name="targetUrl"
                      id="targetUrl"
                      cols={40}
                      rows={2}
                      value={url}
                      onChange={handleUrl}
                    />
                    <label htmlFor="targetUrl">
                      <FormattedMessage id="ad.ad.targetUrl" />
                    </label>
                  </FormInputField>
                  {urlError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}

                  {urlError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.url" />
                    </span>
                  )}
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="pub.app.contentLevel" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">
                    <FormattedMessage id="pub.app.contentLevelDesc" />
                  </span>
                  <FormInputGroup>
                    <FormInputRadio className="form__input--50">
                      <input
                        type="radio"
                        name="filter"
                        id="filterYes"
                        value="Yes"
                        checked={filter === 'Yes'}
                        onChange={handleFilter}
                      />
                      <label htmlFor="filterYes">Yes</label>
                    </FormInputRadio>

                    <FormInputRadio className="form__input--50">
                      <input
                        type="radio"
                        name="filter"
                        id="filterNo"
                        value="No"
                        checked={filter === 'No'}
                        onChange={handleFilter}
                      />
                      <label htmlFor="filterNo">No</label>
                    </FormInputRadio>
                  </FormInputGroup>
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </fieldset>
        </form>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailAppDefault;
