import React from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContainerGap, Content } from '~/components/elements/content';

const OptimalContainer = styled(ContainerGap as any)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const OptimalItemImage = styled.div`
  position: relative;
  background-color: #3f38d6;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1155px) {
    margin-right: 1rem;
    width: calc(50% - 0.5rem);
  }

  @media screen and (max-width: 599px) {
    width: 100%;
  }

  img {
    &.phone__mockup {
      position: relative;
      width: 100%;
      z-index: 2;

      @media screen and (max-width: 599px) {
        width: 50%;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  li {
    position: absolute;
    width: 46.5%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    @media screen and (max-width: 1155px) {
      width: 46.5%;
    }

    @media screen and (max-width: 599px) {
      width: 23.5%;
    }

    &.active {
      z-index: 1;
    }

    img {
      width: 100%;
    }
  }
`;

const OptimalItemList = styled.ul`
  width: calc(50% - 0.5rem);

  @media screen and (max-width: 599px) {
    width: 100%;
    padding-top: 4rem;
  }

  li {
    cursor: pointer;

    h5 {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: auto;
      padding: 1rem 0;
      display: inline-block;
    }

    i {
      padding-left: 0.5rem;
    }

    &.active {
      h5 {
        &::after {
          content: '';
          position: absolute;
          bottom: 0.9rem;
          width: 100%;
          height: 0.2rem;
          background-color: #62dee9;
          display: block;
        }
      }
    }

    p {
      padding-bottom: 3rem;
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

interface IProps {
  adType: string;
  handleAdType: (type: string) => void;
}

function HomeAdForm({ adType, handleAdType }: IProps) {
  return (
    <Content id="ad-form">
      <h3 className="m_tit">
        <FormattedMessage id="home.format.title" />
      </h3>
      <OptimalContainer>
        <OptimalItemImage>
          <div data-aos="fade-up" data-aos-duration="1000">
            <img
              className="phone__mockup"
              src={require('~/assets/images/p1_optimal_mockup.png').default}
              alt=""
            />
            <ul>
              <li className={cx({ active: adType === 'banner' })}>
                <img
                  src={require('~/assets/images/p1_optimal1.png').default}
                  alt=""
                />
              </li>
              <li className={cx({ active: adType === 'inter' })}>
                <img
                  src={require('~/assets/images/p1_optimal2.png').default}
                  alt=""
                />
              </li>
              <li className={cx({ active: adType === 'video' })}>
                <img
                  src={require('~/assets/images/p1_optimal3.gif').default}
                  alt=""
                />
              </li>
              <li className={cx({ active: adType === 'native' })}>
                <img
                  src={require('~/assets/images/p1_optimal4.png').default}
                  alt=""
                />
              </li>
              <li className={cx({ active: adType === 'player' })}>
                <img
                  src={require('~/assets/images/p1_optimal5.gif').default}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </OptimalItemImage>
        <OptimalItemList>
          <li
            className={cx({ active: adType === 'banner' })}
            onClick={() => handleAdType('banner')}
          >
            <h5 className="s_tit">
              <FormattedMessage id="home.format.banner" />
            </h5>
            <i
              className={cx(
                { 'icon-chevron-down': adType !== 'banner' },
                { 'icon-chevron-up': adType === 'banner' },
              )}
            />
            <p className={cx('s_txt', { active: adType === 'banner' })}>
              <FormattedMessage id="home.format.bannerDesc" />
            </p>
          </li>
          <li
            className={cx({ active: adType === 'inter' })}
            onClick={() => handleAdType('inter')}
          >
            <h5 className="s_tit">
              <FormattedMessage id="home.format.inter" />
            </h5>
            <i
              className={cx(
                { 'icon-chevron-down': adType !== 'inter' },
                { 'icon-chevron-up': adType === 'inter' },
              )}
            />
            <p className={cx('s_txt', { active: adType === 'inter' })}>
              <FormattedMessage id="home.format.interDesc" />
            </p>
          </li>
          <li
            className={cx({ active: adType === 'video' })}
            onClick={() => handleAdType('video')}
          >
            <h5 className="s_tit">
              <FormattedMessage id="home.format.video" />
            </h5>
            <i
              className={cx(
                { 'icon-chevron-down': adType !== 'video' },
                { 'icon-chevron-up': adType === 'video' },
              )}
            />
            <p className={cx('s_txt', { active: adType === 'video' })}>
              <FormattedMessage id="home.format.videoDesc" />
            </p>
          </li>
          <li
            className={cx({ active: adType === 'native' })}
            onClick={() => handleAdType('native')}
          >
            <h5 className="s_tit">
              <FormattedMessage id="home.format.native" />
            </h5>
            <i
              className={cx(
                { 'icon-chevron-down': adType !== 'native' },
                { 'icon-chevron-up': adType === 'native' },
              )}
            />
            <p className={cx('s_txt', { active: adType === 'native' })}>
              <FormattedMessage id="home.format.nativeDesc" />
            </p>
          </li>
          <li
            className={cx({ active: adType === 'player' })}
            onClick={() => handleAdType('player')}
          >
            <h5 className="s_tit">
              <FormattedMessage id="home.format.player" />
            </h5>
            <i
              className={cx(
                { 'icon-chevron-down': adType !== 'player' },
                { 'icon-chevron-up': adType === 'player' },
              )}
            />
            <p className={cx('s_txt', { active: adType === 'player' })}>
              <FormattedMessage id="home.format.playerDesc" />
            </p>
          </li>
        </OptimalItemList>
      </OptimalContainer>
    </Content>
  );
}

export default HomeAdForm;
