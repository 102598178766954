import React from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import ReportAd from '~/components/ad/report/report-ad';
import { MobileGnbAd } from '~/components/layout/header-mobile';
import { GnbAd } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function ReportAdPage() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout
        customerTypeName={intl.formatMessage({ id: 'logo.advertiser' })}
        webGnb={<GnbAd />}
        mobileGnb={<MobileGnbAd />}
        mainClassName="console"
      >
        <ReportAd />
      </Layout>
    </>
  );
}

export default ReportAdPage;
