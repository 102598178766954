import styled from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 170px;
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-top: 1px solid #1b1c23;
    white-space: nowrap;
  }

  tr {
    background-color: #fff;
  }

  /* 테이블 뎁스 */
  tr.table__depth {
    background-color: #f8f8f8;
  }

  tr.table__depth th {
    background-color: #f8f8f8;
  }

  tr.table__depth th:not(.table__title) {
    padding-left: 45px;
  }

  tr.table__depth th.table__title {
    padding-left: 28px;
  }

  tr.table__depth th,
  tr.table__depth td {
    border-bottom-color: transparent;
  }

  tr.table__2depth {
    background-color: #e8eaeb;
  }

  tr.table__2depth th {
    background-color: #e8eaeb;
  }

  tr.table__2depth th.table__title {
    padding-left: 41px;
  }

  tr.table__2depth th,
  tr.table__2depth td {
    border-bottom-color: transparent;
  }

  /* end 테이블 뎁스 */

  th,
  td {
    padding: 15px 15px 13px;
    vertical-align: middle;
    border-bottom: 1px solid #e6e8eb;
    color: #808991;
  }

  th:first-child:not(.table__title) {
    min-width: 60px;
    width: 70px;
  }

  /* thead */

  thead th {
    // position: relative;
    // top: 0;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #e6e8eb;
    background-color: #fff;
    color: #808991;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
  }

  thead:hover th {
    border-right-color: #e6e8eb;
  }

  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 4;
  }

  thead th:last-child {
    border-right: 0;
  }

  thead th i.icon-info {
    top: -1px;
    padding-left: 3px;
  }

  /* 정렬 아이콘 icon-drop- */

  thead th i {
    position: relative;
    top: -2px;
    vertical-align: middle;
    color: #808991;
    font-size: 18px !important;
    display: none;
    padding-left: 0.5rem;
  }

  thead th.active i {
    display: inline-block;
    display: none;
  }
  /* end 정렬 아이콘 icon-drop- */

  /* end thead */

  /* tbody */
  tbody {
    cursor: pointer;
  }

  tbody span.subText {
    display: block;
  }

  tbody tr:last-child th,
  tbody tr:last-child td {
    border-bottom-color: #e6e8eb;
  }

  tbody tr:hover,
  tbody tr:hover th {
    background-color: #f2f7ff !important;
  }

  tbody th {
    position: sticky;
    left: 0;
    white-space: nowrap;
    background-color: #fff;
    z-index: 1;
  }

  tbody th:first-child > i {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    width: 20px;
    height: 20px;
  }

  tbody th i.icon-pause,
  tbody th i.icon-box-open {
    color: #3f38d6;
  }

  tbody th i.icon-play_arrow,
  tbody th i.icon-box-closed {
    color: rgba(0, 0, 0, 0.6);
  }

  tbody th:hover > i {
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.3s;
  }

  tbody td {
    position: relative;
  }

  tbody td i:not([class^='icon-chevron']) {
    font-size: 14px !important;
    padding-right: 8px;
    color: #808991;
  }

  tbody td i.icon-gavel {
    position: absolute;
    left: 10px;
    font-size: 12px !important;
    padding: 2px;
    margin-right: 1rem;
    border-radius: 4px;
    color: #ffffff;
    background-color: #3f38d6;
  }

  .table__title {
    color: #1b1c23;
    font-weight: 400;
  }

  .table__important {
    color: #1b1c23;
    font-weight: 400;
  }

  .table__warning {
    color: #ee5253;
  }

  .depth__plus {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #808991;
    border-radius: 6px;
  }

  .depth__plus.open {
    background-color: transparent;
    border: 1px solid #808991;
  }

  .depth__plus.open::before {
    opacity: 0;
    transition: ease-in 0.3s;
  }

  .depth__plus.open:before,
  .depth__plus.open:after {
    background-color: #808991;
  }

  .depth__plus:before,
  .depth__plus:after {
    content: '';
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
  }

  .depth__plus:before {
    left: 50%;
    top: 5px;
    bottom: 5px;
    width: 2px;
    transform: translateX(-50%);
  }

  .depth__plus:after {
    top: 50%;
    left: 5px;
    right: 5px;
    height: 2px;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 716px) {
    .table__title > span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      text-align: left;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-width: 80px;
      margin: 0px;
      height: 100%;
      max-height: 42px;
    }
  }

  @media screen and (max-width: 599px) {
    .border__right {
      border-right: 1px solid #d4d4d4;
    }
  }
`;
