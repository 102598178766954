import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IRevenueDocProps {
  isAccount: number;
  handleClickDoc: () => void;
}

function RevenueDoc({ isAccount, handleClickDoc }: IRevenueDocProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="pubmypage.revenueGive.doc" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputContentWrap>
        <span className="m_txt">
          {isAccount === 0 ? (
            <FormattedMessage id="pubmypage.revenueGive.doc.notSubmit" />
          ) : (
            <FormattedMessage id="pubmypage.revenueGive.doc.submit" />
          )}
          <i className="icon-chevron-right" />
        </span>
        <button className="btn contentBtn" onClick={handleClickDoc}>
          <FormattedMessage id="common.modify" />
        </button>
      </FormInputContentWrap>
    </FormInput>
  );
}

export default RevenueDoc;
