import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Hint from '~/components/common/hint';
import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import {
  IReportByDaily,
  IReportByDailyApp,
  IReportByDailyMedia,
} from '~/models/pub/report';

import ReportDayListDaily from './report-day06-list-daily';

interface IReportDayListProps {
  dailyList: IReportByDaily[];
  registTime: string;
  appList: IReportByDailyApp[] | null;
  appID: string;
  medList: IReportByDailyMedia[] | null;
  handleClickDaily: (value: string) => void;
  handleClickApp: (value: string) => void;
}

const TableContainer = styled(DefaultTableContainer as any)`
  th:first-child:not(.table__title) {
    min-width: 80px;
  }

  @media screen and (max-width: 599px) {
    th:nth-child(2) {
      position: sticky;
      left: 80px;
      z-index: 4;
    }
  }
`;

function ReportDayList({
  dailyList,
  registTime,
  appList,
  appID,
  medList,
  handleClickDaily,
  handleClickApp,
}: IReportDayListProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th />
            <th className="text__left border__right">
              <FormattedMessage id="pub.report.day" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.revenue" />
              (KRW)
              <Hint title="hint.revenue" content="hint.revenue.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.ecpm" />
              (KRW)
              <Hint title="hint.ecpm" content="hint.ecpm.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.impression" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.install" />
              <Hint title="hint.install" content="hint.install.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="pub.report.ctr" />
              (%)
              <Hint title="hint.ctr" content="hint.ctr.desc" />
            </th>
          </tr>
        </thead>
        <tbody>
          {dailyList &&
            dailyList.map((daily, index) => (
              <ReportDayListDaily
                key={index}
                daily={daily}
                registTime={registTime}
                appList={appList}
                appID={appID}
                medList={medList}
                handleClickDaily={handleClickDaily}
                handleClickApp={handleClickApp}
              />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ReportDayList;
