import React, { useCallback, useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import moment from 'moment';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { pubAppAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentBottom,
  OverlayContentTop,
  OverlayHeader,
  OverlayHeaderRight,
  OverlayHeaderTitle,
  OverlayTopWrap,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IResAppDetail } from '~/models/pub/app';
import { IResBase } from '~/models/res-base';
import { setIsFullLoader } from '~/modules/core';
import { setErrorType } from '~/modules/error';

import DetailApp from './detail01-app';

interface IFormData {
  name: string;
  platform: string;
  storeURL: string;
  filter: string;
  dayLimit: string;
}

function Detail() {
  const [searchName, setSearchName] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(0);
  const [storeURL, setStoreURL] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [apiKeyStatus, setApiKeyStatus] = useState<number>(0);
  const [errorData, setErrorData] = useState<any>(null);

  const { id: appID } = useParams<any>();

  const [{ data, loading, error }] = useAxios<IResAppDetail>(
    {
      url: pubAppAPI.get,
      method: 'POST',
      data: {
        appID,
      },
    },
    { useCache: false },
  );

  const { register, handleSubmit, errors, setValue } = useForm<IFormData>({
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    const qs = queryString.parse(location.search);

    setSearchName(String(qs.name || ''));
    setPageNo(Number(qs.pageNo || 1));
  }, [location.search, searchName]);

  useEffect(() => {
    if (data) {
      const { app } = data;
      if (!app) {
        dispatch(setErrorType('NOT_FOUND'));
        return;
      }

      const {
        Name,
        Platform,
        Package,
        ApiKey,
        ApiKeyStatus,
        DayLimit,
        Filter,
      } = app;

      const obj = JSON.parse(Filter);
      const filter = obj.Adult;

      setValue('name', Name);
      setValue('platform', Platform);
      setValue('storeURL', Package);
      setValue('filter', filter ? 'Yes' : 'No');
      setValue('dayLimit', String(DayLimit));

      setStoreURL(Package);
      setApiKey(ApiKey);
      setApiKeyStatus(ApiKeyStatus);
    }
  }, [data, dispatch, setValue]);

  const handleClose = useCallback(() => {
    history.push(`/pub-app?name=${searchName}&pageNo=${pageNo}`);
  }, [history, pageNo, searchName]);

  useKeyboardEvent('Escape', document.body, handleClose);

  const onSubmit = useCallback(
    async ({ name, storeURL: url, filter, dayLimit }: IFormData) => {
      const limit = dayLimit || 0;

      try {
        if (window.confirm(f({ id: 'confirm.modify' }))) {
          dispatch(setIsFullLoader(true));

          const { data: modifyData } = await defaultAxios.post<IResBase>(
            pubAppAPI.modify,
            {
              appID,
              name,
              storeURL: url,
              filter,
              limit,
            },
          );

          dispatch(setIsFullLoader(false));

          if (modifyData.error === 'SUCCESS') {
            window.alert(f({ id: 'alert.modify' }));
            handleClose();
          } else {
            window.alert(f({ id: `errors.${modifyData.error}` }));
          }
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    },
    [appID, dispatch, f, handleClose],
  );

  const handleRequestApiKey = async () => {
    try {
      if (window.confirm(f({ id: 'confirm.request' }))) {
        dispatch(setIsFullLoader(true));

        const { data: modifyData } = await defaultAxios.post<IResBase>(
          pubAppAPI.requestApiKey,
          {
            appID,
          },
        );

        dispatch(setIsFullLoader(false));

        if (modifyData.error === 'SUCCESS') {
          window.alert(f({ id: 'alert.request' }));
          handleClose();
        } else {
          window.alert(f({ id: `errors.${modifyData.error}` }));
        }
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data || !data.app) {
    return null;
  }

  const { RegistTime, UpdateTime } = data.app;

  return (
    <main className="console">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>상세정보 - 앱</legend>
          <OverlayContainer>
            <OverlayHeader>
              <i className="icon-x" onClick={handleClose} />
              <OverlayHeaderTitle className="m_txt">
                {data.app.Name}
              </OverlayHeaderTitle>
              <OverlayHeaderRight>
                <Link to={`/pub-inventory?appID=${appID}`}>
                  <i className="icon-launchopen_in_new" />
                  <div className="btn__txt">
                    <FormattedMessage id="pub.app.goInventory" />
                  </div>
                </Link>
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.modify" />
                  </div>
                </button>
              </OverlayHeaderRight>
            </OverlayHeader>
            <OverlayContent>
              <OverlayContentTop>
                <OverlayTopWrap>
                  <h4 className="overlay__title m_tit">
                    <FormattedMessage id="ad.campaign.detail" />
                  </h4>
                  <ul className="overlay__top--detail">
                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.registTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(RegistTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>

                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.updateTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(UpdateTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>
                  </ul>
                </OverlayTopWrap>
              </OverlayContentTop>
              <OverlayContentBottom>
                <DetailApp
                  register={register}
                  errors={errors}
                  storeURL={storeURL}
                  apiKey={apiKey}
                  apiKeyStatus={apiKeyStatus}
                  handleRequestApiKey={handleRequestApiKey}
                />
              </OverlayContentBottom>
            </OverlayContent>
          </OverlayContainer>
        </fieldset>
      </form>
    </main>
  );
}

export default Detail;
