import React from 'react';

import ReactModal from 'react-modal';

import HideScrollBar from './hide-scroll-bar';

ReactModal.setAppElement('#root');

interface IProps {
  isOpen: boolean;
  sizeClassName?: string;
  isOverlayClose: boolean;
  isEscClose: boolean;
  handleRequestClose: () => void;
  children: React.ReactNode;
}

function Modal({
  isOpen,
  sizeClassName,
  isOverlayClose,
  isEscClose,
  handleRequestClose,
  children,
}: IProps) {
  return (
    <>
      {isOpen && <HideScrollBar />}
      <ReactModal
        isOpen={isOpen}
        className={
          sizeClassName
            ? `modal__box ${sizeClassName} mobile active`
            : 'modal__box mobile active'
        }
        overlayClassName="modal__container"
        shouldCloseOnOverlayClick={isOverlayClose}
        shouldCloseOnEsc={isEscClose}
        onRequestClose={handleRequestClose}
      >
        {children}
      </ReactModal>
    </>
  );
}

export default Modal;
