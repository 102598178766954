import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { pubAppAPI } from '~/api';
import Pager from '~/components/common/pager';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { pressedEnter } from '~/libs/utils';
import { IResAppList } from '~/models/pub/app';
import { setIsFullLoader } from '~/modules/core';

import ListSearch from './list01-search';
import ListTable from './list02-table';

function List() {
  const [pageNo, setPageNo] = useState<number>(1);
  const [name, setName] = useState<string>('');

  const [{ data, loading, error }, execute] = useAxios<IResAppList>(
    {
      url: pubAppAPI.list,
      method: 'GET',
    },
    { manual: true },
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const qsPageNo = Number(qs.pageNo || 1);
    const qsSearchName = String(qs.name || '');

    setPageNo(qsPageNo);
    setName(qsSearchName);

    execute({
      params: {
        pageNo: qsPageNo,
        name: qsSearchName,
      },
    });
  }, [execute, location.search]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!data) {
    return null;
  }

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleKeyPressName = pressedEnter(async () => {
    await handleSearch(name);
  });

  const handleSearch = (value: string) => {
    history.push(`/pub-app?name=${value}&pageNo=1`);
  };

  const handleNewApp = () => {
    history.push(`/pub-app-create?name=${name}&pageNo=${pageNo}`);
  };

  const handleDetail = (appID: string) => {
    if (appID !== '') {
      history.push(`/pub-app/${appID}?name=${name}&pageNo=${pageNo}`);
    }
  };

  const handleChangePage = (no: number) => {
    setPageNo(no);

    history.push(`/pub-app?name=${name}&pageNo=${no}`);
  };

  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="pub.app.list" />
      </h1>
      <ListSearch
        name={name}
        handleName={handleName}
        handleKeyPressName={handleKeyPressName}
        handleSearch={handleSearch}
        handleNewApp={handleNewApp}
      />
      <ListTable apps={data.apps} handleDetail={handleDetail} />
      <Pager
        pageNo={pageNo}
        pageSize={10}
        blockPage={10}
        rowCount={data.rowCount}
        handleChangePage={handleChangePage}
      />
    </ConsoleContent>
  );
}

export default List;
