import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { StateContainer } from '~/components/elements/state';
import { IState } from '~/models/ad/report';

interface IReportAdState {
  state: IState;
}

function ReportAdState({ state }: IReportAdState) {
  const { impression, click, ctr } = state;

  return (
    <StateContainer>
      <li>
        <p className="m_txt">
          <FormattedMessage id="ad.report.totalImpression" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat
              value={impression}
              displayType="text"
              thousandSeparator
            />
          </b>
        </span>
      </li>
      <li>
        <p className="m_txt">
          <FormattedMessage id="ad.report.totalClick" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat value={click} displayType="text" thousandSeparator />
          </b>
        </span>
      </li>
      <li>
        <p className="m_txt">
          <FormattedMessage id="ad.report.ctr" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat
              value={ctr}
              displayType="text"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              suffix=" %"
            />
          </b>
        </span>
      </li>
    </StateContainer>
  );
}

export default ReportAdState;
