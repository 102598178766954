import React, { useCallback, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  NavLink as RouterNavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import { RootState } from '~/modules';
import { setMobileMenuOpen } from '~/modules/header';

interface IProps {
  customerTypeName?: string;
  handleSignout: () => void;
  children: React.ReactNode;
}

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  z-index: 9;

  li {
    i {
      padding: 0.3rem;
    }

    ul {
      flex-direction: column;
      position: absolute;
      top: 50px;
      width: 200px;
      padding: 0.3rem 0;
      background-color: #fff;
      border: 1px solid #ebebeb;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      display: none;

      &::after {
        content: '';
        position: absolute;
        top: -5px;
        left: 10px;
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-top: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        transform: rotate(45deg);
      }

      a {
        width: 100%;
      }
    }
  }

  &.header__scroll {
    border-bottom: 1px solid #ebebeb;
  }

  @media screen and (max-width: 1155px) {
    height: 50px;
  }
`;

const Nav = styled.nav`
  position: relative;
  max-width: 1920px;
  height: 60px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  ul {
    > li {
      padding: 10px 20px;
    }

    li {
      &.open {
        ul {
          display: flex;
        }
      }
    }
  }

  @media screen and (max-width: 1155px) {
    height: 50px;
  }

  @media screen and (max-width: 599px) {
    padding: 0 16px;
  }
`;

const UL = styled.ul`
  display: flex;
  align-items: center;

  &.gnb__menu {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  > li {
    padding: 10px 20px;
  }
`;

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
`;

const NavLink = styled(RouterNavLink)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
`;

const GnbLogo = styled.a`
  padding: 10px 0 !important;

  @media screen and (max-width: 1155px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4px;
  }
`;

const GnbLogoImage = styled.img`
  width: 115px;
  height: 20px;

  + b {
    color: #000;
    padding-left: 8px;
    white-space: nowrap;
  }

  @media screen and (max-width: 1155px) {
    width: 100px;
  }
`;

const GnbMenu = styled(UL as any)`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 1155px) {
    display: none;
  }
`;

const GnbLogin = styled.div`
  @media screen and (max-width: 1155px) {
    display: none;
  }
`;

const GnbLoginAfter = styled(UL as any)``;

const MobileGnbBtn = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  > i {
    border-radius: 60px;
    padding: 15.5px;
    color: #1b1c23;
    z-index: 999;
  }

  @media screen and (max-width: 1155px) {
    display: flex;
  }

  @media screen and (max-width: 599px) {
    left: -4px;
  }
`;

export function GnbHome() {
  return (
    <GnbMenu>
      <NavLink exact to="/" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.home.index" />
        </li>
      </NavLink>
      <NavLink to="/home-start" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.home.start" />
        </li>
      </NavLink>
      <NavLink to="/home-resource" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.home.resource" />
        </li>
      </NavLink>
      <a href="https://xplayz.zenaad.com" target="_blank" rel="noreferrer">
        <li>xPlayz</li>
      </a>
      <a href="https://www.zenaplay.com" target="_blank" rel="noreferrer">
        <li>ZenaPlay</li>
      </a>
      <a href="https://mcn.zenaad.com/" target="_blank" rel="noreferrer">
        <li>MCN</li>
      </a>
    </GnbMenu>
  );
}

export function GnbSdk() {
  const { formatMessage } = useIntl();

  return (
    <GnbMenu>
      <NavLink to="/home-sdk" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.sdkAndSupport.sdk" />
        </li>
      </NavLink>
      {/* <NavLink to="/home-support" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.sdkAndSupport.support" />
        </li>
      </NavLink> */}
      <li
        className="cursor__pointer"
        onClick={() => {
          window.alert(formatMessage({ id: 'alert.ready' }));
        }}
      >
        <FormattedMessage id="gnb.sdkAndSupport.support" />
      </li>
    </GnbMenu>
  );
}

export function GnbApp() {
  return (
    <GnbMenu>
      <NavLink to="/home-game" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.app.game" />
        </li>
      </NavLink>
      <NavLink to="/home-app" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.app.app" />
        </li>
      </NavLink>
    </GnbMenu>
  );
}

export function GnbPartner() {
  return (
    <GnbMenu>
      <NavLink exact to="/home-mediation" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.partner.mediation" />
        </li>
      </NavLink>
      <NavLink to="/home-publisher" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.partner.publisher" />
        </li>
      </NavLink>
    </GnbMenu>
  );
}

export function GnbEmpty() {
  return <GnbMenu />;
}

export function GnbAd() {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const listener = () => {
      setShowMenu(false);
    };

    if (showMenu) {
      window.addEventListener('click', listener);
    } else {
      window.removeEventListener('click', listener);
    }

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [showMenu]);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  return (
    <GnbMenu>
      <NavLink to="/ad" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.ad.home" />
        </li>
      </NavLink>
      <NavLink to="/ad-campaign" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.ad.campaign" />
        </li>
      </NavLink>
      <NavLink to="/ad-ad" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.ad.ad" />
        </li>
      </NavLink>
      <li
        className={showMenu ? 'cursor__pointer open' : 'cursor__pointer'}
        onClick={handleShowMenu}
      >
        <FormattedMessage id="gnb.ad.report" />
        <i className="icon-chevron-down" />
        <ul>
          <NavLink to="/ad-report-campaign" activeClassName="active">
            <li>
              <FormattedMessage id="gnb.ad.report.campaign" />
            </li>
          </NavLink>
          <NavLink to="/ad-report-ad" activeClassName="active">
            <li>
              <FormattedMessage id="gnb.ad.report.ad" />
            </li>
          </NavLink>
        </ul>
      </li>
    </GnbMenu>
  );
}

export function GnbPub() {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const listener = () => {
      setShowMenu(false);
    };

    if (showMenu) {
      window.addEventListener('click', listener);
    } else {
      window.removeEventListener('click', listener);
    }

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [showMenu]);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  return (
    <GnbMenu>
      <NavLink to="/pub" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.pub.home" />
        </li>
      </NavLink>
      <NavLink to="/pub-app" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.pub.app" />
        </li>
      </NavLink>
      <NavLink to="/pub-inventory" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.pub.inventory" />
        </li>
      </NavLink>
      <NavLink to="/pub-app-reward" activeClassName="active">
        <li>
          <FormattedMessage id="gnb.pub.appReward" />
        </li>
      </NavLink>
      <li
        className={showMenu ? 'cursor__pointer open' : 'cursor__pointer'}
        onClick={handleShowMenu}
      >
        <FormattedMessage id="gnb.pub.report" />
        <i className="icon-chevron-down" />
        <ul>
          <NavLink to="/pub-report-day" activeClassName="active">
            <li>
              <FormattedMessage id="gnb.pub.report.day" />
            </li>
          </NavLink>
          <NavLink to="/pub-report-app" activeClassName="active">
            <li>
              <FormattedMessage id="gnb.pub.report.app" />
            </li>
          </NavLink>
          <NavLink to="/pub-report-user" activeClassName="active">
            <li>
              <FormattedMessage id="gnb.pub.report.user" />
            </li>
          </NavLink>
        </ul>
      </li>
    </GnbMenu>
  );
}

function HeaderWeb({ customerTypeName, handleSignout, children }: IProps) {
  // const { mobileMenuOpen, setMobileMenuOpen } = useContext(HeaderContext);

  const dispatch = useDispatch();
  const { user, mobileMenuOpen } = useSelector((state: RootState) => ({
    user: state.user.user,
    mobileMenuOpen: state.header.mobileMenuOpen,
  }));
  const history = useHistory();
  const location = useLocation();

  const handleManageConsole = useCallback(() => {
    if (user) {
      if (user.Type === 'ADVERTISER') {
        history.push('/ad');
      } else {
        history.push('/pub');
      }
    }
  }, [history, user]);

  return (
    <>
      <Header id="header" className="m_txt">
        <MobileGnbBtn onClick={() => dispatch(setMobileMenuOpen(true))}>
          <i className="icon-menu" />
        </MobileGnbBtn>
        <Nav>
          {!mobileMenuOpen && (
            <GnbLogo href="/">
              <GnbLogoImage
                src={require('~/assets/images/header__logo.svg').default}
                width="100%"
                height="100%"
                alt="ZENAAD"
              />
              {customerTypeName && <b>{customerTypeName}</b>}
            </GnbLogo>
          )}
          {children}
          <GnbLogin>
            {user ? (
              <GnbLoginAfter>
                <Link
                  to={
                    user.Type === 'ADVERTISER' ? '/ad-my-page' : '/pub-my-page'
                  }
                >
                  <li>
                    <FormattedMessage id="gnb.mypage" />
                  </li>
                </Link>
                <li className="cursor__pointer" onClick={handleSignout}>
                  <FormattedMessage id="gnb.signout" />
                </li>

                {location.pathname === '/' ||
                location.pathname.startsWith('/home') ? (
                  <button className="small btn" onClick={handleManageConsole}>
                    <FormattedMessage id="gnb.manageConsole" />
                  </button>
                ) : null}
                {/* {location.pathname === '/' ||
                  (location.pathname.startsWith('/home') && (
                    <button className="small btn" onClick={handleManageConsole}>
                      <FormattedMessage id="gnb.manageConsole" />
                    </button>
                  ))} */}
              </GnbLoginAfter>
            ) : (
              <Link to="/signin" className="btn">
                <FormattedMessage id="gnb.signin" />
              </Link>
            )}
          </GnbLogin>
        </Nav>
      </Header>
    </>
  );
}

export default HeaderWeb;
