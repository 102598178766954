import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { pubReportAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { config } from '~/libs/config';
import { getPosibleSearchDate } from '~/libs/utils';
import { IReportByApp, IResReportByApp, IState } from '~/models/pub/report';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import {
  setSearchEndDate,
  setSearchSelectDay,
  setSearchStartDate,
} from '~/modules/user';

import ReportAppFilter from './report-app01-filter';
import ReportAppState from './report-app02-state';
import ReportAppExport from './report-app03-export';
import ReportAppList from './report-app04-list';

function ReportApp() {
  const [state, setState] = useState<IState>({
    impression: 0,
    revenue: 0,
    ecpm: 0,
  });
  const [apps, setApps] = useState<IReportByApp[] | null>(null);
  const [csv, setCSV] = useState<any>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { searchSelectDay, searchStartDate, searchEndDate } = useSelector(
    (s: RootState) => s.user,
  );

  const [{ data, loading, error }] = useAxios<IResReportByApp>(
    {
      url: pubReportAPI.reportByApp,
      method: 'GET',
      params: {
        startDate: searchStartDate,
        endDate: searchEndDate,
      },
    },
    { useCache: false },
  );

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.list) {
        let totalImpression = 0;
        let totalRevenue = 0;
        let totaleCPM = 0;

        const csvData: any = [
          [
            formatMessage({ id: 'pub.report.appName' }),
            formatMessage({ id: 'pub.report.revenue' }),
            formatMessage({ id: 'pub.report.ecpm' }),
            formatMessage({ id: 'pub.report.impression' }),
            formatMessage({ id: 'pub.report.install' }),
            `${formatMessage({ id: 'pub.report.ctr' })}(%)`,
          ],
        ];

        for (const item of data.list) {
          totalImpression += Number(item.Impressions);
          totalRevenue += Number(item.Revenue);
          // totaleCPM = totaleCPM + Number(item.eCPM);

          csvData.push([
            item.AppName,
            item.Revenue,
            item.eCPM,
            item.Impressions,
            item.Installs,
            item.CTR,
          ]);
        }

        if (totalImpression !== 0 && totalRevenue !== 0) {
          totaleCPM = (totalRevenue / totalImpression) * 1000;
        }

        setApps(data.list);
        setState({
          impression: totalImpression,
          revenue: totalRevenue,
          ecpm: totaleCPM,
        });
        setCSV(csvData);
      }
    }
  }, [data, formatMessage]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!apps) {
    return null;
  }

  const handleSearch = (day: number, sdate: any, edate: any) => {
    const valid = getPosibleSearchDate(sdate, edate, config.searchDay);
    if (!valid) {
      window.alert(
        formatMessage({ id: 'validate.searchDay' }, { days: config.searchDay }),
      );
      return;
    }

    dispatch(setSearchSelectDay(day));
    dispatch(setSearchStartDate(sdate));
    dispatch(setSearchEndDate(edate));
  };

  return (
    <>
      <ConsoleContent>
        <ReportAppFilter
          searchSelectDay={searchSelectDay}
          searchStartDate={searchStartDate}
          searchEndDate={searchEndDate}
          handleSearch={handleSearch}
        />
        <ReportAppState state={state} />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        <ReportAppExport
          csv={csv}
          searchStartDate={searchStartDate}
          searchEndDate={searchEndDate}
        />
        <ReportAppList apps={apps} />
      </ConsoleContent>
    </>
  );
}

export default ReportApp;
