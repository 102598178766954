import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { ConsoleContent } from '~/components/elements/console-content';
import { StateContainer } from '~/components/elements/state';
import { IState } from '~/models/pub/home';

interface IHomeNetworkProps {
  state: IState;
}

function HomeNetwork({ state }: IHomeNetworkProps) {
  const { dau, revenue, ecpm, installs } = state;

  return (
    <>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="pub.home.network" />
        </h1>
        <StateContainer>
          <li>
            <p className="m_txt">
              <FormattedMessage id="pub.home.dau" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={dau}
                  displayType="text"
                  thousandSeparator
                />
              </b>
            </span>
          </li>
          <li>
            <p className="m_txt">
              <FormattedMessage id="pub.home.revenue" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={revenue}
                  displayType="text"
                  thousandSeparator
                />
              </b>
              <span className="ms_tit krw">KRW</span>
            </span>
          </li>
          <li>
            <p className="m_txt">
              <FormattedMessage id="pub.home.ecpm" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={ecpm}
                  displayType="text"
                  thousandSeparator
                />
              </b>
              <span className="ms_tit krw">KRW</span>
            </span>
          </li>
          <li>
            <p className="m_txt">
              <FormattedMessage id="pub.home.install" />
            </p>
            <span className="m_tit">
              <b>
                <NumberFormat
                  value={installs}
                  displayType="text"
                  thousandSeparator
                />
              </b>
            </span>
          </li>
        </StateContainer>
      </ConsoleContent>
    </>
  );
}

export default HomeNetwork;
