import React, { useCallback, useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import { pubAppRewardAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentBottom,
  OverlayContentTop,
  OverlayHeader,
  OverlayHeaderRight,
  OverlayHeaderTitle,
  OverlayTopWrap,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IResAppRewardDetail } from '~/models/pub/appReward';
import { IResBase } from '~/models/res-base';
import { setIsFullLoader } from '~/modules/core';
import { setErrorType } from '~/modules/error';

import DetailAppReward from './detail01-app-reward';

interface IFormData {
  rewardName: string;
  reward: string;
  dayLimit: string;
}

function Detail() {
  const [rewardKey, setRewardKey] = useState<string>('');
  const [errorData, setErrorData] = useState<any>(null);

  const { id: appRewardID } = useParams<any>();

  const [{ data, loading, error }] = useAxios<IResAppRewardDetail>(
    {
      url: pubAppRewardAPI.get,
      method: 'POST',
      data: {
        appRewardID,
      },
    },
    { useCache: false },
  );

  const { register, handleSubmit, errors, setValue } = useForm<IFormData>({
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const [query] = useQueryParams({
    appID: StringParam,
  });

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      const { appReward } = data;
      if (!appReward) {
        dispatch(setErrorType('NOT_FOUND'));
        return;
      }

      const { RewardKey, RewardName, Reward, DayLimit } = appReward;

      setValue('rewardName', RewardName);
      setValue('reward', Reward);
      setValue('dayLimit', String(DayLimit));

      setRewardKey(RewardKey);
    }
  }, [data, dispatch, setValue]);

  const handleClose = useCallback(() => {
    history.push(`/pub-app-reward?appID=${query.appID}`);
  }, [history, query]);

  useKeyboardEvent('Escape', document.body, handleClose);

  const onSubmit = useCallback(
    async (formData: IFormData) => {
      try {
        if (window.confirm(f({ id: 'confirm.modify' }))) {
          dispatch(setIsFullLoader(true));

          const { data: modifyData } = await defaultAxios.post<IResBase>(
            pubAppRewardAPI.modify,
            {
              appRewardID,
              rewardName: formData.rewardName,
              reward: formData.reward,
              dayLimit: formData.dayLimit,
            },
          );

          dispatch(setIsFullLoader(false));

          if (modifyData.error === 'SUCCESS') {
            window.alert(f({ id: 'alert.modify' }));
            handleClose();
          } else {
            window.alert(f({ id: `errors.${modifyData.error}` }));
          }
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    },
    [appRewardID, dispatch, f, handleClose],
  );

  const handleDelete = async () => {
    try {
      if (window.confirm(f({ id: 'confirm.delete' }))) {
        dispatch(setIsFullLoader(true));

        const { data: deleteData } = await defaultAxios.post<IResBase>(
          pubAppRewardAPI.remove,
          {
            appRewardID,
          },
        );

        dispatch(setIsFullLoader(false));

        if (deleteData.error === 'SUCCESS') {
          window.alert(f({ id: 'alert.delete' }));
          handleClose();
        } else {
          window.alert(f({ id: `errors.${deleteData.error}` }));
        }
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data || !data.appReward) {
    return null;
  }

  const { RegistTime, UpdateTime } = data.appReward;

  return (
    <main className="console">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>상세정보 - 앱</legend>
          <OverlayContainer>
            <OverlayHeader>
              <i className="icon-x" onClick={handleClose} />
              <OverlayHeaderTitle className="m_txt">
                {data.appReward.RewardName}
              </OverlayHeaderTitle>
              <OverlayHeaderRight>
                <Link to={`/pub-app?appID=${appRewardID}`}>
                  <i className="icon-launchopen_in_new" />
                  <div className="btn__txt">
                    <FormattedMessage id="pub.appReward.goApp" />
                  </div>
                </Link>
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.modify" />
                  </div>
                </button>
                <button
                  type="button"
                  className="btn contentBtn"
                  onClick={handleDelete}
                >
                  <div className="btn__txt">
                    <FormattedMessage id="common.delete" />
                  </div>
                </button>
              </OverlayHeaderRight>
            </OverlayHeader>
            <OverlayContent>
              <OverlayContentTop>
                <OverlayTopWrap>
                  <h4 className="overlay__title m_tit">
                    <FormattedMessage id="ad.campaign.detail" />
                  </h4>
                  <ul className="overlay__top--detail">
                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.registTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(RegistTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>

                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.updateTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(UpdateTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>
                  </ul>
                </OverlayTopWrap>
              </OverlayContentTop>
              <OverlayContentBottom>
                <DetailAppReward
                  register={register}
                  errors={errors}
                  rewardKey={rewardKey}
                />
              </OverlayContentBottom>
            </OverlayContent>
          </OverlayContainer>
        </fieldset>
      </form>
    </main>
  );
}

export default Detail;
