import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SummaryContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  min-height: 391px;
  height: 100%;
  /* max-height: 467px; */
  max-height: 498px;

  @media screen and (max-width: 716px) {
    height: auto;
    max-height: 100%;
  }
`;

export const SummaryItemWrap = styled.div`
  width: calc(50% - 10px);
  margin: 5px;

  &:nth-child(2n + 1) {
    order: 1;
  }

  &:nth-child(2n + 2) {
    order: 1;
    order: 2;
  }

  @media screen and (max-width: 716px) {
    width: calc(100% - 0.5rem);

    &:nth-child(2n + 2) {
      order: 1;
    }
  }
`;

export const SummaryItem = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 30px;
  padding-bottom: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 716px) {
    padding: 20px;
    padding-bottom: 0;
  }

  @media screen and (max-width: 359px) {
    .btn {
      i {
        padding-right: 0;
      }

      > .btn__txt {
        display: none;
      }
    }
  }
`;

export const SummaryItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-weight: 600;
  }

  .btn {
    :first-child {
      margin-right: 0.5rem;
    }
  }
`;

export const SummaryItemBottom = styled(SummaryItemTop)`
  padding: 30px 0;

  p {
    color: #6b88e5;
  }

  span {
    &.krw {
      padding-left: 4px;
    }
  }

  @media screen and (max-width: 716px) {
    padding: 20px 0;
  }
`;

export const SummaryItemAddInfo = styled.ul`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ebebeb;
  padding: 20px 0;

  li {
    b {
      font-weight: 600;
      padding: 0 1.5rem 0 0.5rem;
    }

    &:last-child {
      b {
        padding-right: 0;
      }
    }
  }
`;

export const SummaryItemNotice = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff6f8;
  border: 1px solid #fbced7;
  padding: 20px;
  margin-top: 10px;
  border-radius: 6px;

  .btn__group {
    color: #ee5253 !important;

    i {
      padding-right: 0.5rem;
    }
  }

  span {
    color: #ee5253;
  }

  &.blue {
    background-color: #f4fdfe;
    border-color: #ccedfc;

    .btn__group {
      color: #00b7c3 !important;
    }

    span {
      color: #00b7c3;
    }
  }
`;
