import React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  background-color: #ebebeb;
`;

const GoJoinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1156px;
  margin: 0 auto;
  padding: 2rem 0;

  h5 {
    color: #666666;
  }

  > a {
    padding: 10px 20px;
    color: #ffffff;
    margin-left: 1rem;
  }

  @media screen and (max-width: 1155px) {
    width: 100%;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;

    i {
      font-size: 1.6rem;
    }

    > a {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`;

function Start01() {
  return (
    <Wrapper>
      <GoJoinContainer>
        <h5 className="xl_txt">
          <FormattedHTMLMessage id="start.with" />
        </h5>

        <Link to="/signup-email" className="btn l_txt">
          <FormattedMessage id="start.join" />
        </Link>
      </GoJoinContainer>
    </Wrapper>
  );
}

export default Start01;
