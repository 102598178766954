import React from 'react';

import { useHistory } from 'react-router-dom';

import { Content } from '~/components/elements';

import { Sign, SignContainer, SignFinContainer, SignLog } from '../elements';

function FailedAuthentication() {
  const history = useHistory();

  const handleRequestAgain = () => {
    history.push('/find-password');
  };

  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>
          <SignFinContainer>
            <h5>Authentication failed.</h5>
            <p className="m_txt">Click the link to request again.</p>
          </SignFinContainer>
          <button className="btn" onClick={handleRequestAgain}>
            Request Again
          </button>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default FailedAuthentication;
