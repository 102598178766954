import React from 'react';

import GameIntro from './game01-intro';
import GameContent from './game02-content';

function Game() {
  return (
    <>
      <GameIntro />
      <GameContent />
    </>
  );
}

export default Game;
