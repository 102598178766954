import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import urlRegex from 'url-regex';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { adAdAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentBottom,
  OverlayContentTop,
  OverlayHeader,
  OverlayHeaderRight,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { country as countryOptions } from '~/json/country';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { IAdAppInfo, IAdCreate, IResAdDetail } from '~/models/ad/ad';
import { ISelectOption } from '~/models/select-option';
import { setIsFullLoader } from '~/modules/core';
import { setErrorType } from '~/modules/error';

import DetailTop from './detail01-top';
import DetailMaterial from './detail02-material';
import DetailAppDefault from './detail03-app-default';
import DetailAppInfo from './detail04-app-info';
import DetailSchedule from './detail05-schedule';
import DetailLimit from './detail06-limit';
import DetailBid from './detail07-bid';

function Detail() {
  // const [createData, setCreateData] = useState<IAdCreate>({
  //   product: '',
  //   billingType: '',
  //   name: '',
  //   comment: '',
  //   uploadURLs: [],
  //   filenames: [],
  //   files: [],
  //   videoURL: null,
  //   videoWidth: '',
  //   videoHeight: '',
  //   country: [],
  //   campType: '',
  //   url: '',
  //   storeUrl: '',
  //   appInfo: null,
  //   schedule: '',
  //   week: [],
  //   dayLimit: 0,
  //   budgetLimit: 0,
  //   minCost: 0,
  //   maxCost: 0
  // });
  const [createData, setCreateData] = useState<IAdCreate | null>(null);
  const [appInfo, setAppInfo] = useState<IAdAppInfo | null>(null);
  const [matURLs, setMatURLs] = useState<string[]>([]);
  const [appInfoMatURLs, setAppInfoMatURLs] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('');
  const [registTime, setRegistTime] = useState<string>('');
  const [updateTime, setUpdateTime] = useState<string>('');

  const [countryError, setCountryError] = useState<number>(0);
  const [urlError, setUrlError] = useState<number>(0);
  const [weekError, setWeekError] = useState<number>(0);

  const [dayLimitError, setDayLimitError] = useState<number>(0);
  const [budgetLimitError, setBudgetLimitError] = useState<number>(0);

  const [minCostError, setMinCostError] = useState<number>(0);
  const [maxCostError, setMaxCostError] = useState<number>(0);

  const [reqLoading, setReqLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: proID } = useParams<any>();
  const [query] = useQueryParams({
    campID: NumberParam,
    name: StringParam,
    campType: StringParam,
    status: StringParam,
    product: StringParam,
    billingType: StringParam,
    pageNo: NumberParam,
  });

  const [{ data, loading, error }] = useAxios<IResAdDetail>(
    {
      url: adAdAPI.get,
      method: 'GET',
      params: {
        proID,
      },
    },
    { useCache: false },
  );

  const [{ data: bidData }, getBidCost] = useAxios(
    {
      url: adAdAPI.bidcost,
      method: 'POST',
    },
    { useCache: false, manual: true },
  );

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    const qsCampID = query.campID;
    const qsName = query.name ? query.name : '';
    const qsCampType = query.campType ? query.campType : '';
    const qsStatus = query.status ? query.status : '';
    const qsProduct = query.product ? query.product : '';
    const qsBillingType = query.billingType ? query.billingType : '';
    const qsPageNo = query.pageNo ? query.pageNo : 1;

    history.push(
      `/ad-ad?campID=${qsCampID}&name=${qsName}&campType=${qsCampType}&status=${qsStatus}&product=${qsProduct}&billingType=${qsBillingType}&pageNo=${qsPageNo}`,
    );
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    if (loading || reqLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [dispatch, loading, reqLoading]);

  useEffect(() => {
    if (data) {
      if (!data.ad) {
        dispatch(setErrorType('NOT_FOUND'));
        return;
      }

      const { ad } = data;

      const objMatNames = JSON.parse(ad.MatNames);
      setMatURLs(
        objMatNames.MatNames.map(
          (item: string) =>
            `${config.material}/${ad.Product.toLocaleLowerCase()}/${item}`,
        ),
      );

      const tempMatNames = objMatNames.MatNames.slice(1);
      setAppInfoMatURLs(
        tempMatNames.map(
          (item: string) =>
            `${config.material}/${ad.Product.toLocaleLowerCase()}/${item}`,
        ),
      );

      const objCountry = JSON.parse(ad.Country);
      const { Country: tempArrCountry } = objCountry;

      const arrCountry = tempArrCountry.map((value: string) => {
        const temp = countryOptions.find(
          (c: ISelectOption) => c.value === value,
        );
        return temp;
      });

      let sch = '';

      if (
        ad.Sunday === 16777215 &&
        ad.Monday === 16777215 &&
        ad.Tuesday === 16777215 &&
        ad.Wednesday === 16777215 &&
        ad.Thursday === 16777215 &&
        ad.Friday === 16777215 &&
        ad.Saturday === 16777215
      ) {
        sch = 'daily';
      } else if (
        ad.Sunday === 0 &&
        ad.Monday === 16777215 &&
        ad.Tuesday === 16777215 &&
        ad.Wednesday === 16777215 &&
        ad.Thursday === 16777215 &&
        ad.Friday === 16777215 &&
        ad.Saturday === 0
      ) {
        sch = 'weekday';
      } else if (
        ad.Sunday === 16777215 &&
        ad.Monday === 0 &&
        ad.Tuesday === 0 &&
        ad.Wednesday === 0 &&
        ad.Thursday === 0 &&
        ad.Friday === 0 &&
        ad.Saturday === 16777215
      ) {
        sch = 'weekend';
      } else {
        sch = 'self';
      }

      const objFilter = JSON.parse(ad.Filter);
      const strFilter = objFilter.Adult ? 'Yes' : 'No';

      const adCreate: IAdCreate = {
        product: ad.Product,
        billingType: ad.BillingType,
        name: ad.Name,
        comment: ad.Comment,
        uploadURLs: [],
        filenames: objMatNames.MatNames,
        files: [],
        videoURL: null,
        videoWidth: '',
        videoHeight: '',
        country: arrCountry,
        campType: ad.CampType,
        url: ad.CampType === 'AOS' ? ad.URL_AOS : ad.URL_iOS,
        filter: strFilter,
        storeUrl: '',
        appInfo: null,
        schedule: sch,
        week: [
          ad.Sunday,
          ad.Monday,
          ad.Tuesday,
          ad.Wednesday,
          ad.Thursday,
          ad.Friday,
          ad.Saturday,
        ],
        dayLimit: ad.DayLimit,
        budgetLimit: ad.BudgetLimit,
        minCost: ad.MinCost,
        maxCost: ad.MaxCost,
      };

      setCreateData(adCreate);
      setStatus(ad.Status);
      setRegistTime(moment(ad.RegistTime).format('YYYY-MM-DD HH:mm'));
      setUpdateTime(moment(ad.UpdateTime).format('YYYY-MM-DD HH:mm'));

      if (ad.AppInfo) {
        const temp = JSON.parse(ad.AppInfo);
        setAppInfo(temp.AppInfo);
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      if (data.ad) {
        if (
          data.ad.Status ===
          adtier0.PROMOTION_STATUS[adtier0.PROMOTION_STATUS.INPROGRESS]
        ) {
          toast.error(formatMessage({ id: 'ad.ad.detailNoti' }));
          // alertify.notifier(
          //   formatMessage({ id: 'ad.ad.detailNoti' }),
          //   'error',
          //   5,
          //   'top-center'
          // );
        }
      }
    }
  }, [data, formatMessage]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data || !data.ad || !createData) {
    return null;
  }

  const handleReqLoading = (value: boolean) => {
    setReqLoading(value);
  };

  const handleReqError = (err: any) => {
    setErrorData(err);
  };

  const handleInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setCreateData({ ...createData, [target.name]: target.value });
  };

  const handleStart = async () => {
    if (window.confirm(formatMessage({ id: 'confirm.start' }))) {
      try {
        const { data: startData } = await defaultAxios.post(adAdAPI.start, {
          proID,
        });

        if (startData.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.start' }));
          setStatus(
            adtier0.PROMOTION_STATUS[adtier0.PROMOTION_STATUS.INPROGRESS],
          );
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  const handleStop = async () => {
    if (window.confirm(formatMessage({ id: 'confirm.stop' }))) {
      try {
        const { data: stopData } = await defaultAxios.post(adAdAPI.stop, {
          proID,
        });

        if (stopData.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.stop' }));
          setStatus(adtier0.PROMOTION_STATUS[adtier0.PROMOTION_STATUS.WAITING]);
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  const handleFiles = async (
    arrURL: any[],
    arrFilename: string[],
    arrFile: any[],
  ) => {
    setCreateData({
      ...createData,
      uploadURLs: arrURL,
      filenames: arrFilename,
      files: arrFile,
    });
  };

  const handleCountry = (selectedOption: ISelectOption[]) => {
    if (selectedOption === null || selectedOption.length === 0) {
      // setCountry(null);
      setCreateData({ ...createData, country: [] });
      setCountryError(1);
      return;
    }

    const index = selectedOption.findIndex(
      (o: ISelectOption) => o.value === 'Global',
    );

    let value = null;
    if (index === -1) {
      value = selectedOption;
    } else if (index === 0) {
      const { length } = selectedOption;
      if (length === 1) {
        value = selectedOption;
      } else {
        const filterOptions = selectedOption.filter(
          (o: ISelectOption) => o.value !== 'Global',
        );

        value = filterOptions;
      }
    } else {
      const filterOptions = selectedOption.filter(
        (o: ISelectOption) => o.value === 'Global',
      );

      value = filterOptions;
    }

    // setCountry(value);
    setCreateData({ ...createData, country: value });
    setCountryError(0);
  };

  const handleCampType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateData({ ...createData, campType: e.target.value });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateData({ ...createData, filter: e.target.value });
  };

  const handleUrl = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (value === '') {
      setUrlError(1);
      // setUrl(value);
      setCreateData({ ...createData, url: value });
      return;
    }

    if (!urlRegex({ exact: true }).test(value)) {
      setUrlError(2);
      // setUrl(value);
      setCreateData({ ...createData, url: value });
      return;
    }

    // setUrl(value);
    setCreateData({ ...createData, url: value });
    setUrlError(0);
  };

  const handleSchedule = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === 'daily') {
      setCreateData({
        ...createData,
        schedule: value,
        week: [
          16777215, 16777215, 16777215, 16777215, 16777215, 16777215, 16777215,
        ],
      });
    } else if (value === 'weekday') {
      setCreateData({
        ...createData,
        schedule: value,
        week: [0, 16777215, 16777215, 16777215, 16777215, 16777215, 0],
      });
    } else if (value === 'weekend') {
      setCreateData({
        ...createData,
        schedule: value,
        week: [16777215, 0, 0, 0, 0, 0, 16777215],
      });
    } else {
      setCreateData({
        ...createData,
        schedule: value,
        week: [0, 0, 0, 0, 0, 0, 0],
      });
      setWeekError(1);
      return;
    }

    setWeekError(0);
  };

  const handleWeek = (value: number, index: number) => {
    const newValue = value === 0 ? 16777215 : 0;

    if (createData.schedule === 'self') {
      const newWeek = createData.week.map((w, i) =>
        i === index ? newValue : w,
      );

      setCreateData({
        ...createData,
        week: newWeek,
      });

      const findIndex = newWeek.findIndex((w: number) => w !== 0);

      if (findIndex === -1) {
        setWeekError(1);
        return;
      }
    }

    setWeekError(0);
  };

  const handleDayLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setCreateData({ ...createData, dayLimit: 0 });
      setDayLimitError(1);
      return;
    }

    if (isNaN(Number(value))) {
      setCreateData({ ...createData, dayLimit: 0 });
      setDayLimitError(4);
      return;
    }

    if (Number(value) < 100000) {
      setCreateData({ ...createData, dayLimit: Number(value) });
      setDayLimitError(2);
      return;
    }

    if (Number(value) > 2000000000) {
      setCreateData({ ...createData, dayLimit: Number(value) });
      setDayLimitError(3);
      return;
    }

    setCreateData({ ...createData, dayLimit: Number(value) });
    setDayLimitError(0);
  };

  const handleBudgetLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setCreateData({ ...createData, budgetLimit: 0 });
      setBudgetLimitError(1);
      return;
    }

    if (isNaN(Number(value))) {
      setCreateData({ ...createData, budgetLimit: 0 });
      setBudgetLimitError(4);
      return;
    }

    if (Number(value) < 100000) {
      setCreateData({ ...createData, budgetLimit: Number(value) });
      setBudgetLimitError(2);
      return;
    }

    if (Number(value) > 2000000000) {
      setCreateData({ ...createData, budgetLimit: Number(value) });
      setBudgetLimitError(3);
      return;
    }

    setCreateData({ ...createData, budgetLimit: Number(value) });
    setBudgetLimitError(0);
  };

  const handleMinCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setCreateData({ ...createData, minCost: 0 });
      setMinCostError(1);
      return;
    }

    if (isNaN(Number(value))) {
      setCreateData({ ...createData, minCost: 0 });
      setMinCostError(4);
      return;
    }

    const minValue = createData.billingType === 'CPM' ? 1000 : 1;

    if (Number(value) < minValue) {
      setCreateData({ ...createData, minCost: Number(value) });
      setMinCostError(2);
      return;
    }

    if (Number(value) > 100000) {
      setCreateData({ ...createData, minCost: Number(value) });
      setMinCostError(3);
      return;
    }

    setCreateData({ ...createData, minCost: Number(value) });
    setMinCostError(0);
  };

  const handleMaxCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setCreateData({ ...createData, maxCost: 0 });
      setMaxCostError(1);
      return;
    }

    if (isNaN(Number(value))) {
      setCreateData({ ...createData, maxCost: 0 });
      setMaxCostError(5);
      return;
    }

    let minValue = createData.billingType === 'CPM' ? 1000 : 1;

    if (Number(value) < minValue) {
      setCreateData({ ...createData, maxCost: Number(value) });
      setMaxCostError(2);
      return;
    }

    if (Number(value) > 100000) {
      setCreateData({ ...createData, maxCost: Number(value) });
      setMaxCostError(3);
      return;
    }

    minValue = createData.minCost;
    if (Number(value) < minValue) {
      setCreateData({ ...createData, maxCost: Number(value) });
      setMaxCostError(4);
      return;
    }

    setCreateData({ ...createData, maxCost: Number(value) });
    setMaxCostError(0);
  };

  const handleCreateDataAppInfo = (value: IAdAppInfo | null) => {
    setAppInfo(value);
  };

  const handleVideoMatrial = (names: string[]) => {
    const arr = [createData.filenames[0], ...names];
    setCreateData({ ...createData, filenames: arr });
  };

  const handleSubmit = async () => {
    if (
      countryError !== 0 ||
      urlError !== 0 ||
      weekError !== 0 ||
      dayLimitError !== 0 ||
      budgetLimitError !== 0 ||
      minCostError !== 0 ||
      maxCostError !== 0
    ) {
      return;
    }

    const {
      comment,
      uploadURLs,
      filenames,
      country,
      campType,
      url,
      filter,
      week,
      dayLimit,
      budgetLimit,
      minCost,
      maxCost,
    } = createData;

    if (window.confirm(formatMessage({ id: 'confirm.modify' }))) {
      if (createData.product !== 'VIDEO') {
        if (uploadURLs.length > 0) {
          try {
            const all = await Promise.all(
              uploadURLs.map(
                async (item) =>
                  await defaultAxios.put(item.uploadURL, item.file, {
                    headers: {
                      'Content-Type': item.file.type,
                    },
                  }),
              ),
            );

            all.forEach((res) => {
              if (res.status !== 200) {
                throw new Error();
              }
            });
          } catch (err) {
            setErrorData(err);
          }
        }
      }

      const arrCountry = country.map((o: ISelectOption) => o.value);

      try {
        const { data: addData } = await defaultAxios.post(adAdAPI.modify, {
          proID,
          product: createData.product,
          gmID: data.ad.GmID,
          audID: data.ad.AudID,
          comment,
          campType,
          url,
          filter,
          filenames,
          minCost,
          maxCost,
          dayLimit,
          budgetLimit,
          videoLength: 0,
          countries: arrCountry,
          appInfo,
          week,
        });

        if (addData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.modify' }));
          handleClose();
        } else {
          window.alert(formatMessage({ id: `errors.${addData.error}` }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            {createData.name}
          </OverlayHeaderTitle>
          <OverlayHeaderRight>
            <Link to={`/ad-campaign/${query.campID}`}>
              <i className="icon-launchopen_in_new" />
              <div className="btn__txt">
                <FormattedMessage id="ad.ad.goCampaign" />
              </div>
            </Link>
            <button className="btn contentBtn" onClick={handleSubmit}>
              <div className="btn__txt">
                <FormattedMessage id="common.modify" />
              </div>
            </button>
          </OverlayHeaderRight>
        </OverlayHeader>
        <OverlayContent>
          <OverlayContentTop>
            <DetailTop
              product={createData.product}
              billingType={createData.billingType}
              status={status}
              registTime={registTime}
              updateTime={updateTime}
              handleStart={handleStart}
              handleStop={handleStop}
            />
          </OverlayContentTop>
          <OverlayContentBottom>
            <DetailMaterial
              product={createData.product}
              name={createData.name}
              comment={createData.comment}
              filenames={createData.filenames}
              matURLs={matURLs}
              handleReqLoading={handleReqLoading}
              handleReqError={handleReqError}
              handleInputs={handleInputs}
              handleFiles={handleFiles}
            />

            <DetailAppDefault
              country={createData.country}
              countryError={countryError}
              campType={createData.campType}
              url={createData.url}
              urlError={urlError}
              filter={createData.filter}
              handleCountry={handleCountry}
              handleCampType={handleCampType}
              handleUrl={handleUrl}
              handleFilter={handleFilter}
            />

            {createData.product === 'VIDEO' && (
              <DetailAppInfo
                createData={createData}
                appInfo={appInfo}
                appInfoMatURLs={appInfoMatURLs}
                handleReqLoading={handleReqLoading}
                handleReqError={handleReqError}
                handleCreateDataAppInfo={handleCreateDataAppInfo}
                handleVideoMatrial={handleVideoMatrial}
              />
            )}

            <DetailSchedule
              schedule={createData.schedule}
              week={createData.week}
              weekError={weekError}
              handleSchedule={handleSchedule}
              handleWeek={handleWeek}
            />

            <DetailLimit
              dayLimit={createData.dayLimit}
              budgetLimit={createData.budgetLimit}
              dayLimitError={dayLimitError}
              budgetLimitError={budgetLimitError}
              handleDayLimit={handleDayLimit}
              handleBudgetLimit={handleBudgetLimit}
            />

            <DetailBid
              product={createData.product}
              campType={createData.campType}
              billingType={createData.billingType}
              minCost={createData.minCost}
              maxCost={createData.maxCost}
              minCostError={minCostError}
              maxCostError={maxCostError}
              bidData={bidData}
              getBidCost={getBidCost}
              handleMinCost={handleMinCost}
              handleMaxCost={handleMaxCost}
            />
          </OverlayContentBottom>
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default Detail;
