import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { QueryParamProvider } from 'use-query-params';

import { accountAPI } from '~/api';
import App from '~/components/app';
import AppWrapper from '~/components/app-wrapper';
import IntlContainer from '~/containers/base/intl-container';
import { adtier0 } from '~/enums/adtier0';
import { clearHeader, defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { IUser } from '~/models/user';
import rootReducer from '~/modules';
import { setUser, setUserInitialized } from '~/modules/user';

const store = createStore(
  rootReducer,
  (window as any).__REDUX_STATE__,
  composeWithDevTools(),
);

const loadUser = async () => {
  const { headers, storageKey } = config;

  const stAccessToken = storage.get(storageKey.accessToken);
  const stUser = storage.get(storageKey.user) as IUser;

  if (stUser && stAccessToken) {
    const { data } = await defaultAxios.post(accountAPI.signinCheck, {
      accessToken: stAccessToken,
    });

    const { error } = data;
    if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
      store.dispatch(setUser(stUser));

      defaultAxios.defaults.headers.common[headers.accessToken] = stAccessToken;
      defaultAxios.defaults.headers.common[headers.custID] = stUser.CustID;
      defaultAxios.defaults.headers.common[headers.sessionKey] =
        stUser.SessionKey;
      defaultAxios.defaults.headers.common[headers.timezoneOffset] =
        stUser.TimezoneOffset;
    } else {
      storage.remove(storageKey.accessToken);
      storage.remove(storageKey.user);

      clearHeader();
    }
  } else {
    storage.remove(storageKey.accessToken);
    storage.remove(storageKey.user);

    clearHeader();
  }
};

loadUser().then(() => {
  store.dispatch(setUserInitialized(true));
});

const Root = () => {
  // const basename =
  //   process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_VERSION;

  const basename = process.env.NODE_ENV === 'development' ? '' : '';

  return (
    <Provider store={store}>
      <IntlContainer>
        <Router basename={basename}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <AppWrapper>
              <App />
            </AppWrapper>
          </QueryParamProvider>
        </Router>
      </IntlContainer>
    </Provider>
  );
};

export default Root;
