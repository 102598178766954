import React from 'react';

import { Helmet } from 'react-helmet';

import Game from '~/components/home/game';
import { MobileGnbGame } from '~/components/layout/header-mobile';
import { GnbApp } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeGamePage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbApp />} mobileGnb={<MobileGnbGame />}>
        <Game />
      </Layout>
    </>
  );
}

export default HomeGamePage;
