import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { adAdAPI, adCampaignAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { country as countryOptions } from '~/json/country';
import { defaultAxios } from '~/libs/axios';
import { IAdAppInfo, IAdCreate } from '~/models/ad/ad';
import { IResCampaignDetail } from '~/models/ad/campaign';
import { ISelectOption } from '~/models/select-option';
import { setIsFullLoader } from '~/modules/core';

import CreateTab from './create00-tab';
import CreateProduct from './create01-product';
import CreatebillingType from './create02-billing-type';
import CreateMaterial from './create03-material';
import CreateAppDefault from './create04-app-default';
import CreateAppInfo from './create05-app-info';
import CreateSchedule from './create06-schedule';
import CreateLimit from './create07-limit';
import CreateBid from './create08-bid';

function Create() {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [createData, setCreateData] = useState<IAdCreate>({
    product: '',
    billingType: '',
    name: '',
    comment: '',
    uploadURLs: [],
    filenames: [],
    files: [],
    videoURL: null,
    videoWidth: '',
    videoHeight: '',
    country: [],
    campType: 'AOS',
    url: '',
    filter: '',
    storeUrl: '',
    appInfo: null,
    schedule: 'daily',
    week: [
      16777215, 16777215, 16777215, 16777215, 16777215, 16777215, 16777215,
    ],
    dayLimit: 0,
    budgetLimit: 0,
    minCost: 0,
    maxCost: 0,
  });
  const [appInfo, setAppInfo] = useState<IAdAppInfo | null>(null);
  const [budget, setBudget] = useState<number>(0);
  const [reqLoading, setReqLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const [query] = useQueryParams({
    campID: NumberParam,
    name: StringParam,
    campType: StringParam,
    status: StringParam,
    product: StringParam,
    billingType: StringParam,
    pageNo: NumberParam,
  });

  const [{ data: campData, error: campError }] = useAxios<IResCampaignDetail>(
    {
      url: adCampaignAPI.get,
      method: 'POST',
      data: {
        campID: query.campID,
      },
    },
    { useCache: false },
  );

  const [{ data: bidData }, getBidCost] = useAxios(
    {
      url: adAdAPI.bidcost,
      method: 'POST',
      data: {
        product: createData.product,
        campType: createData.campType,
        billingType: createData.billingType,
      },
    },
    { useCache: false, manual: true },
  );

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    const { campID } = query;
    const name = query.name ? query.name : '';
    const campType = query.campType ? query.campType : '';
    const status = query.status ? query.status : '';
    const product = query.product ? query.product : '';
    const billingType = query.billingType ? query.billingType : '';
    const pageNo = query.pageNo ? query.pageNo : 1;

    history.push(
      `/ad-ad?campID=${campID}&name=${name}&campType=${campType}&status=${status}&product=${product}&billingType=${billingType}&pageNo=${pageNo}`,
    );
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTab]);

  useEffect(() => {
    dispatch(setIsFullLoader(reqLoading));
  }, [dispatch, reqLoading]);

  useEffect(() => {
    if (campData) {
      if (campData.campaign) {
        const { campaign } = campData;

        const objCountry = JSON.parse(campaign.DefaultCountry);
        const { Country: tempArrCountry } = objCountry;

        const arrCountry = tempArrCountry.map((value: string) => {
          const temp = countryOptions.find(
            (c: ISelectOption) => c.value === value,
          );
          return temp;
        });

        const adCreate: IAdCreate = {
          product: '',
          billingType: '',
          name: '',
          comment: '',
          uploadURLs: [],
          filenames: [],
          files: [],
          videoURL: null,
          videoWidth: '',
          videoHeight: '',
          country: arrCountry,
          campType: campaign.CampType,
          url:
            campaign.CampType === 'AOS'
              ? campaign.DefaultURL_AOS
              : campaign.DefaultURL_iOS,
          filter: '',
          storeUrl: '',
          appInfo: null,
          schedule: 'daily',
          week: [
            16777215, 16777215, 16777215, 16777215, 16777215, 16777215,
            16777215,
          ],
          dayLimit: 0,
          budgetLimit: 0,
          minCost: 0,
          maxCost: 0,
        };

        setCreateData(adCreate);

        setBudget(campData.campaign.Budget);
      }
    }
  }, [campData]);

  const handleReqLoading = (value: boolean) => {
    setReqLoading(value);
  };

  const handleReqError = (err: any) => {
    setErrorData(err);
  };

  const handleCurrentTab = (value: number) => {
    setCurrentTab(value);
  };

  const handleProduct = (value: string) => {
    setCreateData({ ...createData, product: value });
    handleCurrentTab(2);
  };

  const handleBillingType = (value: string) => {
    setCreateData({ ...createData, billingType: value });
    handleCurrentTab(3);
  };

  const handleCreateData = (values: object) => {
    setCreateData({ ...createData, ...values });
  };

  const handleCreateDataAppInfo = (value: IAdAppInfo | null) => {
    setAppInfo(value);
  };

  const handleVideoMatrial = (names: string[]) => {
    const arr = [...createData.filenames.slice(0, 1), ...names];
    setCreateData({ ...createData, filenames: arr });
  };

  const handleAdd = async (minCost: number, maxCost: number) => {
    const {
      product,
      billingType,
      name,
      comment,
      uploadURLs,
      filenames,
      country,
      campType,
      url,
      filter,
      // storeUrl,
      // appInfo,
      // schedule,
      week,
      dayLimit,
      budgetLimit,
    } = createData;

    if (window.confirm(f({ id: 'confirm.add' }))) {
      dispatch(setIsFullLoader(true));

      if (product !== 'VIDEO') {
        try {
          const all = await Promise.all(
            uploadURLs.map(
              async (item) =>
                await defaultAxios.put(item.uploadURL, item.file, {
                  headers: {
                    'Content-Type': item.file.type,
                  },
                }),
            ),
          );

          all.forEach((res) => {
            const { status } = res;
            if (status !== 200) {
              throw new Error();
            }
          });
        } catch (err) {
          dispatch(setIsFullLoader(false));

          setErrorData(err);
        }
      }

      const arrCountry = country.map((o: ISelectOption) => o.value);

      try {
        const { data: addData } = await defaultAxios.post(adAdAPI.add, {
          campID: query.campID,
          gmID: 0,
          audID: 0,
          product,
          billingType,
          name,
          comment,
          campType,
          url,
          filenames,
          minCost,
          maxCost,
          dayLimit,
          budgetLimit,
          videoLength: 0,
          filter,
          countries: arrCountry,
          appInfo,
          week,
        });

        if (addData.error === 'SUCCESS') {
          window.alert(f({ id: 'alert.add' }));
          handleClose();
        } else {
          window.alert(f({ id: `errors.${addData.error}` }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }

      dispatch(setIsFullLoader(false));
    }
  };

  if (campError) {
    if (campError.response) {
      if (campError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw campError;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="ad.ad.create" />
          </OverlayHeaderTitle>
        </OverlayHeader>
        <OverlayContent>
          <CreateTab
            currentTab={currentTab}
            product={createData.product}
            billingType={createData.billingType}
          />

          {currentTab === 1 && <CreateProduct handleProduct={handleProduct} />}

          {currentTab === 2 && (
            <CreatebillingType
              product={createData.product}
              handleBillingType={handleBillingType}
            />
          )}

          {currentTab === 3 && (
            <CreateMaterial
              createData={createData}
              handleReqLoading={handleReqLoading}
              handleReqError={handleReqError}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 4 && (
            <CreateAppDefault
              createData={createData}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 5 && (
            <CreateAppInfo
              createData={createData}
              createAppInfo={appInfo}
              handleReqLoading={handleReqLoading}
              handleReqError={handleReqError}
              handleCurrentTab={handleCurrentTab}
              handleCreateDataAppInfo={handleCreateDataAppInfo}
              handleVideoMatrial={handleVideoMatrial}
            />
          )}

          {currentTab === 6 && (
            <CreateSchedule
              createData={createData}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 7 && (
            <CreateLimit
              createData={createData}
              budget={budget}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 8 && (
            <CreateBid
              campID={query.campID}
              createData={createData}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
              bidData={bidData}
              getBidCost={getBidCost}
              handleAdd={handleAdd}
            />
          )}
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default Create;
