import { useEffect } from 'react';

export function useHeaderScroll() {
  const listener = () => {
    const header = document.getElementById('header');
    const section = document.getElementsByTagName('section')[0];
    const subnav = document.getElementById('sub-nav');

    if (header) {
      if (window.scrollY >= 50) {
        header.classList.add('header__scroll');
      } else {
        header.classList.remove('header__scroll');
      }
    }

    if (section && subnav) {
      const height = section.offsetHeight;

      if (
        window.scrollY >= height - 60 &&
        window.matchMedia('(min-width: 360px)').matches
      ) {
        subnav.classList.add('scroll');
      } else {
        subnav.classList.remove('scroll');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);
}
