import React from 'react';

import styled from 'styled-components';

const Division = styled.div`
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid #ebebeb;
`;

function DivisionLine() {
  return <Division />;
}

export default DivisionLine;
