import React from 'react';

import { Helmet } from 'react-helmet';

import RevenueDoc from '~/components/account/pub-my/revenue-doc';

function PubMyRevenueDoc() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <RevenueDoc />
    </>
  );
}

export default PubMyRevenueDoc;
