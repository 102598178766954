import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IAppRewardListItem } from '~/models/pub/appReward';

import ListTableItem from './list03-table-item';

interface IListTabeProps {
  appRewards: IAppRewardListItem[] | null;
  handleDetail: (appRewardID: number) => void;
}

const TableContainer = styled(DefaultTableContainer)`
  @media screen and (max-width: 559px) {
    th:nth-child(2) {
      display: none;
    }

    th:nth-child(3) {
      position: sticky;
      left: 60px;
      z-index: 4;
    }
  }
`;

function ListTable({ appRewards, handleDetail }: IListTabeProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="text__left border__right" style={{ width: '10%' }}>
              ID
            </th>
            <th className="text__left border__right" style={{ width: '30%' }}>
              RewardKey
            </th>
            <th className="text__left border__right" style={{ width: '30%' }}>
              <FormattedMessage id="pub.appReward.rewardName" />
            </th>
            <th className="text__left">
              <FormattedMessage id="pub.appReward.reward" />
            </th>
          </tr>
        </thead>
        <tbody>
          {appRewards &&
            appRewards.map((appReward, index) => (
              <ListTableItem
                key={index}
                appReward={appReward}
                handleDetail={handleDetail}
              />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ListTable;
