import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IInvenListItem } from '~/models/pub/inventory';

import ListTableItem from './list03-table-item';

interface IListTabeProps {
  invens: IInvenListItem[] | null;
  handleDetail: (medIO: string) => void;
  handleActiveMedia: (
    e: React.MouseEvent,
    medID: string,
    active: number,
  ) => void;
}

const TableContainer = styled(DefaultTableContainer)`
  @media screen and (max-width: 559px) {
    th:nth-child(2) {
      display: none;
    }

    th:nth-child(3) {
      position: sticky;
      left: 60px;
      z-index: 4;
    }
  }
`;

function ListTable({
  invens,
  handleDetail,
  handleActiveMedia,
}: IListTabeProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="cursor__default" />
            <th className="text__left">
              <FormattedMessage id="pub.inven.state" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left border__right active">
              <FormattedMessage id="pub.inven.invenName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left">
              <FormattedMessage id="pub.inven.invenType" />
              <i className="icon-drop-down" />
            </th>
            <th className="cursor__default" />
          </tr>
        </thead>
        <tbody>
          {invens &&
            invens.map((inven, index) => (
              <ListTableItem
                key={index}
                inven={inven}
                handleDetail={handleDetail}
                handleActiveMedia={handleActiveMedia}
              />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ListTable;
