import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IAppListItem } from '~/models/pub/app';

import ListTableItem from './list03-table-item';

interface IListTableProps {
  apps: IAppListItem[];
  handleDetail: (appID: string) => void;
}

const TableContainer = styled(DefaultTableContainer as any)`
  @media screen and (max-width: 559px) {
    .app__table {
      th:nth-child(2) {
        position: sticky;
        left: 107px;
        z-index: 4;
      }
    }
  }
`;

function ListTable({ apps, handleDetail }: IListTableProps) {
  return (
    <TableContainer className="app__table">
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="text__left">
              <FormattedMessage id="pub.app.state" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left border__right active">
              <FormattedMessage id="pub.app.appName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left">
              <FormattedMessage id="pub.app.platform" />
              <i className="icon-drop-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          {apps && apps.length > 0 ? (
            apps.map((app) => (
              <ListTableItem
                app={app}
                key={app.AppID}
                handleDetail={handleDetail}
              />
            ))
          ) : (
            <ListTableItem
              app={{
                AppID: '',
                Approval: false,
                Name: '-',
                Platform: '-',
              }}
              handleDetail={handleDetail}
            />
          )}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ListTable;
