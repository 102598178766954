import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';

import RevenueDocForm from './revenue-doc01-form';
import Tab from './tab';

function RevenueDoc() {
  const history = useHistory();

  const handleClose = () => {
    history.push('/pub-my-page');
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="pubmypage.revenueGive" />
          </OverlayHeaderTitle>
        </OverlayHeader>

        <OverlayContent>
          <Tab currentTab={2} />

          <RevenueDocForm handleCancel={handleClose} />
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default RevenueDoc;
