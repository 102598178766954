import path from 'path';

import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { adAdAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { delay } from '~/libs/utils';
import { IAdCreate } from '~/models/ad/ad';

interface ICreateMaterialProps {
  createData: IAdCreate;
  handleReqLoading: (value: boolean) => void;
  handleReqError: (err: any) => void;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (values: object) => void;
}

interface IFormData {
  name: string;
  comment: string;
}

function CreateMaterial({
  createData,
  handleReqLoading,
  handleReqError,
  handleCurrentTab,
  handleCreateData,
}: ICreateMaterialProps) {
  const [uploadURLs, setUploadURLs] = useState<any[]>([]);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [filesError, setFilesError] = useState<number>(0);
  const [videoURL, setVideoURL] = useState<string | null>(null);

  const { formatMessage } = useIntl();
  const { register, handleSubmit, errors, getValues, setValue } =
    useForm<IFormData>({
      defaultValues: {
        name: `${createData.product} ${moment().format('YYYY-MM-DD HH:mm')}`,
        comment: `${createData.product} ${moment().format('YYYY-MM-DD HH:mm')}`,
      },
      submitFocusError: false,
    });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept:
      createData.product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]
        ? 'video/mp4, video/quicktime'
        : 'image/jpeg, image/png',
    multiple: createData.product === adtier0.PRODUCT[adtier0.PRODUCT.BANNER],
    maxSize:
      createData.product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]
        ? 10485760
        : 512000,
    onDropAccepted: async (acceptedFiles) => {
      try {
        if (createData.product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]) {
          if (window.confirm(formatMessage({ id: 'ad.ad.confirmEncoding' }))) {
            handleReqLoading(true);

            const videoFile = acceptedFiles[0];

            const formData = new FormData();
            formData.append('product', createData.product);
            acceptedFiles.forEach((file) => {
              formData.append('uploadFiles', file);
            });

            const { data } = await defaultAxios.post(
              adAdAPI.s3getSignedUrlVideo,
              {
                product: createData.product,
                orginname: videoFile.name,
                filetype: videoFile.type,
              },
            );

            const tempArr: any[] = [];
            tempArr.push({
              uploadURL: data.uploadURL,
              file: videoFile,
            });

            const all = await Promise.all(
              tempArr.map(
                async (item) =>
                  await defaultAxios.put(item.uploadURL, item.file, {
                    headers: {
                      'Content-Type': item.file.type,
                    },
                  }),
              ),
            );

            all.forEach((res) => {
              const { status } = res;
              if (status !== 200) {
                throw new Error();
              }
            });

            const { data: durationData } = await defaultAxios.post(
              adAdAPI.duration,
              {
                url: data.uploadURL.split('?')[0],
              },
            );

            const { duration } = durationData;
            if (duration < 5 || duration > 35) {
              window.alert(formatMessage({ id: 'ad.ad.videoDuration' }));
              return;
            }

            const dir = path.dirname(durationData.url);
            const videoFilename = path.basename(durationData.url);
            const jsonFilename = `${videoFilename.split('.')[0]}.json`;
            const jsonURL = `${dir}/${jsonFilename}`;

            const { data: videoData } = await defaultAxios.post(
              adAdAPI.video2,
              {
                filename: videoFilename,
              },
            );

            const { statusCode } = videoData;
            if (statusCode === 202) {
              let count = 1;
              const check = async () => {
                for (;;) {
                  count++;
                  await delay(10000);

                  try {
                    const { data: jsonFileData } = await defaultAxios.get(
                      jsonURL,
                    );
                    return jsonFileData;
                  } catch {
                    if (count === 10) {
                      return null;
                    }

                    continue;
                  }
                }
              };

              const jsonData = await check();

              handleReqLoading(false);

              if (jsonData) {
                const output = jsonData.Result.Job.Output;
                const filename = output.Key;

                setVideoURL(null);
                setVideoURL(`${config.material}/video/${filename}`);

                setUploadURLs(tempArr);

                const appInfoMatNames = createData.filenames.slice(1);
                const newFileNames = [filename].concat(appInfoMatNames);
                setFilenames(newFileNames);

                setFiles(
                  acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    }),
                  ),
                );
              } else {
                handleReqLoading(false);
                window.alert(formatMessage({ id: 'ad.ad.videoRetry' }));
              }
            } else {
              handleReqLoading(false);
              window.alert(formatMessage({ id: 'ad.ad.videoRetry' }));
            }
          }
        } else {
          const pos = createData.product === 'BANNER' ? 2 : 1;
          const sliceFiles = acceptedFiles.slice(0, pos);

          const formData = new FormData();
          formData.append('product', createData.product);
          sliceFiles.forEach((file) => {
            formData.append('uploadFiles', file);
          });

          const { data } = await defaultAxios.post(
            adAdAPI.s3getSignedUrl,
            formData,
          );

          const { urls } = data;

          const tempArr: any[] = [];
          for (let i = 0; i < urls.length; i++) {
            const temp = {
              uploadURL: urls[i].uploadURL,
              file: sliceFiles[i],
            };

            tempArr.push(temp);
          }

          setUploadURLs(tempArr);

          setFilenames(urls.map((url: any) => url.filename));

          setFiles(
            sliceFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
          );
        }

        setFilesError(0);
      } catch (err) {
        handleReqLoading(false);

        handleReqError(err);
      }
    },
    onDropRejected: () => {
      if (createData.product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]) {
        window.alert(formatMessage({ id: 'ad.ad.videoError' }));
      } else {
        window.alert(formatMessage({ id: 'ad.ad.imageError' }));
      }
    },
  });

  useEffect(() => {
    if (createData.name !== '') {
      setValue('name', createData.name);
    }

    if (createData.comment !== '') {
      setValue('comment', createData.comment);
    }

    setUploadURLs(createData.uploadURLs);
    setFilenames(createData.filenames);
    setFiles(createData.files);
    setVideoURL(createData.videoURL);
  }, [
    createData.comment,
    createData.filenames,
    createData.files,
    createData.name,
    createData.uploadURLs,
    createData.videoURL,
    setValue,
  ]);

  const handlePrevTab = () => {
    const { name, comment } = getValues();
    handleCreateData({
      name,
      comment,
      uploadURLs,
      filenames,
      files,
      videoURL,
    });
    handleCurrentTab(2);
  };

  const onSubmit = ({ name, comment }: IFormData): void => {
    if (filenames.length === 0) {
      setFilesError(1);
      return;
    }

    handleCreateData({
      name,
      comment,
      uploadURLs,
      filenames,
      files,
      videoURL,
    });
    handleCurrentTab(4);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="ad.ad.material" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <legend>상세정보 - 광고(배너) : 소재</legend>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.proName" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="name">
                        <FormattedMessage id="ad.campaign.campName" />
                      </label>
                    </FormInputField>
                    {errors.name && (
                      <span className="warning__message s_txt">
                        {errors.name.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.comment" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="comment"
                        id="comment"
                        placeholder=""
                        ref={register}
                      />
                      <label htmlFor="comment">
                        <FormattedMessage id="ad.ad.comment" />
                      </label>
                    </FormInputField>
                  </FormInput>

                  <FormInput
                    className={cx(
                      // { interstitial: createData.product === 'INTERSTITIAL' },
                      { video: createData.product === 'VIDEO' },
                    )}
                  >
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage
                          id={
                            createData.product === 'VIDEO'
                              ? 'ad.ad.video'
                              : 'ad.ad.image'
                          }
                        />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <div>
                      <FormInputField
                        {...getRootProps({
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        })}
                      >
                        {createData.product ===
                          adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] &&
                          videoURL && (
                            <video controls autoPlay>
                              <source src={videoURL} type="video/mp4" />
                            </video>
                          )}

                        {createData.product !==
                          adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] &&
                          files.length > 0 &&
                          files.map((file, index) => (
                            <img key={index} src={file.preview} alt="" />
                          ))}

                        <input {...getInputProps()} />

                        {isDragActive && files.length > 0 && (
                          <div className="preview--blur" />
                        )}

                        {files.length === 0 && (
                          <div className="form__preview">
                            <i className="icon-image" />
                            <div className="btn__txt">
                              {isDragActive
                                ? 'Drop file here'
                                : `Drag 'n' drop some files here, or click to select files`}
                            </div>
                          </div>
                        )}
                      </FormInputField>
                      {createData.product === 'BANNER' && (
                        <span className="info__message s_txt">
                          <FormattedHTMLMessage id="ad.ad.imageBanner" />
                        </span>
                      )}

                      {createData.product === 'INTERSTITIAL' && (
                        <span className="info__message s_txt">
                          <FormattedHTMLMessage id="ad.ad.imageInter" />
                        </span>
                      )}

                      {createData.product === 'VIDEO' && (
                        <span className="info__message s_txt">
                          <FormattedHTMLMessage id="ad.ad.imageVideo" />
                        </span>
                      )}

                      {filesError === 1 && (
                        <span className="warning__message s_txt">
                          <FormattedMessage id="validate.required" />
                        </span>
                      )}

                      <div
                        className="btn contentBtn btnLine fileSelectBtn"
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          open();
                        }}
                      >
                        <FormattedMessage id="ad.ad.fileSelect" />
                      </div>
                    </div>
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button
                className="btn contentBtn btnClear"
                onClick={handlePrevTab}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.next" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateMaterial;
