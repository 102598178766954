import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { ConsoleContent } from '~/components/elements/console-content';
import { FilterContainer as DefaultFilterContainer } from '~/components/elements/filter';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { IFilter } from '~/models/ad/ad';

interface IListFilterProps {
  filter: IFilter;
  showFilter: boolean;
  statusOptions: string[];
  productOptions: string[];
  billingTypeOptions: string[];
  handleChangeFilter: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleSearch: () => void;
}

const FilterContainer = styled(DefaultFilterContainer as any)`
  position: relative;
  top: -1rem;
  background-color: #fafafa;
  height: 0;
  padding: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  overflow: hidden;
  opacity: 0;

  &.open {
    height: 100%;
    padding: 20px 0;
    border-color: #ebebeb;
    transition: 0.3s;
    margin-bottom: 1rem;
    opacity: 1;
  }

  ${ConsoleContent} {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  ${FormInputContainer} {
    width: 100%;
    display: flex;
  }

  ${FormInput} {
    width: calc(20% - 24px);
    margin-bottom: 0;
  }

  .btn {
    margin-top: 1.7rem;
  }

  .input__search {
    height: 44px;
  }

  @media screen and (max-width: 599px) {
    ${FormInputContainer} {
      flex-direction: column;
    }

    ${FormInput} {
      width: 100%;
      margin-bottom: 16px;
    }

    .btn {
      margin-top: 0;
    }

    .input__search {
      height: 40px;
    }
  }
`;

function ListFilter({
  filter,
  showFilter,
  statusOptions,
  productOptions,
  billingTypeOptions,
  handleChangeFilter,
  handleSearch,
}: IListFilterProps) {
  const { formatMessage: f } = useIntl();

  return (
    <FilterContainer className={showFilter ? 'open' : ''}>
      <ConsoleContent>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.proName" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={f({ id: 'ad.ad.proName' })}
                  value={filter.name}
                  onChange={handleChangeFilter}
                />
                <label htmlFor="name">
                  <FormattedMessage id="ad.ad.proName" />
                </label>
              </FormInputField>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.platform" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <select
                  name="campType"
                  id="campType"
                  value={filter.campType}
                  onChange={handleChangeFilter}
                >
                  <FormattedMessage id="common.all">
                    {(message) => (
                      <option key={1} value="">
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                  <option value="AOS">Android</option>
                  <option value="IOS">iOS</option>
                </select>
                <label htmlFor="campType">
                  <FormattedMessage id="ad.ad.platform" />
                </label>
                <i className="icon-chevron-down" />
              </FormInputField>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.status" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <select
                  name="status"
                  id="status"
                  value={filter.status}
                  onChange={handleChangeFilter}
                >
                  <FormattedMessage id="common.all">
                    {(message) => (
                      <option key={1} value="">
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                  {statusOptions.map((o, index) => (
                    <FormattedMessage
                      key={index}
                      id={`ad.ad.${o.toLowerCase()}`}
                    >
                      {(message) => <option value={o}>{message}</option>}
                    </FormattedMessage>
                  ))}
                </select>
                <label htmlFor="status">
                  <FormattedMessage id="ad.ad.status" />
                </label>
                <i className="icon-chevron-down" />
              </FormInputField>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.product" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <select
                  name="product"
                  id="product"
                  value={filter.product}
                  onChange={handleChangeFilter}
                >
                  <FormattedMessage id="common.all">
                    {(message) => (
                      <option key={1} value="">
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                  {productOptions.map((o, index) => (
                    <FormattedMessage
                      key={index}
                      id={`ad.ad.${o.toLowerCase()}`}
                    >
                      {(message) => <option value={o}>{message}</option>}
                    </FormattedMessage>
                  ))}
                </select>
                <label htmlFor="product">
                  <FormattedMessage id="ad.ad.product" />
                </label>
                <i className="icon-chevron-down" />
              </FormInputField>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.billingType" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <select
                  name="billingType"
                  id="billingType"
                  value={filter.billingType}
                  onChange={handleChangeFilter}
                >
                  <FormattedMessage id="common.all">
                    {(message) => (
                      <option key={1} value="">
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                  {/* {billingTypeOptions.map((o, index) => (
                    <option key={index} value={o}>
                      {o}
                    </option>
                  ))} */}
                  {filter.product === 'BANNER' && (
                    <>
                      <option value="CPM">CPM</option>
                      <option value="nCPI">nCPI</option>
                      <option value="nCPA">nCPA</option>
                    </>
                  )}

                  {filter.product === 'INTERSTITIAL' && (
                    <>
                      <option value="CPC">CPC</option>
                      <option value="nCPI">nCPI</option>
                      <option value="nCPA">nCPA</option>
                    </>
                  )}

                  {filter.product === 'VIDEO' && (
                    <>
                      <option value="CPV">CPV</option>
                      <option value="nCPI">nCPI</option>
                      <option value="nCPA">nCPA</option>
                    </>
                  )}
                </select>
                <label htmlFor="billingType">
                  <FormattedMessage id="ad.ad.billingType" />
                </label>
                <i className="icon-chevron-down" />
              </FormInputField>
            </FormInput>
            <button className="btn contentBtn" onClick={handleSearch}>
              <FormattedMessage id="common.search" />
            </button>
          </FormInputContainer>
        </NoFloating>
      </ConsoleContent>
    </FilterContainer>
  );
}

export default ListFilter;
