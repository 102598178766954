import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Content,
  ContentSubContainer,
  ContentSubText as DefaultContentSubText,
} from '~/components/elements';

const ContentSubText = styled(DefaultContentSubText)`
  max-width: 100% !important;
  word-break: keep-all;
`;

function PublisherIntro() {
  return (
    <Content className="first">
      <ContentSubContainer>
        <ContentSubText>
          <span className="xs_tit">
            <FormattedMessage id="publisher.publisher" />
          </span>
          <h1 className="l_tit">
            <FormattedMessage id="publisher.title" />
          </h1>
          <h5 className="m_txt">
            <FormattedMessage id="publisher.subTitle" />
          </h5>
        </ContentSubText>
        <img src={require('~/assets/images/p9_main.png').default} alt="" />
      </ContentSubContainer>
    </Content>
  );
}

export default PublisherIntro;
