import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IRevenueRequestListProps {
  handleClickRequestList: () => void;
}

function RevenueRequestList({
  handleClickRequestList,
}: IRevenueRequestListProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory" />
        </FormInputTitleName>
        <FormInputContentWrap>
          <span className="m_txt">
            <i className="icon-chevron-right" />
          </span>
          <button className="btn contentBtn" onClick={handleClickRequestList}>
            <FormattedMessage id="pubmypage.revenueGive.history" />
          </button>
        </FormInputContentWrap>
      </FormInputTitleWrap>
    </FormInput>
  );
}

export default RevenueRequestList;
