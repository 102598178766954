import React from 'react';

import { FormattedMessage } from 'react-intl';

import { IAppListItem } from '~/models/pub/app';

interface IListTableItemProps {
  app: IAppListItem;
  handleDetail: (appID: string) => void;
}

function ListTableItem({ app, handleDetail }: IListTableItemProps) {
  const { AppID, Approval, Name, Platform } = app;

  return (
    <tr onClick={() => handleDetail(AppID)}>
      <th className="text__left table__important">
        {AppID === '' ? (
          '-'
        ) : Approval ? (
          <FormattedMessage id="pub.app.approval" />
        ) : (
          <FormattedMessage id="pub.app.notApproval" />
        )}
      </th>
      <td className="text__left border__right table__title">
        <span>{Name}</span>
      </td>
      <td className="text__left">
        {Platform === '-' ? (
          '-'
        ) : Platform === 'AOS' ? (
          <i className="icon-android" />
        ) : (
          <i className="icon-appleinc" />
        )}
        {Platform === '-' ? '' : Platform === 'AOS' ? 'Android' : 'iOS'}
      </td>
    </tr>
  );
}

export default ListTableItem;
