import React from 'react';

import styled from 'styled-components';

interface IPagerProps {
  pageNo: number;
  pageSize: number;
  blockPage: number;
  rowCount: number;
  handleChangePage: (no: number) => void;
}

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  i {
    padding: 0.5rem;
    cursor: pointer;
    color: #d4d4d4;

    &.active {
      color: #666666;
    }
  }

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      text-align: center;
      cursor: pointer;
      margin: 0 0.25rem;

      &.active {
        font-weight: 600;
        color: #ffffff;
        background-color: #3f38d6;
      }
    }
  }

  @media screen and (max-width: 599px) {
    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        line-height: 1;
        display: none;

        &:nth-child(-n + 3) {
          display: flex;
        }
      }
    }
  }
`;

const getRange = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

function Pager({
  pageNo,
  pageSize,
  blockPage,
  rowCount,
  handleChangePage,
}: IPagerProps) {
  // const [pageNo, setPageNo] = useState<number>(1);

  const handleGoToPage = (no: number) => {
    // setPageNo(no);
    handleChangePage(no);
    window.scrollTo(0, 0);
  };

  const pageCount = Math.ceil(rowCount / pageSize);
  const pageStart = Math.floor((pageNo - 1) / blockPage) * blockPage + 1;
  let pageEnd = pageStart + (blockPage - 1);

  if (pageEnd > pageCount) {
    pageEnd = pageCount;
  }

  const pages = getRange(pageStart, pageEnd);

  return (
    <PageContainer className="m_txt">
      {pageNo > 1 ? (
        <i
          className="icon-chevron-left active"
          onClick={() => handleGoToPage(pageNo - 1)}
        />
      ) : (
        <i className="icon-chevron-left inactive" />
      )}

      <ul>
        {pages.map((page: number) => {
          if (pageNo === page) {
            return (
              <li className="active" key={page}>
                <div className="btn__txt">{page}</div>
              </li>
            );
          }

          return (
            <li
              className="inactive"
              key={page}
              onClick={() => handleGoToPage(page)}
            >
              <div className="btn__txt">{page}</div>
            </li>
          );
        })}
      </ul>

      {pageNo < pageCount ? (
        <i
          className="icon-chevron-right active"
          onClick={() => handleGoToPage(pageNo + 1)}
        />
      ) : (
        <i className="icon-chevron-right inactive" />
      )}
    </PageContainer>
  );
}

export default Pager;
