import { useEffect } from 'react';

export function useKeyboardEvent(
  key: string,
  target: HTMLElement | null,
  callback: () => void,
) {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (target) {
        if (e.target === target) {
          if (e.key === key) {
            callback();
          }
        }
      } else if (e.target === target) {
        if (e.key === key) {
          callback();
        }
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [callback, key, target]);
}
