import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { adCampaignAPI, adReportAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { IResCampaignNameList } from '~/models/ad/campaign';
import {
  IReportAdListItem,
  IResReportAdDailyList,
  IResReportAdList,
  IState,
} from '~/models/ad/report';
import { setIsFullLoader } from '~/modules/core';

import ReportFilter from './report-ad01-filter';
import ReportAdState from './report-ad02-state';
import ReportAdExport from './report-ad03-export';
import ReportAdList from './report-ad04-list';

function ReportAd() {
  const [state, setState] = useState<IState>({
    impression: 0,
    click: 0,
    ctr: 0,
  });
  const [campID, setCampID] = useState<number>(0);
  const [proID, setProID] = useState<number>(0);
  const [ads, setAds] = useState<IReportAdListItem[] | null>(null);
  const [daily, setDaily] = useState<any[] | null>(null);
  const [csv, setCSV] = useState<any>(null);

  const [{ data: campData, loading: campLoading, error: campError }] =
    useAxios<IResCampaignNameList>(
      {
        url: adCampaignAPI.listAll,
        method: 'GET',
      },
      { useCache: false },
    );

  const [{ data: adData, loading: adLoading, error: adError }, adExcute] =
    useAxios<IResReportAdList>(
      {
        url: adReportAPI.ad,
        method: 'GET',
      },
      { useCache: false, manual: true },
    );

  const [
    { data: dailyData, loading: dailyLoading, error: dailyError },
    dailyExecute,
  ] = useAxios<IResReportAdDailyList>(
    {
      url: adReportAPI.adDaily,
      method: 'GET',
    },
    { useCache: false, manual: true },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (campLoading || adLoading || dailyLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [adLoading, campLoading, dailyLoading, dispatch]);

  useEffect(() => {
    if (campData) {
      if (campData.campaigns) {
        if (campData.campaigns.length > 0) {
          if (campID === 0) {
            setCampID(campData.campaigns[0].CampID);
            adExcute({
              params: {
                campID: campData.campaigns[0].CampID,
              },
            });
          }
        }
      }
    }
  }, [adExcute, campData, campID]);

  useEffect(() => {
    if (adData) {
      if (adData.ads) {
        let totalImpression = 0;
        let totalClick = 0;
        let totalCTR = 0;
        let avgCTR = 0;

        const csvData: any = [
          [
            'Depth',
            formatMessage({ id: 'ad.report.proName' }),
            formatMessage({ id: 'ad.report.impression' }),
            formatMessage({ id: 'ad.report.click' }),
            formatMessage({ id: 'ad.report.ctrper' }),
            formatMessage({ id: 'ad.report.execute' }),
          ],
        ];

        for (const item of adData.ads) {
          totalImpression += Number(item.Impression);
          totalClick += Number(item.Click);
          totalCTR += Number(item.CTR);

          csvData.push([
            formatMessage({ id: 'ad.ad' }),
            item.PromotionName,
            item.Impression,
            item.Click,
            item.CTR,
            item.Cost,
          ]);
        }

        if (totalImpression > 0 && totalClick > 0) {
          avgCTR = (totalClick / totalImpression) * 100;
        }

        setState({
          impression: totalImpression,
          click: totalClick,
          ctr: avgCTR,
        });

        setAds(adData.ads);
        setCSV(csvData);
      }
    }
  }, [adData, formatMessage]);

  useEffect(() => {
    if (dailyData) {
      if (dailyData.daily) {
        setDaily(dailyData.daily);
      }
    }
  }, [dailyData]);

  if (campError) {
    if (campError.response) {
      if (campError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw campError;
  }

  if (adError) {
    if (adError.response) {
      if (adError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw adError;
  }

  if (dailyError) {
    if (dailyError.response) {
      if (dailyError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw dailyError;
  }

  if (!campData) {
    return null;
  }

  const handleChangeCampID = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    if (value !== 0) {
      setCampID(value);
      adExcute({
        params: {
          campID: value,
        },
      });
    }
  };

  const handleChangeProID = (value: number) => {
    if (proID === value) {
      setProID(0);
    } else {
      setProID(value);
      dailyExecute({
        params: {
          campID,
          proID: value,
        },
      });
    }
  };

  return (
    <>
      <ConsoleContent>
        <ReportFilter
          campID={campID}
          campaigns={campData.campaigns}
          handleChangeCampID={handleChangeCampID}
        />
        <ReportAdState state={state} />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        {adData && (
          <>
            <ReportAdExport csv={csv} />
            <ReportAdList
              proID={proID}
              ads={ads}
              daily={daily}
              handleChangeProID={handleChangeProID}
            />
          </>
        )}
      </ConsoleContent>
    </>
  );
}

export default ReportAd;
