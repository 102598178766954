import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Content } from '~/components/elements/content';

import {
  ProfitContainer,
  ProfitItem,
  ProfitItemSymbol,
} from './home03-elements';

function HomeRevenue1() {
  return (
    <Content>
      <h3 className="m_tit">
        <FormattedMessage id="home.revenue.title" />
      </h3>
      <h5 className="m_txt">
        <FormattedMessage id="home.revenue.subTitle" />
      </h5>
      <ProfitContainer>
        <ProfitItem>
          <ProfitItemSymbol>
            <i className="icon-network" />
          </ProfitItemSymbol>
          <h5 className="s_tit">
            <FormattedMessage id="home.revenue.network" />
          </h5>
          <p className="s_txt">
            <FormattedMessage id="home.revenue.networkDesc" />
          </p>
        </ProfitItem>

        <ProfitItem>
          <ProfitItemSymbol>
            <i className="icon-time" />
          </ProfitItemSymbol>
          <h5 className="s_tit">
            <FormattedMessage id="home.revenue.bid" />
          </h5>
          <p className="s_txt">
            <FormattedMessage id="home.revenue.bidDesc" />
          </p>
        </ProfitItem>

        <ProfitItem>
          <ProfitItemSymbol>
            <i className="icon-people" />
          </ProfitItemSymbol>
          <h5 className="s_tit">
            <FormattedMessage id="home.revenue.share" />
          </h5>
          <p className="s_txt">
            <FormattedMessage id="home.revenue.shareDesc" />
          </p>
        </ProfitItem>
      </ProfitContainer>
    </Content>
  );
}

export default HomeRevenue1;
