import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IDetailInventoryProps {
  register: any;
  errors: any;
  medID: string | undefined;
}

function DetailInventory({ register, errors, medID }: IDetailInventoryProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="pub.inven" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.inven.name" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                  })}
                />
                <label htmlFor="name">
                  <FormattedMessage id="pub.inven.invenName" />
                </label>
              </FormInputField>
              {errors.name && (
                <span className="warning__message s_txt">
                  {errors.name.message}
                </span>
              )}
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.inven.id" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputGroup>
                <span className="m_txt">{medID}</span>
                <CopyToClipboard
                  text={medID || ''}
                  onCopy={() =>
                    toast.success(formatMessage({ id: 'alert.clipboard' }))
                  }
                >
                  <i className="icon-filter_none cursor__pointer" />
                </CopyToClipboard>
              </FormInputGroup>
            </FormInput>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailInventory;
