import React, { useEffect } from 'react';

import styled from 'styled-components';

const Styles = styled.div`
  .wrapper {
    position: relative;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
    margin: auto;
    width: 600px;
  }

  table {
    position: relative;
  }

  thead {
    position: relative;
    overflow: visible;
  }

  tbody {
    position: relative;
  }
`;

function TestTable() {
  useEffect(() => {
    const fixedHeaders = document.querySelectorAll('.fixed');

    for (let i = 0; i < fixedHeaders.length; i++) {
      const item = fixedHeaders[i] as HTMLElement;
      item.style.position = 'relative';
    }

    const wrapper = document.querySelector('.wrapper');
    if (wrapper) {
      wrapper.addEventListener('scroll', () => {
        const thead = document.querySelector('.thead') as HTMLElement;
        if (thead) {
          thead.style.left = `-${wrapper.scrollLeft}px`;
        }

        // for (let i = 0; i < fixedHeaders.length; i++) {
        //   const item = fixedHeaders[i] as HTMLElement;
        //   item.style.left = `${wrapper.scrollLeft}px`;
        // }
      });
    }
  }, []);

  return (
    <Styles>
      <div className="view">
        <div className="wrapper">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="fixed">Number</th>
                <th className="fixed">First Name</th>
                <th>Last Name</th>
                <th>Employer</th>
              </tr>
            </thead>
            <tbody className="tbody">
              <tr>
                <td className="sticky-col first-col">1</td>
                <td className="sticky-col second-col">Mark</td>
                <td>Ham</td>
                <td>Micro</td>
              </tr>
              <tr>
                <td className="sticky-col first-col">2</td>
                <td className="sticky-col second-col">Jacob</td>
                <td>Smith</td>
                <td>Adob Adob Adob AdobAdob Adob Adob Adob Adob</td>
              </tr>
              <tr>
                <td className="sticky-col first-col">3</td>
                <td className="sticky-col second-col">Larry</td>
                <td>Wen</td>
                <td>Goog Goog Goog GoogGoog Goog Goog Goog Goog Goog</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Styles>
  );
}

export default TestTable;
