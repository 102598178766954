import React from 'react';

import { Helmet } from 'react-helmet';

import Create from '~/components/ad/campaign/create';

function CampaignCreatePage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Create />
    </>
  );
}

export default CampaignCreatePage;
