import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { accountAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContainer,
  OverlayContent as DefaultOverlayContent,
  OverlayContentBottom,
  OverlayHeader,
  OverlayHeaderTitle,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
  OverlayTopWrap,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { pwPattern } from '~/libs/regexp';
import { RootState } from '~/modules';

const OverlayContent = styled(DefaultOverlayContent as any)`
  height: 100%;
`;

interface IFormData {
  pwNow: string;
  pw: string;
  pwConfirm: string;
}

function PasswordChange() {
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, getValues, handleSubmit } = useForm<IFormData>();
  const { formatMessage } = useIntl();
  const user = useSelector((state: RootState) => state.user.user);
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    if (user) {
      if (user.Type === 'ADVERTISER') {
        history.push('/ad-my-page');
      } else {
        history.push('/pub-my-page');
      }
    } else {
      history.push('/signin');
    }
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  const onSubmit = async ({ pwNow, pw }: IFormData) => {
    if (window.confirm(formatMessage({ id: 'confirm.modify' }))) {
      try {
        const { data } = await defaultAxios.post(accountAPI.changePassword, {
          oldPw: pwNow,
          newPw: pw,
        });

        const { error } = data;

        if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.modify' }));
          handleClose();
        } else {
          window.alert(formatMessage({ id: `errors.${error}` }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <main className="console">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>기본 정보 - 비밀번호 변경</legend>
          <OverlayContainer>
            <OverlayHeader>
              <i className="icon-x" onClick={handleClose} />
              <OverlayHeaderTitle className="m_txt">
                <FormattedMessage id="mypage.pwChange" />
              </OverlayHeaderTitle>
            </OverlayHeader>
            <OverlayContent>
              <OverlayTopWrap>
                <h4 className="m_tit">
                  <FormattedMessage id="mypage.default" />
                </h4>
              </OverlayTopWrap>
              <OverlayContentBottom>
                <OverlayItemWrap>
                  <OverlayItemWrapTitle>
                    <h5 className="s_tit">
                      <FormattedMessage id="mypage.pwChange" />
                    </h5>
                  </OverlayItemWrapTitle>
                  <OverlayItem>
                    <NoFloating>
                      <FormInputContainer>
                        <FormInput>
                          <FormInputTitleWrap>
                            <FormInputTitleName className="m_txt">
                              <FormattedMessage id="mypage.pwChange.currentPassword" />
                              <i className="required" />
                            </FormInputTitleName>
                          </FormInputTitleWrap>
                          <FormInputField>
                            <input
                              type="password"
                              name="pwNow"
                              id="pwNow"
                              placeholder="&nbsp;"
                              ref={register({
                                required: formatMessage({
                                  id: 'validate.required',
                                }),
                                pattern: {
                                  value: pwPattern,
                                  message: formatMessage({ id: 'validate.pw' }),
                                },
                              })}
                            />
                            <label htmlFor="pwNow">
                              <FormattedMessage id="mypage.pwChange.currentPassword" />
                            </label>
                          </FormInputField>
                          <span className="info__message s_txt">
                            <FormattedMessage id="mypage.pwChange.pwDesc" />
                          </span>
                          {errors.pwNow && (
                            <span className="warning__message s_txt">
                              {errors.pwNow.message}
                            </span>
                          )}
                        </FormInput>

                        <FormInput>
                          <FormInputTitleWrap>
                            <FormInputTitleName className="m_txt">
                              <FormattedMessage id="mypage.pwChange.newPassword" />
                              <i className="required" />
                            </FormInputTitleName>
                          </FormInputTitleWrap>
                          <FormInputField>
                            <input
                              type="password"
                              name="pw"
                              id="pw"
                              placeholder="&nbsp;"
                              ref={register({
                                required: formatMessage({
                                  id: 'validate.required',
                                }),
                                pattern: {
                                  value: pwPattern,
                                  message: formatMessage({ id: 'validate.pw' }),
                                },
                              })}
                            />
                            <label htmlFor="pw">
                              <FormattedMessage id="mypage.pwChange.newPassword" />
                            </label>
                          </FormInputField>
                          <span className="info__message s_txt">
                            <FormattedMessage id="mypage.pwChange.pwDesc" />
                          </span>
                          {errors.pw && (
                            <span className="warning__message s_txt">
                              {errors.pw.message}
                            </span>
                          )}
                        </FormInput>

                        <FormInput>
                          <FormInputTitleWrap>
                            <FormInputTitleName className="m_txt">
                              <FormattedMessage id="mypage.pwChange.passwordConfirm" />
                              <i className="required" />
                            </FormInputTitleName>
                          </FormInputTitleWrap>
                          <FormInputField>
                            <input
                              type="password"
                              name="pwConfirm"
                              id="pwConfirm"
                              placeholder="&nbsp;"
                              ref={register({
                                required: formatMessage({
                                  id: 'validate.required',
                                }),
                                validate: (value: string) =>
                                  getValues().pw === value ||
                                  formatMessage({ id: 'validate.pwNotMatch' }),
                              })}
                            />
                            <label htmlFor="pwConfirm">
                              <FormattedMessage id="mypage.pwChange.passwordConfirm" />
                            </label>
                          </FormInputField>
                          {errors.pwConfirm && (
                            <span className="warning__message s_txt">
                              {errors.pwConfirm.message}
                            </span>
                          )}
                        </FormInput>
                      </FormInputContainer>
                    </NoFloating>
                  </OverlayItem>
                </OverlayItemWrap>
                <div className="btn__group">
                  <button
                    type="button"
                    className="btn contentBtn btnClear"
                    onClick={handleClose}
                  >
                    <div className="btn__txt">
                      <FormattedMessage id="common.cancel" />
                    </div>
                  </button>
                  <button type="submit" className="btn contentBtn">
                    <div className="btn__txt">
                      <FormattedMessage id="common.modify" />
                    </div>
                  </button>
                </div>
              </OverlayContentBottom>
            </OverlayContent>
          </OverlayContainer>
        </fieldset>
      </form>
    </main>
  );
}

export default PasswordChange;
