import React from 'react';

import { FormattedMessage } from 'react-intl';

import { TableContainer } from '~/components/elements/table';
import { ICampaignListItem } from '~/models/ad/campaign';

import ListTableItem from './list03-table-item';

interface IListTableProps {
  campaigns: ICampaignListItem[];
  handleDetail: (campID: number) => void;
}

function ListTable({ campaigns, handleDetail }: IListTableProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="text__left border__right active">
              <FormattedMessage id="ad.campaign.campName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left">
              <FormattedMessage id="ad.campaign.campType" />
              <i className="icon-chevron-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.campaign.remainBalance" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.campaign.promotion" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.campaign.inProgress" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.campaign.complete" />
              <i className="icon-drop-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns && campaigns.length > 0 ? (
            campaigns.map((camp) => (
              <ListTableItem
                campaign={camp}
                key={camp.CampID}
                handleDetail={handleDetail}
              />
            ))
          ) : (
            <ListTableItem
              campaign={{
                CampID: 0,
                Name: '-',
                CampType: '-',
                Balance: 0,
                Promotion: 0,
                InProgress: 0,
                Complete: 0,
              }}
              key={0}
              handleDetail={handleDetail}
            />
          )}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ListTable;
