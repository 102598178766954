import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContainer,
  FormInputGroup,
  FormInputRadio,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemFrequency,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { IAdCreate } from '~/models/ad/ad';

interface ICreateScheduleProps {
  createData: IAdCreate;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (values: object) => void;
}

function CreateSchedule({
  createData,
  handleCurrentTab,
  handleCreateData,
}: ICreateScheduleProps) {
  const [schedule, setSchedule] = useState<string>('daily');
  const [week, setWeek] = useState<number[]>([
    16777215, 16777215, 16777215, 16777215, 16777215, 16777215, 16777215,
  ]);
  const [weekError, setWeekError] = useState<number>(0);

  useEffect(() => {
    setSchedule(createData.schedule);
    setWeek(createData.week);
  }, [createData.schedule, createData.week]);

  const handleSchedule = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSchedule(value);

    if (value === 'daily') {
      setWeek([
        16777215, 16777215, 16777215, 16777215, 16777215, 16777215, 16777215,
      ]);
    } else if (value === 'weekday') {
      setWeek([0, 16777215, 16777215, 16777215, 16777215, 16777215, 0]);
    } else if (value === 'weekend') {
      setWeek([16777215, 0, 0, 0, 0, 0, 16777215]);
    } else {
      setWeek([0, 0, 0, 0, 0, 0, 0]);
    }

    setWeekError(0);
  };

  const handleWeek = (value: number, index: number) => {
    const newValue = value === 0 ? 16777215 : 0;

    if (schedule === 'self') {
      const newWeek = week.map((w, i) => (i === index ? newValue : w));
      setWeek(newWeek);
    }

    setWeekError(0);
  };

  const handlePrevTab = () => {
    handleCreateData({ schedule, week });
    handleCurrentTab(5);
  };

  const handleNextTab = () => {
    if (schedule === 'self') {
      const index = week.findIndex((w) => w !== 0);

      if (index === -1) {
        setWeekError(1);
        return;
      }
    }

    handleCreateData({ schedule, week });
    handleCurrentTab(7);
  };

  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">
            <FormattedMessage id="ad.ad.schedule" />
          </h5>
        </OverlayItemWrapTitle>

        <OverlayItem>
          <NoFloating>
            <FormInputContainer>
              <FormInput>
                <FormInputGroup className="m_txt">
                  <FormInputRadio>
                    <input
                      type="radio"
                      name="schedule"
                      id="daily"
                      className="m_txt"
                      value="daily"
                      checked={schedule === 'daily'}
                      onChange={handleSchedule}
                    />
                    <label htmlFor="daily">
                      <FormattedMessage id="ad.ad.daily" />
                    </label>
                  </FormInputRadio>

                  <FormInputRadio>
                    <input
                      type="radio"
                      name="schedule"
                      id="weekday"
                      className="m_txt"
                      value="weekday"
                      checked={schedule === 'weekday'}
                      onChange={handleSchedule}
                    />
                    <label htmlFor="weekday">
                      <FormattedMessage id="ad.ad.weekday" />
                    </label>
                  </FormInputRadio>

                  <FormInputRadio>
                    <input
                      type="radio"
                      name="schedule"
                      id="weekend"
                      className="m_txt"
                      value="weekend"
                      checked={schedule === 'weekend'}
                      onChange={handleSchedule}
                    />
                    <label htmlFor="weekend">
                      <FormattedMessage id="ad.ad.weekend" />
                    </label>
                  </FormInputRadio>

                  <FormInputRadio>
                    <input
                      type="radio"
                      name="schedule"
                      id="self"
                      className="m_txt"
                      value="self"
                      checked={schedule === 'self'}
                      onChange={handleSchedule}
                    />
                    <label htmlFor="self">
                      <FormattedMessage id="ad.ad.directSelect" />
                    </label>
                  </FormInputRadio>

                  <OverlayItemFrequency className="m_txt">
                    {week.map((w, index) => (
                      <li
                        key={index}
                        className={cx('btnLight', {
                          active: w === 16777215,
                        })}
                        onClick={() => handleWeek(w, index)}
                      >
                        <FormattedMessage id={`week.${index}`} />
                      </li>
                    ))}
                  </OverlayItemFrequency>
                </FormInputGroup>
                {weekError === 1 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.required" />
                  </span>
                )}
              </FormInput>
            </FormInputContainer>
          </NoFloating>
        </OverlayItem>
      </OverlayItemWrap>
      <OverlayFooter>
        <div className="btn__group">
          <button className="btn contentBtn btnClear" onClick={handlePrevTab}>
            <div className="btn__txt">
              <FormattedMessage id="common.prev" />
            </div>
          </button>
          <button className="btn contentBtn" onClick={handleNextTab}>
            <div className="btn__txt">
              <FormattedMessage id="common.next" />
            </div>
          </button>
        </div>
      </OverlayFooter>
    </OverlayContentBottom>
  );
}

export default CreateSchedule;
