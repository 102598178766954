import React, { useCallback, useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { accountAPI } from '~/api';
import ConfirmPassword from '~/components/common/confirm-password';
import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';

import RevenueRequestForm from './revenue-request01-form';
import Tab from './tab';

function Request() {
  const [revenue, setRevenue] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const [{ data, loading, error }] = useAxios(
    {
      url: accountAPI.getRevenue,
      method: 'POST',
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.push('/pub-my-page');
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.error === 'SUCCESS') {
        setRevenue(data.revenue);
      }
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      setEmail(user.Email);
    }
  }, [user]);

  const handleRequest = useCallback(async () => {
    // const today = new Date();
    // const date = today.getDate();

    // if (date > 5) {
    //   window.alert(formatMessage({ id: 'errors.INVALID_PERIOD' }));
    //   return;
    // }

    // if (revenue < 1000000) {
    //   window.alert(formatMessage({ id: 'errors.NOTENOUGH_REVENUE' }));
    //   return;
    // }

    try {
      dispatch(setIsFullLoader(true));

      const { data: requestData } = await defaultAxios.post(
        accountAPI.requestRevenueCheck,
        {
          num: revenue,
        },
      );

      dispatch(setIsFullLoader(false));

      if (requestData.error === 'SUCCESS') {
        setOpenConfirm(true);
      } else {
        if (requestData.error === 'NEED_ACCOUNT') {
          window.alert(formatMessage({ id: `errors.${requestData.error}` }));
          history.push('/pub-my-page-doc');
          return;
        }
        window.alert(formatMessage({ id: `errors.${requestData.error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  }, [dispatch, formatMessage, history, revenue]);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    setOpenConfirm(false);

    try {
      dispatch(setIsFullLoader(true));

      const { data: requestData } = await defaultAxios.post(
        accountAPI.requestRevenue,
        {
          num: revenue,
        },
      );

      dispatch(setIsFullLoader(false));

      if (requestData.error === 'SUCCESS') {
        window.alert(formatMessage({ id: 'alert.request' }));
        history.push('/pub-my-page-request-list');
      } else {
        if (requestData.error === 'NEED_ACCOUNT') {
          window.alert(formatMessage({ id: `errors.${requestData.error}` }));
          history.push('/pub-my-page-doc');
          return;
        }
        window.alert(formatMessage({ id: `errors.${requestData.error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  }, [dispatch, formatMessage, history, revenue]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <main className="console">
        <OverlayContainer>
          <OverlayHeader>
            <i className="icon-x" onClick={handleClose} />
            <OverlayHeaderTitle className="m_txt">
              <FormattedMessage id="pubmypage.revenueGive" />
            </OverlayHeaderTitle>
          </OverlayHeader>

          <OverlayContent>
            <Tab currentTab={3} />

            <RevenueRequestForm
              revenue={revenue}
              handleRequest={handleRequest}
            />
          </OverlayContent>
        </OverlayContainer>
      </main>

      {openConfirm && (
        <ConfirmPassword
          email={email}
          openModal={openConfirm}
          handleRequestClose={handleCloseConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default Request;
