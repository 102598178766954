import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  FormInput,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

// import Hint from '~/components/common/hint';

interface IProps {
  title: string;
  required?: boolean;
  formInputFieladClass?: string;
  children: React.ReactNode;
  info?: string;
  error?: any;
  clipboard?: boolean;
  clipboardText?: string;
  hint?: JSX.Element;
}

function TitleInput({
  title,
  required,
  formInputFieladClass,
  children,
  info,
  error,
  clipboard,
  clipboardText,
  hint,
}: IProps) {
  const { formatMessage } = useIntl();

  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          {title}
          {required && <i className="required" />}
        </FormInputTitleName>
        {hint && hint}
        {clipboard && (
          <CopyToClipboard
            text={clipboardText || ''}
            onCopy={() =>
              toast.success(formatMessage({ id: 'alert.clipboard' }))
            }
          >
            <i className="icon-filter_none cursor__pointer" />
          </CopyToClipboard>
        )}
      </FormInputTitleWrap>
      <FormInputField className={formInputFieladClass || ''}>
        {children}
      </FormInputField>
      {info && <span className="info__message s_txt">{info}</span>}
      {error && <span className="warning__message s_txt">{error.message}</span>}
    </FormInput>
  );
}

export default TitleInput;
