import { combineReducers } from 'redux';

import core from './core';
import error from './error';
import header from './header';
import user from './user';

const rootReducer = combineReducers({
  core,
  header,
  user,
  error,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
