import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { IAdCreate } from '~/models/ad/ad';

interface ICreateLimitProps {
  createData: IAdCreate;
  budget: number;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (values: object) => void;
}

interface IFormData {
  dayLimit: number;
  budgetLimit: number;
}

function CreateLimit({
  createData,
  budget,
  handleCurrentTab,
  handleCreateData,
}: ICreateLimitProps) {
  const { formatMessage } = useIntl();
  const { register, handleSubmit, getValues, setValue, errors } =
    useForm<IFormData>({
      submitFocusError: false,
    });

  useEffect(() => {
    if (createData.dayLimit === 0) {
      setValue('dayLimit', budget);
    } else {
      setValue('dayLimit', createData.dayLimit);
    }

    if (createData.budgetLimit === 0) {
      setValue('budgetLimit', budget);
    } else {
      setValue('budgetLimit', createData.budgetLimit);
    }
  }, [budget, createData.budgetLimit, createData.dayLimit, setValue]);

  const handlePrevTab = () => {
    const { dayLimit, budgetLimit } = getValues();
    handleCreateData({ dayLimit, budgetLimit });
    handleCurrentTab(6);
  };

  const onSubmit = ({ dayLimit, budgetLimit }: IFormData): void => {
    handleCreateData({ dayLimit, budgetLimit });
    handleCurrentTab(8);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>상세정보 - 광고(배너) : 한도</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="ad.ad.limit" />
              </h5>
              <Hint
                title={formatMessage({ id: 'hint.limit' })}
                content={formatMessage({ id: 'hint.limit.desc' })}
              />
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.dayLimit" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField className="unit">
                      <input
                        type="number"
                        name="dayLimit"
                        id="dayLimit"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                          min: {
                            value: 100000,
                            message: formatMessage(
                              { id: 'validate.min' },
                              { min: '100000' },
                            ),
                          },
                          max: {
                            value: 2000000000,
                            message: formatMessage(
                              { id: 'validate.max' },
                              { max: '2000000000' },
                            ),
                          },
                          validate: {
                            onlyNum: (value) =>
                              !isNaN(Number(value)) ||
                              formatMessage({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="dayLimit">
                        <FormattedMessage id="ad.ad.dayLimit" />
                      </label>
                      <i className="unit xs_txt">KRW</i>
                    </FormInputField>
                    {errors.dayLimit && (
                      <span className="warning__message s_txt">
                        {errors.dayLimit.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.budgetLimit" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField className="unit">
                      <input
                        type="number"
                        name="budgetLimit"
                        id="budgetLimit"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                          min: {
                            value: 100000,
                            message: formatMessage(
                              { id: 'validate.min' },
                              { min: '100000' },
                            ),
                          },
                          max: {
                            value: 2000000000,
                            message: formatMessage(
                              { id: 'validate.max' },
                              { max: '2000000000' },
                            ),
                          },
                          validate: {
                            onlyNum: (value) =>
                              !isNaN(Number(value)) ||
                              formatMessage({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="adLimit">
                        <FormattedMessage id="ad.ad.budgetLimit" />
                      </label>
                      <i className="unit xs_txt">KRW</i>
                    </FormInputField>
                    {errors.budgetLimit && (
                      <span className="warning__message s_txt">
                        {errors.budgetLimit.message}
                      </span>
                    )}
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button
                className="btn contentBtn btnClear"
                onClick={handlePrevTab}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.next" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateLimit;
