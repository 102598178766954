import React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Content } from '~/components/elements/content';

const Intro = styled(Content as any)`
  strong {
    position: relative;
    padding: 0.4rem 0.25rem 0;
    background-color: #f3f7ff;
    color: #3f38d6;
    font-size: 2.8rem;
    font-weight: 600;

    @media screen and (max-width: 599px) {
      font-size: 3.5rem;
    }
  }
`;

function HomeIntro() {
  return (
    <Intro id="p1__intro" className="first">
      <h1 className="l_tit">
        <FormattedHTMLMessage id="home.intro.title" />
      </h1>
      <h5 className="m_txt">
        <FormattedMessage id="home.intro.subTitle" />
      </h5>
      <img src={require('~/assets/images/p1_main.png').default} alt="" />
    </Intro>
  );
}

export default HomeIntro;
