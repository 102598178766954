import React, { useEffect } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { accountAPI } from '~/api';
import AddInfo from '~/components/account/my/add-info';
import DefaultInfo from '~/components/account/my/default-info';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { IResAdMyPage } from '~/models/account/my-page';
import { setIsFullLoader } from '~/modules/core';

import Asset from './asset';

const MyPageWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #ebebeb;
`;

function MyPage() {
  const [{ data, loading, error }] = useAxios<IResAdMyPage>(
    {
      url: accountAPI.adMyPage,
      method: 'POST',
    },
    { useCache: false },
  );

  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!data) {
    return null;
  }

  const { customer } = data;

  return (
    <MyPageWrap>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="mypage.mypage" />
        </h1>

        <DefaultInfo customer={customer} />

        <AddInfo customer={customer} />

        <Asset customer={customer} />
      </ConsoleContent>
    </MyPageWrap>
  );
}

export default MyPage;
