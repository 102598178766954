import React from 'react';

import NumberFormat from 'react-number-format';

import { IReportByDailyMedia } from '~/models/pub/report';

interface IReportDayListMediaProps {
  med: IReportByDailyMedia;
}

function ReportDayListMedia({ med }: IReportDayListMediaProps) {
  const { Name, Revenue, eCPM, Impressions, Installs, CTR } = med;

  return (
    <tr className="table__2depth">
      <th />
      <th className="text__left border__right table__title">
        <span>{Name}</span>
      </th>
      <td className="text__right table__important">
        <NumberFormat value={Revenue} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={eCPM} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat
          value={Impressions}
          displayType="text"
          thousandSeparator
        />
      </td>
      <td className="text__right">
        <NumberFormat value={Installs} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">{CTR}</td>
    </tr>
  );
}

export default ReportDayListMedia;
