import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContainerGap, Content } from '~/components/elements/content';

const StartItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    width: calc(50% - 20px);

    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }
`;

const StartItemText = styled.div`
  @media screen and (max-width: 599px) {
    margin-bottom: 2rem;
  }

  span {
    display: block;
    line-height: 2.2rem;
    width: 2rem;
    height: 2rem;
    background-color: #3f38d6;
    font-size: 1.5rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 599px) {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      width: 3rem;
      height: 3rem;
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }
  }
`;

function Start01() {
  return (
    <Content>
      <h3 className="m_tit">
        <FormattedMessage id="start.email" />
      </h3>
      <ContainerGap>
        <StartItem>
          <StartItemText>
            <span>1</span>
            <p className="xl_txt">
              <FormattedMessage id="start.email.step01" />
            </p>
          </StartItemText>
          <img src={require('~/assets/images/p2_1.png').default} alt="" />
        </StartItem>

        <StartItem>
          <StartItemText>
            <span>2</span>
            <p className="xl_txt">
              <FormattedMessage id="start.email.step02" />
            </p>
          </StartItemText>
          <img src={require('~/assets/images/p2_2.png').default} alt="" />
        </StartItem>

        <StartItem>
          <StartItemText>
            <span>3</span>
            <p className="xl_txt">
              <FormattedMessage id="start.email.step03" />
            </p>
          </StartItemText>
          <img src={require('~/assets/images/p2_3.png').default} alt="" />
        </StartItem>
      </ContainerGap>
    </Content>
  );
}

export default Start01;
