import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  AdType,
  OverlayContentBottom,
  OverlayItemSelect,
  OverlayItemWrap,
  OverlayItemWrapTitle,
  OverlaySelectImg,
} from '~/components/elements/overlay';

interface ICreateProductProps {
  handleProduct: (value: string) => void;
}

function CreateProduct({ handleProduct }: ICreateProductProps) {
  return (
    <OverlayContentBottom>
      <AdType>
        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.banner" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.bannerDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct('BANNER')}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_banner.png').default}
              alt="banner"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>

        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.interstitial" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.interstitialDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct('INTERSTITIAL')}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_interstitial.png').default}
              alt="interstitial"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>

        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.video" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.videoDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct('VIDEO')}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_video.png').default}
              alt="video"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>
      </AdType>
    </OverlayContentBottom>
  );
}

export default CreateProduct;
