import styled from 'styled-components';

import { FormInput, FormInputContainer, FormInputRadio } from './form';
import { NoFloating } from './no-floating';

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: calc(100vh + 1px);
  height: 100%;
  z-index: 99;
`;

export const OverlayHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
  z-index: 5;

  a {
    display: flex;
    align-items: center;
  }

  i {
    &.icon-x {
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1155px) {
    height: 50px;
  }
`;

export const OverlayHeaderTitle = styled.h4`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 599px) {
    width: 180px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    left: 40px;
    transform: translate(0, -50%);
    text-align: left;
  }

  @media screen and (max-width: 359px) {
    width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const OverlayHeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;

  a {
    display: flex;
    align-items: center;
    padding: 8px 18px;
    font-size: 16px;

    i {
      color: #3f38d6;
    }
  }

  @media screen and (max-width: 1155px) {
    a {
      > .btn__txt {
        display: none;
      }
    }
  }
`;

export const OverlayContent = styled.div`
  padding-top: 60px;
  background-color: #f8f8f8;
  min-height: 100%;
  height: auto;

  @media screen and (max-width: 1155px) {
    padding-top: 50px;
  }
`;

export const OverlayContentTop = styled.div`
  &.create {
    border-bottom: 1px solid #ebebeb;
  }
`;

export const OverlayTopWrap = styled.div`
  max-width: 1156px;
  margin: 0 auto;
  padding-top: 7.5rem;

  ul {
    padding-top: 5rem;

    &.overlay__top--detail {
      li {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 10px;

        &:last-child {
          padding-bottom: 0;
        }

        h5 {
          min-width: 124px;
          white-space: nowrap;
          margin-right: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 1155px) {
    width: 100%;
    padding: 7.5rem 24px 0;
  }

  @media screen and (max-width: 599px) {
    padding: 7.5rem 16px 0;
  }
`;

export const OverlayTitleWrap = styled.div`
  display: flex;
  align-items: baseline;
`;

export const OverlayTitleKeyword = styled.span`
  display: block;
  height: auto;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 3px 8px 0;
  border-radius: 4px;
  background-color: #ebebeb;
  margin-left: 6px;
`;

export const OverlayTopCreate = styled.div`
  ul {
    display: flex;
  }

  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: auto;
    height: auto;
    margin: 0 1.5rem;
    padding: 0.5rem 0;

    &:first-child {
      margin-left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0%;
      height: 2px;
      display: block;
      background-color: #3f38d6;
      z-index: 4;
    }

    &.active {
      &::after {
        width: 100%;
        transition: 0.3s ease;
      }
    }
  }

  @media screen and (max-width: 716px) {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const OverlayContentBottom = styled.div`
  position: relative;
  width: 1156px;
  margin: 0 auto;
  padding: 5rem 0 7.5rem;
  height: auto;
  max-width: 100vw;

  @media screen and (max-width: 1155px) {
    width: 100%;
    padding: 80px 24px;
  }

  @media screen and (max-width: 1155px) {
    padding: 40px 16px;
  }

  .btn__group {
    justify-content: flex-end;
  }

  button:not(button[type='button, submit']) {
    height: auto;
    margin-top: 0.5rem;
  }

  > button {
    margin-left: auto;
  }

  button.btnLine {
    height: auto;
    margin-top: 0.5rem;
    margin-left: auto;
  }
`;

export const OverlayItemWrap = styled.div`
  display: flex;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  margin-bottom: 20px;

  &.clear {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-top: 5rem;
  }

  @media screen and (max-width: 716px) {
    flex-direction: column;
    padding: 20px;

    h5 {
      padding-bottom: 2rem;
    }
  }
`;

export const OverlayItemWrapTitle = styled.div`
  display: flex;
  min-width: 274px;
  height: min-content;

  @media screen and (max-width: 1155px) {
    min-width: 176px;
    flex-wrap: wrap;
  }
`;

export const OverlayItem = styled.div`
  position: relative;
  flex: 1 0 auto;
  height: 100%;

  ${FormInputContainer} {
    flex-direction: column;
  }

  ${NoFloating} {
    &:last-child {
      ${FormInput} {
        + ${FormInput} {
          &:last-child {
            padding-bottom: 0;
          }

          > span {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  ${FormInput} {
    max-width: 470px;
    padding-bottom: 1rem;

    > span {
      padding-bottom: 1rem;
      display: block;
    }

    &.interstitial {
      img {
        max-width: 50%;
      }
    }

    &.video {
      video {
        position: relative;
        max-width: 100%;
        display: block;
        z-index: 1;
      }
    }
  }

  input[type='checkbox'] + label {
    left: 10px;
    height: auto;
    line-height: normal;
  }

  ${FormInputRadio} {
    position: relative;
    left: -10px;

    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 716px) {
    width: 100%;
  }
`;

export const OverlayItemSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 161px;
  height: 100%;

  button {
    margin-left: auto;
    align-self: flex-end;
  }
`;

export const OverlaySelectImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 165px;
  min-width: 274px;
  height: 100%;
  border: 1px solid #ebebeb;
  background-color: #fdfdfd;
  margin-left: 1rem;

  img {
    position: relative;
    width: auto;
    height: 100%;
    display: block;
  }
`;

export const AdType = styled.div`
  @media screen and (max-width: 716px) {
    ${OverlayItemWrapTitle} {
      order: 2;
    }

    ${OverlayItemSelect} {
      order: 3;
      min-height: auto;

      button {
        margin-top: 3rem;
      }
    }

    ${OverlaySelectImg} {
      order: 1;
      min-width: 100%;
      margin-left: 0;
      margin-bottom: 3rem;
    }
  }
`;

export const OverlayItemFrequency = styled.ul`
  display: flex;
  padding-top: 1rem;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: #3f38d6;
      border: 0;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 599px) {
    width: 100%;

    li {
      max-width: 40px;
      width: 100%;
    }
  }
`;

export const OverlayFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid #ebebeb;
  padding: 0 16px;
  z-index: 5;

  .btn__group {
    width: 100%;
    max-width: 1155px;
  }

  .btn__group .btn:first-child {
    margin-right: 0 !important;
  }

  @media screen and (max-width: 1155px) {
    height: 50px;
  }

  @media screen and (max-width: 599px) {
    .btn__group button:last-child {
      margin-left: auto;
    }
  }
`;
