import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { numberPattern, numberPointPattern } from '~/libs/regexp';

interface IDetailAppProps {
  register: any;
  errors: any;
  rewardKey: string;
}

function DetailAppReward({ register, errors, rewardKey }: IDetailAppProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="pub.appReward" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  Reward Key
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputGroup>
                <span className="m_txt">{rewardKey}</span>
                <CopyToClipboard
                  text={rewardKey || ''}
                  onCopy={() =>
                    toast.success(formatMessage({ id: 'alert.clipboard' }))
                  }
                >
                  <i className="icon-filter_none cursor__pointer" />
                </CopyToClipboard>
              </FormInputGroup>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.appReward.rewardName" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="rewardName"
                  id="rewardName"
                  placeholder=""
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                  })}
                />
                <label htmlFor="rewardName">
                  <FormattedMessage id="pub.appReward.rewardName" />
                </label>
              </FormInputField>
              {errors.rewardName && (
                <span className="warning__message s_txt">
                  {errors.rewardName.message}
                </span>
              )}
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.appReward.reward" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="reward"
                  id="reward"
                  placeholder=""
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                    pattern: {
                      value: numberPointPattern,
                      message: formatMessage({ id: 'validate.onlyNum' }), // JS only: <p>error message</p> TS only support string
                    },
                  })}
                />
                <label htmlFor="reward">
                  <FormattedMessage id="pub.appReward.reward" />
                </label>
              </FormInputField>
              {errors.reward && (
                <span className="warning__message s_txt">
                  {errors.reward.message}
                </span>
              )}
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.apiKeyDayLimit" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="dayLimit"
                  id="dayLimit"
                  placeholder={formatMessage({
                    id: 'pub.appReward.dayLimit',
                  })}
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                    pattern: {
                      value: numberPattern,
                      message: formatMessage({ id: 'validate.onlyNum' }), // JS only: <p>error message</p> TS only support string
                    },
                  })}
                />
                <label htmlFor="name">
                  <FormattedMessage id="pub.app.apiKeyDayLimit" />
                </label>
              </FormInputField>
              {errors.dayLimit && (
                <span className="warning__message s_txt">
                  {errors.dayLimit.message}
                </span>
              )}
            </FormInput>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailAppReward;
