import React from 'react';

import { CSVLink } from 'react-csv';

interface IReportCampaignExportProps {
  csv: any;
}

function ReportCampaignExport({ csv }: IReportCampaignExportProps) {
  if (!csv) {
    return null;
  }

  return (
    <CSVLink
      data={csv}
      filename="Advertiser-Campaign.csv"
      className="btn contentBtn btnLine"
    >
      <i className="icon-save_alt" />
      <div className="btn__txt">Export</div>
    </CSVLink>
  );
}

export default ReportCampaignExport;
