import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import urlRegex from 'url-regex';

import TitleInput from '~/components/base/title-input';
import {
  FormInput,
  FormInputContainer,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { Select, selectStyles } from '~/components/elements/select';
import { ISelectOption } from '~/models/select-option';

interface IDetailAppProps {
  countryOptions: ISelectOption[];
  defaultCountry: ISelectOption[];
  campType: string;
  register: any;
  errors: any;
  defaultURL: string;
  handleDefaultCountry: (selectedOption: any) => void;
}

function DetailApp({
  countryOptions,
  defaultCountry,
  campType,
  register,
  errors,
  defaultURL,
  handleDefaultCountry,
}: IDetailAppProps) {
  const { formatMessage: f } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.campaign.app" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <TitleInput
              title={f({ id: 'ad.campaign.country' })}
              required
              error={errors.defaultCountry}
            >
              <Select
                name="defaultCountry"
                placeholder={f({ id: 'ad.campaign.country' })}
                options={countryOptions}
                value={defaultCountry}
                styles={selectStyles}
                isClearable
                isSearchable
                isMulti
                onChange={handleDefaultCountry}
              />
            </TitleInput>

            <FormInput className="unit">
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.campaign.campType" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputGroup className="disable">
                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="campType"
                    id="android"
                    value="AOS"
                    ref={register}
                    checked={campType === 'AOS'}
                    disabled
                  />
                  <label htmlFor="android">Android</label>
                </FormInputRadio>

                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="campType"
                    id="ios"
                    value="IOS"
                    ref={register}
                    checked={campType === 'IOS'}
                    disabled
                  />
                  <label htmlFor="ios">iOS</label>
                </FormInputRadio>
              </FormInputGroup>
            </FormInput>

            <TitleInput
              title={f({ id: 'ad.campaign.targetUrl' })}
              required
              error={errors.defaultURL}
              clipboard
              clipboardText={defaultURL}
            >
              <textarea
                name="defaultURL"
                cols={40}
                rows={2}
                ref={register({
                  required: f({ id: 'validate.required' }),
                  validate: (value: string) =>
                    urlRegex({ exact: true }).test(value) ||
                    f({ id: 'validate.url' }),
                })}
              />
              <label htmlFor="defaultURL">
                <FormattedMessage id="ad.campaign.targetUrl" />
              </label>
            </TitleInput>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailApp;
