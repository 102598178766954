import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { IInvenCreate } from '~/models/pub/inventory';

import CreateTab from './create00-tab';
import CreateInvenType from './create01-inven-type';
import CreateInventory from './create02-inventory';
import CreateReward from './create03-reward';

function Create() {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [createData, setCreateData] = useState<IInvenCreate>({
    product: '',
    name: '',
    reward: '',
    rewards: '',
  });
  const [errorData, setErrorData] = useState<any>(null);

  const history = useHistory();
  const [query] = useQueryParams({
    appID: StringParam,
  });

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.push(`/pub-inventory?appID=${query.appID}`);
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  const handleCurrentTab = (value: number) => {
    setCurrentTab(value);
  };

  const handleCreateData = (obj: object) => {
    setCreateData({ ...createData, ...obj });
  };

  const handleReqError = (err: any) => {
    setErrorData(err);
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="pub.inven.create" />
          </OverlayHeaderTitle>
        </OverlayHeader>
        <OverlayContent>
          <CreateTab currentTab={currentTab} product={createData.product} />

          {currentTab === 1 && (
            <CreateInvenType
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 2 && (
            <CreateInventory
              createData={createData}
              appID={query.appID}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
              handleReqError={handleReqError}
            />
          )}

          {currentTab === 3 && createData.product !== 'BANNER' && (
            <CreateReward
              createData={createData}
              appID={query.appID}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
              handleReqError={handleReqError}
            />
          )}
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default Create;
