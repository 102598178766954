import React from 'react';

import { Helmet } from 'react-helmet';

import FailedAuthentication from '~/components/account/failed-authentication';

function FailedAuthenticationPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <FailedAuthentication />
    </>
  );
}

export default FailedAuthenticationPage;
