import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IRevenueRequestProps {
  revenue: number;
  handleClickRequest: () => void;
}

function RevenueRequest({ revenue, handleClickRequest }: IRevenueRequestProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="pubmypage.revenueGive.giveRequest" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputContentWrap>
        <span className="m_txt">
          <NumberFormat value={revenue} displayType="text" thousandSeparator />
          <i className="unit xs_tit">KRW</i>
          <i className="icon-chevron-right" />
        </span>
        <button className="btn contentBtn" onClick={handleClickRequest}>
          <FormattedMessage id="pubmypage.revenueGive.request" />
        </button>
      </FormInputContentWrap>
    </FormInput>
  );
}

export default RevenueRequest;
