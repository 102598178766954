import React from 'react';

import { Chart as DefaultChart } from 'react-google-charts';
import styled from 'styled-components';

const Chart = styled(DefaultChart)`
  g:first-of-type g g text,
  g g g + path,
  g g g + path + circle {
    cursor: pointer;
  }
`;

function LineChart({ chartData, options, handleImageURI }) {
  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        // console.log('ready ', chartWrapper.getChart().getImageURI());
        if (handleImageURI) {
          handleImageURI(chartWrapper.getChart().getImageURI());
        }
      },
    },
  ];

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="500px"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={options}
      chartEvents={chartEvents}
    />
  );
}

export default LineChart;
