import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  AdType,
  OverlayContentBottom,
  OverlayItemSelect,
  OverlayItemWrap,
  OverlayItemWrapTitle,
  OverlaySelectImg,
} from '~/components/elements/overlay';

interface ICreateInvenTypeProps {
  handleCurrentTab: (value: number) => void;
  handleCreateData: (obj: object) => void;
}

function CreateInvenType({
  handleCurrentTab,
  handleCreateData,
}: ICreateInvenTypeProps) {
  const handleProduct = (obj: object) => {
    handleCreateData(obj);
    handleCurrentTab(2);
  };

  return (
    <OverlayContentBottom>
      <AdType>
        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.banner" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.bannerDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct({ product: 'BANNER' })}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_banner.png').default}
              alt="banner"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>

        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.interstitial" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.interstitialDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct({ product: 'INTERSTITIAL' })}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_interstitial.png').default}
              alt="banner"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>

        <OverlayItemWrap>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="ad.ad.video" />
            </h5>
          </OverlayItemWrapTitle>
          <OverlayItemSelect>
            <p className="m_txt">
              <FormattedMessage id="ad.ad.videoDesc" />
            </p>
            <button
              className="btn contentBtn btnLight"
              onClick={() => handleProduct({ product: 'VIDEO' })}
            >
              <FormattedMessage id="common.select" />
            </button>
          </OverlayItemSelect>
          <OverlaySelectImg>
            <img
              src={require('~/assets/images/ad_video.png').default}
              alt="banner"
            />
          </OverlaySelectImg>
        </OverlayItemWrap>
      </AdType>
    </OverlayContentBottom>
  );
}

export default CreateInvenType;
