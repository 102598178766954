import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Content as DefaultContent } from '~/components/elements/content';

import {
  ProfitItem as DefaultProfitItem,
  ProfitItemSymbol as DefaultProfitSymbol,
} from './home03-elements';

const Wrapper = styled.div`
  background-color: #3f38d6;

  h5 {
    color: #ffffff;
  }
`;

const Content = styled(DefaultContent as any)`
  display: flex;

  @media screen and (max-width: 1155px) {
    display: flex;
    flex-direction: column;
  }
`;

const ProfitItem = styled(DefaultProfitItem as any)`
  width: 30%;
`;

const ProfitItemSymbol = styled(DefaultProfitSymbol as any)`
  background-color: #ffffff;

  i {
    color: #3f38d6;
  }
`;

const ProfitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Profit2Item = styled.div`
  width: calc(33.33% - 13.33px);
  display: flex;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  align-items: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 599px) {
    width: calc(50% - 8px);
  }

  @media screen and (max-width: 399px) {
    flex-direction: column;
    padding: 1.5rem;
  }

  img {
    width: 3rem;
    height: auto;
    margin-left: auto;

    @media screen and (max-width: 599px) {
      width: 5rem;
    }
  }
`;

const Profit2ItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 399px) {
    margin-right: auto;
    padding-bottom: 2rem;
  }

  span {
    font-size: 0.75rem;
    font-weight: 400;
    color: #6b88e5;

    @media screen and (max-width: 599px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1b1c23;
    line-height: 1;

    @media screen and (max-width: 599px) {
      font-size: 1.8rem;
    }
  }
`;

function HomeRevenue2() {
  return (
    <Wrapper>
      <Content>
        <ProfitItem>
          <ProfitItemSymbol>
            <i className="icon-graph" />
          </ProfitItemSymbol>
          <h5 className="s_tit">
            <FormattedMessage id="home.revenue.networkResult" />
          </h5>
        </ProfitItem>

        <ProfitContainer>
          <Profit2Item data-aos="fade-up" data-aos-duration="1000">
            <Profit2ItemText>
              <span>eCPM</span>
              <p>6,000 KRW</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network1.png').default}
              alt=""
            />
          </Profit2Item>

          <Profit2Item data-aos="fade-up" data-aos-duration="1000">
            <Profit2ItemText>
              <span>Requests</span>
              <p>15,981</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network2.png').default}
              alt=""
            />
          </Profit2Item>

          <Profit2Item data-aos="fade-up" data-aos-duration="1000">
            <Profit2ItemText>
              <span>CTR</span>
              <p>92.17%</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network3.png').default}
              alt=""
            />
          </Profit2Item>

          <Profit2Item
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="50"
          >
            <Profit2ItemText>
              <span>Impressions</span>
              <p>14,023</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network4.png').default}
              alt=""
            />
          </Profit2Item>

          <Profit2Item
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="50"
          >
            <Profit2ItemText>
              <span>Installs</span>
              <p>233</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network6.png').default}
              alt=""
            />
          </Profit2Item>

          <Profit2Item
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <Profit2ItemText>
              <span>Revenue</span>
              <p>580,000 KRW</p>
            </Profit2ItemText>
            <img
              src={require('~/assets/images/p1_network7.png').default}
              alt=""
            />
          </Profit2Item>
        </ProfitContainer>
      </Content>
    </Wrapper>
  );
}

export default HomeRevenue2;
