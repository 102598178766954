import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContainerGap, Content } from '~/components/elements/content';

const AnalysisImgFade = styled.div`
  width: calc(50% - 20px);

  @media screen and (max-width: 599px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

const AnalysisItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }

  &:first-child {
    margin-bottom: 1rem;

    @media screen and (max-width: 599px) {
      margin-bottom: 4rem;
    }

    ${AnalysisImgFade} {
      background-color: #62dee9;
    }
  }

  &:nth-child(2) {
    ${AnalysisImgFade} {
      background-color: #3f38d6;
    }
  }
`;

const AnalysisItemText = styled.div`
  width: 23.5rem;
  min-width: calc(50% - 20px);
  margin-right: 1rem;

  @media screen and (max-width: 599px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

function HomeData() {
  return (
    <Content id="data">
      <h3 className="m_tit">
        <FormattedMessage id="home.data.title" />
      </h3>
      <ContainerGap>
        <AnalysisItem>
          <AnalysisItemText>
            <h5 className="s_tit">
              <FormattedMessage id="home.data.user" />
            </h5>
            <p className="s_txt">
              <FormattedMessage id="home.data.userDesc" />
            </p>
          </AnalysisItemText>
          <AnalysisImgFade>
            <img
              src={require('~/assets/images/p1_analysis1.png').default}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom"
            />
          </AnalysisImgFade>
        </AnalysisItem>

        <AnalysisItem>
          <AnalysisItemText>
            <h5 className="s_tit">
              <FormattedMessage id="home.data.report" />
            </h5>
            <p className="s_txt">
              <FormattedMessage id="home.data.reportDesc" />
            </p>
          </AnalysisItemText>
          <AnalysisImgFade>
            <img
              src={require('~/assets/images/p1_analysis2.png').default}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom"
            />
          </AnalysisImgFade>
        </AnalysisItem>
      </ContainerGap>
    </Content>
  );
}

export default HomeData;
