import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContainerGap, Content } from '~/components/elements';

const AppContainer = styled(ContainerGap as any)`
  display: flex;
  flex-wrap: wrap;
`;

const AppItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(33.33% - 13.33px);
  height: 100%;
  max-height: 78px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: 0.4s ease;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:hover {
    i {
      transform: translate(0, -50%);
    }
  }

  i {
    position: absolute;
    top: 50%;
    right: 20%;
    color: #1b1c23;
    transform: translate(0, 400%);
    transition: 0.3s ease;
    display: block;
  }

  img {
    width: 21%;
    min-width: 21%;
    height: 100%;
    margin-right: 1rem;
    border-radius: 25%;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    margin-right: 0;

    img {
      width: 15%;
      min-width: 15%;
      margin-right: 16px;
    }

    i {
      display: none;
    }
  }
`;

const AppItemText = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`;

function GameContent() {
  return (
    <Content>
      <h3 className="m_tit">
        <FormattedMessage id="game.game" />
      </h3>

      <AppContainer>
        <AppItem
          href="https://play.google.com/store/apps/details?id=kr.co.rhaon.Planet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game1.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Space Tab</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.fusion"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game2.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Union Star</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.Aqua"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game3.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Aqua Pang</h5>
            <p className="xs_txt">Match-3-Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.Match3"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game4.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Cube Plus</h5>
            <p className="xs_txt">Match-3-Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.rhaon.DigDig"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game5.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Bigbo's DigDig</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=kr.co.rhaon.DriftUnlimited"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game6.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Drift Unlimited</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=kr.co.rhaon.wing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game7.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Hyper Wing</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        {/* <AppItem
          href="https://play.google.com/store/apps/details?id=com.rhaon.Swipe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game8.png')} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Henri's Swipe</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem> */}

        {/* <AppItem
          href="https://play.google.com/store/apps/details?id=com.rhaon.Knife"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game9.png')} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Kuro's Knife</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem> */}

        <AppItem
          href="https://play.google.com/store/apps/details?id=kr.co.rhaon.thiefoff"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game10.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Legend of thief</h5>
            <p className="xs_txt">Racing Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.merge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game11.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Merge Rune</h5>
            <p className="xs_txt">Clicker Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.archer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game12.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Night Archer</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.Sniper"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game13.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">One Shot Sniper</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.zenaad.packaging2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game15.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Packaging Master</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        {/* <AppItem
          href="https://play.google.com/store/apps/details?id=kr.co.rhaon.packaging2"
          target="_blank"
          rel="noopener noreferrer"
        > 
          <img src={require('~/assets/images/p7_game14.png')} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Packaging Master2</h5>
            <p className="xs_txt">Hyper Casual Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem> */}

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.dbox.KPopPoly"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game16.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">K-Pop Poly</h5>
            <p className="xs_txt">3D Puzzle Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.haste.archer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game17.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Lengendary Archer : Rebirth</h5>
            <p className="xs_txt">Arcade Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=net.wenee.blockpanda"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game18.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">Block Panda</h5>
            <p className="xs_txt">Puzzle Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.silnabi.Puzzle"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('~/assets/images/p7_game19.png').default} alt="" />
          <AppItemText>
            <h5 className="xs_tit">움직여봐요, 한글퍼즐</h5>
            <p className="xs_txt">Puzzle Game</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>
      </AppContainer>
    </Content>
  );
}

export default GameContent;
