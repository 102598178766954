import React, { useEffect, useRef } from 'react';

import { AxiosPromise } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';

interface IDetailBidProps {
  product: string;
  campType: string;
  billingType: string;
  minCost: number;
  maxCost: number;
  minCostError: number;
  maxCostError: number;
  bidData: any;
  getBidCost: (data: any) => AxiosPromise<any>;
  handleMinCost: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaxCost: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function DetailBid({
  product,
  campType,
  billingType,
  minCost,
  maxCost,
  minCostError,
  maxCostError,
  bidData,
  getBidCost,
  handleMinCost,
  handleMaxCost,
}: IDetailBidProps) {
  const minValue = useRef<number>(1);
  const maxValue = useRef<number>(100000);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (billingType === adtier0.BILLING_TYPE[adtier0.BILLING_TYPE.CPM]) {
      minValue.current = 1000;
    }
  }, [billingType]);

  useEffect(() => {
    getBidCost({
      data: {
        product,
        campType,
        billingType,
      },
    });
  }, [billingType, campType, getBidCost, product]);

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.bid" />
        </h5>
        <Hint
          title={formatMessage({ id: 'hint.bid' })}
          content={formatMessage({ id: 'hint.bid.desc' })}
        />
      </OverlayItemWrapTitle>
      <OverlayItem>
        <form>
          <fieldset>
            <legend>상세정보 - 광고(배너) : 입찰</legend>
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.bidCost" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">
                    <NumberFormat
                      value={bidData ? bidData.value : 0}
                      displayType="text"
                      thousandSeparator
                    />
                    <i className="unit xs_txt">KRW</i>
                  </span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.minCost" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputField className="unit">
                    <input
                      type="text"
                      name="minCost"
                      id="minCost"
                      placeholder=""
                      value={minCost}
                      onChange={handleMinCost}
                    />
                    <label htmlFor="minCost">
                      <FormattedMessage id="ad.ad.minCost" />
                    </label>
                    <i className="unit xs_txt">KRW</i>
                  </FormInputField>
                  {minCostError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}

                  {minCostError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.min"
                        values={{ min: minValue.current }}
                      />
                    </span>
                  )}

                  {minCostError === 3 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.max"
                        values={{ max: maxValue.current }}
                      />
                    </span>
                  )}

                  {minCostError === 4 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.onlyNum" />
                    </span>
                  )}
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.maxCost" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputField className="unit">
                    <input
                      type="text"
                      name="maxCost"
                      id="maxCost"
                      placeholder=""
                      value={maxCost}
                      onChange={handleMaxCost}
                    />
                    <label htmlFor="maxCost">
                      <FormattedMessage id="ad.ad.maxCost" />
                    </label>
                    <i className="unit xs_txt">KRW</i>
                  </FormInputField>
                  {maxCostError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}

                  {maxCostError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.min"
                        values={{ min: minValue.current }}
                      />
                    </span>
                  )}

                  {maxCostError === 3 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.max"
                        values={{ max: maxValue.current }}
                      />
                    </span>
                  )}

                  {maxCostError === 4 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.minMax"
                        values={{ min: minCost }}
                      />
                    </span>
                  )}

                  {maxCostError === 5 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.onlyNum" />
                    </span>
                  )}
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </fieldset>
        </form>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailBid;
