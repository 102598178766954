import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link as ScrollLink } from 'react-scroll';

import { SubNav } from '~/components/elements/sub-nav';

function HomeSubNav() {
  return (
    <SubNav id="sub-nav" className="m_txt">
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="revenue"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="home.subNav.revenue" />
      </ScrollLink>
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="ad-form"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="home.subNav.format" />
      </ScrollLink>
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="data"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="home.subNav.data" />
      </ScrollLink>
    </SubNav>
  );
}

export default HomeSubNav;
