import path from 'path';

import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { adAdAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { delay } from '~/libs/utils';

interface IDetailCampaignProps {
  product: string;
  name: string;
  comment: string;
  filenames: string[];
  matURLs: string[];
  handleReqError: (err: any) => void;
  handleReqLoading: (value: boolean) => void;
  handleInputs: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFiles: (
    arrURL: any[],
    arrFilename: string[],
    arrFile: any[],
  ) => Promise<void>;
}

function DeatilCampaign({
  product,
  name,
  comment,
  filenames,
  matURLs,
  handleReqError,
  handleReqLoading,
  handleInputs,
  handleFiles,
}: IDetailCampaignProps) {
  const [videoMats, setVideoMats] = useState<string[] | null>(null);
  const [imageMats, setImageMats] = useState<string[] | null>(null);

  const { formatMessage } = useIntl();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept:
      product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]
        ? 'video/mp4, video/quicktime'
        : 'image/jpeg, image/png',
    multiple: product === adtier0.PRODUCT[adtier0.PRODUCT.BANNER],
    maxSize:
      product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] ? 10485760 : 512000,
    onDropAccepted: async (acceptedFiles) => {
      try {
        if (product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]) {
          if (window.confirm(formatMessage({ id: 'ad.ad.confirmEncoding' }))) {
            handleReqLoading(true);

            const videoFile = acceptedFiles[0];

            const formData = new FormData();
            formData.append('product', product);
            acceptedFiles.forEach((file) => {
              formData.append('uploadFiles', file);
            });

            const { data } = await defaultAxios.post(
              adAdAPI.s3getSignedUrlVideo,
              {
                product,
                orginname: videoFile.name,
                filetype: videoFile.type,
              },
            );

            const uploadURLs: any[] = [];
            uploadURLs.push({
              uploadURL: data.uploadURL,
              file: videoFile,
            });

            const all = await Promise.all(
              uploadURLs.map(
                async (item) =>
                  await defaultAxios.put(item.uploadURL, item.file, {
                    headers: {
                      'Content-Type': item.file.type,
                    },
                  }),
              ),
            );

            all.forEach((res) => {
              const { status } = res;
              if (status !== 200) {
                throw new Error();
              }
            });

            const { data: durationData } = await defaultAxios.post(
              adAdAPI.duration,
              {
                url: data.uploadURL.split('?')[0],
              },
            );

            const { duration } = durationData;
            if (duration < 5 || duration > 35) {
              window.alert(formatMessage({ id: 'ad.ad.videoDuration' }));
              return;
            }

            const dir = path.dirname(durationData.url);
            const videoFilename = path.basename(durationData.url);
            const jsonFilename = `${videoFilename.split('.')[0]}.json`;
            const jsonURL = `${dir}/${jsonFilename}`;

            const { data: videoData } = await defaultAxios.post(
              adAdAPI.video2,
              {
                filename: videoFilename,
              },
            );

            const { statusCode } = videoData;
            if (statusCode === 202) {
              let count = 1;
              const check = async () => {
                for (;;) {
                  count++;
                  await delay(10000);

                  try {
                    const { data: jsonFileData } = await defaultAxios.get(
                      jsonURL,
                    );
                    return jsonFileData;
                  } catch {
                    if (count === 10) {
                      return null;
                    }

                    continue;
                  }
                }
              };

              const jsonData = await check();

              handleReqLoading(false);

              if (jsonData) {
                const output = jsonData.Result.Job.Output;
                const filename = output.Key;

                const appInfoMatNames = filenames.slice(1);
                const newFileNames = [filename].concat(appInfoMatNames);

                const files = acceptedFiles.map((file) =>
                  Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  }),
                );

                setVideoMats(null);
                setVideoMats([
                  `${config.material}/video/${filename}`,
                  ...matURLs.slice(1),
                ]);

                handleFiles(uploadURLs, newFileNames, files);
              } else {
                handleReqLoading(false);
                window.alert(formatMessage({ id: 'ad.ad.videoRetry' }));
              }
            } else {
              handleReqLoading(false);
              window.alert(formatMessage({ id: 'ad.ad.videoRetry' }));
            }
          }
        } else {
          const pos =
            product === adtier0.PRODUCT[adtier0.PRODUCT.BANNER] ? 2 : 1;
          const sliceFiles = acceptedFiles.slice(0, pos);

          const formData = new FormData();
          formData.append('product', product);
          sliceFiles.forEach((file) => {
            formData.append('uploadFiles', file);
          });

          const { data: urlData } = await defaultAxios.post(
            adAdAPI.s3getSignedUrl,
            formData,
          );

          const { urls } = urlData;

          const uploadURLs: any[] = [];
          for (let i = 0; i < urls.length; i++) {
            const temp = {
              uploadURL: urls[i].uploadURL,
              file: sliceFiles[i],
            };

            uploadURLs.push(temp);
          }

          const newFileNames = urls.map((url: any) => url.filename);

          const files = sliceFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          );

          setImageMats(null);
          setImageMats(sliceFiles.map((file) => URL.createObjectURL(file)));

          handleFiles(uploadURLs, newFileNames, files);
        }
      } catch (err) {
        handleReqLoading(false);

        handleReqError(err);
      }
    },
    onDropRejected: () => {
      if (product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]) {
        window.alert(formatMessage({ id: 'ad.ad.videoError' }));
      } else {
        window.alert(formatMessage({ id: 'ad.ad.imageError' }));
      }
    },
  });

  useEffect(() => {
    if (product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO]) {
      setVideoMats(matURLs);
    } else {
      setImageMats(matURLs);
    }
  }, [matURLs, product]);

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.material" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.proName" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <span className="m_txt">{name}</span>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.comment" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="comment"
                  id="comment"
                  placeholder=""
                  value={comment}
                  onChange={handleInputs}
                />
                <label htmlFor="comment">
                  <FormattedMessage id="ad.ad.comment" />
                </label>
              </FormInputField>
            </FormInput>

            <FormInput
              className={cx(
                // { interstitial: product === 'INTERSTITIAL' },
                { video: product === 'VIDEO' },
              )}
            >
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage
                    id={product === 'VIDEO' ? 'ad.ad.video' : 'ad.ad.image'}
                  />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <div>
                <FormInputField
                  {...getRootProps({
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  {product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] &&
                    videoMats && (
                      <video controls autoPlay>
                        <source src={videoMats[0]} type="video/mp4" />
                      </video>
                    )}

                  {product !== adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] &&
                    imageMats &&
                    imageMats.map((mat, index) => (
                      <img key={index} src={mat} alt="" />
                    ))}

                  <input {...getInputProps()} />

                  {isDragActive && (videoMats || imageMats) && (
                    <div className="preview--blur" />
                  )}
                </FormInputField>
                {product === 'BANNER' && (
                  <span className="info__message s_txt">
                    <FormattedHTMLMessage id="ad.ad.imageBanner" />
                  </span>
                )}

                {product === 'INTERSTITIAL' && (
                  <span className="info__message s_txt">
                    <FormattedHTMLMessage id="ad.ad.imageInter" />
                  </span>
                )}

                {product === 'VIDEO' && (
                  <span className="info__message s_txt">
                    <FormattedMessage id="ad.ad.imageVideo" />
                  </span>
                )}

                <div
                  className="btn contentBtn btnLine fileSelectBtn"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    open();
                  }}
                >
                  <FormattedMessage id="ad.ad.fileSelect" />
                </div>
              </div>
            </FormInput>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DeatilCampaign;
