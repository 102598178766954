import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { setIsFullLoader } from '~/modules/core';

import { ErrorContainer, ErrorItemImg } from './elements';

function Error404() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsFullLoader(false));
  }, [dispatch]);

  return (
    <ErrorContainer>
      <ErrorItemImg>
        <img src={require('~/assets/images/error.png').default} alt="" />
      </ErrorItemImg>

      <h3 className="m_tit">
        <b>Oops!</b>
      </h3>

      <p className="s_txt">We can't find the page that you're looking for</p>

      <span className="xs_txt">ERROR: 404</span>

      <a className="btn" href="/">
        Home
      </a>
    </ErrorContainer>
  );
}

export default Error404;
