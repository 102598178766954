import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import urlRegex from 'url-regex';

import { adCampaignAPI } from '~/api';
import TitleInput from '~/components/base/title-input';
import {
  FormInput,
  FormInputContainer,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { Select, selectStyles } from '~/components/elements/select';
import { country } from '~/json/country';
import { defaultAxios } from '~/libs/axios';
import { ICreateData, IResCampaignCreate } from '~/models/ad/campaign';
import { ISelectOption } from '~/models/select-option';
import { setIsFullLoader } from '~/modules/core';

interface IProps {
  createData: ICreateData;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (obj: object) => void;
  handleReqError: (err: any) => void;
}

interface IFormData {
  defaultCountry: ISelectOption[];
  campType: string;
  defaultURL: string;
}

function CreateApp({
  createData,
  handleCurrentTab,
  handleCreateData,
  handleReqError,
}: IProps) {
  const [defaultCountry, setDefaultCountry] = useState<any>(null);

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    getValues,
    setValue,
    setError,
    clearError,
  } = useForm<IFormData>({
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    register(
      { name: 'defaultCountry', type: 'custom' },
      { required: f({ id: 'validate.required' }) },
    );

    setValue('defaultCountry', createData.defaultCountry);
    setValue('campType', createData.campType);
    setValue('defaultURL', createData.defaultURL);

    setDefaultCountry(createData.defaultCountry);

    return () => {
      unregister('defaultCountry');
    };
  }, [
    createData.campType,
    createData.defaultCountry,
    createData.defaultURL,
    f,
    register,
    setValue,
    unregister,
  ]);

  const handleDefaultCountry = (selectedOption: ISelectOption[]) => {
    if (selectedOption === null || selectedOption.length === 0) {
      setDefaultCountry([]);
      setValue('defaultCountry', []);
      setError('defaultCountry', 'required', f({ id: 'validate.required' }));

      return;
    }

    const index = selectedOption.findIndex(
      (o: ISelectOption) => o.value === 'Global',
    );

    let value = null;
    if (index === -1) {
      value = selectedOption;
    } else if (index === 0) {
      const { length } = selectedOption;
      if (length === 1) {
        value = selectedOption;
      } else {
        const filterOptions = selectedOption.filter(
          (o: ISelectOption) => o.value !== 'Global',
        );

        value = filterOptions;
      }
    } else {
      const filterOptions = selectedOption.filter(
        (o: ISelectOption) => o.value === 'Global',
      );

      value = filterOptions;
    }

    setDefaultCountry(value);
    setValue('defaultCountry', value);
    clearError('defaultCountry');
  };

  const handlePrev = () => {
    const { defaultCountry: arrCountry, campType, defaultURL } = getValues();
    handleCreateData({ defaultCountry: arrCountry, campType, defaultURL });
    handleCurrentTab(1);
  };

  const onSubmit = async (formData: IFormData) => {
    if (defaultCountry.length === 0) {
      setValue('defaultCountry', []);
      setError('defaultCountry', 'required', f({ id: 'validate.required' }));
      return;
    }

    const { name } = createData;
    const { budget } = createData;

    const arrCountry = formData.defaultCountry.map((o: any) => o.value);

    let defaultURL_iOS = '';
    let defaultURL_AOS = '';
    if (formData.campType === 'IOS') {
      defaultURL_iOS = formData.defaultURL;
    } else {
      defaultURL_AOS = formData.defaultURL;
    }

    if (window.confirm(f({ id: 'confirm.add' }))) {
      try {
        dispatch(setIsFullLoader(true));

        const { data } = await defaultAxios.post<IResCampaignCreate>(
          adCampaignAPI.add,
          {
            name,
            campType: formData.campType,
            budget,
            comment: '',
            defaultTime: moment().format('YYYY-MM-DD'),
            defaultURL_iOS,
            defaultURL_AOS,
            defaultCountry: arrCountry,
          },
        );

        dispatch(setIsFullLoader(false));

        const { error } = data;
        if (error === 'SUCCESS') {
          window.alert(f({ id: 'alert.add' }));
          history.push('/ad-campaign');
        } else {
          window.alert(f({ id: `errors.${error}` }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        handleReqError(err);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>캠페인생성 - 앱</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="ad.campaign.app" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <TitleInput
                    title={f({ id: 'ad.campaign.country' })}
                    required
                    error={errors.defaultCountry}
                  >
                    <Select
                      name="defaultCountry"
                      placeholder={f({ id: 'ad.campaign.country' })}
                      options={country}
                      value={defaultCountry}
                      styles={selectStyles}
                      isClearable
                      isSearchable
                      isMulti
                      onChange={handleDefaultCountry}
                    />
                  </TitleInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.campaign.campType" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputGroup>
                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="campType"
                          id="android"
                          value="AOS"
                          ref={register}
                        />
                        <label htmlFor="android">Android</label>
                      </FormInputRadio>

                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="campType"
                          id="ios"
                          value="IOS"
                          ref={register}
                        />
                        <label htmlFor="ios">iOS</label>
                      </FormInputRadio>
                    </FormInputGroup>
                  </FormInput>

                  <TitleInput
                    title={f({ id: 'ad.campaign.targetUrl' })}
                    required
                    error={errors.defaultURL}
                  >
                    <textarea
                      name="defaultURL"
                      cols={40}
                      rows={2}
                      ref={register({
                        required: f({ id: 'validate.required' }),
                        maxLength: {
                          value: 1024,
                          message: f(
                            { id: 'validate.maxLength' },
                            { len: '1024' },
                          ),
                        },
                        validate: (value: string) =>
                          urlRegex({ exact: true }).test(value) ||
                          f({ id: 'validate.url' }),
                      })}
                    />
                    <label htmlFor="defaultURL">
                      <FormattedMessage id="ad.campaign.targetUrl" />
                    </label>
                  </TitleInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button
                type="button"
                className="btn contentBtn btnClear"
                onClick={handlePrev}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button type="submit" className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.create" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateApp;
