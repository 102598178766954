import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { config } from '~/libs/config';
import { storage } from '~/libs/storage';

// action type
const SET_LANGUAGE = 'core/SET_LANGUAGE';
const SET_IS_FULL_LOADER = 'core/SET_IS_FULL_LOADER';
const SET_IS_LOADER = 'core/IS_LOADER';

// create action method
export const setLanguage = createAction(SET_LANGUAGE)<string>();
export const setIsFullLoader = createAction(SET_IS_FULL_LOADER)<boolean>();
export const setIsLoader = createAction(SET_IS_LOADER)<boolean>();

// action object type
const actions = { setLanguage, setIsFullLoader, setIsLoader };
type CoreAction = ActionType<typeof actions>;

// state
interface ICoreState {
  language: string;
  isFullLoader: boolean;
  isLoader: boolean;
}

// initial state
const initialState: ICoreState = {
  language: 'en',
  isFullLoader: false,
  isLoader: false,
};

const core = createReducer<ICoreState, CoreAction>(initialState, {
  // [SET_LANGUAGE]: (state, action) => ({
  //   ...state,
  //   language: action.payload
  // }),
  [SET_LANGUAGE]: (state, action) => {
    const { storageKey } = config;
    storage.set(storageKey.language, action.payload);
    setLanguage(action.payload);

    return {
      ...state,
      language: action.payload,
    };
  },
  [SET_IS_FULL_LOADER]: (state, action) => ({
    ...state,
    isFullLoader: action.payload,
  }),
  [SET_IS_LOADER]: (state, action) => ({
    ...state,
    isLoader: action.payload,
  }),
});

export default core;
