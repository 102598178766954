import React from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { MobileGnbPub } from '~/components/layout/header-mobile';
import { GnbPub } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';
import Home from '~/components/pub';

function PubHomePage() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout
        customerTypeName={intl.formatMessage({ id: 'logo.publisher' })}
        webGnb={<GnbPub />}
        mobileGnb={<MobileGnbPub />}
        mainClassName="console"
      >
        <Home />
      </Layout>
    </>
  );
}

export default PubHomePage;
