import { version } from './helper';

const list = `api/${version.v1}/ad/ad/`;

const start = `api/${version.v1}/ad/ad/start`;

const stop = `api/${version.v1}/ad/ad/stop`;

const s3getSignedUrl = `api/${version.v1}/ad/ad/s3-get-signed-url`;

const s3getSignedUrlVideo = `api/${version.v1}/ad/ad/s3-get-signed-url-video`;

const bidcost = `api/${version.v1}/ad/ad/bidcost`;

const add = `api/${version.v1}/ad/ad/add`;

const get = `api/${version.v1}/ad/ad/get`;

const modify = `api/${version.v1}/ad/ad/modify`;

const duration = `api/${version.v1}/ad/ad/duration`;

const video = `api/${version.v1}/ad/ad/video`;

const video2 = `api/${version.v1}/ad/ad/video2`;

const scraper = `api/${version.v1}/ad/ad/scraper`;

export default {
  list,
  start,
  stop,
  s3getSignedUrl,
  s3getSignedUrlVideo,
  bidcost,
  add,
  get,
  modify,
  duration,
  video,
  video2,
  scraper,
};
