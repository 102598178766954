import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IDetailRewardProps {
  register: any;
  setValue: any;
  reward: string;
}

function DetailReward({ register, setValue, reward }: IDetailRewardProps) {
  const [isRequired, setIsRequired] = useState<boolean>(false);

  useEffect(() => {
    if (reward === '') {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
  }, [reward]);

  const handleChangeReward = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setIsRequired(false);
      setValue('rewards', '');
    } else {
      setIsRequired(true);
    }
  };

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="pub.inven.reward" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.inven.name" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="reward"
                  id="reward"
                  placeholder=""
                  ref={register}
                  onChange={handleChangeReward}
                />
                <label htmlFor="reward">
                  <FormattedMessage id="pub.inven.name" />
                </label>
              </FormInputField>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.inven.rewardCount" />
                  {isRequired && <i className="required" />}
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="rewards"
                  id="rewards"
                  placeholder=""
                  ref={register}
                />
                <label htmlFor="rewrads">
                  <FormattedMessage id="pub.inven.rewardCount" />
                </label>
              </FormInputField>
            </FormInput>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailReward;
