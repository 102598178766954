import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import urlparse from 'url-parse';
import urlRegex from 'url-regex';

import { adAdAPI } from '~/api';
import Carousel from '~/components/common/carousel';
import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { IAdAppInfo, IAdCreate, IResScraper } from '~/models/ad/ad';

import {
  FormInputVideo,
  FormInputVideoInfo,
  FormInputVideoThumb,
} from './elements';

interface ICreateAppInfoProps {
  createData: IAdCreate;
  createAppInfo: IAdAppInfo | null;
  handleReqLoading: (value: boolean) => void;
  handleReqError: (err: any) => void;
  handleCurrentTab: (value: number) => void;
  handleCreateDataAppInfo: (value: IAdAppInfo | null) => void;
  handleVideoMatrial: (names: string[]) => void;
}

function CreateAppInfo({
  createData,
  createAppInfo,
  handleReqLoading,
  handleReqError,
  handleCurrentTab,
  handleCreateDataAppInfo,
  handleVideoMatrial,
}: ICreateAppInfoProps) {
  const [storeURL, setStoreURL] = useState<string>('');
  const [storeURLError, setStoreURLError] = useState<number>(0);
  const [appInfo, setAppInfo] = useState<IAdAppInfo | null>(null);
  const [matNames, setMatNames] = useState<string[]>([]);
  const [icon, setIcon] = useState<string | null>(null);
  const [matURLs, setMatURLs] = useState<string[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (createAppInfo) {
      setStoreURL(createAppInfo.StoreURL);
      setShow(createAppInfo.Show);
      setAppInfo(createAppInfo);
      if (createAppInfo) {
        const tempMatNames = createData.filenames.slice(1);
        setMatNames(tempMatNames);
        setIcon(`${config.material}/video/${tempMatNames[0]}`);
        setMatURLs(
          tempMatNames.slice(1).map((mat) => `${config.material}/video/${mat}`),
        );
      }
    }
  }, [createAppInfo, createData.filenames]);

  const handleStoreURL = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    // if (value === '') {
    //   setStoreURLError(1);
    //   setStoreURL(value);
    //   return;
    // }

    if (!urlRegex({ exact: true }).test(value)) {
      setStoreURLError(2);
      setStoreURL(value);
      return;
    }

    setStoreURLError(0);
    setStoreURL(value);
  };

  const handleAppInfo = async () => {
    if (storeURL === '') {
      setStoreURLError(1);
      return;
    }

    let country = '';
    if (createData.country.length > 1) {
      country = 'Global';
    } else {
      for (const item of createData.country) {
        country = item.value;
      }
    }

    const platform = createData.campType;
    const material0 = createData.filenames[0];
    const parsed = urlparse(storeURL, true);

    if (platform === adtier0.PLATFORM[adtier0.PLATFORM.IOS]) {
      if (
        parsed.hostname !== 'itunes.apple.com' &&
        parsed.hostname !== 'apps.apple.com'
      ) {
        window.alert(formatMessage({ id: 'ad.ad.invalidStoreURL' }));
        return;
      }
    }

    if (platform === adtier0.PLATFORM[adtier0.PLATFORM.AOS]) {
      if (parsed.hostname !== 'play.google.com') {
        window.alert(formatMessage({ id: 'ad.ad.invalidStoreURL' }));
        return;
      }
    }

    try {
      handleReqLoading(true);
      const { data } = await defaultAxios.post<IResScraper>(adAdAPI.scraper, {
        country,
        platform,
        material0,
        storeURL,
      });
      handleReqLoading(false);

      const { error } = data;
      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        setAppInfo(data.appInfo);
        setMatNames(data.matNames);
        setShow(data.appInfo.Show);
        setIcon(`${config.material}/video/${data.matNames[0]}`);
        setMatURLs(
          data.matNames
            .slice(1)
            .map((mat) => `${config.material}/video/${mat}`),
        );
      } else {
        window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
      }
    } catch (err) {
      handleReqLoading(false);

      handleReqError(err);
    }
  };

  const handleShow = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShow(e.target.checked);
  };

  const handlePrevTab = () => {
    if (appInfo) {
      handleCreateDataAppInfo({ ...appInfo, Show: show });
      handleVideoMatrial(matNames);
    }

    handleCurrentTab(4);
  };

  const handleNext = () => {
    if (appInfo) {
      handleCreateDataAppInfo({ ...appInfo, Show: show });
      handleVideoMatrial(matNames);
    }

    handleCurrentTab(6);
  };

  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">
            <FormattedMessage id="ad.ad.addInfo" />
          </h5>
          <Hint
            title={formatMessage({ id: 'hint.addinfo' })}
            content={formatMessage({ id: 'hint.addinfo.desc' })}
          />
        </OverlayItemWrapTitle>

        <OverlayItem>
          {createData.product === adtier0.PRODUCT[adtier0.PRODUCT.VIDEO] ? (
            <>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.storeUrl" />
                        {/* <i className="required" /> */}
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <textarea
                        name="storeUrl"
                        id="storeUrl"
                        cols={40}
                        rows={2}
                        value={storeURL}
                        onChange={handleStoreURL}
                      />
                      <label htmlFor="storeUrl">
                        <FormattedMessage id="ad.ad.storeUrl" />
                      </label>
                    </FormInputField>
                    {/* {storeURLError === 1 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.required" />
                  </span>
                )} */}

                    {storeURLError === 2 && (
                      <span className="warning__message s_txt">
                        <FormattedMessage id="validate.url" />
                      </span>
                    )}
                    <button
                      className="btn contentBtn btnLine"
                      onClick={handleAppInfo}
                    >
                      <i className="icon-file_downloadget_app" />
                      <div className="btn__txt">
                        <FormattedMessage id="ad.ad.getAppInfo" />
                      </div>
                    </button>
                  </FormInput>

                  <FormInputVideo>
                    <FormInputVideoThumb>
                      {icon && <img src={icon} alt="" />}
                    </FormInputVideoThumb>

                    <FormInputVideoInfo>
                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.storeAppName" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <span className="m_txt">
                          {appInfo && appInfo.Title}
                        </span>
                      </FormInput>

                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.grade" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <span className="m_txt">
                          {appInfo && appInfo.Score}
                        </span>
                      </FormInput>

                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.downloadCount" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <span className="m_txt">
                          {appInfo
                            ? createData.campType === 'AOS'
                              ? appInfo.Installs
                              : 'N/A'
                            : ''}
                          {/* {createData.campType === 'AOS'
                            ? appInfo
                              ? appInfo.Installs
                              : 'N/A'
                            : 'N/A'} */}
                        </span>
                      </FormInput>

                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.genre" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <span className="m_txt">
                          {appInfo && appInfo.Genres.join()}
                        </span>
                      </FormInput>

                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.chargeNoCharge" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <span className="m_txt">
                          {appInfo && appInfo.Free && (
                            <FormattedMessage id="ad.ad.charge" />
                          )}

                          {appInfo && !appInfo.Free && (
                            <FormattedMessage id="ad.ad.noCharge" />
                          )}
                        </span>
                      </FormInput>

                      <FormInput>
                        <FormInputTitleWrap>
                          <FormInputTitleName className="m_txt">
                            <FormattedMessage id="ad.ad.showGradeDownloadCount" />
                          </FormInputTitleName>
                        </FormInputTitleWrap>
                        <FormInputRadio className="m_txt">
                          <input
                            id="show"
                            type="checkbox"
                            name="show"
                            checked={show}
                            onChange={handleShow}
                          />
                          <label htmlFor="show">
                            <i className="icon-check" />
                          </label>
                        </FormInputRadio>
                      </FormInput>
                    </FormInputVideoInfo>
                  </FormInputVideo>

                  <Carousel matURLs={matURLs} />
                </FormInputContainer>
              </NoFloating>
            </>
          ) : (
            <span className="m_txt">
              <FormattedMessage id="ad.ad.notAddInfo" />
            </span>
          )}
        </OverlayItem>
      </OverlayItemWrap>
      <OverlayFooter>
        <div className="btn__group">
          <button className="btn contentBtn btnClear" onClick={handlePrevTab}>
            <div className="btn__txt">
              <FormattedMessage id="common.prev" />
            </div>
          </button>
          <button className="btn contentBtn" onClick={handleNext}>
            <div className="btn__txt">
              <FormattedMessage id="common.next" />
            </div>
          </button>
        </div>
      </OverlayFooter>
    </OverlayContentBottom>
  );
}

export default CreateAppInfo;
