import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import TitleInput from '~/components/base/title-input';
import TitleStatic from '~/components/base/title-static';
import Hint from '~/components/common/hint';
import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IDeatilCampaignProps {
  budget: number;
  register: any;
  errors: any;
}

function DeatilCampaign({ budget, register, errors }: IDeatilCampaignProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.campaign" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <TitleInput
              title={formatMessage({ id: 'ad.campaign.campName' })}
              required
              error={errors.name}
            >
              <input
                type="text"
                name="name"
                id="name"
                placeholder=""
                ref={register({
                  required: formatMessage({ id: 'validate.required' }),
                })}
              />
              <label htmlFor="name">
                <FormattedMessage id="ad.campaign.campName" />
              </label>
            </TitleInput>

            <TitleStatic
              title={formatMessage({ id: 'ad.campaign.budget' })}
              hint={<Hint title="hint.budget" content="hint.budget.desc" />}
            >
              <span className="m_txt">
                <NumberFormat
                  value={budget}
                  displayType="text"
                  thousandSeparator
                />
                <i className="unit xs_tit">KRW</i>
              </span>
            </TitleStatic>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DeatilCampaign;
