import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import qs from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { pubAppAPI, pubAppRewardAPI } from '~/api';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { IAppNameListItem, IResAppNameList } from '~/models/pub/app';
import { IAppRewardListItem, IResAppRewardList } from '~/models/pub/appReward';
import { setIsFullLoader } from '~/modules/core';

import ListSearch from './list01-search';
import ListTable from './list02-table';

function List() {
  const [appID, setAppID] = useState<string>('');
  const [apps, setApps] = useState<IAppNameListItem[] | null>(null);
  const [appRewards, setAppRewards] = useState<IAppRewardListItem[] | null>(
    null,
  );

  const [{ data: appData, loading: appLoading, error: appError }] =
    useAxios<IResAppNameList>(
      {
        url: pubAppAPI.listAll,
        method: 'GET',
      },
      { useCache: false },
    );

  const [
    { data: appRewardData, loading: appRewardLoading, error: appRewardError },
    execute,
  ] = useAxios<IResAppRewardList>(
    {
      url: pubAppRewardAPI.list,
      method: 'GET',
    },
    { useCache: false, manual: true },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (appLoading || appRewardLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [appLoading, dispatch, appRewardLoading]);

  useEffect(() => {
    if (appData) {
      if (appData.apps) {
        setApps(appData.apps);
      }
    }
  }, [appData]);

  useEffect(() => {
    const query = qs.parse(location.search);
    const qsAppID = String(query.appID || '');

    execute({
      params: {
        appID: qsAppID,
      },
    });
  }, [execute, location.search]);

  useEffect(() => {
    if (appRewardData) {
      if (appRewardData.appRewards) {
        setAppID(appRewardData.appID);
        setAppRewards(appRewardData.appRewards);
      }
    }
  }, [appRewardData]);

  const handleChangeAppID = (e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(`/pub-app-reward?appID=${e.target.value}`);
  };

  const handleNewAppReward = () => {
    if (!apps) {
      window.alert(formatMessage({ id: 'pub.appReward.emptyApp' }));
      return;
    }

    if (apps.length === 0) {
      window.alert(formatMessage({ id: 'pub.appReward.emptyApp' }));
      return;
    }

    history.push(`/pub-app-reward-create?appID=${appID}`);
  };

  const handleDetail = (appRewardID: number) => {
    history.push(`/pub-app-reward/${appRewardID}?appID=${appID}`);
  };

  if (appError) {
    if (appError.response) {
      if (appError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw appError;
  }

  if (appRewardError) {
    if (appRewardError.response) {
      if (appRewardError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw appRewardError;
  }

  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="pub.appReward.list" />
      </h1>

      <ListSearch
        appID={appID}
        apps={apps}
        handleChangeAppID={handleChangeAppID}
        handleNewAppReward={handleNewAppReward}
      />

      <ListTable appRewards={appRewards} handleDetail={handleDetail} />
    </ConsoleContent>
  );
}

export default List;
