import React from 'react';

import { Helmet } from 'react-helmet';

import ChangePassword from '~/components/account/change-password';

function ChangePasswordPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <ChangePassword />
    </>
  );
}

export default ChangePasswordPage;
