import React from 'react';

import { Content } from '~/components/elements/content';

import ResourceDoc1 from './resource03-doc1';
import ResourceDoc2 from './resource03-doc2';

function ResourceDoc() {
  return (
    <Content id="doc">
      <ResourceDoc1 />
      <ResourceDoc2 />
    </Content>
  );
}

export default ResourceDoc;
