import React from 'react';

import moment from 'moment';
import { CSVLink } from 'react-csv';

interface IReportAppExportProps {
  csv: any;
  searchStartDate: Date;
  searchEndDate: Date;
}

function ReportAppExport({
  csv,
  searchStartDate,
  searchEndDate,
}: IReportAppExportProps) {
  if (!csv) {
    return null;
  }

  return (
    <CSVLink
      data={csv}
      filename={`Publisher-App-${moment(searchStartDate).format(
        'YYYY-MM-DD',
      )}-${moment(searchEndDate).format('YYYY-MM-DD')}.csv`}
      className="btn contentBtn btnLine"
    >
      <i className="icon-save_alt" />
      <div className="btn__txt">Export</div>
    </CSVLink>
  );
}

export default ReportAppExport;
