import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import urlRegex from 'url-regex';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { numberPattern } from '~/libs/regexp';

interface IDetailAppProps {
  register: any;
  errors: any;
  storeURL: string;
  apiKey: string;
  apiKeyStatus: number;
  handleRequestApiKey: () => void;
}

function DetailApp({
  register,
  errors,
  storeURL,
  apiKey,
  apiKeyStatus,
  handleRequestApiKey,
}: IDetailAppProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="pub.app" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.appName" />
                  <i className="required" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                  })}
                />
                <label htmlFor="name">
                  <FormattedMessage id="pub.app.appName" />
                </label>
              </FormInputField>
              {errors.name && (
                <span className="warning__message s_txt">
                  {errors.name.message}
                </span>
              )}
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.platform" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputGroup className="disable">
                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="platform"
                    id="android"
                    value="AOS"
                    ref={register}
                    disabled
                  />
                  <label htmlFor="android">Android</label>
                </FormInputRadio>

                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="platform"
                    id="ios"
                    value="IOS"
                    ref={register}
                    disabled
                  />
                  <label htmlFor="ios">iOS</label>
                </FormInputRadio>
              </FormInputGroup>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.storeUrl" />
                  <i className="required" />
                </FormInputTitleName>

                <CopyToClipboard
                  text={storeURL}
                  onCopy={() =>
                    toast.success(formatMessage({ id: 'alert.clipboard' }))
                  }
                >
                  <i className="icon-filter_none cursor__pointer" />
                </CopyToClipboard>
              </FormInputTitleWrap>
              <FormInputField>
                <textarea
                  name="storeURL"
                  id="storeURL"
                  cols={40}
                  rows={2}
                  ref={register({
                    required: formatMessage({ id: 'validate.required' }),
                    maxLength: {
                      value: 1024,
                      message: formatMessage(
                        { id: 'validate.maxLength' },
                        { len: '1024' },
                      ),
                    },
                    validate: (value: string) =>
                      urlRegex({ exact: true }).test(value) ||
                      formatMessage({ id: 'validate.url' }),
                  })}
                />
                <label htmlFor="storeURL">
                  <FormattedMessage id="pub.app.storeUrl" />
                </label>
              </FormInputField>
              {errors.storeURL && (
                <span className="warning__message s_txt">
                  {errors.storeURL.message}
                </span>
              )}
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.contentLevel" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <span className="m_txt">
                <FormattedMessage id="pub.app.contentLevelDesc" />
              </span>
              <FormInputGroup>
                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="filter"
                    id="filterYes"
                    value="Yes"
                    ref={register}
                  />
                  <label htmlFor="filterYes">Yes</label>
                </FormInputRadio>

                <FormInputRadio className="form__input--50">
                  <input
                    type="radio"
                    name="filter"
                    id="filterNo"
                    value="No"
                    ref={register}
                  />
                  <label htmlFor="filterNo">No</label>
                </FormInputRadio>
              </FormInputGroup>
            </FormInput>

            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.app.apiKey" />
                </FormInputTitleName>
              </FormInputTitleWrap>

              {apiKey ? (
                <FormInputGroup>
                  <span className="m_txt">{apiKey}</span>
                  <CopyToClipboard
                    text={apiKey || ''}
                    onCopy={() =>
                      toast.success(formatMessage({ id: 'alert.clipboard' }))
                    }
                  >
                    <i className="icon-filter_none cursor__pointer" />
                  </CopyToClipboard>
                </FormInputGroup>
              ) : apiKeyStatus === -1 ? (
                <button
                  type="button"
                  className="btn contentBtn"
                  onClick={handleRequestApiKey}
                >
                  <FormattedMessage id="pub.app.apiKeyRequest" />
                </button>
              ) : apiKeyStatus === 0 ? (
                <>
                  <span className="m_txt">
                    <FormattedMessage id="pub.app.apiKeyRequestWait" />
                  </span>
                </>
              ) : (
                <></>
              )}
            </FormInput>

            <div style={{ display: apiKey ? 'block' : 'none' }}>
              <FormInput>
                <FormInputTitleWrap>
                  <FormInputTitleName className="m_txt">
                    <FormattedMessage id="pub.app.apiKeyDayLimit" />
                    <i className="required" />
                  </FormInputTitleName>
                </FormInputTitleWrap>
                <FormInputField>
                  <input
                    type="text"
                    name="dayLimit"
                    id="dayLimit"
                    placeholder={formatMessage({
                      id: 'pub.app.apiKeyDayLimit',
                    })}
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      pattern: {
                        value: numberPattern,
                        message: formatMessage({ id: 'validate.onlyNum' }), // JS only: <p>error message</p> TS only support string
                      },
                      valueAsNumber: true,
                    })}
                  />
                  <label htmlFor="name">
                    <FormattedMessage id="pub.app.apiKeyDayLimit" />
                  </label>
                </FormInputField>
                {errors.dayLimit && (
                  <span className="warning__message s_txt">
                    {errors.dayLimit.message}
                  </span>
                )}
              </FormInput>
            </div>
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailApp;
