import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

interface IDefaultInfoEmailProps {
  email: string;
}

function DefaultInfoEmail({ email }: IDefaultInfoEmailProps) {
  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="mypage.default.email" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <span className="m_txt">{email}</span>
    </FormInput>
  );
}

export default DefaultInfoEmail;
