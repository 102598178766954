import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';

import bg from '~/assets/images/p1_xplaUpdate_bg.png';
import { Content } from '~/components/elements/content';

const Wrapper = styled.div`
  background: #181538;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
`;

const Styles = styled(Content as any)`
  #p1_xplaUpdate {
    color: white;
    margin-top: 60px;
  }

  .xplaUpdate__header {
    width: auto;
    display: grid;
    justify-content: center;
    text-align: center;
  }

  .xplaUpdate__header > span {
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
  }

  .xplaUpdate__header h1 {
    position: relative;
    font-size: 120px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
  }

  .xplaUpdate__header h1 span {
    position: relative;
    display: flex;
    z-index: 1;
  }

  .xplaUpdate__header img {
    position: absolute;
    top: -64px;
    right: -148px;
    width: 264px;
  }

  .xplaUpdate__header span b {
    background: linear-gradient(180deg, #939adf 50%, #3f38d6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
  }

  .xplaUpdate__main {
    text-align: center;
    display: grid;
    justify-items: center;
  }

  .xplaUpdate__main .ethToXpla {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 8px 16px;
    border-radius: 9999px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin: 32px 0;
  }

  .xplaUpdate__main .ethToXpla img:not(:nth-child(2)) {
    width: 28px;
  }

  .xplaUpdate__main p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 60px;
    word-break: keep-all;
  }
  .xplaUpdate__main p a {
    position: relative;
    display: inline;
    color: #00b1ff;
    font-weight: 600;
  }

  .xplaUpdate__main p a::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #00b1ff;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
  }

  .xplaUpdate__main p a:hover {
    color: #00b1ff;
  }

  .xplaUpdate__main p b:last-child {
    color: white;
    font-weight: normal;
  }

  .xplaUpdate__main a.btn {
    padding: 8px 12px;
  }

  .xplayzComingSoon {
    font-size: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.82);
  }

  @media screen and (max-width: 1155px) {
    #p1_xplaUpdate {
      margin-top: 50px;
    }
    .xplaUpdate__header > span {
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 300;
    }
    .xplaUpdate__header h1 {
      font-size: 80px;
    }

    .xplaUpdate__header img {
      top: -54px;
      right: -104px;
      width: 184px;
    }
  }

  @media screen and (max-width: 599px) {
    #p1_xplaUpdate .content {
      padding: 80px 16px;
    }
    .xplaUpdate__header > span {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .xplaUpdate__header h1 {
      font-size: 60px;
    }

    .xplaUpdate__header img {
      top: -10px;
      right: -10.5vw;
      width: 100px;
    }

    .xplaUpdate__main .ethToXpla img:not(:nth-child(2)) {
      width: 22px;
    }

    .xplaUpdate__main p {
      font-size: 14px;
      margin-bottom: 28px;
    }

    .xplayzComingSoon {
      font-size: 18px;
    }
  }
`;

function HomeXpla() {
  return (
    <Wrapper>
      <Styles>
        <section id="p1_xplaUpdate">
          <div className="content">
            <div className="xplaUpdate__header">
              <span>
                Main-net Migration for <b>ZenaAD</b>
              </span>
              <h1>
                XPLA <span>Update</span>
                <img
                  src={require('~/assets/images/p1_xplaUpdate.png').default}
                  alt=""
                />
              </h1>
            </div>
            <div className="xplaUpdate__main">
              <div className="ethToXpla">
                <img
                  src={require('~/assets/images/p1_ethSymbol.png').default}
                  alt=""
                />
                <img
                  src={require('~/assets/images/p1_arrow.svg').default}
                  alt=""
                />
                <img
                  src={require('~/assets/images/p1_xplaSymbol.png').default}
                  alt=""
                />
              </div>
              <p>
                <FormattedMessage
                  id="home.xpla.title"
                  values={{
                    link: (
                      <a
                        href="https://xpla.io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        XPLA
                      </a>
                    ),
                    br: <br />,
                    xPlayz: <b>xPlayz</b>,
                  }}
                />
              </p>
              {/* <div className="xplayzComingSoon">xPlayz is Coming soon!</div> */}

              <ScrollLink
                className="cursor__pointer btn"
                to="p1_xPlayz"
                spy
                smooth
                offset={-20}
                duration={500}
              >
                <FormattedMessage id="home.xpla.btn" />
              </ScrollLink>
            </div>
          </div>
        </section>
      </Styles>
    </Wrapper>
  );
}

export default HomeXpla;
