import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import TitleInput from '~/components/base/title-input';
import Hint from '~/components/common/hint';
import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { ICreateData } from '~/models/ad/campaign';

interface IProps {
  createData: ICreateData;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (obj: object) => void;
}

interface IFormData {
  name: string;
  budget: number;
}

function CreateCampaign({
  createData,
  handleCurrentTab,
  handleCreateData,
}: IProps) {
  const { formatMessage } = useIntl();
  const { register, handleSubmit, errors, setValue } = useForm<IFormData>({
    submitFocusError: false,
  });

  useEffect(() => {
    setValue('name', createData.name);
    setValue('budget', createData.budget);
  }, [createData.budget, createData.name, setValue]);

  const onSubmit = ({ name, budget }: IFormData): void => {
    handleCreateData({ name, budget });

    handleCurrentTab(2);
  };

  // const handleChangeBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value;
  //   value = value.replace(/[^0-9]/g, '');
  //   value = value.replace(/,/g, '');
  //   setValue('budget', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>캠페인생성 - 캠페인</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="ad.campaign" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <TitleInput
                    title={formatMessage({ id: 'ad.campaign.campName' })}
                    required
                    error={errors.name}
                  >
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder=""
                      ref={register({
                        required: formatMessage({ id: 'validate.required' }),
                      })}
                    />
                    <label htmlFor="name">
                      <FormattedMessage id="ad.campaign.campName" />
                    </label>
                  </TitleInput>

                  <TitleInput
                    title={formatMessage({ id: 'ad.campaign.budget' })}
                    required
                    formInputFieladClass="unit"
                    error={errors.budget}
                    hint={
                      <Hint title="hint.budget" content="hint.budget.desc" />
                    }
                  >
                    <input
                      type="number"
                      name="budget"
                      id="budget"
                      placeholder=""
                      ref={register({
                        required: formatMessage({ id: 'validate.required' }),
                        min: {
                          value: 100000,
                          message: formatMessage(
                            { id: 'validate.min' },
                            { min: '100000' },
                          ),
                        },
                        max: {
                          value: 2000000000,
                          message: formatMessage(
                            { id: 'validate.max' },
                            { max: '2000000000' },
                          ),
                        },
                      })}
                    />
                    <label htmlFor="budget">
                      <FormattedMessage id="ad.campaign.budget" />
                    </label>
                    <i className="unit xs_tit">KRW</i>
                  </TitleInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button className="btn contentBtn btnClear display__none">
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.next" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateCampaign;
