import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import { ConsoleContent } from '~/components/elements/console-content';
import {
  SummaryContainer,
  SummaryItem,
  SummaryItemAddInfo,
  SummaryItemBottom,
  SummaryItemNotice,
  SummaryItemTop,
  SummaryItemWrap,
} from '~/components/elements/summary';
import { IPromotion, IResAdHome } from '~/models/ad/home';

interface IProps {
  data: IResAdHome;
  promotion: IPromotion;
  stopAllPromotion: () => void;
  startAllPromotion: () => void;
}

function HomeSummary({
  data: { campaigns, user },
  promotion,
  stopAllPromotion,
  startAllPromotion,
}: IProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleNewCampaign = () => {
    history.push('/ad-campaign-create');
  };

  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="ad.home.summary" />
      </h1>
      <SummaryContainer>
        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="ad.home.campaign" />
              </h5>
              <div className="btn contentBtn" onClick={handleNewCampaign}>
                <i className="icon-plus" />
                <div className="btn__txt">
                  <FormattedMessage id="ad.home.newcampaign" />
                </div>
              </div>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt">
                <FormattedMessage id="ad.home.all" />
              </p>
              <span className="ms_tit">
                <NumberFormat
                  value={campaigns}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </SummaryItemBottom>
          </SummaryItem>
        </SummaryItemWrap>

        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="ad.home.advertising" />
              </h5>
              <div className="btn__group">
                <button
                  className="btn contentBtn btnClear"
                  onClick={stopAllPromotion}
                >
                  <i className="icon-pause" />
                  <div className="btn__txt">
                    <FormattedMessage id="ad.home.allStop" />
                  </div>
                </button>
                <button className="btn contentBtn" onClick={startAllPromotion}>
                  <i className="icon-play_arrow" />
                  <div className="btn__txt">
                    <FormattedMessage id="ad.home.allStart" />
                  </div>
                </button>
              </div>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt">
                <FormattedMessage id="ad.home.all" />
              </p>
              <span className="ms_tit">
                <NumberFormat
                  value={promotion.Promotion}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </SummaryItemBottom>
            <SummaryItemAddInfo className="xs_txt">
              <li>
                <FormattedMessage id="ad.home.inprogress" />{' '}
                <b>
                  <NumberFormat
                    value={promotion.InProgress}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
              <li>
                <FormattedMessage id="ad.home.winning" />{' '}
                <b>
                  <NumberFormat
                    value={promotion.Winning}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
              <li>
                <FormattedMessage id="ad.home.complete" />{' '}
                <b>
                  <NumberFormat
                    value={promotion.Complete}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
            </SummaryItemAddInfo>
          </SummaryItem>
        </SummaryItemWrap>

        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="ad.home.asset" />
              </h5>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt">
                <FormattedMessage id="ad.home.cash" />
              </p>
              <span className="ms_tit">
                <NumberFormat
                  value={user.Cash}
                  displayType="text"
                  thousandSeparator
                />
                <span className="s_tit krw">KRW</span>
              </span>
            </SummaryItemBottom>
          </SummaryItem>
          {user.NeedPayment === 1 && (
            <SummaryItemNotice
              to="#"
              onClick={() => {
                window.alert(formatMessage({ id: 'alert.ready' }));
                return false;
              }}
            >
              <div className="btn__group xs_tit">
                <i className="icon-warningreport_problem" />
                <div className="btn_txt">
                  <FormattedMessage id="ad.home.needpay" />
                </div>
              </div>
              <span className="xs_txt">
                <FormattedMessage id="ad.home.gopay" />
              </span>
            </SummaryItemNotice>
          )}
        </SummaryItemWrap>
      </SummaryContainer>
    </ConsoleContent>
  );
}

export default HomeSummary;
