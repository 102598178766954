import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';

import { DateFilter, DateFilterWrap } from '~/components/elements/filter';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { IRevenueRequestListItem } from '~/models/account/my-page';

import RevenueRequestListList from './revenue-request-list02-list';

interface IRevenueRequestListFilterProps {
  searchSelectDay: number;
  searchStartDate: Date;
  searchEndDate: Date;
  handleSearch: (day: number, sdate: any, edate: any) => void;
  list: IRevenueRequestListItem[];
}

function RevenueRequestListFilter({
  searchSelectDay,
  searchStartDate,
  searchEndDate,
  handleSearch,
  list,
}: IRevenueRequestListFilterProps) {
  const [selectDay, setSelectDay] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  useEffect(() => {
    setSelectDay(searchSelectDay);
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
  }, [searchStartDate, searchEndDate, searchSelectDay]);

  const handleChangeSelectDay2 = (day: number) => {
    const sdate = moment()
      .subtract(day - 1, 'days')
      .toDate();

    const edate = moment().toDate();

    setSelectDay(day);
    setStartDate(sdate);
    setEndDate(edate);
  };

  const handleChangeStartDate2 = (date: any) => {
    setStartDate(date);
  };

  const handleChangeEndDate2 = (date: any) => {
    setEndDate(date);
  };

  const handleSearch2 = () => {
    handleSearch(selectDay, startDate, endDate);
  };

  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItem>
          <OverlayItemWrapTitle>
            <h5 className="s_tit">
              <FormattedMessage id="pubmypage.revenueGive.giveRequestHistory" />
            </h5>
            <DateFilter>
              <DateFilterWrap>
                <ul className="m_txt">
                  <li
                    className={selectDay === 7 ? 'active' : ''}
                    onClick={() => handleChangeSelectDay2(7)}
                  >
                    <FormattedMessage
                      id="common.formatDay"
                      values={{ day: '7' }}
                    />
                  </li>
                  <li
                    className={selectDay === 15 ? 'active' : ''}
                    onClick={() => handleChangeSelectDay2(15)}
                  >
                    <FormattedMessage
                      id="common.formatDay"
                      values={{ day: '15' }}
                    />
                  </li>
                  <li
                    className={selectDay === 30 ? 'active' : ''}
                    onClick={() => handleChangeSelectDay2(30)}
                  >
                    <FormattedMessage
                      id="common.formatDay"
                      values={{ day: '30' }}
                    />
                  </li>
                </ul>

                <NoFloating>
                  <FormInputContainer>
                    <FormInput>
                      <FormInputField>
                        <i className="icon-calendar_today" />
                        <DatePicker
                          selected={startDate}
                          onChange={handleChangeStartDate2}
                          dateFormat="yyyy-MM-dd"
                          popperPlacement="bottom-end"
                          popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                          }}
                          withPortal={
                            window.matchMedia('(max-width: 599px)').matches
                          }
                        />
                        <span className="m_txt date__bar">-</span>
                        <i className="icon-calendar_today" />
                        <DatePicker
                          selected={endDate}
                          onChange={handleChangeEndDate2}
                          dateFormat="yyyy-MM-dd"
                          popperPlacement="bottom-end"
                          popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                          }}
                          withPortal={
                            window.matchMedia('(max-width: 599px)').matches
                          }
                        />
                      </FormInputField>
                    </FormInput>
                  </FormInputContainer>
                </NoFloating>
                <button className="btn contentBtn" onClick={handleSearch2}>
                  <FormattedMessage id="common.search" />
                </button>
              </DateFilterWrap>
              <span className="xs_txt">
                <FormattedMessage id="pub.report.searchDesc" />
              </span>
            </DateFilter>
          </OverlayItemWrapTitle>

          <RevenueRequestListList list={list} />
        </OverlayItem>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

export default RevenueRequestListFilter;
