import React from 'react';

import { Helmet } from 'react-helmet';

import Help from '~/components/home/help';
import { MobileGnbEmpty } from '~/components/layout/header-mobile';
import { GnbEmpty } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeHelpPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbEmpty />} mobileGnb={<MobileGnbEmpty />}>
        <Help />
      </Layout>
    </>
  );
}

export default HomeHelpPage;
