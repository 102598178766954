import ReactSelect from 'react-select';
import styled from 'styled-components';

export const Select = styled(ReactSelect)`
  width: 100%;
  height: 100%;
`;

export const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    fontFamily: `"cairo", "Noto Sans KR", sans-serif`,
    fontSize: '16px',
    fontWeight: '300',
    border: '0px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: `"cairo", "Noto Sans KR", sans-serif`,
    fontSize: '16px',
    fontWeight: '300',
    color: '#666666',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '1999',
    fontFamily: `"cairo", "Noto Sans KR", sans-serif`,
    fontSize: '16px',
    fontWeight: '300',
    color: '#666666',
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: `"cairo", "Noto Sans KR", sans-serif`,
    fontSize: '16px',
    fontWeight: '300',
    color: '#666666',
    overFlow: 'visible',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: `"cairo", "Noto Sans KR", sans-serif`,
    fontSize: '16px',
    fontWeight: '300',
    color: '#666666',
    paddingLeft: '6px',
  }),
};
