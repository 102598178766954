import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IAdListItem } from '~/models/ad/ad';

import ListTableItem from './list04-table-item';

interface IListTableProps {
  ads: IAdListItem[] | null;
  handleChangeStatus: (e: React.MouseEvent, proID: number) => Promise<void>;
  handleDetail: (proID: number) => void;
}

const TableContainer = styled(DefaultTableContainer as any)`
  @media screen and (max-width: 559px) {
    th {
      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        position: sticky;
        left: 60px;
        z-index: 4;
      }
    }
  }
`;

function ListTable({ ads, handleChangeStatus, handleDetail }: IListTableProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="cursor__default" />
            <th className="text__left">
              <FormattedMessage id="ad.ad.status" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left border__right">
              <FormattedMessage id="ad.ad.proName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left border__right">
              <FormattedMessage id="ad.ad.platform" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left">
              <FormattedMessage id="ad.ad.product" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__left">
              <FormattedMessage id="ad.ad.billingType" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.ad.avgCost" />
              (KRW)
              <i className="icon-drop-down" />
            </th>
            <th className="cursor__default" />
          </tr>
        </thead>
        <tbody>
          {ads &&
            ads.map((ad: IAdListItem) => (
              <ListTableItem
                key={ad.ProID}
                ad={ad}
                handleChangeStatus={handleChangeStatus}
                handleDetail={handleDetail}
              />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default ListTable;
