import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Switch, SwitchBtn, SwitchTxt } from '~/components/elements/switch';
import { IInvenListItem } from '~/models/pub/inventory';

interface IListTableItemProps {
  inven: IInvenListItem;
  handleDetail: (medIO: string) => void;
  handleActiveMedia: (
    e: React.MouseEvent,
    medID: string,
    active: number,
  ) => void;
}

function ListTableItem({
  inven,
  handleDetail,
  handleActiveMedia,
}: IListTableItemProps) {
  const { MedID, Active, Name, Product } = inven;
  return (
    <tr onClick={() => handleDetail(MedID)}>
      <th className="text__center">
        <i className={Active === 1 ? 'icon-box-open' : 'icon-box-closed'} />
      </th>
      <th className="text__left table__important">
        {Active === 1 ? (
          <FormattedMessage id="pub.inven.open" />
        ) : (
          <FormattedMessage id="pub.inven.close" />
        )}
      </th>
      <th className="text__left border__right table__title">
        <span>{Name}</span>
      </th>
      <td className="text__left">
        <FormattedMessage id={`pub.inven.${Product.toLowerCase()}`} />
      </td>
      <td className="text__left">
        <Switch
          className={Active === 1 ? '' : 'stop'}
          onClick={(e: React.MouseEvent) =>
            handleActiveMedia(e, MedID, Active === 1 ? 0 : 1)
          }
        >
          <SwitchTxt className="switch__txt--start">Open</SwitchTxt>
          <SwitchTxt className="switch__txt--stop">Close</SwitchTxt>
          <SwitchBtn>
            <i className="switch__btn--bar" />
          </SwitchBtn>
        </Switch>
      </td>
    </tr>
  );
}

export default ListTableItem;
