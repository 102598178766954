import React, { useEffect } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { pubInvenAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { defaultAxios } from '~/libs/axios';
import { IInvenCreate } from '~/models/pub/inventory';
import { setIsFullLoader } from '~/modules/core';

interface ICreateInventoryProps {
  createData: IInvenCreate;
  appID: string | undefined;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (obj: object) => void;
  handleReqError: (err: any) => void;
}

interface IFormData {
  name: string;
}

function CreateInventory({
  createData,
  appID,
  handleCurrentTab,
  handleCreateData,
  handleReqError,
}: ICreateInventoryProps) {
  const { register, handleSubmit, errors, setValue } = useForm<IFormData>({
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (createData.name === '') {
      setValue(
        'name',
        `${createData.product} ${moment().format('YYYY-MM-DD HH:mm')}`,
      );
    } else {
      setValue('name', createData.name);
    }
  }, [createData.name, createData.product, setValue]);

  const onSubmit = async ({ name }: IFormData) => {
    if (createData.product === 'BANNER') {
      if (window.confirm(f({ id: 'confirm.add' }))) {
        try {
          dispatch(setIsFullLoader(true));

          const { data: addData } = await defaultAxios.post(pubInvenAPI.add, {
            appID,
            name,
            product: createData.product,
            reward: '',
            rewards: '',
          });

          dispatch(setIsFullLoader(false));

          if (addData.error === 'SUCCESS') {
            alert(f({ id: 'alert.add' }));
            history.push(`/pub-inventory?appID=${appID}`);
          } else {
            alert(f({ id: `errors.${addData.error}` }));
          }
        } catch (err) {
          dispatch(setIsFullLoader(false));

          handleReqError(err);
        }
      }
    } else {
      handleCreateData({ name });
      handleCurrentTab(3);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>캠페인생성 - 앱</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="pub.inven" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.inven.invenName" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder=""
                        ref={register({
                          required: f({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="name">
                        <FormattedMessage id="pub.inven.invenName" />
                      </label>
                    </FormInputField>
                    {errors.name && (
                      <span className="warning__message s_txt">
                        {errors.name.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.inven.id" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <span className="m_txt">
                      <FormattedMessage id="pub.inven.idAuto" />
                    </span>
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              {createData.product === 'BANNER' ? (
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.create" />
                  </div>
                </button>
              ) : (
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.next" />
                  </div>
                </button>
              )}
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateInventory;
