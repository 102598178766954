import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link as ScrollLink } from 'react-scroll';

import { SubNav } from '~/components/elements/sub-nav';

function ResourceSubNav() {
  return (
    <SubNav id="sub-nav" className="m_txt">
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="doc"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="resource.subNav.doc" />
      </ScrollLink>
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="media"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="resource.subNav.media" />
      </ScrollLink>
      <ScrollLink
        className="cursor__pointer"
        activeClass="active"
        to="success"
        spy
        smooth
        offset={-20}
        duration={500}
      >
        <FormattedMessage id="resource.subNav.success" />
      </ScrollLink>
    </SubNav>
  );
}

export default ResourceSubNav;
