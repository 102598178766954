import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

function AssetPayment() {
  const { formatMessage } = useIntl();

  return (
    <FormInput>
      <FormInputContentWrap>
        <FormInputTitleWrap>
          <FormInputTitleName className="m_txt">
            <FormattedMessage id="admypage.asset.paymentHistory" />
          </FormInputTitleName>
        </FormInputTitleWrap>
        <span className="m_txt">
          <i className="icon-chevron-right" />
        </span>
        <button
          className="btn contentBtn btnLine"
          onClick={() => {
            window.alert(formatMessage({ id: 'alert.ready' }));
          }}
        >
          <FormattedMessage id="admypage.asset.history" />
        </button>
      </FormInputContentWrap>
    </FormInput>
  );
}

export default AssetPayment;
