import React from 'react';

import { ListSectionLine } from '~/components/elements/list-section';
import { useHeaderScroll } from '~/hooks/use-header-scroll';

import ResourceIntro from './resource01-intro';
import ResourceSubNav from './resource02-subnav';
import ResourceDoc from './resource03-doc';
import ResourceMedia from './resource03-media';
import ResourceSuccess from './resource04-success';

function Resource() {
  useHeaderScroll();

  return (
    <>
      <ResourceIntro />
      <ResourceSubNav />
      <ResourceDoc />
      <ListSectionLine />
      <ResourceMedia />
      <ListSectionLine />
      <ResourceSuccess />
    </>
  );
}

export default Resource;
