import React, { useEffect, useState } from 'react';

import moment from 'moment';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { ICreateData } from '~/models/ad/campaign';
import { RootState } from '~/modules';

import CreateTab from './create00-tab';
import CreateCampaign from './create01-campaign';
import CreateApp from './create02-app';

function Create() {
  const [searchName, setSearchName] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [createData, setCreateData] = useState<ICreateData>({
    name: '',
    budget: 0,
    defaultCountry: [],
    campType: '',
    defaultURL: '',
  });
  const [errorData, setErrorData] = useState<any>(null);

  const user = useSelector((state: RootState) => state.user.user);
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    let value = '';
    let label = '';

    if (user) {
      value = user.Iso2;
      label = user.ShortName;
    }

    setCreateData({
      name: `Campaign ${moment().format('YYYY-MM-DD HH:mm')}`,
      budget: 100000,
      defaultCountry: [
        {
          value,
          label,
        },
      ],
      campType: 'AOS',
      defaultURL: '',
    });
  }, [user]);

  const handleClose = () => {
    history.push(`/ad-campaign?name=${searchName}&pageNo=${pageNo}`);
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    const query = queryString.parse(location.search);

    setSearchName(String(query.name || ''));
    setPageNo(Number(query.pageNo || 1));
  }, [location.search]);

  const handleCurrentTab = (value: number) => {
    setCurrentTab(value);
  };

  const handleCreateData = (obj: object) => {
    setCreateData({ ...createData, ...obj });
  };

  const handleReqError = (err: any) => {
    setErrorData(err);
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="ad.campaign.create" />
          </OverlayHeaderTitle>
        </OverlayHeader>
        <OverlayContent>
          <CreateTab currentTab={currentTab} />

          {currentTab === 1 && (
            <CreateCampaign
              createData={createData}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
            />
          )}

          {currentTab === 2 && (
            <CreateApp
              createData={createData}
              handleCurrentTab={handleCurrentTab}
              handleCreateData={handleCreateData}
              handleReqError={handleReqError}
            />
          )}
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default Create;
