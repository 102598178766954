import React from 'react';

import { Helmet } from 'react-helmet';

import SignupSuccess from '~/components/account/signup-success';

function SignupSuccessPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <SignupSuccess />
    </>
  );
}

export default SignupSuccessPage;
