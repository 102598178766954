import styled from 'styled-components';

export const Content = styled.section`
  osition: relative;
  width: 1156px;
  margin: 0 auto;
  padding: 7.5rem 0;

  // &:first-of-type {
  //   padding-top: 10.5rem;
  //   padding-bottom: 0;

  //   @media screen and (max-width: 599px) {
  //     padding-top: 9rem;
  //   }
  // }

  &.first {
    padding-top: 10.5rem;
    padding-bottom: 0;

    @media screen and (max-width: 599px) {
      padding-top: 9rem;
    }
  }

  > h1 {
    text-align: center;
  }

  > h3 {
    text-align: center !important;

    @media screen and (max-width: 599px) {
      padding-top: 2rem;
      word-break: keep-all;
    }
  }

  > h5 {
    padding-top: 2rem;
    text-align: center;
  }

  > img {
    position: relative;
    padding-top: 2rem;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 1155px) {
      width: 90%;
    }
  }

  p.s_txt {
    padding-top: 0.5rem;
  }

  @media screen and (max-width: 1155px) {
    width: 100%;
    padding: 120px 24px;
  }

  @media screen and (max-width: 599px) {
    padding: 40px 16px;
  }
`;

export const ContentSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 40%;

    @media screen and (max-width: 1155px) {
      width: 45%;
    }

    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContentSubText = styled.div`
  max-width: 50%;
  letter-spacing: -0.05px;

  h5.m_txt {
    padding-top: 1rem;
  }

  span.xs_tit {
    color: #6b88e5;
  }

  @media screen and (max-width: 599px) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
`;

export const ContainerGap = styled.div`
  padding-top: 5rem;

  @media screen and (max-width: 599px) {
    padding-top: 6rem;
  }
`;
