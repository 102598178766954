import React, { useCallback, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputContentWrap,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { emailPattern, numberPattern } from '~/libs/regexp';
import { IResConfirmVCode, IResSendVCode } from '~/models/account/vcode';
import { RootState } from '~/modules';

import Modal from './modal';
import Recaptcha from './recaptcha';

interface IVCodeProps {
  openModal: boolean;
  handleRequestClose: () => void;
  handleClose: () => void;
  handleCallback?: () => any;
}

function VCode({
  openModal,
  handleRequestClose,
  handleClose,
  handleCallback,
}: IVCodeProps) {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<number>(0);
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<number>(0);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [openRecaptcha, setOpenRecaptcha] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { formatMessage } = useIntl();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (user) {
      setEmail(user.Email);
      setAuthenticated(true);
    }

    return () => {
      setEmail('');
      setEmailError(0);
      setCode('');
      setCodeError(0);
      setAuthenticated(false);
      setOpenRecaptcha(false);
    };
  }, [user]);

  const handleEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setEmailError(1);
    } else if (!emailPattern.test(value)) {
      setEmailError(2);
    } else {
      setEmailError(0);
    }

    setEmail(value);
  }, []);

  const handleCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setCodeError(1);
    } else if (!numberPattern.test(value)) {
      setCodeError(2);
    } else {
      setCodeError(0);
    }
    setCode(value);
  }, []);

  const handleSend = useCallback(() => {
    if (email === '') {
      setEmailError(1);
      return;
    }

    if (!emailPattern.test(email)) {
      setEmailError(2);
      return;
    }

    setOpenRecaptcha(true);
  }, [email]);

  const handleCloseRecaptcha = useCallback(() => {
    setOpenRecaptcha(false);
  }, []);

  const handleRecaptcha = useCallback(
    async (value: any) => {
      const token = value;

      try {
        const { data: reCaptchaData } = await defaultAxios.post(
          accountAPI.recaptcha,
          { token },
        );

        if (reCaptchaData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          try {
            const { data } = await defaultAxios.post<IResSendVCode>(
              accountAPI.sendVCode,
              { email },
            );

            const { error } = data;
            if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
              window.alert(formatMessage({ id: 'vcode.sendSuccess' }));
              handleCloseRecaptcha();
            } else {
              window.alert(formatMessage({ id: `errors.${error}` }));
            }
          } catch (err) {
            setErrorData(err);
          }
        }
      } catch (err) {
        setErrorData(err);
      }
    },
    [email, formatMessage, handleCloseRecaptcha],
  );

  const handleVerify = useCallback(async () => {
    if (code === '') {
      setCodeError(1);
      return;
    }

    if (!numberPattern.test(code)) {
      setCodeError(2);
      return;
    }

    try {
      const { data } = await defaultAxios.post<IResConfirmVCode>(
        accountAPI.confirmVCode,
        { email, code },
      );

      const { error } = data;
      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert(formatMessage({ id: 'alert.confirm' }));

        if (handleCallback) {
          await handleCallback();
        }
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      setErrorData(err);
    }
  }, [code, email, formatMessage, handleCallback]);

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        sizeClassName="medium"
        isOverlayClose={false}
        isEscClose={false}
        handleRequestClose={handleRequestClose}
      >
        <div className="modal__box--content">
          <i className="icon-x" onClick={handleClose} />
          <h4 className="m_tit">
            <b>Verification code</b>
          </h4>
          <div className="overlay__item">
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="vcode.email" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>

                  <FormInputContentWrap>
                    <FormInputField
                      className={authenticated ? 'input__disabled' : ''}
                    >
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        value={email}
                        onChange={handleEmail}
                        disabled={authenticated}
                        required
                      />
                      <label htmlFor="email">
                        <FormattedMessage id="vcode.email" />*
                      </label>
                    </FormInputField>
                    <button className="btn contentBtn" onClick={handleSend}>
                      Send
                    </button>
                  </FormInputContentWrap>
                  {emailError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}
                  {emailError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.email" />
                    </span>
                  )}
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="vcode.code" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>

                  <FormInputContentWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="code"
                        id="code"
                        maxLength={6}
                        placeholder="Verification code*"
                        required
                        value={code}
                        onChange={handleCode}
                        autoFocus
                      />
                      <label htmlFor="code">
                        <FormattedMessage id="vcode.code" />*
                      </label>
                    </FormInputField>
                    <button className="btn contentBtn" onClick={handleVerify}>
                      Verify
                    </button>
                  </FormInputContentWrap>
                  {codeError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}
                  {codeError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.onlyNum" />
                    </span>
                  )}
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </div>
        </div>
      </Modal>
      <Recaptcha
        openModal={openRecaptcha}
        handleRequestClose={handleCloseRecaptcha}
        handleClose={handleCloseRecaptcha}
        handleRecaptcha={handleRecaptcha}
      />
    </>
  );
}

export default VCode;
