import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Content as DefaultContent } from '~/components/elements/content';

const Content = styled(DefaultContent)`
  h1 {
    max-width: 42.5rem;
    margin: 0 auto;
    word-break: keep-all;
  }
`;

function ResourceIntro() {
  return (
    <Content className="first">
      <h1 className="l_tit">
        <FormattedMessage id="resource.title" />
      </h1>
      <img src={require('~/assets/images/p3_main.png').default} alt="" />
    </Content>
  );
}

export default ResourceIntro;
