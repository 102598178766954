import React from 'react';

import { Helmet } from 'react-helmet';

import RevenueRequest from '~/components/account/pub-my/revenue-request';

function PubMyRevenueRequest() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <RevenueRequest />
    </>
  );
}

export default PubMyRevenueRequest;
