module.exports = {
  'language.ko': '한국어',

  // common
  'common.prev': '이전',
  'common.next': '다음',
  'common.create': '생성',
  'common.modify': '변경',
  'common.select': '선택',
  'common.fileselect': '파일 선택',
  'common.cancel': '취소',
  'common.formatDay': '{day}일',
  'common.all': '모두',
  'common.search': '조회',
  'common.close': '닫기',
  'common.request': '신청',
  'common.registTime': '등록 시간',
  'common.updateTime': '수정 시간',
  'common.delete': '삭제',

  // alert
  'alert.add': '생성되었습니다.',
  'alert.modify': '변경되었습니다.',
  'alert.request': '신청되었습니다.',
  'alert.stop': '중지되었습니다.',
  'alert.start': '시작하였습니다.',
  'alert.execute': '실행하였습니다.',
  'alert.clipboard': '클립보드에 복사되었습니다.',
  'alert.ready': '준비중입니다.',
  'alert.fileSize': '파일 사이즈는 {size}MB까지 가능합니다.',
  'alert.confirm': '인증되었습니다.',
  'alert.delete': '삭제되었습니다.',

  // confirm
  'confirm.signout': '로그아웃 하시겠습니까?',
  'confirm.add': '생성하시겠습니까?',
  'confirm.modify': '변경하시겠습니까?',
  'confirm.request': '신청하시겠습니까?',
  'confirm.allStop': '전체 중지하시겠습니까?',
  'confirm.allStart': '전체 시작하시겠습니까?',
  'confirm.stop': '중지하시겠습니까?',
  'confirm.start': '시작하시겠습니까?',
  'confirm.execute': '실행하시겠습니까?',
  'confirm.delete': '삭제하시겠습니까?',

  // validate
  'validate.required': '필수 입력',
  'validate.min': '최소값은 {min}입니다.',
  'validate.max': '최대값은 {max}입니다.',
  'validate.maxLength': '최대 길이는 {len}까지 입니다.',
  'validate.url': '올바른 url을 입력해주세요.',
  'validate.email': '올바른 이메일을 입력해주세요.',
  'validate.pw': '올바른 비밀번호를 입력해주세요.',
  'validate.pwNotMatch': '비밀번호가 일치하지 않습니다.',
  'validate.minMax': '최소 {min}이상 입력해주세요.',
  'validate.onlyNum': '숫자만 입력해주세요.',
  'validate.searchDay': '검색 가능일은 1일부터 {days}일까지 입니다.',

  // errors
  'errors.UNKNOWN': '알수 없는 오류입니다.\n관리자에게 문의해주십시오.',
  'errors.NEED_SIGNUP': '회원가입 후 이용해주세요',
  'errors.ALREADY_SIGNUP': '이미 사용중인 메일입니다.',
  'errors.TOKEN_EXPIRED': '토큰이 만료되었습니다.',
  'errors.DUPLICATE_EMAIL': '이미 사용중인 메일입니다.',
  'errors.INVALID_PW': '비밀번호를 확인해주세요.',
  'errors.INVALID_CONFTOKEN': '유효하지 않은 토큰입니다.',
  'errors.UNKNOWN_TIMEZONE': '알 수 없는 타임존입니다.',
  'errors.ONLY_BUSINESSMAIL': '업무용 메일만 사용하실 수 있습니다.',
  'errors.DAYLIMIT_LESSTHAN': '일 한도제한은 캠페인 예산보다 작아야 합니다.',
  'errors.PROLIMIT_LESSTHAN': '광고 한도제한은 캠페인 예산보다 작아야 합니다.',
  'errors.GREATER_THANZERO': '한도제한은 0보다 커야 합니다.',
  'errors.INVALID_TRACKURLAOS': '유효하지 않은 트래킹 URL(Android)입니다.',
  'errors.INVALID_TRACKURLIOS': '유효하지 않은 트래킹 URL(iOS)입니다.',
  'errors.MUSTBE_STOPPED': '광고를 중지해야 합니다.',
  'errors.INVALID_SESSIONKEY': '다른 곳에서 로그인 되었습니다.',
  'errors.DUPLICATE_REQUEST': '중복된 지급 요청 입니다.',
  'errors.NOTENOUGH_REVENUE': '최소 지급 가능 금액은 100,000 입니다.',
  'errors.INVALID_PERIOD': '신청 기간이 아닙니다.',
  'errors.NEED_ACCOUNT': '서류 제출이 필요합니다.',
  'errors.INVALID_VCODE': '유효하지 않은 인증 번호입니다.',
  'errors.VCODE_EXPIRED': '인증 번호가 만료 되었습니다.',

  // hint
  'hint.budget': '캠페인 예산',
  'hint.budget.desc':
    '캠페인 예산이 모두 집행되면 하위에 속한 광고들은 게재 되지 않습니다.',
  'hint.limit': '한도',
  'hint.limit.desc': '지정된 한도 초과시 이 광고는 게재 되지 않습니다.',
  'hint.bid': '입찰',
  'hint.bid.desc':
    '최저, 최대 중간값 이 낙찰가 보다 같거나 클 경우 입찰에 성공합니다.',
  'hint.banner': 'Banner',
  'hint.banner.desc':
    '사용자가 앱과 상호작용하는 동안 화면에 머무르며 특정 시간이 지나면 자동으로 새로 고침 할 수 있습니다.<br />모바일 광고를 처음 시작하는 경우 이 형식부터 이용해 보시기 바랍니다.',
  'hint.interstitial': 'Interstitial',
  'hint.interstitial.desc':
    '일반적으로 앱에서 다음 레벨로 넘어갈 때처럼 이용이 잠시 중단될 때 자연스럽게 광고가 게재됩니다.<br />앱에서 Interstitial 광고가 표시될 때 사용자는 광고를 탭 하여 대상으로 이동하거나 광고를 닫고 앱으로 돌아갈 수도 있습니다.',
  'hint.video': 'Video',
  'hint.video.desc':
    'Video 광고는 클릭 또는 탭 할 수 있는 Video 콘텐츠가 삽입되어 있는 광고입니다.<br />이 광고를 활용하면 사용자가 관련 앱 스토어로 이동하여 홍보 중인 앱을 설치하기 전에 앱 내부의 광고에 포함된 Video을 시청할 수 있습니다.',
  'hint.cpm': 'CPM',
  'hint.cpm.desc':
    "CPM은 1000회 노출 당 비용을 의미하는 'Cost Per Mille'의 약자입니다.<br />광고가 1000회 게재될 때 비용을 지급하며 브랜드의 인지도와 참여도를 높이는데 가장 효과적입니다.",
  'hint.cpc': 'CPC',
  'hint.cpc.desc':
    "CPC는 클릭 당 비용을 의미하는 'Cost Per Click'의 약자입니다.<br />성과를 향상하거나 특정 행동(예: 웹사이트 방문 등)을 유도하는데 가장 효과적인 방법입니다.",
  'hint.cpv': 'CPV',
  'hint.cpv.desc':
    "CPV는 Video 시청 1회당 비용을 의미하는 'Cost Per View'의 약자입니다.<br />Video과 함께 다운로드 수, 평점, 스크린샷 등 앱 추가 정보를 사용자에게 보여줌으로써 정보 전달에 용이합니다.",
  'hint.ncpi': 'nCPI',
  'hint.ncpi.desc':
    "nCPI는 'Non-incentive Cost Per Install'의 약자입니다.<br />nCPI는 사용자에게 보상을 하지 않으면서 앱 설치를 유도하는데 효과적입니다.",
  'hint.ncpa': 'nCPA',
  'hint.ncpa.desc':
    "nCPA는 'Non-incentive Cost Per Action'의 약자입니다.<br />nCPA는 사용자에게 보상을 하지 않으면서 앱 내에서 특정 행동을(예: 튜토리얼, 레벨업 등) 유도하는데 효과적입니다.",
  'hint.addinfo': '추가 정보',
  'hint.addinfo.desc':
    '출시된 앱의 스토어 URL을 입력하면 앱의 추가정보를 가져와 엔딩스크린으로 표현해 줍니다.',
  'hint.ctr': 'CTR',
  'hint.ctr.desc': 'CTR = 클릭수 / 노출수',
  'hint.ecpm': 'eCPM',
  'hint.ecpm.desc':
    'eCPM = (수익 / 노출수) x 1,000<br />eCPM은 1,000회 광고 노출당 게시자가 얻는 수익의 추정치입니다.',
  'hint.revenue': '수입',
  'hint.revenue.desc': '해당 앱을 통해 유입된 수입입니다.',
  'hint.install': '설치 수',
  'hint.install.desc': '해당 앱을 통해 유입된 설치 수 입니다.',

  // week
  'week.sunday': '일요일',
  'week.monday': '월요일',
  'week.tuesday': '화요일',
  'week.wednesday': '수요일',
  'week.thursday': '목요일',
  'week.friday': '금요일',
  'week.saturday': '토요일',
  'week.0': 'Sun',
  'week.1': 'Mon',
  'week.2': 'Tue',
  'week.3': 'Wed',
  'week.4': 'Thu',
  'week.5': 'Fri',
  'week.6': 'Sat',

  // logo
  'logo.advertiser': '광고주',
  'logo.publisher': '퍼블리셔(개발자)',

  // global navigation bar
  'gnb.home.index': 'ZENAAD의 이점',
  'gnb.home.start': '시작하기',
  'gnb.home.resource': '리소스',
  'gnb.home.announce': '공지',

  'gnb.sdkAndSupport': '학습 및 지원',
  'gnb.sdkAndSupport.sdk': 'SDK',
  'gnb.sdkAndSupport.support': '지원',

  'gnb.app': '앱',
  'gnb.app.game': '게임',
  'gnb.app.app': '앱',

  'gnb.partner': '파트너',
  'gnb.partner.mediation': '미디에이션',
  'gnb.partner.publisher': '퍼블리셔',

  'gnb.help': '문의',
  'gnb.help.help': '문의 하기',

  'gnb.privacy': '개인정보처리방침',
  'gnb.adTerms': '광고주 이용약관',
  'gnb.pubTerms': '퍼블리셔 이용약관',

  'gnb.ad.home': '홈',
  'gnb.ad.campaign': '캠페인',
  'gnb.ad.ad': '광고',
  'gnb.ad.report': '지표',
  'gnb.ad.report.campaign': '캠페인 별 지표',
  'gnb.ad.report.ad': '광고 별 지표',

  'gnb.pub.home': '홈',
  'gnb.pub.app': '앱',
  'gnb.pub.inventory': '인벤토리',
  'gnb.pub.appReward': '보상',
  'gnb.pub.report': '지표',
  'gnb.pub.report.day': '일 별 지표',
  'gnb.pub.report.app': '앱 별 지표',
  'gnb.pub.report.user': '유저 지표',

  'gnb.signin': '로그인',
  'gnb.signout': '로그 아웃',
  'gnb.mypage': '마이페이지',
  'gnb.manageConsole': '관리 콘솔',

  // signin
  'signin.signin': '로그인',
  'signin.joinMessage': '아직 <b>ZENAAD</b>에 가입하지 않으셨나요?',
  'signin.join': '가입하기',
  'signin.findEmailMessage': '이메일이 생각나지 않으세요?',
  'signin.findEmail': '이메일 찾기',
  'signin.findPasswordMessage': '비밀번호를 잊으셨나요?',
  'signin.findPassword': '비밀번호 찾기',

  'findEmail.title': '이메일이 생각나지 않으세요?',
  'findEmail.submit': '확인',
  'findEmail.desc':
    'ZENAAD는 이메일을 아이디로 사용합니다.<br />기억하고 계신 이메일을 입력해보세요.<br />가입여부를 확인해 드립니다.',
  'findEmail.success': '회원으로 등록된 이메일입니다.',
  'findEmail.login': '로그인',
  'findEmail.loginMessage': '해당 이메일로 {login}하고 ZENAAD를 이용해주세요.',
  'findEmail.failed': 'ZENAAD에 등록되지 않은 계정입니다.',

  // find password
  'findpw.title': '비밀번호를 잊으셨나요?',
  'findpw.submit': '인증 메일 보내기',
  'findpw.step':
    '간단한 절차로 비밀번호를 변경하십시오.<br />이 방법은 귀하의 비밀번호를 안전하게 지켜드립니다.',
  'findpw.step1': '가입하신 이메일 주소를 입력해 주세요.',
  'findpw.step2': '비밀번호 초기화 링크를 보내드립니다.',
  'findpw.step3': '3분 안에 새 비밀번호를 등록해 주십시오.',

  // signup
  'signup.email.title': '이메일을 확인하고 다음 가입절차를 진행해 주세요.',
  'signup.email.submit': '인증 메일 보내기',
  'signup.email.step': '가입 절차는 아래와 같습니다',
  'signup.email.step1': '입력된 이메일 주소로 인증 메일이 발송 됩니다.',
  'signup.email.step2': `인증 메일의 'Verify Email' 링크를 클릭해 주세요.`,
  'signup.email.step3': '약관 동의 후 회원 기본정보를 입력해 주세요.',
  'signup.email.step4': '가입하기 완료',
  'signup.email.sendSuccess':
    '인증 메일을 발송하였습니다.\n메일을 확인해주세요.',
  'signup.agree.advertiser': '광고주',
  'signup.agree.publisher': '퍼블리셔<span>(개발자)</span>',
  'sugnup.agree.agree': '약관에 동의합니다.',
  'sugnup.agree.notAgree': '약관에 동의해주십시오.',
  'signup.form.pwDesc':
    '비밀번호는 최소 8자 이며 하나 이상의 숫자, 특수문자 !@#$%^*+=- 가 포함되어야 합니다.',
  'signup.form.join': '가입하기',
  'signup.success': '가입되었습니다.',
  'signup.success.welcome': 'ZENAAD에 오신 것을 환영합니다!',
  'signup.success.info': '{name}님의 아이디는 {email}입니다.',
  'signup.success.start': '시작하기',

  // main - home
  'home.xpla.title':
    'ZENAAD메인넷이 Ethereum에서 {link}로 변경됩니다.{br}ZENAAD 플랫폼을 통해 획득한 코인은 {xPlayz}에서 확인 가능합니다!',
  'home.xpla.btn': '확인하기',
  'home.xplaintro.title':
    'ZENAAD 플랫폼을 통해 얻은 수익을 xPlayz에서 확인하고<br />어플 내 여러가지 활동을 통해 추가포인트를 획득하여 XPLA를 받으세요.',
  'home.xplaintro.wallet1': '지급된 XPLA를 관리할 수 있는 표준 지갑입니다.',
  'home.xplaintro.wallet2':
    'ZENAAD 플랫폼을 통해 일정 XPLA 누적시 자동 지급됩니다.',
  'home.xplaintro.reward1': '앱 내 활동을 통해 볼트를 지급합니다.',
  'home.xplaintro.reward2': '볼트를 사용하여 추가 보너스를 받을수 있습니다.',
  'home.xplaintro.reward3': '볼트는 앱 내에서만 사용 가능한 포인트입니다.',
  'home.intro.title': '<strong>ZENAAD</strong>의 강점을 경험해보세요',
  'home.intro.subTitle':
    'ZENAAD의 사용자 측정 항목, 보고서를 활용해 앱 수익을 늘리고 활용 가능한유용한 정보를 얻을 수 있습니다.',
  'home.subNav.revenue': '안정적인 수입',
  'home.subNav.format': '다양한 광고 형식',
  'home.subNav.data': '활용 가능한 데이터',
  'home.revenue.title': '안정적인 수익이 보장됩니다',
  'home.revenue.subTitle': '실적이 우수한 광고 네트워크와 연결되어 있습니다.',
  'home.revenue.network': '글로벌 광고 네트워크',
  'home.revenue.networkDesc':
    'ZENAAD는 글로벌 광고 네트워크와 연동되어 있어 언제 어디서든 광고를 노출할 수 있습니다.',
  'home.revenue.bid': '실시간 입찰',
  'home.revenue.bidDesc':
    '실시간으로 입찰에 참여해 항상 최고가를 제시하는 광고주를 확보할 수 있습니다.',
  'home.revenue.share': '유저 수익 공유',
  'home.revenue.shareDesc':
    '낙찰된 가격의 10%를 유저에게 공유함으로써 앱에 대한 참여도를 높일 수 있습니다.',
  'home.revenue.networkResult': '네트워크 실적',
  'home.revenue.partner': '파트너 광고 네트워크',
  'home.revenue.partnerDesc1': '높은 노출률을 보장합니다.',
  'home.revenue.partnerDesc2':
    'Mobvista와 같은 글로벌 광고 네트워크와 연동되어 있고 실시간으로 광고를제공해 높은 노출율을 보장합니다.',
  'home.format.title': '모바일 앱에 최적화된 광고 형식을 활용하세요',
  'home.format.banner': 'Banner 광고',
  'home.format.bannerDesc':
    '사용자가 앱과 상호작용하는 동안 화면에 머무르며 특정 시간이 지나면 자동으로 새로고침 할 수 있습니다. 모바일 광고를 처음 시작하는 경우 이 형식부터 이용해 보시기 바랍니다.',
  'home.format.inter': 'Interstitial 광고',
  'home.format.interDesc':
    '일반적으로 앱에서 다음 레벨로 넘어갈 때처럼 이용이 잠시 중단될 때 자연스럽게 광고가 게재됩니다. 앱에서 Interstitial 광고가 표시될 때 사용자는 광고를 탭하여 대상으로 이동하거나 광고를 닫고 앱으로 돌아갈 수도 있습니다.',
  'home.format.video': 'Video 광고',
  'home.format.videoDesc':
    'Video 광고는 클릭 또는 탭할 수 있는 Video 콘텐츠가 삽입되어 있는 광고입니다. 이 광고를 활용하면 사용자가 관련 앱 스토어로 이동하여 홍보 중인 앱을 설치하기 전에 앱 내부의 광고에 포함된 Video을 시청할 수 있습니다.',
  'home.format.native': 'Native ads (출시 예정)',
  'home.format.nativeDesc':
    '네이티브 앱 코드에서 자연스럽게 앱의 일부처럼 보이는 광고를 게재할 수 있습니다.',
  'home.format.player': '플레이어블 광고 (출시 예정)',
  'home.format.playerDesc':
    '광고를 통해 상호작용한 사용자에게 리워드를 제공하는 광고 형식입니다. 무료 게임 사용자로부터 수익을 창출하는 데 효과적입니다.',
  'home.data.title': '분석 정보를 적극 활용하세요',
  'home.data.user': '유저 지표',
  'home.data.userDesc':
    '분석 데이터를 활용해 수익에 영향을 미칠 만한 요소를 미리 파악하고대응 할 수 있습니다.',
  'home.data.report': '보고서',
  'home.data.reportDesc':
    '예상 수익 및 수입에 미치는 영향을 파악할 수 있게 설계했습니다. 차트 및 표가 포함되어 전체 추이를 보고 중요한 측정 항목을 비교할 수 있습니다.',

  // main - start
  'start.title': '빠르게 가입하고 수익을 창출하세요',
  'start.email': '이메일 인증을 통해 빠르게 가입할 수 있습니다',
  'start.email.step01': '이메일 인증을 하고 ZENAAD 계정에 로그인하세요.',
  'start.email.step02': '광고를 만들고 ZENAAD SDK를 앱에 통합하세요.',
  'start.email.step03': '분석 정보를 활용하여 수익을 창출하세요.',
  'start.with': '<b>ZENAAD</b>와 함께하시겠어요?',
  'start.join': '가입하기',

  // main - resource
  'resource.title':
    '다음 문서, 리소스, 성공사례를 사용하여 앱을 한 단계 업그레이드하세요',
  'resource.subNav.doc': '문서',
  'resource.subNav.media': '미디어',
  'resource.subNav.success': '성공사례',
  'resource.introduce': 'ZENAAD 소개서',
  'resource.business': '사업 소개서',
  'resource.businessDesc': 'ZENAAD 사업 소개서입니다.',
  'resource.media': '매체 소개서',
  'resource.mediaDesc': 'ZENAAD 매체 소개서입니다.',
  'resource.ad': '광고주',
  'resource.adGuide': '광고주 가이드',
  'resource.adGuideDesc': '광고주를 위한 가이드입니다.',
  'resource.logo': 'ZENAAD 로고',
  'resource.mainLogo': '메인 로고',
  'resource.mainLogoDesc': 'ZENAAD의 메인 로고입니다.',
  'resource.walletLinkLogo': '지갑 링크 로고',
  'resource.walletLinkLogoDesc':
    '게임/앱에서 사용되는 ZENAAD 지갑 링크 로고입니다.',
  'resource.success': '성공사례',
  'resource.ready': '준비중',
  'resource.update': '업데이트 예정입니다.',

  // main -- sdk
  'sdk.title': 'SDK를 앱에 통합하세요',
  'sdk.titleDesc':
    'ZENAAD 광고를 게재하고 수익을 창출하려면 먼저 SDK를 앱에 통합하세요.<br />통합이 완료되면 광고 형식을 선택하고 가이드에 따라 세부적인 내용을구현하세요.',
  'sdk.default': '기본조건',
  'sdk.default.ios': 'Xcode 10 이상',
  'sdk.default.android': 'Android API 레벨 14 이상',
  'sdk.default.unity': '5.1 이상',
  'sdk.download': '다운로드',
  'sdk.guide.kr': '가이드(KO)',
  'sdk.release': '릴리즈 노트',

  // main - game
  'game.app': '앱',
  'game.title': '최적의 수익화를 경험하세요',
  'game.subTitle':
    '이미 많은 앱들이 ZENAAD를 통해 최적의 수익화를 경험하고 있습니다.',
  'game.game': '게임',

  // main - app
  'app.app': '앱',
  'app.title': '최적의 수익화를 경험하세요',
  'app.subTitle':
    '이미 많은 앱들이 ZENAAD를 통해 최적의 수익화를 경험하고 있습니다.',

  // main - mediation
  'mediation.mediation': '미디에이션',
  'mediation.title': '글로벌 광고 네트워크를 이용하세요',
  'mediation.subTitle':
    'ZENAAD의 글로벌 광고 네트워크를 활용해 사업을 확장하세요.',
  'mediation.sp': 'SmartPosting',
  'mediation.sp.title': '인플루언서 마케팅 플랫폼',
  'mediation.sp.subTitle':
    'SmartPosting은 디지털광고대행과 인플루언서 마케팅 솔루션을 제공하고 있다. 페이스북, 카카오, 블로그, 유튜브, 인스타그램 등 소셜미디어를 사용하는 사람이라면 누구나 SmartPosting을 통해 인플루언서에 도전할 수 있으며, 콘텐츠 수익화를 위한 다양한 기회를 제공하고 있다.',
  'mediation.ms': 'Movista',
  'mediation.ms.title': '모바일 통합 마케팅 비지니스 플랫폼',
  'mediation.ms.subTitle':
    '첨단 데이터 기술을 기반으로 한 혁신적인 광고 크리에이티브 소재와 예측 분석기술을 통해 선두 기업들이 더욱 성장할 수 있는 원동력을 제공합니다.',
  'mediation.vt': 'Ventumm',
  'mediation.vt.title': '디지털 마케팅 에이전시',
  'mediation.vt.subTitle':
    'Ventumm은 독보적인 글로벌 성공경험을 보유한 광고 전문 기업입니다. 데이터 분석가와 실력있는 퍼포먼스 마케팅 전문가로 구성되어 있으며 글로벌 현장에서 직접 얻은 경험과 통찰력을 아낌없이 제공합니다. 광고주가 세계시장을 무대로 즐거운 모험을 이끌 수 있도록 적극 지원합니다.',
  'mediation.admax': 'ADMAX',
  'mediation.admax.title': '소셜 인플루언서 마켓팅',
  'mediation.admax.subTitle':
    'FSN의 자회사인 ADMAX는 AdQUA, CAULY등 20여 개의 다양한 AD-Agency 및 AD-Tech 계열사들과 협업 중에 있습니다. 국내 최대 규모의 Social Media Partnership을 보유하고 있으며 광고주의 니즈에 맞는 다양한 퍼포먼스 마케팅 광고를 진행하고 있습니다.',

  // main - publisher
  'publisher.publisher': '퍼블리셔',
  'publisher.title': '광고 수익화를 원하시나요?',
  'publisher.subTitle':
    '유수한 모바일 게임 회사들은 ZENAAD를 선택하고 있습니다.',
  'publisher.itam': 'ITAM Games',
  'publisher.itam.title': '블록체인 모바일 게임 플랫폼',
  'publisher.itam.subTitle':
    'ITAM Games는 블록체인 게임 플랫폼을 통해 게임 생태계의 혁신을 도모하고 있는 회사입니다. 또한 개발사를 위해 SDK를 제공하고, 탈 중앙화된 디지털 자산의 거래를 제공함으로써 Play-to-Earn을 실현시키기 위해 노력하고 있습니다.',
  'publisher.rhaon': 'Rhaon Entertainment',
  'publisher.rhaon.title': '게임을 전문적으로 개발하는 벤처기업',
  'publisher.rhaon.subTitle':
    'PC, 모바일 게임을 전문적으로 개발하는 회사입니다. 대표 게임 테일즈런너는 지난 2005년부터 서비스된 장수 게임으로 누적 회원 1,300만 명을 보유한 명작 레이싱 게임입니다. 최고동시접속자 수 6만 5,000여 명을 돌파하며 스마일게이트 메가포트 이전 후 제2의 전성기를 맞이했습니다.',
  'publisher.kai': 'Kai Entertainment',
  'publisher.kai.title': '모바일 게임 앱, 웹 서비스 전문 개발사',
  'publisher.kai.subTitle':
    '모바일 게임 앱, 웹 서비스를 전문적으로 개발하는 신생 회사입니다. Dispatch(대한민국 온라인 연예 매체)에서 보유한 다양한 스타 사진을 기반으로 제작된 K-Pop Poly라는 모바일 게임을 최근에 출시했습니다.',
  'publisher.three': '3F Factory',
  'publisher.three.title': '모바일 게임 전문 개발사',
  'publisher.three.subTitle':
    '모바일 게임을 전문적으로 개발하는 회사입니다. 대표적인 게임 신비아파트-고스트헌터는 CJ ENM의 인기 애니메이션 신비아파트를 소재로 제작되었으며 현재 100만 다운로드를 기록한 최고의 인기 게임입니다.',
  'publisher.weneed': 'Wenee',
  'publisher.weneed.title': '게임 컨텐츠와 솔루션 제공',
  'publisher.weneed.subTitle':
    'Weneed는 업계를 리딩 할 수 있는 최정예 인력과 글로벌 수준의 최적화된 컨설팅 방법론을 보유하고 있습니다. 이러한 역량을 바탕으로 전략에서 솔루션 컨설팅에 이르기까지 표준화된 방법론을 기반으로 고객의 경쟁력 강화에 기여하는 IT 리더를 지향하고 있습니다.',
  'publisher.sinest': 'SINEST',
  'publisher.sinest.title': '디지털자산 즐거운 플랫폼',
  'publisher.sinest.subTitle':
    'SINEST는 블록체인 어플리케이션인 Adapter를 제공하는 기업입니다. Adapter는 공정/공유/신뢰의 정신으로 사용자와 파트너, 모두가 함께 가치를 성장시키는 블록체인으로 연결된 즐거운 세상을 만들어 갑니다.',

  // main - help
  'help.help': '문의',
  'help.title': '도움이 필요하세요?',
  'help.subTitle':
    '다음 양식을 작성하여 ZENAAD 전문가에게 문의하시면 빠른 시일 내에 연락드리겠습니다.',
  'help.send.success': '메일을 전송하였습니다.',

  // mypage
  'mypage.mypage': '마이페이지',
  'mypage.default': '기본 정보',
  'mypage.default.email': '이메일',
  'mypage.default.password': '비밀번호',
  'mypage.default.name': '이름',
  'mypage.default.company': '회사 이름',
  'mypage.default.timezone': '타임 존',
  'mypage.addinfo': '추가 정보',
  'mypage.addinfo.mobile': '휴대폰 번호',

  // mypage - password change
  'mypage.pwChange': '비밀번호 변경',
  'mypage.pwChange.currentPassword': '현재 비밀번호',
  'mypage.pwChange.newPassword': '새 비밀번호',
  'mypage.pwChange.passwordConfirm': '비밀번호 확인',
  'mypage.pwChange.pwDesc':
    '비밀번호는 최소 8자 이며 하나 이상의 숫자, 특수문자 !@#$%^*+=- 가 포함되어야 합니다.',

  // advertiser mypage - asset
  'admypage.asset': '자산',
  'admypage.asset.cash': 'Cash',
  'admypage.asset.payment': '결제',
  'admypage.asset.paymentHistory': '결제 내역',
  'admypage.asset.history': '내역',

  // publisher mypage - revenue
  'pubmypage.revenueGive': '수입금',
  'pubmypage.revenueGive.revenueHistory': '수입금 내역',
  'pubmypage.revenueGive.doc': '서류 제출',
  'pubmypage.revenueGive.doc.submit': '제출',
  'pubmypage.revenueGive.doc.notSubmit': '미제출',
  'pubmypage.revenueGive.giveRequest': '지급 신청',
  'pubmypage.revenueGive.request': '신청',
  'pubmypage.revenueGive.giveRequestHistory': '지급 신청 내역',
  'pubmypage.revenueGive.history': '내역',
  'pubmypage.revenueGive.tab.history': '수입금 내역',
  'pubmypage.revenueGive.tab.doc': '서류',
  'pubmypage.revenueGive.tab.request': '지급 신청',
  'pubmypage.revenueGive.tab.requestList': '지급 신청 내역',
  'pubmypage.revenueGive.revenueDetails.month': '월',
  'pubmypage.revenueGive.revenueDetails.revenue': '수입',
  'pubmypage.revenueGive.revenueDetails.eCPM': 'eCPM',
  'pubmypage.revenueGive.revenueDetails.impressions': '노출 수',
  'pubmypage.revenueGive.doc.name': '예금주',
  'pubmypage.revenueGive.doc.bank': '은행',
  'pubmypage.revenueGive.doc.bankNum': '계좌번호',
  'pubmypage.revenueGive.doc.bankBook': '통장 사본',
  'pubmypage.revenueGive.doc.bankBookError': '통장 사본을 선택해주세요.',
  'pubmypage.revenueGive.doc.certificate': '사업자 등록증',
  'pubmypage.revenueGive.doc.certificateError': '사업자 등록증을 선택해주세요.',
  'pubmypage.revenueGive.giveRequest.revenue': '수입금',
  'pubmypage.revenueGive.giveRequest.sum': '금액',
  'pubmypage.revenueGive.giveRequest.guide': '안내',
  'pubmypage.revenueGive.giveRequest.min': '신청 가능한 최소 수입금',
  'pubmypage.revenueGive.giveRequest.period': '신청 기간',
  'pubmypage.revenueGive.giveRequest.periodDesc': '매월 1일 ~ 5일 23시 59분',
  'pubmypage.revenueGive.giveRequest.date': '지급일',
  'pubmypage.revenueGive.giveRequest.dateDesc1': '익월 말일 까지 지급',
  'pubmypage.revenueGive.giveRequest.dateDesc2':
    '예) 7월 수입금, 8월 5일까지 신청, 9월 30일 까지 지급',
  'pubmypage.revenueGive.giveRequestHistory.date': '시간',
  'pubmypage.revenueGive.giveRequestHistory.name': '예금주',
  'pubmypage.revenueGive.giveRequestHistory.bank': '은행',
  'pubmypage.revenueGive.giveRequestHistory.bankNum': '계좌 번호',
  'pubmypage.revenueGive.giveRequestHistory.revenue': '신청 수입금(KRW)',

  // advertiser - home
  'ad.home.network': '네트워크 실적',
  'ad.home.impression': 'Total. 노출 수',
  'ad.home.click': 'Total. 클릭 수',
  'ad.home.ctr': 'CTR',
  'ad.home.summary': '요약',
  'ad.home.campaign': '캠페인',
  'ad.home.newcampaign': '새 캠페인',
  'ad.home.all': '전체',
  'ad.home.advertising': '광고',
  'ad.home.allStop': '전체 중지',
  'ad.home.allStart': '전체 시작',
  'ad.home.inprogress': '진행중',
  'ad.home.winning': '낙찰됨',
  'ad.home.complete': '완료됨',
  'ad.home.asset': '자산',
  'ad.home.cash': 'Cash',
  'ad.home.needpay': '결제가 필요합니다.',
  'ad.home.gopay': '바로가기',

  // advertiser - campaign
  'ad.campaign': '캠페인',
  'ad.campaign.list': '캠페인 목록',
  'ad.campaign.campName': '캠페인 이름',
  'ad.campaign.newCampaign': '새 캠페인',
  'ad.campaign.campType': '플랫폼',
  'ad.campaign.remainBalance': '남은 예산(KRW)',
  'ad.campaign.promotion': '광고',
  'ad.campaign.inProgress': '진행중',
  'ad.campaign.complete': '완료됨',
  'ad.campaign.create': '새 캠페인 생성',
  'ad.campaign.app': '앱',
  'ad.campaign.budget': '캠페인 예산',
  'ad.campaign.country': '국가',
  'ad.campaign.targetUrl': '타켓 URL',
  'ad.campaign.detail': '상세 정보',
  'ad.campaign.goAd': '광고로 바로 가기',

  // advertiser - ad
  'ad.ad': '광고',
  'ad.ad.list': '광고 목록',
  'ad.ad.proName': '이름',
  'ad.ad.newPromotion': '새 광고',
  'ad.ad.emptyCampaign': '캠페인을 먼저 생성해주십시오.',
  'ad.ad.status': '상태',
  'ad.ad.platform': '플랫폼',
  'ad.ad.product': '광고 유형',
  'ad.ad.billingType': '가격 모델',
  'ad.ad.avgCost': '입찰가',
  'ad.ad.create': '새 광고 생성',
  'ad.ad.material': '소재',
  'ad.ad.app': '앱',
  'ad.ad.info': '추가정보',
  'ad.ad.schedule': '개제 빈도',
  'ad.ad.limit': '한도',
  'ad.ad.bid': '입찰',
  'ad.ad.banner': 'Banner',
  'ad.ad.interstitial': 'Interstitial',
  'ad.ad.video': 'Video',
  'ad.ad.first': '중지됨',
  'ad.ad.waiting': '중지됨',
  'ad.ad.inprogress': '진행중',
  'ad.ad.complete': '완료됨',
  'ad.ad.bannerDesc':
    '사용자가 앱과 상호작용하는 동안 화면에 머무르며 특정 시간이 지나면 자동으로 새로 고침 할 수 있습니다. 모바일 광고를 처음 시작하는 경우 이 형식부터 이용해 보시기 바랍니다.',
  'ad.ad.interstitialDesc':
    '일반적으로 앱에서 다음 레벨로 넘어갈 때처럼 이용이 잠시 중단될 때 자연스럽게 광고가 게재됩니다. 앱에서 Interstitial 광고가 표시될 때 사용자는 광고를 탭 하여 대상으로 이동하거나 광고를 닫고 앱으로 돌아갈 수도 있습니다.',
  'ad.ad.videoDesc':
    'Video 광고는 클릭 또는 탭 할 수 있는 Video 콘텐츠가 삽입되어 있는 광고입니다. 이 광고를 활용하면 사용자가 관련 앱 스토어로 이동하여 홍보 중인 앱을 설치하기 전에 앱 내부의 광고에 포함된 Video을 시청할 수 있습니다.',
  'ad.ad.cpmDesc': `CPM은 1000회 노출 당 비용을 의미하는 'Cost Per Mille'의 약자입니다. 광고가 1000회 게재될 때 비용을 지급하며 브랜드의 인지도와 참여도를 높이는데 가장 효과적입니다.`,
  'ad.ad.cpcDesc': `CPC는 클릭 당 비용을 의미하는 'Cost Per Click'의 약자입니다. 성과를 향상하거나 특정 행동(예: 웹사이트 방문 등)을 유도하는데 가장 효과적인 방법입니다.`,
  'ad.ad.cpvDesc': `CPV는 Video 시청 1회당 비용을 의미하는 'Cost Per View'의 약자입니다. Video과 함께 다운로드 수, 평점, 스크린샷 등 앱 추가 정보를 사용자에게 보여줌으로써 정보 전달에 용이합니다.`,
  'ad.ad.ncpiDesc': `nCPI는 ‘Non-incentive Cost Per Install'의 약자입니다. nCPI는 사용자에게 보상을 하지 않으면서 앱 설치를 유도하는데 효과적입니다.`,
  'ad.ad.ncpaDesc': `nCPA는 'Non-incentive Cost Per Action'의 약자입니다. nCPA는 사용자에게 보상을 하지 않으면서 앱 내에서 특정 행동을(예: 튜토리얼, 레벨업 등) 유도하는데 효과적입니다.`,
  'ad.ad.comment': '설명',
  'ad.ad.image': '이미지',
  'ad.ad.imageBanner':
    'Banner 이미지는 2개까지 선택하실 수 있습니다.<br />확장자는 jpg, png만 가능합니다.',
  'ad.ad.imageInter':
    'Interstitial 이미지는 1개만 선택하실 수 있습니다.<br />확장자는 jpg, png만 가능합니다.',
  'ad.ad.imageVideo': '확장자가 mp4, mov 파일만 가능합니다.',
  'ad.ad.imageError':
    '확장자가 jpg, png 또는 500KB 이하의 이미지만 등록 가능합니다.',
  'ad.ad.videoError':
    '확장자가 mp4, mov 또는 10MB 이하의 Video만 등록 가능합니다.',
  'ad.ad.videoDuration': 'Video 시간은 5초 ~ 35초까지 가능합니다.',
  'ad.ad.videoRetry': 'Video 파일 업로드에 실패하였습니다.\n다시 등록해주세요.',
  'ad.ad.fileSelect': '파일 선택',
  'ad.ad.confirmEncoding': '시간이 걸리는 작업입니다. 인코딩 하시겠습니까?',
  'ad.ad.country': '국가',
  'ad.ad.campType': '플랫폼',
  'ad.ad.targetUrl': '타켓 URL',
  'ad.ad.addInfo': '추가 정보',
  'ad.ad.notAddInfo': '해당 광고 유형은 추가 정보 기능을 제공하지 않습니다.',
  'ad.ad.storeUrl': '스토어 URL',
  'ad.ad.invalidStoreURL': '올바른 주소를 등록해주세요.',
  'ad.ad.getAppInfo': '앱 정보 가져오기',
  'ad.ad.storeAppName': '이름',
  'ad.ad.grade': '평점',
  'ad.ad.downloadCount': '다운로드 수',
  'ad.ad.genre': '장르',
  'ad.ad.chargeNoCharge': '유/무료',
  'ad.ad.charge': '유료',
  'ad.ad.noCharge': '무료',
  'ad.ad.showGradeDownloadCount': '평점/다운로드 수 표시',
  'ad.ad.daily': '매일',
  'ad.ad.weekday': '평일',
  'ad.ad.weekend': '주말',
  'ad.ad.directSelect': '직접 선택',
  'ad.ad.dayLimit': '일 한도',
  'ad.ad.budgetLimit': '광고 한도',
  'ad.ad.bidCost': '최근 낙찰가',
  'ad.ad.minCost': '최저',
  'ad.ad.maxCost': '최고',
  'ad.ad.goCampaign': '캠페인으로 바로 가기',
  'ad.ad.detail': '상세 정보',
  'ad.ad.detailNoti': '변경 하시려면 광고를 중지하셔야 합니다.',

  // advertiser - report
  'ad.report.campaign': '캠페인 별 지표',
  'ad.report.totalImpression': 'Total. 노출 수',
  'ad.report.totalClick': 'Total. 클릭 수',
  'ad.report.ctr': 'CTR',
  'ad.report.campName': '캠페인 이름',
  'ad.report.impression': '노출 수',
  'ad.report.click': '클릭 수',
  'ad.report.ctrper': 'CTR(%)',
  'ad.report.execute': '집행 비용',
  'ad.report.ad': '광고 별 지표',
  'ad.report.proName': '광고 이름',

  // publisher - home
  'pub.home.network': '네트워크 실적',
  'pub.home.dau': 'DAU',
  'pub.home.revenue': 'Total. 수입',
  'pub.home.ecpm': 'eCPM',
  'pub.home.install': 'Total. Number of Installations',
  'pub.home.summary': '요약',
  'pub.home.app': '앱',
  'pub.home.inventory': '인벤토리',
  'pub.home.revenueAmount': '수입금',
  'pub.home.all': '전체',
  'pub.home.approval': '승인됨',
  'pub.home.open': '열림',
  'pub.home.close': '닫힘',
  'pub.home.newapp': '새 앱 생성',
  'pub.home.allclose': '전체 닫기',
  'pub.home.allopen': '전체 열기',
  'pub.home.revenueRequest': '지급 신청',
  'pub.home.revenueRequestDesc': '수입금 지급 신청 가능합니다.',
  'pub.home.goRequest': '바로가기',

  // publisher - app
  'pub.app': '앱',
  'pub.app.list': '앱 목록',
  'pub.app.appName': '앱 이름',
  'pub.app.newApp': '새 앱 생성',
  'pub.app.state': '상태',
  'pub.app.platform': '플랫폼',
  'pub.app.approval': '승인됨',
  'pub.app.notApproval': '검토중',
  'pub.app.create': '새 앱 생성',
  'pub.app.storeUrl': '스토어 URL',
  'pub.app.apiKey': 'API KEY',
  'pub.app.apiKeyDayLimit': '일일 제한',
  'pub.app.contentLevel': '콘텐트 수위',
  'pub.app.contentLevelDesc':
    '선정성,사행성,폭력성 등 청소년에게 유해한 내용이 포함되어 있나요?',
  'pub.app.detail': '상세 정보',
  'pub.app.apiKeyRequest': 'API KEY 요청',
  'pub.app.apiKeyRequestWait': '승인 대기',
  'pub.app.goInventory': '인벤토리로 바로 가기',

  // publisher - inventory
  'pub.inven': '인벤토리',
  'pub.inven.list': '인벤토리 목록',
  'pub.inven.newInven': '새 인벤토리',
  'pub.inven.emptyApp': '앱을 먼저 생성해주십시오.',
  'pub.inven.state': '상태',
  'pub.inven.invenName': '인벤토리 이름',
  'pub.inven.invenType': '인벤토리 유형',
  'pub.inven.open': '열림',
  'pub.inven.close': '닫힘',
  'pub.inven.create': '새 인벤토리 생성',
  'pub.inven.reward': '보상',
  'pub.inven.name': '이름',
  'pub.inven.id': '아이디',
  'pub.inven.idAuto': '자동 발급됩니다.',
  'pub.inven.rewardCount': '수량',
  'pub.inven.detail': '상세 정보',
  'pub.inven.banner': 'Banner',
  'pub.inven.interstitial': 'Interstitial',
  'pub.inven.video': 'Video',
  'pub.inven.goApp': '앱으로 바로 가기',
  'pub.inven.alertRewards': '보상 수량을 입력해주세요.',

  // publisher - appReward
  'pub.appReward': '보상',
  'pub.appReward.list': '보상 목록',
  'pub.appReward.newAppReward': '새 보상',
  'pub.appReward.emptyApp': '앱을 먼저 생성해주십시오.',
  'pub.appReward.create': '새 보상 생성',
  'pub.appReward.rewardName': '보상 이름',
  'pub.appReward.reward': '보상 가격(원)',
  'pub.appReward.dayLimit': '일일 제한(원)',
  'pub.appReward.goApp': '앱으로 바로 가기',

  // publisher - report
  'pub.report.dailyReport': '일 별 지표',
  'pub.report.searchDesc': '조회 구간은 180일까지 가능합니다.',
  'pub.report.totalImpression': 'Total. 노출 수',
  'pub.report.totalRevenue': 'Total. 수입',
  'pub.report.ecpm': 'eCPM',
  'pub.report.daily': '일 별',
  'pub.report.impression': '노출 수',
  'pub.report.revenue': '수입',
  'pub.report.ctr': 'CTR',
  'pub.report.nru': 'NRU',
  'pub.report.install': '설치 수',
  'pub.report.day': '일',
  'pub.report.appReport': '앱 별 지표',
  'pub.report.appName': '앱 이름',
  'pub.report.userReport': '유저 지표',
  'pub.report.dau': 'DAU',
  'pub.report.totalNru': 'Total. NRU',

  // vcode
  'vcode.email': '이메일',
  'vcode.code': '인증코드',
  'vcode.sendSuccess':
    '입력된 이메일 주소로 인증 코드가 전송 되었습니다.\n3분 안에 확인해 주십시오.',

  'test.zenaad': '제나애드',
  'test.hwkim': '김현우',
};
