import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  OverlayContentTop,
  OverlayTopCreate,
  OverlayTopWrap,
} from '~/components/elements/overlay';

function CreateTab() {
  return (
    <OverlayContentTop className="create">
      <OverlayTopWrap>
        <h4 className="overlay__title m_tit">
          <FormattedMessage id="pub.appReward.create" />
        </h4>
        <OverlayTopCreate className="xs_tit">
          <ul>
            <li className="active">
              <FormattedMessage id="pub.appReward" />
            </li>
          </ul>
        </OverlayTopCreate>
      </OverlayTopWrap>
    </OverlayContentTop>
  );
}

export default CreateTab;
