import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { pubAppAPI, pubReportAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { config } from '~/libs/config';
import { getPosibleSearchDate } from '~/libs/utils';
import { IAppNameListItem, IResAppNameList } from '~/models/pub/app';
import { IReportByUser, IResReportByUser } from '~/models/pub/report';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import {
  setSearchEndDate,
  setSearchSelectDay,
  setSearchStartDate,
} from '~/modules/user';

import ReportUserFilter from './report-user01-filter';
import ReportUserState from './report-user02-state';
import ReportUserChart from './report-user03-chart';

function ReportUser() {
  const [apps, setApps] = useState<IAppNameListItem[] | null>(null);
  const [appID, setAppID] = useState<string>('');

  const [totalDAU, setTotalDAU] = useState<number>(0);
  const [minDAU, setMinDAU] = useState<number>(0);
  const [maxDAU, setMaxDAU] = useState<number>(0);
  const [totalNRU, setTotalNRU] = useState<number>(0);
  const [minNRU, setMinNRU] = useState<number>(0);
  const [maxNRU, setMaxNRU] = useState<number>(0);

  const [users, setUsers] = useState<IReportByUser[] | null>(null);
  const [chartData, setChartData] = useState<any>([]);

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const { searchSelectDay, searchStartDate, searchEndDate } = useSelector(
    (s: RootState) => s.user,
  );

  const [{ data: appData, loading: appLoading, error: appError }] =
    useAxios<IResAppNameList>(
      {
        url: pubAppAPI.listAll,
        method: 'GET',
      },
      { useCache: false },
    );

  const [{ data: userData, loading: userLoading, error: userError }] =
    useAxios<IResReportByUser>(
      {
        url: pubReportAPI.reportByUser,
        method: 'GET',
        params: {
          appID,
          startDate: searchStartDate,
          endDate: searchEndDate,
        },
      },
      { useCache: false },
    );

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (appLoading || userLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [appLoading, dispatch, userLoading]);

  useEffect(() => {
    if (appData) {
      if (appData.apps) {
        setApps(appData.apps);
      }
    }
  }, [appData]);

  useEffect(() => {
    if (userData) {
      // if (userData.list) {
      //   setUsers(userData.list);

      //   let dau: number = 0;
      //   let install: number = 0;

      //   for (const item of userData.list) {
      //     dau = dau + Number(item.DAU);
      //     install = install + Number(item.NRU);
      //   }

      //   if (dau > 0) {
      //     const temp = dau / userData.list.length;
      //     const avgDAU = Number(temp.toFixed(2));
      //     setTotalDAU(avgDAU);
      //   }

      //   setTotalInstall(install);
      // }

      if (userData.list) {
        setUsers(userData.list);
      }

      if (userData.report) {
        const { report } = userData;

        setTotalDAU(report.DAU);
        setMinDAU(report.MinDAU);
        setMaxDAU(report.MaxDAU);
        setTotalNRU(report.TotalNRU);
        setMinNRU(report.MinNRU);
        setMaxNRU(report.MaxNRU);
      }
    }
  }, [userData]);

  useEffect(() => {
    let temp = null;
    if (users) {
      if (users.length > 0) {
        const arr = users.map((item) => [
          item.RegistTime.substr(0, 10),
          Number(item.DAU),
          Number(item.NRU),
        ]);

        temp = [['Date', 'DAU', f({ id: 'pub.report.nru' })], ...arr];
      } else {
        temp = [
          ['Date', 'DAU', f({ id: 'pub.report.nru' })],
          ['', 0, 0],
        ];
      }
    } else {
      temp = [
        ['Date', 'DAU', f({ id: 'pub.report.nru' })],
        ['', 0, 0],
      ];
    }

    setChartData(temp);
  }, [f, users]);

  if (appError) {
    if (appError.response) {
      if (appError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw appError;
  }

  if (userError) {
    if (userError.response) {
      if (userError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw userError;
  }

  const handleChangeAppID = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setAppID(value);
  };

  const handleSearch = (day: number, sdate: any, edate: any) => {
    const valid = getPosibleSearchDate(sdate, edate, config.searchDay);
    if (!valid) {
      window.alert(f({ id: 'validate.searchDay' }, { days: config.searchDay }));
      return;
    }

    dispatch(setSearchSelectDay(day));
    dispatch(setSearchStartDate(sdate));
    dispatch(setSearchEndDate(edate));
  };

  return (
    <>
      <ConsoleContent>
        <ReportUserFilter
          apps={apps}
          appID={appID}
          searchSelectDay={searchSelectDay}
          searchStartDate={searchStartDate}
          searchEndDate={searchEndDate}
          handleChangeAppID={handleChangeAppID}
          handleSearch={handleSearch}
        />
        <ReportUserState
          dau={totalDAU}
          minDau={minDAU}
          maxDau={maxDAU}
          nru={totalNRU}
          minNru={minNRU}
          maxNru={maxNRU}
        />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        <ReportUserChart chartData={chartData} />
      </ConsoleContent>
    </>
  );
}

export default ReportUser;
