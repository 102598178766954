import React from 'react';

import NumberFormat from 'react-number-format';

import { ICampaignListItem } from '~/models/ad/campaign';

interface IListTableItemProps {
  campaign: ICampaignListItem;
  handleDetail: (campID: number) => void;
}

function ListTableItem({ campaign, handleDetail }: IListTableItemProps) {
  const { CampID, Name, CampType, Balance, Promotion, InProgress, Complete } =
    campaign;

  return (
    <tr onClick={() => handleDetail(CampID)}>
      <th className="text__left border__right table__title">
        <span>{Name}</span>
      </th>
      <td>
        {CampType === '-' ? (
          '-'
        ) : CampType === 'AOS' ? (
          <i className="icon-android" />
        ) : (
          <i className="icon-appleinc" />
        )}
        {CampType === '-' ? '' : CampType === 'AOS' ? 'Android' : 'iOS'}
      </td>
      <td className="text__right table__important table__warning">
        <NumberFormat value={Balance} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={Promotion} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={InProgress} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={Complete} displayType="text" thousandSeparator />
      </td>
    </tr>
  );
}

export default ListTableItem;
