import React from 'react';

import { Helmet } from 'react-helmet';

import ResetPassword from '~/components/account/reset-password';

function ResetPasswordPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <ResetPassword />
    </>
  );
}

export default ResetPasswordPage;
