export const config = {
  headers: {
    accessToken: 'ad-access-token',
    custID: 'ad-custid',
    sessionKey: 'ad-sessionkey',
    timezoneOffset: 'ad-timezoneoffset',
  },
  storageKey: {
    accessToken: 'acessToken',
    user: 'user',
    language: 'language',
  },
  searchDay: 180,
  material:
    process.env.NODE_ENV === 'development'
      ? 'https://ad-hwkim-material.s3.ap-northeast-2.amazonaws.com'
      : process.env.REACT_APP_MAT_ADDR,
};
