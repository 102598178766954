import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import { useWindowResize } from '~/hooks/use-window-resize';

interface IHintProps {
  title?: string;
  content?: string;
}

const Icon = styled.i`
  display: inline-block !important;
  position: relative;
  top: 2px;
  color: #3f38d6;
  color: #808991 !important;
  cursor: pointer;
  padding-left: 3px;
  vertical-align: middle;
  z-index: 1;
  font-size: 18px !important;

  @media screen and (max-width: 1155px) {
    top: 0;
  }
`;

const HintItem = styled.div`
  z-index: 99;

  @media screen and (max-width: 599px) {
    &::after {
      content: '';
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 9;
    }
  }
`;

const HintItemClose = styled.div``;

const HintItemText = styled.div`
  width: 300px;
  height: auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  transition: 0.2s ease-out;
  white-space: normal;
  text-align: left;
  z-index: 4;

  span {
    padding-bottom: 8px;
    display: block;
  }

  ${HintItemClose} {
    position: relative;
    width: 100%;
    bottom: -10px;
    border-top: 1px solid #ebebeb;
    color: #1b1c23;
    cursor: pointer;
    text-align: center;
    padding: 16px 0 8px;
    margin-top: 10px;
    display: none;
  }

  @media screen and (max-width: 599px) {
    position: fixed;
    top: inherit;
    left: 0;
    bottom: 20px;
    width: calc(100% - 32px);
    margin: 0 16px;
    z-index: 99;

    ${HintItemClose} {
      display: none;
    }
  }
`;

const HintItemBg = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 98;
`;

function Hint({ title, content }: IHintProps) {
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [showMobilePopper, setShowMobilePopper] = useState<boolean>(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const hintRef = useRef<HTMLDivElement>(null);
  const mobileHintRef = useRef<HTMLDivElement>(null);
  const timeoutId = useRef<NodeJS.Timeout | undefined>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const [width] = useWindowResize();

  const hintListener = useCallback(
    (e: MouseEvent) => {
      if (hintRef.current === e.target) {
        setShowPopper(false);
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
      }
    },
    [timeoutId],
  );

  const showListener = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  }, [timeoutId]);

  const hideListener = useCallback(() => {
    const id = setTimeout(() => {
      setShowPopper(false);
    }, 2000);
    timeoutId.current = id;
  }, []);

  const mobileHintListener = useCallback((e: MouseEvent) => {
    if (mobileHintRef.current === e.target) {
      setShowMobilePopper(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', hintListener);

    if (popperElement) {
      popperElement.addEventListener('mouseover', showListener);
      popperElement.addEventListener('mouseout', hideListener);
    }

    return () => {
      window.removeEventListener('click', hintListener);

      if (popperElement) {
        popperElement.removeEventListener('mouseover', showListener);
        popperElement.removeEventListener('mouseout', hideListener);
      }
    };
  }, [hideListener, hintListener, popperElement, showListener]);

  useEffect(() => {
    window.addEventListener('click', mobileHintListener);

    return () => {
      window.removeEventListener('click', mobileHintListener);
    };
  }, [mobileHintListener, showMobilePopper]);

  const handleMobileClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowMobilePopper(true);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    const id = setTimeout(() => {
      setShowPopper(false);
    }, 2000);

    timeoutId.current = id;
    setShowPopper(true);
  };

  if (width < 600) {
    return (
      <>
        <Icon className="icon-info" onClick={handleMobileClick} />
        {showMobilePopper && (
          <HintItem ref={mobileHintRef}>
            <HintItemText className="xs_txt">
              <span>
                <b>
                  <FormattedMessage id={title} />
                </b>
              </span>
              <p>
                <FormattedHTMLMessage id={content} />
              </p>
            </HintItemText>
          </HintItem>
        )}
      </>
    );
  }

  return (
    <>
      <Icon
        className="icon-info"
        ref={setReferenceElement}
        onClick={handleClick}
      />
      {showPopper && (
        <>
          <HintItem
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <HintItemText className="xs_txt">
              <span>
                <b>
                  <FormattedMessage id={title} />
                </b>
              </span>
              <p>
                <FormattedHTMLMessage id={content} />
              </p>
            </HintItemText>
          </HintItem>
          <HintItemBg ref={hintRef} />
        </>
      )}
    </>
  );
}

export default Hint;
