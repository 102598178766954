import React from 'react';

import MediationIntro from './mediation01-intro';
import MediationContent from './mediation02-content';

function Game() {
  return (
    <>
      <MediationIntro />
      <MediationContent />
    </>
  );
}

export default Game;
