import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import moment from 'moment';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { adCampaignAPI } from '~/api';
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentBottom,
  OverlayContentTop,
  OverlayHeader,
  OverlayHeaderRight,
  OverlayHeaderTitle,
  OverlayTopWrap,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { country as countryOptions } from '~/json/country';
import { defaultAxios } from '~/libs/axios';
import { IResCampaignDetail } from '~/models/ad/campaign';
import { IResBase } from '~/models/res-base';
import { ISelectOption } from '~/models/select-option';
import { setIsFullLoader } from '~/modules/core';
import { setErrorType } from '~/modules/error';

import DetailCampaign from './detail01-campaign';
import DetailApp from './detail02-app';

interface IFormData {
  name: string;
  defaultCountry: ISelectOption[] | null;
  campType: string;
  defaultURL: string;
}

function Detail() {
  const [searchName, setSearchName] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(0);
  const [defaultCountry, setDefaultCountry] = useState<any>(null);
  const [campType, setCampType] = useState<string>('AOS');
  const [defaultURL, setDefaultURL] = useState<string>('');
  const [errorData, setErrorData] = useState<any>(null);

  const { id: campID } = useParams<any>();

  const [{ data, loading, error }] = useAxios<IResCampaignDetail>(
    {
      url: adCampaignAPI.get,
      method: 'POST',
      data: {
        campID,
      },
    },
    { useCache: false },
  );

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    clearError,
    setValue,
  } = useForm<IFormData>({
    submitFocusError: false,
  });

  const history = useHistory();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.push(`/ad-campaign?name=${searchName}&pageNo=${pageNo}`);
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    const qs = queryString.parse(location.search);

    setSearchName(String(qs.name || ''));
    setPageNo(Number(qs.pageNo || 1));

    register(
      { name: 'defaultCountry', type: 'custom' },
      { required: formatMessage({ id: 'validate.required' }) },
    );

    return () => {
      unregister('defaultCountry');
    };
  }, [formatMessage, location.search, register, unregister]);

  useEffect(() => {
    if (data) {
      const { campaign } = data;
      if (!campaign) {
        dispatch(setErrorType('NOT_FOUND'));
        return;
      }

      if (campaign.CampID) {
        const objCountry = JSON.parse(campaign.DefaultCountry);
        const { Country: tempArrCountry } = objCountry;

        const arrCountry = tempArrCountry.map((value: string) => {
          const temp = countryOptions.find(
            (c: ISelectOption) => c.value === value,
          );
          return temp;
        });

        setDefaultCountry(arrCountry);
        setCampType(campaign.CampType);

        setValue('name', campaign.Name);
        setValue('defaultCountry', arrCountry);
        if (campaign.CampType === 'IOS') {
          setValue('defaultURL', campaign.DefaultURL_iOS);
          setDefaultURL(campaign.DefaultURL_iOS);
        } else {
          setValue('defaultURL', campaign.DefaultURL_AOS);
          setDefaultURL(campaign.DefaultURL_AOS);
        }
      }
    }
  }, [data, dispatch, setValue]);

  const handleDefaultCountry = (selectedOption: ISelectOption[]) => {
    if (selectedOption === null || selectedOption.length === 0) {
      setError(
        'defaultCountry',
        'required',
        formatMessage({ id: 'validate.required' }),
      );
      setDefaultCountry(null);
      setValue('defaultCountry', null);
      return;
    }

    const index = selectedOption.findIndex(
      (o: ISelectOption) => o.value === 'Global',
    );

    let value = null;
    if (index === -1) {
      value = selectedOption;
    } else if (index === 0) {
      const { length } = selectedOption;
      if (length === 1) {
        value = selectedOption;
      } else {
        const filterOptions = selectedOption.filter(
          (o: ISelectOption) => o.value !== 'Global',
        );

        value = filterOptions;
      }
    } else {
      const filterOptions = selectedOption.filter(
        (o: ISelectOption) => o.value === 'Global',
      );

      value = filterOptions;
    }

    setDefaultCountry(value);
    setValue('defaultCountry', value);

    clearError('defaultCountry');
  };

  const onSubmit = async (formData: IFormData) => {
    const { name } = formData;

    let arrCountry: string[] = [];

    if (formData.defaultCountry) {
      arrCountry = formData.defaultCountry.map((o: ISelectOption) => o.value);
    }

    let defaultURL_iOS = '';
    let defaultURL_AOS = '';
    if (formData.campType === 'IOS') {
      defaultURL_iOS = formData.defaultURL;
    } else {
      defaultURL_AOS = formData.defaultURL;
    }

    if (window.confirm(formatMessage({ id: 'confirm.modify' }))) {
      try {
        const { data: modifyData } = await defaultAxios.post<IResBase>(
          adCampaignAPI.modify,
          {
            campID,
            name,
            defaultURL_iOS,
            defaultURL_AOS,
            defaultCountry: arrCountry,
          },
        );

        if (modifyData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.modify' }));
          history.push(
            `/ad-campaign?pageNo=${pageNo}&searchName=${searchName}`,
          );
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data || !data.campaign) {
    return null;
  }

  const { Name, Budget, RegistTime, UpdateTime } = data.campaign;

  return (
    <main className="console">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>캠페인 조회, 수정</legend>
          <OverlayContainer>
            <OverlayHeader>
              <i className="icon-x" onClick={handleClose} />
              <OverlayHeaderTitle className="m_txt">{Name}</OverlayHeaderTitle>
              <OverlayHeaderRight>
                <Link to={`/ad-ad?campID=${campID}`}>
                  <i className="icon-launchopen_in_new" />
                  <div className="btn__txt">
                    <FormattedMessage id="ad.campaign.goAd" />
                  </div>
                </Link>
                <button className="btn contentBtn">
                  <div className="btn__txt">
                    <FormattedMessage id="common.modify" />
                  </div>
                </button>
              </OverlayHeaderRight>
            </OverlayHeader>
            <OverlayContent>
              <OverlayContentTop>
                <OverlayTopWrap>
                  <h4 className="overlay__title m_tit">
                    <FormattedMessage id="ad.campaign.detail" />
                  </h4>
                  <ul className="overlay__top--detail">
                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.registTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(RegistTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>

                    <li>
                      <h5 className=" xs_tit">
                        <FormattedMessage id="common.updateTime" />
                      </h5>
                      <span className="m_txt">
                        {moment(UpdateTime).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </li>
                  </ul>
                </OverlayTopWrap>
              </OverlayContentTop>
              <OverlayContentBottom>
                <DetailCampaign
                  budget={Budget}
                  register={register}
                  errors={errors}
                />
                <DetailApp
                  countryOptions={countryOptions}
                  defaultCountry={defaultCountry}
                  campType={campType}
                  register={register}
                  errors={errors}
                  defaultURL={defaultURL}
                  handleDefaultCountry={handleDefaultCountry}
                />
              </OverlayContentBottom>
            </OverlayContent>
          </OverlayContainer>
        </fieldset>
      </form>
    </main>
  );
}

export default Detail;
