import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import qs from 'query-string';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { adAdAPI, adCampaignAPI } from '~/api';
import Pager from '~/components/common/pager';
import { ConsoleContent as DefaultConsoleContent } from '~/components/elements/console-content';
import { adtier0 } from '~/enums/adtier0';
import { useAdtier0Keys } from '~/hooks/use-adtier0-keys';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IAdListItem, IFilter, IResAdList } from '~/models/ad/ad';
import {
  ICampaignNameListItem,
  IResCampaignNameList,
} from '~/models/ad/campaign';
import { setIsFullLoader } from '~/modules/core';

import ListSearch from './list01-search';
import ListFilter from './list02-filter';
import ListTable from './list03-table';

const ConsoleContent = styled(DefaultConsoleContent as any)`
  padding-top: 0 !important;
`;

function List() {
  const [campID, setCampID] = useState<number>(0);
  const [filter, setFilter] = useState<IFilter>({
    name: '',
    campType: '',
    status: '',
    product: '',
    billingType: '',
  });
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<ICampaignNameListItem[] | null>(
    null,
  );
  const [ads, setAds] = useState<IAdListItem[] | null>(null);
  const [pageNo, setPageNo] = useState<number>(1);
  const [errorData, setErrorData] = useState<any>(null);

  const [statusOptions] = useAdtier0Keys(adtier0.PROMOTION_STATUS);
  const [productOptions] = useAdtier0Keys(adtier0.PRODUCT);
  const [blillingTypeOptions] = useAdtier0Keys(adtier0.BILLING_TYPE);

  const [{ data: campData, loading: campLoading, error: campError }] =
    useAxios<IResCampaignNameList>(
      {
        url: adCampaignAPI.listAll,
        method: 'GET',
      },
      { useCache: false },
    );

  const [{ data: adData, loading: adLoading, error: adError }, execute] =
    useAxios<IResAdList>(
      {
        url: adAdAPI.list,
        method: 'GET',
      },
      { useCache: false, manual: true },
    );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (campLoading || adLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [adLoading, campLoading, dispatch]);

  useEffect(() => {
    if (campData) {
      if (campData.campaigns) {
        setCampaigns(campData.campaigns);
      }
    }
  }, [campData]);

  useEffect(() => {
    const query = qs.parse(location.search);
    const qsCampID = Number(query.campID || 0);
    const qsName = String(query.name || '');
    const qsCampType = String(query.campType || '');
    const qsStatus = String(query.status || '');
    const qsProduct = String(query.product || '');
    const qsBillingType = String(query.billingType || '');
    const qsPageNo = Number(query.pageNo || 1);

    setFilter({
      name: qsName,
      campType: qsCampType,
      status: qsStatus,
      product: qsProduct,
      billingType: qsBillingType,
    });

    if (
      qsName !== '' ||
      qsCampType !== '' ||
      qsStatus !== '' ||
      qsProduct !== '' ||
      qsBillingType !== ''
    ) {
      setShowFilter(true);
    }

    setPageNo(qsPageNo);

    execute({
      params: {
        campID: qsCampID,
        name: qsName,
        campType: qsCampType,
        status: qsStatus,
        product: qsProduct,
        billingType: qsBillingType,
        pageNo: qsPageNo,
      },
    });
  }, [execute, location.search]);

  useEffect(() => {
    if (adData) {
      if (adData.ads) {
        setCampID(adData.campID);
        setAds(adData.ads);
      }
    }
  }, [adData]);

  const handleChangeCampID = (e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(
      `/ad-ad?campID=${e.target.value}&name=&campType=&status=&product=&billingType=&pageNo=`,
    );
  };

  const handleNewAd = () => {
    if (!campaigns) {
      window.alert(formatMessage({ id: 'ad.ad.emptyCampaign' }));
      return;
    }

    if (campaigns.length === 0) {
      window.alert(formatMessage({ id: 'ad.ad.emptyCampaign' }));
      return;
    }

    const { name, campType, status, product, billingType } = filter;

    history.push(
      `/ad-ad-create?campID=${campID}&name=${name}&campType=${campType}&status=${status}&product=${product}&billingType=${billingType}&pageNo=${pageNo}`,
    );
  };

  const handleShowFilter = () => {
    const value = !showFilter;
    setShowFilter(value);
  };

  const handleChangeFilter = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target } = e;

    if (target.name === 'product') {
      setFilter({
        ...filter,
        [target.name]: target.value,
        billingType: '',
      });
    } else {
      setFilter({
        ...filter,
        [target.name]: target.value,
      });
    }
  };

  const handleSearch = () => {
    const { name, campType, status, product, billingType } = filter;

    history.push(
      `/ad-ad?campID=${campID}&name=${name}&campType=${campType}&status=${status}&product=${product}&billingType=${billingType}&pageNo=1`,
    );
  };

  const handleChangeStatus = async (e: React.MouseEvent, proID: number) => {
    e.stopPropagation();
    if (!ads) {
      return;
    }

    const temp = ads.find((ad) => ad.ProID === proID);

    if (!temp) {
      return;
    }

    if (temp.Status === 'FIRST' || temp.Status === 'WAITING') {
      await handleStart(proID);
    } else {
      await handleStop(proID);
    }
  };

  const handleStart = async (proID: number) => {
    if (window.confirm(formatMessage({ id: 'confirm.start' }))) {
      try {
        const { data } = await defaultAxios.post(adAdAPI.start, {
          proID,
        });

        if (data.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.start' }));
          if (ads) {
            const arr = ads.map((ad) =>
              ad.ProID === proID ? { ...ad, Status: 'INPROGRESS' } : ad,
            );

            setAds(arr);
          }
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  const handleStop = async (proID: number) => {
    if (window.confirm(formatMessage({ id: 'confirm.stop' }))) {
      try {
        const { data } = await defaultAxios.post(adAdAPI.stop, {
          proID,
        });

        if (data.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.stop' }));
          if (ads) {
            const arr = ads.map((ad) =>
              ad.ProID === proID ? { ...ad, Status: 'WAITING' } : ad,
            );

            setAds(arr);
          }
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        setErrorData(err);
      }
    }
  };

  const handleDetail = (proID: number) => {
    const { name, campType, status, product, billingType } = filter;

    history.push(
      `/ad-ad/${proID}?campID=${campID}&name=${name}&campType=${campType}&status=${status}&product=${product}&billingType=${billingType}&pageNo=${pageNo}`,
    );
  };

  const handleChangePage = (no: number) => {
    const { name, status, product, billingType } = filter;

    setPageNo(no);

    history.push(
      `/ad-ad?campID=${campID}&name=${name}&status=${status}&product=${product}&billingType=${billingType}&pageNo=${no}`,
    );
  };

  if (campError) {
    if (campError.response) {
      if (campError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw campError;
  }

  if (adError) {
    if (adError.response) {
      if (adError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw adError;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!adData) {
    return null;
  }

  return (
    <>
      <ListSearch
        campID={campID}
        campaigns={campaigns}
        showFilter={showFilter}
        handleChangeCampID={handleChangeCampID}
        handleNewAd={handleNewAd}
        handleShowFilter={handleShowFilter}
      />
      <ListFilter
        filter={filter}
        showFilter={showFilter}
        statusOptions={statusOptions}
        productOptions={productOptions}
        billingTypeOptions={blillingTypeOptions}
        handleChangeFilter={handleChangeFilter}
        handleSearch={handleSearch}
      />
      <ConsoleContent>
        <ListTable
          ads={ads}
          handleChangeStatus={handleChangeStatus}
          handleDetail={handleDetail}
        />
        <Pager
          pageNo={pageNo}
          pageSize={10}
          blockPage={10}
          rowCount={adData.rowCount}
          handleChangePage={handleChangePage}
        />
      </ConsoleContent>
    </>
  );
}

export default List;
