import React from 'react';

import { FormattedMessage } from 'react-intl';

import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { ICustomer } from '~/models/account/my-page';

import AssetCash from './asset01-cash';
import AssetPayment from './asset02-payment';

interface IDefaultInfoProps {
  customer: ICustomer;
}

function DefaultInfo({ customer }: IDefaultInfoProps) {
  const { Cash } = customer;

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="admypage.asset" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <AssetCash cash={Cash} />

            <AssetPayment />
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DefaultInfo;
