import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Content,
  ContentSubContainer,
  ContentSubText,
} from '~/components/elements';

function HelpIntro() {
  return (
    <Content className="first">
      <ContentSubContainer>
        <ContentSubText>
          <span className="xs_tit">
            <FormattedMessage id="help.help" />
          </span>
          <h1 className="l_tit">
            <FormattedMessage id="help.title" />
          </h1>
          <h5 className="m_txt">
            <FormattedMessage id="help.subTitle" />
          </h5>
        </ContentSubText>
        <img src={require('~/assets/images/p10_main.png').default} alt="" />
      </ContentSubContainer>
    </Content>
  );
}

export default HelpIntro;
