import styled from 'styled-components';

export const ErrorContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;

  p {
    text-align: center;
  }

  span {
    margin: 1.5rem 0 2rem 0;
  }

  a {
    padding: 10px 20px;
  }
`;

export const ErrorItemImg = styled.div`
  max-width: 778px;
  width: 70%;
  height: auto;
  min-height: 1px;

  img {
    width: 100%;
    height: 100%;
  }
`;
