import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { accountAPI } from '~/api';
import ConfirmPassword from '~/components/common/confirm-password';
import {
  FormInput,
  FormInputContentWrap,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

interface IDefaultInfoCompanyProps {
  email: string;
  company: string;
}

interface IFormData {
  company: string;
}

function DefaultInfoCampany({ email, company }: IDefaultInfoCompanyProps) {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, getValues, setValue, handleSubmit } =
    useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    setValue('company', company);
  }, [company, setValue]);

  const onSubmit = useCallback(async () => {
    setOpenConfirm(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    setOpenConfirm(false);

    const { company: companyName } = getValues();

    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post(accountAPI.changeCompany, {
        company: companyName,
      });

      dispatch(setIsFullLoader(false));

      const { error } = data;

      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert(formatMessage({ id: 'alert.modify' }));
        if (user) {
          const { storageKey } = config;

          const newUser = { ...user };
          newUser.Company = companyName;

          dispatch(setUser(newUser));

          storage.set(storageKey.user, newUser);
        }
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  }, [dispatch, formatMessage, getValues, user]);

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>기본 정보 - 회사 이름 변경</legend>
          <FormInput>
            <FormInputTitleWrap>
              <FormInputTitleName className="m_txt">
                <FormattedMessage id="mypage.default.company" />
              </FormInputTitleName>
            </FormInputTitleWrap>
            <FormInputContentWrap>
              <FormInputField>
                <input
                  type="text"
                  name="company"
                  id="company"
                  placeholder="Company Name*"
                  ref={register({
                    required: formatMessage({
                      id: 'validate.required',
                    }),
                  })}
                />
                <label htmlFor="company">Company Name</label>
              </FormInputField>

              <button className="btn contentBtn">
                <FormattedMessage id="common.modify" />
              </button>
            </FormInputContentWrap>
            {errors.company && (
              <span className="warning__message s_txt">
                {errors.company.message}
              </span>
            )}
          </FormInput>
        </fieldset>
      </form>

      {openConfirm && (
        <ConfirmPassword
          email={email}
          openModal={openConfirm}
          handleRequestClose={handleCloseConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default DefaultInfoCampany;
