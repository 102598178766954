import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { StateContainer } from '~/components/elements/state';

interface IReportUserStateProps {
  dau: number;
  minDau: number;
  maxDau: number;
  nru: number;
  minNru: number;
  maxNru: number;
}

const StyledStateContainer = styled(StateContainer as any)`
  @media screen and (max-width: 599px) {
    > li {
      flex-wrap: wrap;
      height: 100%;
      min-height: 85px;
    }

    .stats--detail li {
      margin-left: auto;
    }
  }
`;

function ReportUserState({
  dau,
  minDau,
  maxDau,
  nru,
  minNru,
  maxNru,
}: IReportUserStateProps) {
  return (
    <StyledStateContainer>
      <li>
        <p className="m_txt">
          <FormattedMessage id="pub.report.dau" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat value={dau} displayType="text" thousandSeparator />
          </b>
        </span>
        <ul className="stats--detail xs_txt">
          <li>
            <span>Min</span>
            <NumberFormat value={minDau} displayType="text" thousandSeparator />
          </li>
          <li>
            <span>Max</span>
            <NumberFormat value={maxDau} displayType="text" thousandSeparator />
          </li>
        </ul>
      </li>
      <li>
        <p className="m_txt">
          <FormattedMessage id="pub.report.totalNru" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat value={nru} displayType="text" thousandSeparator />
          </b>
        </span>
        <ul className="stats--detail xs_txt">
          <li>
            <span>Min</span>
            <NumberFormat value={minNru} displayType="text" thousandSeparator />
          </li>
          <li>
            <span>Max</span>
            <NumberFormat value={maxNru} displayType="text" thousandSeparator />
          </li>
        </ul>
      </li>
    </StyledStateContainer>
  );
}

export default ReportUserState;
