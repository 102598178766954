import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import Hint from '~/components/common/hint';
import { TableContainer as DefaultTableContainer } from '~/components/elements/table';
import { IReportAdDailyListItem, IReportAdListItem } from '~/models/ad/report';

interface IReportAdListProps {
  proID: number;
  ads: IReportAdListItem[] | null;
  daily: IReportAdDailyListItem[] | null;
  handleChangeProID: (value: number) => void;
}

interface IReportAdListItemProps {
  proID: number;
  ad: IReportAdListItem;
  daily: IReportAdDailyListItem[] | null;
  handleChangeProID: (value: number) => void;
}

interface IReportDailyListItemProps {
  daily: IReportAdDailyListItem;
}

const TableContainer = styled(DefaultTableContainer as any)`
  th:first-child:not(.table__title) {
    min-width: 80px;
  }

  @media screen and (max-width: 1155px) {
    th:nth-child(2) {
      left: 60px;
      z-index: 1;
    }
  }

  @media screen and (max-width: 559px) {
    th:nth-child(2) {
      position: sticky;
      left: 60px;
      z-index: 4;
    }

    th:nth-child(2) {
      position: sticky;
      left: 80px;
      z-index: 4;
    }
  }
`;

function ReportAdList({
  proID,
  ads,
  daily,
  handleChangeProID,
}: IReportAdListProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="cursor__default" />
            <th className="text__left border__right active">
              <FormattedMessage id="ad.report.proName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.impression" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.click" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.ctrper" />
              <Hint title="hint.ctr" content="hint.ctr.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.execute" />
              (KRW)
              <i className="icon-drop-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          {ads &&
            ads.map((ad, index) => {
              if (ad.ProID !== 0) {
                return (
                  <ReportAdListItem
                    key={index}
                    proID={proID}
                    ad={ad}
                    daily={daily}
                    handleChangeProID={handleChangeProID}
                  />
                );
              }

              return null;
            })}
        </tbody>
      </table>
    </TableContainer>
  );
}

function ReportAdListItem({
  proID,
  ad,
  daily,
  handleChangeProID,
}: IReportAdListItemProps) {
  const { ProID, PromotionName, Impression, Click, CTR, Cost } = ad;

  return (
    <>
      <tr onClick={() => handleChangeProID(ProID)}>
        <th>
          <i className={proID === ProID ? 'depth__plus open' : 'depth__plus'} />
        </th>
        <th className="text__left border__right table__title">
          <span>{PromotionName}</span>
        </th>
        <td className="text__right">
          <NumberFormat
            value={Impression}
            displayType="text"
            thousandSeparator
          />
        </td>
        <td className="text__right">
          <NumberFormat value={Click} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">{CTR}</td>
        <td className="text__right table__important">
          <NumberFormat value={Cost} displayType="text" thousandSeparator />
        </td>
      </tr>
      {proID === ProID &&
        daily &&
        daily.map((d, index) => (
          <ReportAdDailyListItem key={index} daily={d} />
        ))}
    </>
  );
}

function ReportAdDailyListItem({ daily }: IReportDailyListItemProps) {
  const { RegistTime, Impression, Click, CTR, Cost } = daily;
  return (
    <tr className="table__depth">
      <th className="text__center" />
      <th className="text__left border__right table__title">
        <span>{RegistTime}</span>
      </th>
      <td className="text__right">
        <NumberFormat value={Impression} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={Click} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">{CTR}</td>
      <td className="text__right table__important">
        <NumberFormat value={Cost} displayType="text" thousandSeparator />
      </td>
      <td className="text__center" />
    </tr>
  );
}

export default ReportAdList;
