import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  OverlayContentBottom,
  OverlayItemSelect,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IBillingTypeProps {
  handleBillingType: (value: string) => void;
}

interface ICreateBillingTypeProps {
  product: string;
  handleBillingType: (value: string) => void;
}

function BannerBillingType({ handleBillingType }: IBillingTypeProps) {
  return (
    <OverlayContentBottom className="adType">
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">CPM</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.cpmDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('CPM')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPI</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpiDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPI')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPA</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpaDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPA')}
            disabled
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

function InterstitialBillingType({ handleBillingType }: IBillingTypeProps) {
  return (
    <OverlayContentBottom className="adType">
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">CPC</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.cpcDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('CPC')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPI</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpiDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPI')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPA</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpaDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPA')}
            disabled
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

function VideoBillingType({ handleBillingType }: IBillingTypeProps) {
  return (
    <OverlayContentBottom className="adType">
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">CPV</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.cpvDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('CPV')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPI</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpiDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPI')}
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>

      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">nCPA</h5>
        </OverlayItemWrapTitle>
        <OverlayItemSelect>
          <p className="m_txt">
            <FormattedMessage id="ad.ad.ncpaDesc" />
          </p>
          <button
            className="btn contentBtn btnLight"
            onClick={() => handleBillingType('nCPA')}
            disabled
          >
            <FormattedMessage id="common.select" />
          </button>
        </OverlayItemSelect>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

function CreateBillingType({
  product,
  handleBillingType,
}: ICreateBillingTypeProps) {
  if (product === 'BANNER') {
    return <BannerBillingType handleBillingType={handleBillingType} />;
  }

  if (product === 'INTERSTITIAL') {
    return <InterstitialBillingType handleBillingType={handleBillingType} />;
  }

  return <VideoBillingType handleBillingType={handleBillingType} />;
}

export default CreateBillingType;
