import React from 'react';

import { PropagateLoader } from 'react-spinners';
import styled from 'styled-components';

import HideScrollBar from './hide-scroll-bar';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30, 30, 30, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function FullLoader() {
  return (
    <>
      <HideScrollBar />
      <Wrapper>
        <PropagateLoader color="#36D7B7" sizeUnit="px" size={20} />
      </Wrapper>
    </>
  );
}

export default FullLoader;
