import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { ConsoleContent } from '~/components/elements/console-content';
import {
  SummaryContainer,
  SummaryItem,
  SummaryItemAddInfo,
  SummaryItemBottom,
  SummaryItemNotice,
  SummaryItemTop,
  SummaryItemWrap,
} from '~/components/elements/summary';
import { IApp, IMed, IRev } from '~/models/pub/home';

interface IHomeSummaryProps {
  app: IApp;
  med: IMed;
  rev: IRev;
  handleNewApp: () => void;
  handleActiveAllMedia: (active: number) => void;
  handleRevenuRequest: () => void;
}

function HomeSummary({
  app,
  med,
  rev,
  handleNewApp,
  handleActiveAllMedia,
  handleRevenuRequest,
}: IHomeSummaryProps) {
  return (
    <ConsoleContent>
      <h1 className="m_tit">
        <FormattedMessage id="pub.home.summary" />
      </h1>
      <SummaryContainer>
        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="pub.home.app" />
              </h5>
              <div className="btn contentBtn" onClick={handleNewApp}>
                <i className="icon-plus" />
                <div className="btn__txt">
                  <FormattedMessage id="pub.home.newapp" />
                </div>
              </div>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt">
                <FormattedMessage id="pub.home.all" />
              </p>
              <span className="ms_tit">
                <NumberFormat
                  value={app.apps}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </SummaryItemBottom>
            <SummaryItemAddInfo className="xs_txt">
              <li>
                <FormattedMessage id="pub.home.approval" />{' '}
                <b>
                  <NumberFormat
                    value={app.approved}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
            </SummaryItemAddInfo>
          </SummaryItem>
        </SummaryItemWrap>

        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="pub.home.inventory" />
              </h5>
              <div className="btn__group">
                <button
                  className="btn contentBtn btnClear"
                  onClick={() => handleActiveAllMedia(0)}
                >
                  <i className="icon-box-closed" />
                  <div className="btn__txt">
                    <FormattedMessage id="pub.home.allclose" />
                  </div>
                </button>
                <button
                  className="btn contentBtn"
                  onClick={() => handleActiveAllMedia(1)}
                >
                  <i className="icon-box-open" />
                  <div className="btn__txt">
                    <FormattedMessage id="pub.home.allopen" />
                  </div>
                </button>
              </div>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt">
                <FormattedMessage id="ad.home.all" />
              </p>
              <span className="ms_tit">
                <NumberFormat
                  value={med.meds}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </SummaryItemBottom>
            <SummaryItemAddInfo className="xs_txt">
              <li>
                <FormattedMessage id="pub.home.open" />{' '}
                <b>
                  <NumberFormat
                    value={med.opened}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
              <li>
                <FormattedMessage id="pub.home.close" />{' '}
                <b>
                  <NumberFormat
                    value={med.closed}
                    displayType="text"
                    thousandSeparator
                  />
                </b>
              </li>
            </SummaryItemAddInfo>
          </SummaryItem>
        </SummaryItemWrap>

        <SummaryItemWrap>
          <SummaryItem>
            <SummaryItemTop>
              <h5 className="xl_txt">
                <FormattedMessage id="pub.home.revenueAmount" />
              </h5>
              <div className="btn contentBtn" onClick={handleRevenuRequest}>
                <i className="icon-assignment" />
                <div className="btn__txt">
                  <FormattedMessage id="pub.home.revenueRequest" />
                </div>
              </div>
            </SummaryItemTop>
            <SummaryItemBottom>
              <p className="m_txt" />
              <span className="ms_tit">
                <NumberFormat
                  value={rev.revenue}
                  displayType="text"
                  thousandSeparator
                />
                <span className="s_tit krw">KRW</span>
              </span>
            </SummaryItemBottom>
          </SummaryItem>
          {rev.canRequest === 1 && (
            <SummaryItemNotice to="/pub-my-page-request" className="blue">
              <div className="btn__group xs_tit">
                <i className="icon-warningreport_problem" />
                <div className="btn_txt">
                  <FormattedMessage id="pub.home.revenueRequestDesc" />
                </div>
              </div>
              <span className="xs_txt">
                <FormattedMessage id="pub.home.goRequest" />
              </span>
            </SummaryItemNotice>
          )}
        </SummaryItemWrap>
      </SummaryContainer>
    </ConsoleContent>
  );
}

export default HomeSummary;
