import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { accountAPI } from '~/api';
import TitleInput from '~/components/base/title-input';
import VCode from '~/components/common/vcode';
import {
  FormInput,
  FormInputContainer,
  FormInputContentWrap,
  FormInputField,
  FormInputGroup,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { Select, selectStyles } from '~/components/elements/select';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { callingCode as numberOptions } from '~/json/calling-code';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { numberPattern } from '~/libs/regexp';
import { storage } from '~/libs/storage';
import { ISelectOption } from '~/models/select-option';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

import Upload from './upload';

interface IRevenueDocFormProps {
  handleCancel: () => void;
}

interface IFormData {
  name: string;
  bank: string;
  number: string;
  countryCode: string;
  mobileNumber: string;
}

const REACT_APP_CLIENT_ENV = process.env.REACT_APP_CLIENT_ENV
  ? process.env.REACT_APP_CLIENT_ENV.toLocaleLowerCase()
  : '';

const MAT_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://s3.ap-northeast-2.amazonaws.com/ad-hwkim-material'
    : `https://s3.ap-northeast-2.amazonaws.com/ad-${REACT_APP_CLIENT_ENV}-material`;

function RevenueDocForm({ handleCancel }: IRevenueDocFormProps) {
  const [serverBankBook, setServerBankBook] = useState<string>('');
  const [serverCertificate, setServerCertificate] = useState<string>('');
  const [bankBook, setBankBook] = useState<any>(null);
  const [certificate, setCertificate] = useState<any>(null);
  const [code, setCode] = useState<ISelectOption | null>(null);
  const [isData, setIsData] = useState<boolean>(false);
  const [openVCode, setOpenVCode] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, handleSubmit, errors, getValues, setValue } =
    useForm<IFormData>({
      submitFocusError: false,
    });

  const [{ data, loading, error }] = useAxios(
    {
      url: accountAPI.getAccount,
      method: 'POST',
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    register({ name: 'countryCode', type: 'custom' });
  }, [register]);

  useEffect(() => {
    if (data) {
      if (data.error === 'SUCCESS') {
        if (data.account) {
          const { account } = data;
          setValue('name', account.Name);
          setValue('bank', account.Bank);
          setValue('number', account.Number);

          if (account.CountryCode === '') {
            setValue('countryCode', numberOptions[119].value);
            setCode(numberOptions[119]);
          } else {
            const obj = numberOptions.find(
              (o: ISelectOption) => o.value === account.CountryCode,
            );

            setValue('countryCode', account.CountryCode);
            if (!obj) {
              setCode(null);
            } else {
              setCode(obj);
            }
          }

          setValue('mobileNumber', account.MobileNumber);

          setServerBankBook(account.BankBook);
          setServerCertificate(account.Certificate);

          if (account.CustID) {
            setIsData(true);
          }
        }
      } else {
        setValue('countryCode', numberOptions[119].value);
        setCode(numberOptions[119]);
      }
    }
  }, [data, register, setValue]);

  const handleBankBook = (value: any) => {
    setBankBook(value);
  };

  const handleCertificate = (value: any) => {
    setCertificate(value);
  };

  const handleChangeCode = (selected: ISelectOption) => {
    setValue('countryCode', selected.value);
    setCode(selected);
  };

  const onSubmit = () => {
    if (isData) {
      if (serverBankBook === '') {
        if (bankBook === null) {
          window.alert(
            formatMessage({ id: 'pubmypage.revenueGive.doc.bankBookError' }),
          );
          return;
        }
      }

      if (serverCertificate === '') {
        if (certificate === null) {
          window.alert(
            formatMessage({ id: 'pubmypage.revenueGive.doc.certificateError' }),
          );
          return;
        }
      }
    } else {
      if (bankBook === null) {
        window.alert(
          formatMessage({ id: 'pubmypage.revenueGive.doc.bankBookError' }),
        );
        return;
      }

      if (certificate === null) {
        window.alert(
          formatMessage({ id: 'pubmypage.revenueGive.doc.certificateError' }),
        );
        return;
      }
    }

    setOpenVCode(true);
  };

  const handleCloseVCode = () => {
    setOpenVCode(false);
  };

  const handleCallback = async () => {
    const { name, bank, number, countryCode, mobileNumber } = getValues();

    try {
      dispatch(setIsFullLoader(true));

      if (isData) {
        const temp = [];

        if (bankBook) {
          temp.push(bankBook);
        }

        if (certificate) {
          temp.push(certificate);
        }

        const all = await Promise.all(
          temp.map(
            async (item) =>
              await defaultAxios.put(item.uploadURL, item.file, {
                headers: {
                  'Content-Type': item.file.type,
                },
              }),
          ),
        );

        all.forEach((res) => {
          const { status } = res;
          if (status !== 200) {
            throw new Error();
          }
        });

        const { data: modifyData } = await defaultAxios.post(
          accountAPI.modifyAccount,
          {
            name,
            bank,
            number,
            bankBook: bankBook ? bankBook.filename : serverBankBook,
            certificate: certificate ? certificate.filename : serverCertificate,
            countryCode,
            mobileNumber,
          },
        );

        if (modifyData.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.modify' }));

          if (user) {
            const { storageKey } = config;

            const newUser = { ...user };
            newUser.CountryCode = countryCode;
            newUser.MobileNumber = mobileNumber;

            dispatch(setUser(newUser));

            storage.set(storageKey.user, newUser);
          }

          history.push('/pub-my-page');
        } else {
          window.alert(formatMessage({ id: `errors.${modifyData.error}` }));
        }
      } else {
        const temp = [bankBook, certificate];
        const all = await Promise.all(
          temp.map(
            async (item) =>
              await defaultAxios.put(item.uploadURL, item.file, {
                headers: {
                  'Content-Type': item.file.type,
                },
              }),
          ),
        );

        all.forEach((res) => {
          const { status } = res;
          if (status !== 200) {
            throw new Error();
          }
        });

        const { data: modifyData } = await defaultAxios.post(
          accountAPI.modifyAccount,
          {
            name,
            bank,
            number,
            bankBook: bankBook.filename,
            certificate: certificate.filename,
            countryCode,
            mobileNumber,
          },
        );

        if (modifyData.error === 'SUCCESS') {
          window.alert(formatMessage({ id: 'alert.modify' }));

          if (user) {
            const { storageKey } = config;

            const newUser = { ...user };
            newUser.CountryCode = countryCode;
            newUser.MobileNumber = mobileNumber;

            setUser(user);

            storage.set(storageKey.user, newUser);
          }

          history.push('/pub-my-page');
        } else {
          window.alert(formatMessage({ id: `errors.${modifyData.error}` }));
        }
      }

      dispatch(setIsFullLoader(false));
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>수입금 지급 - 서류 제출</legend>
          <OverlayContentBottom>
            <OverlayItemWrap>
              <OverlayItemWrapTitle>
                <h5 className="s_tit">
                  <FormattedMessage id="pubmypage.revenueGive.doc" />
                </h5>
              </OverlayItemWrapTitle>
              <OverlayItem>
                <NoFloating>
                  <FormInputContainer>
                    <TitleInput
                      title={formatMessage({
                        id: 'pubmypage.revenueGive.doc.name',
                      })}
                      required
                      error={errors.name}
                    >
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="name">
                        <FormattedMessage id="pubmypage.revenueGive.doc.name" />
                      </label>
                    </TitleInput>

                    <TitleInput
                      title={formatMessage({
                        id: 'pubmypage.revenueGive.doc.bank',
                      })}
                      required
                      error={errors.bank}
                    >
                      <input
                        type="text"
                        name="bank"
                        id="bank"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="bank">
                        <FormattedMessage id="pubmypage.revenueGive.doc.bank" />
                      </label>
                    </TitleInput>

                    <TitleInput
                      title={formatMessage({
                        id: 'pubmypage.revenueGive.doc.bankNum',
                      })}
                      required
                      error={errors.number}
                    >
                      <input
                        type="text"
                        name="number"
                        id="number"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                          validate: {
                            onlynum: (value) =>
                              numberPattern.test(value) ||
                              formatMessage({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="number">
                        <FormattedMessage id="pubmypage.revenueGive.doc.bankNum" />
                      </label>
                    </TitleInput>

                    <FormInput>
                      <FormInputTitleWrap>
                        <FormInputTitleName className="m_txt">
                          <FormattedMessage id="mypage.addinfo.mobile" />
                        </FormInputTitleName>
                      </FormInputTitleWrap>
                      <FormInputContentWrap className="vertical">
                        <FormInputGroup>
                          <FormInputField>
                            <Select
                              name="countryCode"
                              placeholder="Country code"
                              styles={selectStyles}
                              isClearable={false}
                              isSearchable
                              options={numberOptions}
                              value={code}
                              onChange={handleChangeCode}
                            />
                          </FormInputField>

                          <FormInputField>
                            <input
                              type="text"
                              name="mobileNumber"
                              id="mobileNumber"
                              placeholder="Mobile phone number*"
                              ref={register({
                                required: formatMessage({
                                  id: 'validate.required',
                                }),
                                validate: {
                                  onlynum: (value) =>
                                    numberPattern.test(value) ||
                                    formatMessage({ id: 'validate.onlyNum' }),
                                },
                              })}
                            />
                            <label htmlFor="mobileNumber">
                              Mobile phone number
                            </label>
                          </FormInputField>
                          {errors.mobileNumber && (
                            <span className="warning__message s_txt">
                              {errors.mobileNumber.message}
                            </span>
                          )}
                        </FormInputGroup>
                      </FormInputContentWrap>
                    </FormInput>

                    <Upload
                      title={formatMessage({
                        id: 'pubmypage.revenueGive.doc.bankBook',
                      })}
                      required
                      matURL={MAT_URL}
                      folder="bankbook"
                      filename={serverBankBook}
                      handleFile={handleBankBook}
                    />

                    <Upload
                      title={formatMessage({
                        id: 'pubmypage.revenueGive.doc.certificate',
                      })}
                      required
                      matURL={MAT_URL}
                      folder="certificate"
                      filename={serverCertificate}
                      handleFile={handleCertificate}
                    />
                  </FormInputContainer>
                </NoFloating>
              </OverlayItem>
            </OverlayItemWrap>
            <div className="btn__group">
              <button
                type="button"
                className="btn contentBtn btnClear"
                onClick={handleCancel}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.cancel" />
                </div>
              </button>
              <button type="submit" className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.modify" />
                </div>
              </button>
            </div>
          </OverlayContentBottom>
        </fieldset>
      </form>
      {openVCode && (
        <VCode
          openModal={openVCode}
          handleRequestClose={handleCloseVCode}
          handleClose={handleCloseVCode}
          handleCallback={handleCallback}
        />
      )}
    </>
  );
}

export default RevenueDocForm;
