import React from 'react';

import { FormInput } from '~/components/elements/form';

interface IProps {
  children: React.ReactNode;
}

function BasicCheck({ children }: IProps) {
  return <FormInput>{children}</FormInput>;
}

export default BasicCheck;
