import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContainerGap, Content } from '~/components/elements';

const AppContainer = styled(ContainerGap as any)`
  display: flex;
  flex-wrap: wrap;
`;

const AppItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(33.33% - 13.33px);
  height: 100%;
  max-height: 78px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: 0.4s ease;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:hover {
    i {
      transform: translate(0, -50%);
    }
  }

  i {
    position: absolute;
    top: 50%;
    right: 20%;
    color: #1b1c23;
    transform: translate(0, 400%);
    transition: 0.3s ease;
    display: block;
  }

  img {
    width: 21%;
    min-width: 21%;
    height: 100%;
    margin-right: 1rem;
    border-radius: 25%;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    margin-right: 0;

    img {
      width: 15%;
      min-width: 15%;
      margin-right: 16px;
    }

    i {
      display: none;
    }
  }
`;

const AppItemText = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`;

function GameContent() {
  return (
    <Content>
      <h3 className="m_tit">
        <FormattedMessage id="app.app" />
      </h3>

      <AppContainer>
        <AppItem
          href="https://play.google.com/store/apps/details?id=com.rhaon.ad_skip"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('~/assets/images/p7_app-adSkip.png').default}
            alt=""
          />
          <AppItemText>
            <h5 className="xs_tit">Ad Skip</h5>
            <p className="xs_txt">Tool</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>

        <AppItem
          href="https://play.google.com/store/apps/details?id=com.sinest.todaycoin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('~/assets/images/p7_app-adapter.png').default}
            alt=""
          />
          <AppItemText>
            <h5 className="xs_tit">Adapter</h5>
            <p className="xs_txt">BlockChain Content Platform</p>
            <i className="icon-launchopen_in_new" />
          </AppItemText>
        </AppItem>
      </AppContainer>
    </Content>
  );
}

export default GameContent;
