import React from 'react';

import { FormattedMessage } from 'react-intl';

import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { ICustomer } from '~/models/account/my-page';

import DefaultInfoEmail from './default-info01-email';
import DefaultInfoPassword from './default-info02-password';
import DefaultInfoName from './default-info03-name';
import DefaultInfoCompany from './default-info04-company';
import DefaultInfoTimeZone from './default-info05-time-zone';

interface IDefaultInfoProps {
  customer: ICustomer;
}

function DefaultInfo({ customer }: IDefaultInfoProps) {
  const { Email, FirstName, LastName, Company, TimezoneName } = customer;

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="mypage.default" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <DefaultInfoEmail email={Email} />

            <DefaultInfoPassword />

            <DefaultInfoName
              email={Email}
              firstName={FirstName}
              lastName={LastName}
            />

            <DefaultInfoCompany email={Email} company={Company} />

            <DefaultInfoTimeZone timezoneName={TimezoneName} />
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DefaultInfo;
