import React from 'react';

import { Helmet } from 'react-helmet';

import Signin from '~/components/account/signin';

function SigninPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Signin />
    </>
  );
}

export default SigninPage;
