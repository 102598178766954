import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  ListSection,
  ListSectionContainer,
  ListSectionFile,
  ListSectionItem,
  ListSectionItemFile,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements/list-section';

function ResourceDoc1() {
  return (
    <>
      <ListSection>
        <h3 className="m_tit">
          <FormattedMessage id="resource.introduce" />
        </h3>
        <ListSectionContainer>
          <ListSectionItem>
            <ListSectionItemImg>
              <img src={require('~/assets/images/p3_1.png').default} alt="" />
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="resource.business" />
              </h4>
              <p className="s_txt">
                <FormattedMessage id="resource.businessDesc" />
              </p>
            </ListSectionItemText>
            <ListSectionItemFile>
              <ListSectionFile>
                <h5 className="xs_tit">English</h5>
                <a
                  href="https://resource.zenaad.com/Introduction-Business-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
              <ListSectionFile>
                <h5 className="xs_tit">
                  <FormattedMessage id="language.ko" />
                </h5>
                <a
                  href="https://resource.zenaad.com/Introduction-Business-ko.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
            </ListSectionItemFile>
          </ListSectionItem>

          <ListSectionItem>
            <ListSectionItemImg>
              <img src={require('~/assets/images/p3_2.png').default} alt="" />
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="resource.media" />
              </h4>
              <p className="s_txt">
                <FormattedMessage id="resource.mediaDesc" />
              </p>
            </ListSectionItemText>
            <ListSectionItemFile>
              <ListSectionFile>
                <h5 className="xs_tit">English</h5>
                <a
                  href="https://resource.zenaad.com/Introduction-Media-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
              <ListSectionFile>
                <h5 className="xs_tit">
                  <FormattedMessage id="language.ko" />
                </h5>
                <a
                  href="https://resource.zenaad.com/Introduction-Media-ko.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
            </ListSectionItemFile>
          </ListSectionItem>
        </ListSectionContainer>
      </ListSection>
    </>
  );
}

export default ResourceDoc1;
