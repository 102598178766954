import React from 'react';

import HelpIntro from './help01-intro';
import HelpContent from './help02-content';

function Help() {
  return (
    <>
      <HelpIntro />
      <HelpContent />
    </>
  );
}

export default Help;
