import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FormInputContainer } from '~/components/elements/form';

export const Sign = styled.main`
  min-height: 100vh;
  background-color: #fbfbfb;
  padding-bottom: 7.5rem;
`;

export const SignContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 470px;
  margin: 0 auto;

  h3 {
    font-size: 21px;
    font-weight: 300;
    color: #1b1c23;
    text-align: center;
    padding-bottom: 20px;
    word-break: keep-all;
  }

  img {
    padding: 1rem 0 2rem;
  }

  .findEmail__text {
    margin-bottom: 20px;
  }

  ${FormInputContainer} {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
  }
`;

export const SignLog = styled(Link)`
  width: 40%;
  margin-bottom: 10%;
`;

export const MenuList = styled.ul`
  align-self: flex-start;
`;

export const MenuItem = styled.li`
  padding-top: 10px;

  &:first-child {
    padding-top: 20px;
  }

  a {
    color: #3f38d6;
  }
`;

export const SignEmailContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 40px;
  padding: 40px 0;
  border-top: 1px solid #ebebeb;

  h4 {
    padding-bottom: 0.8rem;
  }

  p {
    padding: 0.5rem 0;

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      text-align: center;
      background-color: #3f38d6;
      color: #ffffff;
      margin-right: 0.5rem;
    }

    a {
      color: #3f38d6;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 359px) {
    letter-spacing: -0.4px;
  }
`;

export const SignFinContainer = styled.div`
  text-align: center;
  padding-bottom: 3rem;

  h5 {
    font-size: 1.6rem;
    word-break: keep-all;
  }

  span {
    color: #1b1c23;
  }

  @media screen and (max-width: 599px) {
    h5 {
      font-size: 4rem;
      padding-bottom: 1rem;
    }
  }
`;

export const FindEmailContainer = styled.div``;
