import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { accountAPI } from '~/api';
import BasicInput from '~/components/base/basic-input';
import { Content } from '~/components/elements/content';
import { FormInputContainer } from '~/components/elements/form';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { storage } from '~/libs/storage';
import { IResSignin } from '~/models/account/signin';
import { setIsFullLoader } from '~/modules/core';
import { setUser } from '~/modules/user';

import { MenuItem, MenuList, Sign, SignContainer, SignLog } from '../elements';

interface IFormData {
  email: string;
  pw: string;
}

function Signin() {
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, handleSubmit } = useForm<IFormData>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(false));
  }, [dispatch]);

  const onSubmit = async ({ email, pw }: IFormData) => {
    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post<IResSignin>(accountAPI.signin, {
        email: email.trim(),
        pw,
      });

      dispatch(setIsFullLoader(false));

      const { error } = data;

      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        const { accessToken, user } = data;
        const { headers, storageKey } = config;

        dispatch(setUser(user));

        storage.set(storageKey.accessToken, accessToken);
        storage.set(storageKey.user, user);

        defaultAxios.defaults.headers.common[headers.accessToken] = accessToken;
        defaultAxios.defaults.headers.common[headers.custID] = user.CustID;
        defaultAxios.defaults.headers.common[headers.sessionKey] =
          user.SessionKey;
        defaultAxios.defaults.headers.common[headers.timezoneOffset] =
          user.TimezoneOffset;

        const qs = queryString.parse(location.search);
        const returnURL = String(qs.returnURL || '');
        const type = String(qs.type || '');

        if (returnURL !== '') {
          if (user.Type === type) {
            history.push(returnURL);
          } else if (user.Type === 'ADVERTISER') {
            history.push('/ad');
          } else {
            history.push('/pub');
          }
        } else if (user.Type === 'ADVERTISER') {
          history.push('/ad');
        } else {
          history.push('/pub');
        }
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      // logger.error('signin error', prettyJson(err));

      // if (err.response) {
      //   logger.error('signin error response', prettyJson(err.response));
      // }

      // dispatch(setIsFullLoader(false));

      // window.alert(formatMessage({ id: 'errors.UNKNOWN' }));

      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (errorData) {
    throw errorData;
  }

  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>로그인</legend>
              <FormInputContainer>
                <BasicInput error={errors.email}>
                  <input
                    name="email"
                    id="email"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="email">Email</label>
                </BasicInput>

                <BasicInput error={errors.pw}>
                  <input
                    type="password"
                    name="pw"
                    id="pw"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="pw">Password</label>
                </BasicInput>
              </FormInputContainer>
              <button className="btn">
                <FormattedMessage id="signin.signin" />
              </button>
            </fieldset>
          </form>

          <MenuList className="s_txt">
            <MenuItem>
              <FormattedHTMLMessage id="signin.joinMessage" />
              &nbsp;
              <Link to="/signup-email">
                <b>
                  <FormattedMessage id="signin.join" />
                </b>
              </Link>
            </MenuItem>
            <MenuItem>
              <FormattedHTMLMessage id="signin.findEmailMessage" />
              &nbsp;
              <Link to="/find-email">
                <b>
                  <FormattedMessage id="signin.findEmail" />
                </b>
              </Link>
            </MenuItem>
            <MenuItem>
              <FormattedHTMLMessage id="signin.findPasswordMessage" />
              &nbsp;
              <Link to="/find-password">
                <b>
                  <FormattedMessage id="signin.findPassword" />
                </b>
              </Link>
            </MenuItem>
          </MenuList>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default Signin;
