import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Content,
  ListSection,
  ListSectionContainer,
  ListSectionItem as DefaultListSectionItem,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements';

const ListSectionItem = styled(DefaultListSectionItem as any)`
  p {
    &.l_txt {
      padding: 0.5rem 0 1.5rem 0;
      transition: 0.3s ease;
    }
  }

  @media screen and (max-width: 599px) {
    p {
      &.l_txt {
        min-height: 6rem;
        padding: 0.625rem 0 0rem 0;
      }
    }
  }
`;

function PublisherContent() {
  return (
    <Content>
      <ListSection>
        <h3 className="m_tit">
          <FormattedMessage id="publisher.publisher" />
        </h3>
        <ListSectionContainer>
          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('https://itam.games/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher1.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.itam" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.itam.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.itam.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://www.rhaon.co.kr/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher2.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.rhaon" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.rhaon.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.rhaon.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://sinest.co.kr/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher6.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.sinest" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.sinest.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.sinest.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://www.kaient.co.kr/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher3.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.kai" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.kai.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.kai.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://www.3ffactory.net/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher4.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.three" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.three.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.three.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>

          <ListSectionItem
            className="cursor__pointer"
            onClick={() => {
              window.open('http://www.wenee.net/', '_blank');
            }}
          >
            <ListSectionItemImg>
              <span>
                <img
                  src={require('~/assets/images/p9_publisher5.png').default}
                  alt=""
                />
              </span>
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="publisher.weneed" />
                <i className="icon-launchopen_in_new" />
              </h4>
              <p className="l_txt">
                <FormattedMessage id="publisher.weneed.title" />
              </p>
              <p className="s_txt">
                <FormattedMessage id="publisher.weneed.subTitle" />
              </p>
            </ListSectionItemText>
          </ListSectionItem>
        </ListSectionContainer>
      </ListSection>
    </Content>
  );
}

export default PublisherContent;
