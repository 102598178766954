import styled from 'styled-components';

export const ConsoleContent = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1660px;
  height: auto;
  margin: 0 auto;
  padding: 7.5rem 0;

  i.icon-chevron-down {
    color: #666666 !important;
  }

  > button,
  > a {
    margin: 0 0 2rem;
    margin-left: auto;
    align-self: flex-end;
    flex: 1 0 auto;
  }

  @media screen and (max-width: 1659px) {
    width: 100%;
    padding: 4rem 24px;
  }

  @media screen and (max-width: 599px) {
    padding: 50px 16px;
  }
`;
