import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Content } from '~/components/elements/content';

function Start01() {
  return (
    <Content className="first">
      <h1 className="l_tit">
        <FormattedMessage id="start.title" />
      </h1>
      <img src={require('~/assets/images/p2_main.png').default} alt="" />
    </Content>
  );
}

export default Start01;
