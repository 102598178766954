import styled from 'styled-components';

import { FormInput, FormInputContainer, FormInputField } from './form';
import { NoFloating } from './no-floating';

export const FilterContainer = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 1155px) {
    flex-direction: column;
  }
`;

export const DateFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;

  > span {
    text-align: right;
    color: #808991;
    padding-top: 8px;
    margin-left: auto;
    display: block;
  }

  button {
    margin-left: 0.5rem;
    white-space: nowrap;
  }

  input {
    text-align: center;
  }

  @media screen and (max-width: 1155px) {
    height: 100%;
    padding-bottom: 5rem;

    ${NoFloating} {
      width: auto;
    }

    ${FormInputField} {
      width: auto;
    }

    button {
      width: auto;
      margin: 0 0 0 0.5rem;
    }
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
    padding-bottom: 5rem;

    input {
      max-width: 100%;
    }

    button {
      margin: 0 auto;
      margin-top: 1rem;
      width: 100%;
    }

    > span {
      order: 1;
      padding-top: 0;
    }
  }
`;

export const DateFilterWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    width: 240px;
    height: 42px;
    align-items: center;
    padding: 0 1rem;

    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 1px solid #d1d5d9;
      height: 100%;
      border-right: 0;
      white-space: nowrap;
      cursor: pointer;

      &:first-child {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border: 1px solid #d1d5d9;
        border-radius: 0 2px 2px 0;
      }

      &.active {
        color: #3f38d6;
        border: 1px solid #3f38d6;
      }
    }
  }

  form {
    position: relative;
    width: auto;
    height: auto;
    justify-content: flex-end;
  }

  i {
    &.icon-calendar_today {
      display: none;
    }
  }

  ${FormInputContainer} {
    position: relative;
    align-items: flex-start;
    flex-wrap: nowrap;
    height: 38px;

    span {
      &.date__bar {
        width: auto;
        height: 100%;
        background-color: #fff;
        padding: 0 1rem;
        line-height: 42px;
      }
    }
  }

  ${NoFloating} {
    ${FormInputField} {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 1155px) {
    flex-direction: row;

    ${FormInputContainer} {
      width: auto;
    }

    ul {
      min-width: 100px;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    flex: 1 0 auto;
    height: 100%;
    order: 2;

    ${FormInput} {
      height: 100%;
    }

    ul {
      flex: 1 0 auto;
      max-width: 100%;
      width: 100%;
      height: 40px;
      padding: 0;
      margin: 8px 0;
    }
  }
`;
