import React from 'react';

import SdkIntro from './sdk01-intro';
import SdkContent from './sdk02-content';

function Sdk() {
  return (
    <>
      <SdkIntro />
      <SdkContent />
    </>
  );
}

export default Sdk;
