import React from 'react';

import Start01 from './start01';
import Start02 from './start02';
import Start03 from './start03';

function Start() {
  return (
    <>
      <Start01 />
      <Start02 />
      <Start03 />
    </>
  );
}

export default Start;
