import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

import RevenueDetails from './revenue00-details';
import RevenueDoc from './revenue01-doc';
import RevenueRequest from './revenue02-request';
import RevenueRequestList from './revenue03-request-list';

interface IRevenueProps {
  isAccount: number;
  revenue: number;
}

function Revenue({ isAccount, revenue }: IRevenueProps) {
  const history = useHistory();

  const handleClickRevenueDetails = () => {
    history.push('/pub-my-page-revenue-details');
  };

  const handleClickDoc = () => {
    history.push('/pub-my-page-doc');
  };

  const handleClickRequest = () => {
    history.push('/pub-my-page-request');
  };

  const handleClickRequestList = () => {
    history.push('/pub-my-page-request-list');
  };

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="pubmypage.revenueGive" />
        </h5>
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <RevenueDetails
              handleClickRevenueDetails={handleClickRevenueDetails}
            />

            <RevenueDoc isAccount={isAccount} handleClickDoc={handleClickDoc} />

            <RevenueRequest
              revenue={revenue}
              handleClickRequest={handleClickRequest}
            />

            <RevenueRequestList
              handleClickRequestList={handleClickRequestList}
            />
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default Revenue;
