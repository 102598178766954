import React from 'react';

import { Helmet } from 'react-helmet';

import Detail from '~/components/pub/app/detail';

function AppDetailPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Detail />
    </>
  );
}

export default AppDetailPage;
