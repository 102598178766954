import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { adHomeAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import {
  IPromotion,
  IResAdHome,
  IResStartAllPromotion,
  IResStopAllPromotion,
} from '~/models/ad/home';
import { setIsFullLoader } from '~/modules/core';

import HomeNetwork from './home01-network';
import HomeSummary from './home02-summary';

function Home() {
  const [promotion, setPromotion] = useState<IPromotion | null>(null);
  const [errorData, setErrorData] = useState<any>(null);

  const [{ data, loading, error }] = useAxios<IResAdHome>(
    {
      url: adHomeAPI.home,
      method: 'POST',
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      setPromotion(data.promotion);
    }
  }, [data]);

  const stopAllPromotion = async () => {
    if (window.confirm(formatMessage({ id: 'confirm.allStop' }))) {
      try {
        dispatch(setIsFullLoader(true));

        const { data: stopData } =
          await defaultAxios.post<IResStartAllPromotion>(
            adHomeAPI.stopAllPromotion,
          );

        dispatch(setIsFullLoader(false));

        if (stopData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.stop' }));
          setPromotion(stopData.promotion);
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    }
  };

  const startAllPromotion = async () => {
    if (window.confirm(formatMessage({ id: 'confirm.allStart' }))) {
      try {
        dispatch(setIsFullLoader(true));

        const { data: startData } =
          await defaultAxios.post<IResStopAllPromotion>(
            adHomeAPI.startAllPromotion,
          );

        dispatch(setIsFullLoader(false));

        if (startData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          window.alert(formatMessage({ id: 'alert.start' }));
          setPromotion(startData.promotion);
        } else {
          window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    }
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data) {
    return null;
  }

  if (!promotion) {
    return null;
  }

  return (
    <>
      <HomeNetwork data={data} />
      <DivisionLine />
      <HomeSummary
        data={data}
        promotion={promotion}
        stopAllPromotion={stopAllPromotion}
        startAllPromotion={startAllPromotion}
      />
    </>
  );
}

export default Home;
