import styled from 'styled-components';

import { FormInputField } from './form';

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  ${FormInputField} {
    width: 401px;
  }

  @media screen and (max-width: 599px) {
    ${FormInputField} {
      width: 100%;
    }

    .contentBtn {
      flex: 1;
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0px;
      height: 50px;
      border-radius: 0;
      justify-content: center;
      z-index: 9;
    }
  }
`;
