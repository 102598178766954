import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import urlRegex from 'url-regex';

import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { Select, selectStyles } from '~/components/elements/select';
import { country as countryOptions } from '~/json/country';
import { IAdCreate } from '~/models/ad/ad';
import { ISelectOption } from '~/models/select-option';

interface ICreateAppProps {
  createData: IAdCreate;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (values: object) => void;
}

function CreateAppDefault({
  createData,
  handleCurrentTab,
  handleCreateData,
}: ICreateAppProps) {
  const [country, setCountry] = useState<ISelectOption[] | null>(null);
  const [countryError, setCountryError] = useState<number>(0);
  const [campType, setCampType] = useState<string>('AOS');
  const [url, setUrl] = useState<string>('');
  const [urlError, setUrlError] = useState<number>(0);
  const [filter, setFilter] = useState<string>('No');

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    setCountry(createData.country);
    setCampType(createData.campType);
    setUrl(createData.url);
  }, [createData.campType, createData.country, createData.url]);

  const handleCountry = (selectedOption: ISelectOption[]) => {
    if (selectedOption === null || selectedOption.length === 0) {
      setCountry(null);
      setCountryError(1);
      return;
    }

    const index = selectedOption.findIndex(
      (o: ISelectOption) => o.value === 'Global',
    );

    let value = null;
    if (index === -1) {
      value = selectedOption;
    } else if (index === 0) {
      const { length } = selectedOption;
      if (length === 1) {
        value = selectedOption;
      } else {
        const filterOptions = selectedOption.filter(
          (o: ISelectOption) => o.value !== 'Global',
        );

        value = filterOptions;
      }
    } else {
      const filterOptions = selectedOption.filter(
        (o: ISelectOption) => o.value === 'Global',
      );

      value = filterOptions;
    }

    setCountry(value);
    setCountryError(0);
  };

  const handleCampType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCampType(e.target.value);
  };

  const handleUrl = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    console.log(value);
    if (value === '') {
      setUrlError(1);
      setUrl(value);
      return;
    }

    if (!urlRegex({ exact: true }).test(value)) {
      setUrlError(2);
      setUrl(value);
      return;
    }

    setUrlError(0);
    setUrl(value);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handlePrevTab = () => {
    handleCreateData({ country, campType, url });
    handleCurrentTab(3);
  };

  const handleNext = () => {
    let valid = true;
    if (country === null || country.length === 0) {
      valid = false;
      setCountryError(1);
    }

    if (url === '') {
      valid = false;
      setUrlError(1);
    }

    if (!valid) {
      return;
    }

    handleCreateData({ country, campType, url, filter });
    handleCurrentTab(5);
  };

  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">
            <FormattedMessage id="ad.ad.app" />
          </h5>
        </OverlayItemWrapTitle>
        <OverlayItem>
          <NoFloating>
            <FormInputContainer>
              <FormInput>
                <FormInputTitleWrap>
                  <FormInputTitleName className="m_txt">
                    <FormattedMessage id="ad.ad.country" />
                    <i className="required" />
                  </FormInputTitleName>
                </FormInputTitleWrap>
                <FormInputField>
                  <Select
                    name="country"
                    placeholder={f({ id: 'ad.ad.country' })}
                    options={countryOptions}
                    styles={selectStyles}
                    isClearable
                    isSearchable
                    isMulti
                    value={country}
                    onChange={handleCountry}
                  />
                </FormInputField>
                {countryError === 1 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.required" />
                  </span>
                )}
              </FormInput>

              <FormInput className="unit">
                <FormInputTitleWrap>
                  <FormInputTitleName className="m_txt">
                    <FormattedMessage id="ad.ad.campType" />
                    <i className="required" />
                  </FormInputTitleName>
                </FormInputTitleWrap>
                <FormInputGroup className="m_txt">
                  <FormInputRadio className="form__input--50">
                    <input
                      type="radio"
                      name="campType"
                      id="android"
                      className="m_txt"
                      value="AOS"
                      checked={campType === 'AOS'}
                      onChange={handleCampType}
                    />
                    <label htmlFor="android">Android</label>
                  </FormInputRadio>

                  <FormInputRadio className="form__input--50">
                    <input
                      type="radio"
                      name="campType"
                      id="ios"
                      className="m_txt"
                      value="IOS"
                      checked={campType === 'IOS'}
                      onChange={handleCampType}
                    />
                    <label htmlFor="ios">iOS</label>
                  </FormInputRadio>
                </FormInputGroup>
              </FormInput>

              <FormInput>
                <FormInputTitleWrap>
                  <FormInputTitleName className="m_txt">
                    <FormattedMessage id="ad.ad.targetUrl" />
                    <i className="required" />
                  </FormInputTitleName>
                </FormInputTitleWrap>
                <FormInputField>
                  <textarea
                    name="url"
                    id="url"
                    cols={40}
                    rows={2}
                    value={url}
                    onChange={handleUrl}
                  />
                  <label htmlFor="url">
                    <FormattedMessage id="ad.ad.targetUrl" />
                  </label>
                </FormInputField>
                {urlError === 1 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.required" />
                  </span>
                )}

                {urlError === 2 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.url" />
                  </span>
                )}
              </FormInput>
              <FormInput>
                <FormInputTitleWrap>
                  <FormInputTitleName className="m_txt">
                    <FormattedMessage id="pub.app.contentLevel" />
                  </FormInputTitleName>
                </FormInputTitleWrap>
                <span className="m_txt">
                  <FormattedMessage id="pub.app.contentLevelDesc" />
                </span>
                <FormInputGroup>
                  <FormInputRadio className="form__input--50">
                    <input
                      type="radio"
                      name="filter"
                      id="filterYes"
                      value="Yes"
                      checked={filter === 'Yes'}
                      onChange={handleFilter}
                    />
                    <label htmlFor="filterYes">Yes</label>
                  </FormInputRadio>

                  <FormInputRadio className="form__input--50">
                    <input
                      type="radio"
                      name="filter"
                      id="filterNo"
                      value="No"
                      checked={filter === 'No'}
                      onChange={handleFilter}
                    />
                    <label htmlFor="filterNo">No</label>
                  </FormInputRadio>
                </FormInputGroup>
              </FormInput>
            </FormInputContainer>
          </NoFloating>
        </OverlayItem>
      </OverlayItemWrap>
      <OverlayFooter>
        <div className="btn__group">
          <button className="btn contentBtn btnClear" onClick={handlePrevTab}>
            <div className="btn__txt">
              <FormattedMessage id="common.prev" />
            </div>
          </button>
          <button className="btn contentBtn" onClick={handleNext}>
            <div className="btn__txt">
              <FormattedMessage id="common.next" />
            </div>
          </button>
        </div>
      </OverlayFooter>
    </OverlayContentBottom>
  );
}

export default CreateAppDefault;
