import React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import BasicCheck from '~/components/base/basic-check';
import BasicInput from '~/components/base/basic-input';
import { Content } from '~/components/elements/content';
import { FormInputContainer, FormInputField } from '~/components/elements/form';

import { Sign, SignContainer, SignLog } from '../elements';

interface IProps {
  type: string;
  handleChangeType: (e: React.ChangeEvent<HTMLInputElement>) => void;
  agree: boolean;
  handleChangeAgree: (value: boolean) => void;
  handleNext: () => void;
}

function SignupAgree({
  type,
  handleChangeType,
  agree,
  handleChangeAgree,
  handleNext,
}: IProps) {
  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>
          <form>
            <fieldset>
              <legend>로그인</legend>
              <FormInputContainer>
                <BasicInput formInputClass="form__input--50">
                  <input
                    type="radio"
                    name="type"
                    id="advertiser"
                    value="ADVERTISER"
                    checked={type === 'ADVERTISER'}
                    onChange={handleChangeType}
                  />
                  <label htmlFor="advertiser">
                    <FormattedMessage id="signup.agree.advertiser" />
                  </label>
                </BasicInput>

                <BasicInput formInputClass="form__input--50">
                  <input
                    type="radio"
                    name="type"
                    id="publisher"
                    value="PUBLISHER"
                    checked={type === 'PUBLISHER'}
                    onChange={handleChangeType}
                  />
                  <label htmlFor="publisher">
                    <FormattedHTMLMessage id="signup.agree.publisher" />
                  </label>
                </BasicInput>

                {type === 'ADVERTISER' && (
                  <FormInputField>
                    <iframe
                      width="100%"
                      src="https://resource.zenaad.com/TermsofUse-ZenaAdAdvertiser.html"
                      title="terms"
                    />
                  </FormInputField>
                )}

                {type === 'PUBLISHER' && (
                  <FormInputField>
                    <iframe
                      width="100%"
                      src="https://resource.zenaad.com/TermsofUse-ZenaAdPublisher.html"
                      title="terms"
                    />
                  </FormInputField>
                )}

                <BasicCheck>
                  <input
                    type="checkbox"
                    name="agree"
                    id="agree"
                    checked={agree}
                    onChange={() => {}}
                  />
                  <label
                    htmlFor="agree"
                    onClick={() => handleChangeAgree(!agree)}
                  >
                    <i className="icon-check" />
                    <FormattedMessage id="sugnup.agree.agree" />
                  </label>
                </BasicCheck>
              </FormInputContainer>
              <button className="btn" type="button" onClick={handleNext}>
                <FormattedMessage id="common.next" />
              </button>
            </fieldset>
          </form>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default SignupAgree;
