import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FilterContainer as DefaultFilterContainer } from '~/components/elements/filter';
import {
  FormInput,
  FormInputContainer,
  FormInputField as DefaultFormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { ICampaignNameListItem } from '~/models/ad/campaign';

interface IReportAdFilterProps {
  campID: number;
  campaigns: ICampaignNameListItem[] | null;
  handleChangeCampID: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FilterContainer = styled(DefaultFilterContainer)`
  form {
    position: relative;
    width: auto;
    justify-content: flex-end;
    padding-left: 1rem;
    margin-left: auto;
  }

  @media screen and (max-width: 1155px) {
    margin-bottom: 2rem;

    form {
      padding-left: 0;
      margin-left: inherit;
    }
  }
`;

const FormInputField = styled(DefaultFormInputField)`
  width: auto;
`;

function ReportAdFilter({
  campID,
  campaigns,
  handleChangeCampID,
}: IReportAdFilterProps) {
  return (
    <FilterContainer>
      <h1 className="m_tit">
        <FormattedMessage id="ad.report.ad" />
      </h1>
      <form>
        <fieldset>
          <legend>필터</legend>
          <NoFloating>
            <FormInputContainer>
              <FormInput>
                <FormInputField>
                  <select
                    name="filter"
                    id="filter"
                    value={campID}
                    onChange={handleChangeCampID}
                  >
                    <FormattedMessage id="ad.campaign">
                      {(message) => (
                        <option value="0">=== {message} ===</option>
                      )}
                    </FormattedMessage>
                    {campaigns &&
                      campaigns.map((campaign, index) => (
                        <option key={index} value={campaign.CampID}>
                          {campaign.Name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="filter">
                    <FormattedMessage id="ad.report.campName" />
                  </label>
                  <i className="icon-chevron-down" />
                </FormInputField>
              </FormInput>
            </FormInputContainer>
          </NoFloating>
        </fieldset>
      </form>
    </FilterContainer>
  );
}

export default ReportAdFilter;
