import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Content,
  ListSection,
  ListSectionContainer,
  ListSectionFile,
  ListSectionItem,
  ListSectionItemFile,
  ListSectionItemImg,
} from '~/components/elements';

const SdkContainer = styled.div`
  ${ListSection} {
    &:first-child {
      p {
        padding-bottom: 2rem;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
`;

function SdkContent() {
  return (
    <Content>
      <SdkContainer>
        <ListSection>
          <h3 className="m_tit">
            <FormattedMessage id="sdk.default" />
          </h3>
          <ListSectionContainer>
            <ListSectionItem>
              <h4 className="s_tit">iOS</h4>
              <p className="s_txt">
                <FormattedMessage id="sdk.default.ios" />
              </p>
              <h4 className="s_tit">Android</h4>
              <p className="s_txt">
                <FormattedMessage id="sdk.default.android" />
              </p>
              <h4 className="s_tit">Unity</h4>
              <p className="s_txt">
                <FormattedMessage id="sdk.default.unity" />
              </p>
            </ListSectionItem>
          </ListSectionContainer>
        </ListSection>

        <ListSection>
          <h3 className="m_tit">
            <FormattedMessage id="sdk.download" />
          </h3>
          <ListSectionContainer>
            <ListSectionItem>
              <ListSectionItemImg>
                <span>
                  <i className="icon-ios" />
                </span>
              </ListSectionItemImg>
              <ListSectionItemFile>
                <ListSectionFile>
                  <h5 className="xs_tit">Objective-C</h5>
                  <a
                    href="https://github.com/zenaad/sdk_ios"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-save_alt" />
                  </a>
                </ListSectionFile>
                <ListSectionFile />
                <ListSectionFile />
              </ListSectionItemFile>
            </ListSectionItem>

            <ListSectionItem>
              <ListSectionItemImg>
                <span>
                  <i className="icon-android" />
                  <span className="xl_txt">&nbsp;Android</span>
                </span>
              </ListSectionItemImg>
              <ListSectionItemFile>
                <ListSectionFile>
                  <h5 className="xs_tit">Java</h5>
                  <a
                    href="https://github.com/zenaad/sdk_android"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-save_alt" />
                  </a>
                </ListSectionFile>
                <ListSectionFile />
                <ListSectionFile />
              </ListSectionItemFile>
            </ListSectionItem>

            <ListSectionItem>
              <ListSectionItemImg>
                <span>
                  <i className="icon-unity" />
                  <span className="xl_txt">&nbsp;Unity</span>
                </span>
              </ListSectionItemImg>
              <ListSectionItemFile>
                <ListSectionFile>
                  <h5 className="xs_tit">Package</h5>
                  <a
                    href="https://github.com/zenaad/sdk_unity"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-save_alt" />
                  </a>
                </ListSectionFile>
                <ListSectionFile />
                <ListSectionFile />
              </ListSectionItemFile>
            </ListSectionItem>

            <ListSectionItem>
              <ListSectionItemImg>
                <span>
                  <i className="icon-unreal" />
                  <span className="xl_txt">&nbsp;Unreal Engine</span>
                </span>
              </ListSectionItemImg>
              <ListSectionItemFile>
                <ListSectionFile>
                  <h5 className="xs_tit">Plugin</h5>
                  <a
                    href="https://github.com/zenaad/sdk_unreal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-save_alt" />
                  </a>
                </ListSectionFile>
                <ListSectionFile />
                <ListSectionFile />
              </ListSectionItemFile>
            </ListSectionItem>
          </ListSectionContainer>
        </ListSection>

        {/* <ListSection>
          <h3 className="m_tit">
            <FormattedMessage id="sdk.release" />
          </h3>
          <ListSectionContainer>
            <ListSectionItem>
              <ListSectionFile>
                <h5 className="xs_tit">iOS</h5>
                <a
                  href="https://resource.zenaad.com/zena2d_ios_releaseNote.txt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
              <ListSectionFile>
                <h5 className="xs_tit">Android</h5>
                <a
                  href="https://resource.zenaad.com/zena2d_android_releaseNote.txt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-save_alt" />
                </a>
              </ListSectionFile>
            </ListSectionItem>
          </ListSectionContainer>
        </ListSection> */}
      </SdkContainer>
    </Content>
  );
}

export default SdkContent;
