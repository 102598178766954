import React from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { pubAppRewardAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { defaultAxios } from '~/libs/axios';
import { numberPattern, numberPointPattern } from '~/libs/regexp';
import { IResAppCreate } from '~/models/pub/app';
import { setIsFullLoader } from '~/modules/core';

interface ICreateAppProps {
  appID: string;
  handleReqError: (err: any) => void;
}

interface IFormData {
  rewardName: string;
  reward: string;
  dayLimit: string;
}

function CreateAppReward({ appID, handleReqError }: ICreateAppProps) {
  const { register, handleSubmit, errors } = useForm<IFormData>({
    defaultValues: {
      rewardName: '',
    },
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async ({ rewardName, reward, dayLimit }: IFormData) => {
    try {
      if (window.confirm(f({ id: 'confirm.add' }))) {
        dispatch(setIsFullLoader(true));

        const { data } = await defaultAxios.post<IResAppCreate>(
          pubAppRewardAPI.add,
          {
            appID,
            rewardName,
            reward,
            dayLimit,
          },
        );

        if (data.error === 'SUCCESS') {
          window.alert(f({ id: 'alert.add' }));
          history.push(`/pub-app-reward?appID=${appID}`);
        } else {
          window.alert(f({ id: `errors.${data.error}` }));
        }

        dispatch(setIsFullLoader(false));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      handleReqError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>New App Reward</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="pub.appReward" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.appReward.rewardName" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="rewardName"
                        id="rewardName"
                        placeholder=""
                        ref={register({
                          required: f({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="rewardName">
                        <FormattedMessage id="pub.appReward.rewardName" />
                      </label>
                    </FormInputField>
                    {errors.rewardName && (
                      <span className="warning__message s_txt">
                        {errors.rewardName.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.appReward.reward" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="reward"
                        id="reward"
                        placeholder=""
                        ref={register({
                          required: f({ id: 'validate.required' }),
                          pattern: {
                            value: numberPointPattern,
                            message: f({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="reward">
                        <FormattedMessage id="pub.appReward.reward" />
                      </label>
                    </FormInputField>
                    {errors.reward && (
                      <span className="warning__message s_txt">
                        {errors.reward.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.appReward.dayLimit" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="dayLimit"
                        id="dayLimit"
                        placeholder=""
                        ref={register({
                          required: f({ id: 'validate.required' }),
                          pattern: {
                            value: numberPattern,
                            message: f({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="reward">
                        <FormattedMessage id="pub.appReward.dayLimit" />
                      </label>
                    </FormInputField>
                    {errors.dayLimit && (
                      <span className="warning__message s_txt">
                        {errors.dayLimit.message}
                      </span>
                    )}
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.create" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateAppReward;
