import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { adCampaignAPI } from '~/api';
import Pager from '~/components/common/pager';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { pressedEnter } from '~/libs/utils';
import { IResCampaignList } from '~/models/ad/campaign';
import { setIsFullLoader } from '~/modules/core';

import ListSearch from './list01-search';
import ListTable from './list02-table';

function List() {
  const [pageNo, setPageNo] = useState<number>(1);
  const [name, setName] = useState<string>('');

  const [{ data, loading, error }, execute] = useAxios<IResCampaignList>(
    {
      url: adCampaignAPI.list,
      method: 'GET',
    },
    { manual: true },
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const qsName = String(qs.name || '');
    const qsPageNo = Number(qs.pageNo || 1);

    setPageNo(qsPageNo);
    setName(qsName);

    execute({
      params: {
        name: qsName,
        pageNo: qsPageNo,
      },
    });
  }, [execute, location.search]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        const to = `/signin${returnQuery}`;
        return <Redirect to={to} />;
      }
    }

    throw error;
  }

  if (!data) {
    return null;
  }

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleKeyPressName = pressedEnter(async () => {
    await handleSearch(name);
  });

  const handleSearch = (value: string) => {
    history.push(`/ad-campaign?name=${value}&pageNo=1`);
  };

  const handleNewCampaign = () => {
    history.push(`/ad-campaign-create?name=${name}&pageNo=${pageNo}`);
  };

  const handleDetail = (campID: number) => {
    if (campID !== 0) {
      history.push(`/ad-campaign/${campID}?name=${name}&pageNo=${pageNo}`);
    }
  };

  const handleChangePage = (no: number) => {
    setPageNo(no);

    history.push(`/ad-campaign?name=${name}&pageNo=${no}`);
  };

  return (
    <>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="ad.campaign.list" />
        </h1>
        <ListSearch
          name={name}
          handleName={handleName}
          handleKeyPressName={handleKeyPressName}
          handleSearch={handleSearch}
          handleNewCampaign={handleNewCampaign}
        />
        <ListTable campaigns={data.campaigns} handleDetail={handleDetail} />
        <Pager
          pageNo={pageNo}
          pageSize={10}
          blockPage={10}
          rowCount={data.rowCount}
          handleChangePage={handleChangePage}
        />
      </ConsoleContent>
    </>
  );
}

export default List;
