import React, { useEffect, useState } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import urlparse from 'url-parse';
import urlRegex from 'url-regex';

import { adAdAPI } from '~/api';
import Carousel from '~/components/common/carousel';
import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { config } from '~/libs/config';
import { IAdAppInfo, IAdCreate, IResScraper } from '~/models/ad/ad';

import {
  FormInputVideo,
  FormInputVideoInfo,
  FormInputVideoThumb,
} from './elements';

interface IDetailAppInfoProps {
  createData: IAdCreate;
  appInfo: IAdAppInfo | null;
  appInfoMatURLs: string[] | null;
  handleReqError: (err: any) => void;
  handleReqLoading: (value: boolean) => void;
  handleCreateDataAppInfo: (value: IAdAppInfo | null) => void;
  handleVideoMatrial: (names: string[]) => void;
}

function DetailAppInfo({
  createData,
  appInfo,
  appInfoMatURLs,
  handleReqError,
  handleReqLoading,
  handleCreateDataAppInfo,
  handleVideoMatrial,
}: IDetailAppInfoProps) {
  const [storeURL, setStoreURL] = useState<string>('');
  const [storeURLError, setStoreURLError] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [icon, setIcon] = useState<string | null>(null);
  const [matURLs, setMatURLs] = useState<string[]>([]);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (appInfo) {
      setStoreURL(appInfo.StoreURL);
      setShow(appInfo.Show);

      if (appInfoMatURLs) {
        setIcon(appInfoMatURLs[0]);
        setMatURLs(appInfoMatURLs.slice(1));
      }
    }
  }, [appInfo, appInfoMatURLs]);

  const handleStoreURL = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    // if (value === '') {
    //   setStoreURLError(1);
    //   setStoreURL(value);
    //   return;
    // }

    if (!urlRegex({ exact: true }).test(value)) {
      setStoreURLError(2);
      setStoreURL(value);
      return;
    }

    setStoreURLError(0);
    setStoreURL(value);
  };

  const handleAppInfo = async () => {
    if (storeURL === '') {
      setStoreURLError(1);
      return;
    }

    let country = '';
    if (createData.country.length > 1) {
      country = 'Global';
    } else {
      for (const item of createData.country) {
        country = item.value;
      }
    }

    const platform = createData.campType;
    const material0 = createData.filenames[0];
    const parsed = urlparse(storeURL, true);

    if (platform === adtier0.PLATFORM[adtier0.PLATFORM.IOS]) {
      if (
        parsed.hostname !== 'itunes.apple.com' &&
        parsed.hostname !== 'apps.apple.com'
      ) {
        window.alert(formatMessage({ id: 'ad.ad.invalidStoreURL' }));
        return;
      }
    }

    if (platform === adtier0.PLATFORM[adtier0.PLATFORM.AOS]) {
      if (parsed.hostname !== 'play.google.com') {
        window.alert(formatMessage({ id: 'ad.ad.invalidStoreURL' }));
        return;
      }
    }

    try {
      handleReqLoading(true);
      const { data } = await defaultAxios.post<IResScraper>(adAdAPI.scraper, {
        country,
        platform,
        material0,
        storeURL,
      });
      handleReqLoading(false);

      const { error } = data;
      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        handleCreateDataAppInfo(data.appInfo);
        handleVideoMatrial(data.matNames);
        setShow(data.appInfo.Show);
        setIcon(`${config.material}/video/${data.matNames[0]}`);
        setMatURLs(
          data.matNames
            .slice(1)
            .map((mat) => `${config.material}/video/${mat}`),
        );
      } else {
        window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
      }
    } catch (err) {
      handleReqLoading(false);

      handleReqError(err);
    }
  };

  const handleShow = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShow(e.target.checked);

    if (appInfo) {
      handleCreateDataAppInfo({ ...appInfo, Show: e.target.checked });
    }
  };

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.addInfo" />
        </h5>
        <Hint
          title={formatMessage({ id: 'hint.addinfo' })}
          content={formatMessage({ id: 'hint.addinfo.desc' })}
        />
      </OverlayItemWrapTitle>
      <OverlayItem>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="ad.ad.storeUrl" />
                  <i className="required" />
                </FormInputTitleName>
                <CopyToClipboard
                  text={storeURL}
                  onCopy={() =>
                    toast.success(formatMessage({ id: 'alert.clipboard' }))
                  }
                >
                  <i className="icon-filter_none cursor__pointer" />
                </CopyToClipboard>
              </FormInputTitleWrap>
              <FormInputField>
                <textarea
                  name="storeUrl"
                  id="storeUrl"
                  cols={40}
                  rows={2}
                  value={storeURL}
                  onChange={handleStoreURL}
                />
                <label htmlFor="storeUrl">
                  <FormattedMessage id="ad.ad.storeUrl" />
                </label>
              </FormInputField>
              {/* {storeURLError === 1 && (
                  <span className="warning__message s_txt">
                    <FormattedMessage id="validate.required" />
                  </span>
                )} */}

              {storeURLError === 2 && (
                <span className="warning__message s_txt">
                  <FormattedMessage id="validate.url" />
                </span>
              )}
              <button
                className="btn contentBtn btnLine"
                onClick={handleAppInfo}
              >
                <i className="icon-file_downloadget_app" />
                <div className="btn__txt">
                  <FormattedMessage id="ad.ad.getAppInfo" />
                </div>
              </button>
            </FormInput>

            <FormInputVideo>
              <FormInputVideoThumb>
                {icon && <img src={icon} alt="" />}
              </FormInputVideoThumb>

              <FormInputVideoInfo>
                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.storeAppName" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">{appInfo && appInfo.Title}</span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.grade" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">{appInfo && appInfo.Score}</span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.downloadCount" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">
                    {appInfo
                      ? createData.campType === 'AOS'
                        ? appInfo.Installs
                        : 'N/A'
                      : ''}
                    {/* {createData.campType === 'AOS' &&
                      appInfo &&
                      appInfo.Installs} */}
                  </span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.genre" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">
                    {appInfo && appInfo.Genres.join()}
                  </span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.chargeNoCharge" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <span className="m_txt">
                    {appInfo && appInfo.Free ? (
                      <FormattedMessage id="ad.ad.charge" />
                    ) : (
                      <FormattedMessage id="ad.ad.noCharge" />
                    )}
                  </span>
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.showGradeDownloadCount" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputRadio className="m_txt">
                    <input
                      id="show"
                      type="checkbox"
                      name="show"
                      checked={show}
                      onChange={handleShow}
                    />
                    <label htmlFor="show">
                      <i className="icon-check" />
                    </label>
                  </FormInputRadio>
                </FormInput>
              </FormInputVideoInfo>
            </FormInputVideo>

            <Carousel matURLs={matURLs} />
          </FormInputContainer>
        </NoFloating>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailAppInfo;
