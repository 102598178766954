import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { accountAPI } from '~/api';
import BasicInput from '~/components/base/basic-input';
import Recaptcha from '~/components/common/recaptcha';
import { Content } from '~/components/elements/content';
import { FormInputContainer } from '~/components/elements/form';
import { adtier0 } from '~/enums/adtier0';
import { defaultAxios } from '~/libs/axios';
import { logger } from '~/libs/logger';
import { emailPattern } from '~/libs/regexp';
import { prettyJson } from '~/libs/utils';
import { IResAuthEmail } from '~/models/res/res-auth-email';
import { setIsFullLoader } from '~/modules/core';

import { Sign, SignContainer, SignEmailContainer, SignLog } from '../elements';

interface IFormData {
  email: string;
}

function SignupEmail() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const { register, errors, handleSubmit, getValues, reset } =
    useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleOpenModal = (value: boolean) => {
    setOpenModal(value);
  };

  const handleCloseRecaptcha = () => {
    setOpenModal(false);
  };

  const onSubmit = async (): Promise<void> => {
    handleOpenModal(true);
  };

  const handleReCAPTCHA = async (value: any): Promise<void> => {
    const token = value;

    try {
      const { data: reCaptchaData } = await defaultAxios.post(
        accountAPI.recaptcha,
        { token },
      );

      if (reCaptchaData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        handleOpenModal(false);

        try {
          dispatch(setIsFullLoader(true));

          const { data } = await defaultAxios.post<IResAuthEmail>(
            accountAPI.email,
            { url: window.location.href, email: getValues().email },
          );

          dispatch(setIsFullLoader(false));

          const { error } = data;

          if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
            window.alert(formatMessage({ id: 'signup.email.sendSuccess' }));
            reset();
          } else {
            window.alert(formatMessage({ id: `errors.${error}` }));
          }
        } catch (err) {
          dispatch(setIsFullLoader(true));

          setErrorData(err);
        }
      }
    } catch (err) {
      logger.error('recaptcha error', prettyJson(err));

      if (err.response) {
        logger.error('recaptcha error response', prettyJson(err.response));
      }

      window.alert(formatMessage({ id: 'errors.UNKNOWN' }));
    }
  };

  if (errorData) {
    throw errorData;
  }

  return (
    <>
      <Sign>
        <Content className="first">
          <SignContainer>
            <SignLog to="/">
              <img
                src={require('~/assets/images/header__logo.svg').default}
                alt=""
                width="100%"
                height="100%"
              />
            </SignLog>

            <h3 className="xl_txt">
              <FormattedMessage id="signup.email.title" />
            </h3>
            <img
              src={require('~/assets/images/signEmail.png').default}
              alt=""
            />

            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <legend>회원가입</legend>
                <FormInputContainer>
                  <BasicInput error={errors.email}>
                    <input
                      name="email"
                      id="email"
                      placeholder="&nbsp;"
                      ref={register({
                        required: formatMessage({ id: 'validate.required' }),
                        pattern: {
                          value: emailPattern,
                          message: formatMessage({ id: 'validate.email' }),
                        },
                      })}
                    />
                    <label htmlFor="email">Email</label>
                  </BasicInput>
                </FormInputContainer>
                <button className="btn">
                  <FormattedMessage id="signup.email.submit" />
                </button>
              </fieldset>
            </form>

            <SignEmailContainer>
              <h4 className="s_tit">
                <FormattedMessage id="signup.email.step1" />
              </h4>
              <div className="signEmail__text s_txt">
                <p>
                  <span>1</span>
                  <FormattedMessage id="signup.email.step1" />
                </p>
                <p>
                  <span>2</span>
                  <FormattedMessage id="signup.email.step2" />
                </p>
                <p>
                  <span>3</span>
                  <FormattedMessage id="signup.email.step3" />
                </p>
                <p>
                  <span>4</span>
                  <FormattedMessage id="signup.email.step4" />
                </p>
              </div>
            </SignEmailContainer>
          </SignContainer>
        </Content>
      </Sign>

      <Recaptcha
        openModal={openModal}
        handleRequestClose={handleCloseRecaptcha}
        handleClose={handleCloseRecaptcha}
        handleRecaptcha={handleReCAPTCHA}
      />
    </>
  );
}

export default SignupEmail;
