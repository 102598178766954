import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayHeaderTitle,
} from '~/components/elements/overlay';
import { useKeyboardEvent } from '~/hooks/use-keyboard-event';
import { usePrevLocation } from '~/hooks/use-prev-location';

import CreateTab from './create00-tab';
import CreateAppReward from './create01-app-reward';

function Create() {
  const [errorData, setErrorData] = useState<any>(null);

  const history = useHistory();
  const [query] = useQueryParams({
    appID: StringParam,
  });

  const { returnQuery } = usePrevLocation();

  const handleClose = () => {
    history.goBack();
  };

  useKeyboardEvent('Escape', document.body, handleClose);

  const handleReqError = (err: any) => {
    setErrorData(err);
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <main className="console">
      <OverlayContainer>
        <OverlayHeader>
          <i className="icon-x" onClick={handleClose} />
          <OverlayHeaderTitle className="m_txt">
            <FormattedMessage id="pub.appReward.create" />
          </OverlayHeaderTitle>
        </OverlayHeader>
        <OverlayContent>
          <CreateTab />
          <CreateAppReward
            appID={query.appID || ''}
            handleReqError={handleReqError}
          />
        </OverlayContent>
      </OverlayContainer>
    </main>
  );
}

export default Create;
