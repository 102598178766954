import React from 'react';

import { Helmet } from 'react-helmet';

import Create from '~/components/pub/appReward/create';

function AppRewardCreatePage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Create />
    </>
  );
}

export default AppRewardCreatePage;
