import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface ICreateLimitProps {
  dayLimit: number;
  budgetLimit: number;
  dayLimitError: number;
  budgetLimitError: number;
  handleDayLimit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBudgetLimit: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CreateLimit({
  dayLimit,
  budgetLimit,
  dayLimitError,
  budgetLimitError,
  handleDayLimit,
  handleBudgetLimit,
}: ICreateLimitProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.limit" />
        </h5>
        <Hint
          title={formatMessage({ id: 'hint.limit' })}
          content={formatMessage({ id: 'hint.limit.desc' })}
        />
      </OverlayItemWrapTitle>
      <OverlayItem>
        <form>
          <fieldset>
            <legend>상세정보 - 광고(배너) : 한도</legend>
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.dayLimit" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputField className="unit">
                    <input
                      type="text"
                      name="dayLimit"
                      id="dayLimit"
                      placeholder=""
                      value={dayLimit}
                      onChange={handleDayLimit}
                    />
                    <label htmlFor="dayLimit">
                      <FormattedMessage id="ad.ad.dayLimit" />
                    </label>
                    <i className="unit xs_txt">KRW</i>
                  </FormInputField>
                  {dayLimitError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}

                  {dayLimitError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.min"
                        values={{ min: 100000 }}
                      />
                    </span>
                  )}

                  {dayLimitError === 3 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.max"
                        values={{ max: 2000000000 }}
                      />
                    </span>
                  )}

                  {dayLimitError === 4 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.onlyNum" />
                    </span>
                  )}
                </FormInput>

                <FormInput>
                  <FormInputTitleWrap>
                    <FormInputTitleName className="m_txt">
                      <FormattedMessage id="ad.ad.budgetLimit" />
                      <i className="required" />
                    </FormInputTitleName>
                  </FormInputTitleWrap>
                  <FormInputField className="unit">
                    <input
                      type="text"
                      name="budgetLimit"
                      id="budgetLimit"
                      placeholder=""
                      value={budgetLimit}
                      onChange={handleBudgetLimit}
                    />
                    <label htmlFor="budgetLimit">
                      <FormattedMessage id="ad.ad.budgetLimit" />
                    </label>
                    <i className="unit xs_txt">KRW</i>
                  </FormInputField>
                  {budgetLimitError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}

                  {budgetLimitError === 2 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.min"
                        values={{ min: 100000 }}
                      />
                    </span>
                  )}

                  {budgetLimitError === 3 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage
                        id="validate.max"
                        values={{ max: 2000000000 }}
                      />
                    </span>
                  )}

                  {budgetLimitError === 4 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.onlyNum" />
                    </span>
                  )}
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </fieldset>
        </form>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default CreateLimit;
