import React from 'react';

import { IAppRewardListItem } from '~/models/pub/appReward';

interface IListTableItemProps {
  appReward: IAppRewardListItem;
  handleDetail: (appRewardID: number) => void;
}

function ListTableItem({ appReward, handleDetail }: IListTableItemProps) {
  const { AppRewardID, RewardKey, RewardName, Reward } = appReward;
  return (
    <tr onClick={() => handleDetail(AppRewardID)}>
      <th className="text__left border__right table__title">{AppRewardID}</th>
      <th className="text__left border__right table__title">
        <span>{RewardKey}</span>
      </th>
      <th className="text__left border__right table__title">
        <span>{RewardName}</span>
      </th>
      <th className="text__left border__right table__title">
        <span>{Reward}</span>
      </th>
    </tr>
  );
}

export default ListTableItem;
