import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { accountAPI } from '~/api';
import BasicInput from '~/components/base/basic-input';
import { Content } from '~/components/elements/content';
import { FormInputContainer } from '~/components/elements/form';
import { Select, selectStyles } from '~/components/elements/select';
import { adtier0 } from '~/enums/adtier0';
import { timezone } from '~/json/timezone';
import { defaultAxios } from '~/libs/axios';
import { pwPattern } from '~/libs/regexp';
import { IResSignup } from '~/models/res/res-signup';
import { setIsFullLoader } from '~/modules/core';

import { Sign, SignContainer, SignLog } from '../elements';

interface IProps {
  email: string;
  token: string;
  type: string;
}

interface IFormData {
  pw: string;
  pwConfirm: string;
  firstName: string;
  lastName: string;
  company: string;
  timezoneName: any;
}

function SignupForm({ email, token, type }: IProps) {
  const [errorData, setErrorData] = useState<any>(null);

  const {
    register,
    errors,
    getValues,
    setValue,
    setError,
    clearError,
    handleSubmit,
  } = useForm<IFormData>();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    register(
      { name: 'timezoneName', type: 'custom' },
      {
        required: formatMessage({ id: 'validate.required' }),
      },
    );

    setValue('timezoneName', timezone[274]);
  }, [formatMessage, register, setValue]);

  const handleChangeTimezoneName = (selectedOption: any) => {
    if (selectedOption) {
      clearError('timezoneName');
      setValue('timezoneName', selectedOption);
      return;
    }

    setError(
      'timezoneName',
      'required',
      formatMessage({ id: 'validate.required' }),
    );
  };

  const onSubmit = async ({
    pw,
    firstName,
    lastName,
    company,
    timezoneName,
  }: IFormData): Promise<void> => {
    try {
      dispatch(setIsFullLoader(true));

      const { data } = await defaultAxios.post<IResSignup>(accountAPI.signup, {
        token,
        type,
        firstName,
        lastName,
        company,
        pw,
        timezoneName: timezoneName.value,
      });

      dispatch(setIsFullLoader(false));

      const { error } = data;

      if (error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        window.alert(formatMessage({ id: 'signup.success' }));
        history.push(
          `/signup-success?name=${firstName} ${lastName}&email=${email}`,
        );
      } else {
        window.alert(formatMessage({ id: `errors.${error}` }));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (errorData) {
    throw errorData;
  }

  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>회원가입</legend>
              <FormInputContainer>
                <BasicInput>
                  <span>{email}</span>
                </BasicInput>

                <BasicInput
                  info={formatMessage({ id: 'signup.form.pwDesc' })}
                  error={errors.pw}
                >
                  <input
                    type="password"
                    name="pw"
                    id="pw"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      pattern: {
                        value: pwPattern,
                        message: formatMessage({ id: 'validate.pw' }),
                      },
                    })}
                  />
                  <label htmlFor="pw">Password</label>
                </BasicInput>

                <BasicInput error={errors.pwConfirm}>
                  <input
                    type="password"
                    name="pwConfirm"
                    id="pwConfirm"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                      validate: (value: string) =>
                        getValues().pw === value ||
                        formatMessage({ id: 'validate.pwNotMatch' }),
                    })}
                  />
                  <label htmlFor="pwConfirm">Confirm Password</label>
                </BasicInput>

                <BasicInput error={errors.firstName}>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="firstName">First Name</label>
                </BasicInput>

                <BasicInput error={errors.lastName}>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="lastName">Last Name</label>
                </BasicInput>

                <BasicInput error={errors.company}>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    placeholder="&nbsp;"
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                  />
                  <label htmlFor="company">Company Name</label>
                </BasicInput>

                <BasicInput error={errors.timezoneName}>
                  <Select
                    name="timezoneName"
                    id="timezoneName"
                    placeholder="&nbsp;Timezone Name"
                    options={timezone}
                    defaultValue={timezone[274]}
                    ref={register({
                      required: formatMessage({ id: 'validate.required' }),
                    })}
                    styles={selectStyles}
                    isClearable
                    isSearchable
                    menuPosition="fixed"
                    menuPlacement="bottom"
                    menuPortalTarget={document.body}
                    onChange={handleChangeTimezoneName}
                  />
                </BasicInput>
              </FormInputContainer>
              <button className="btn">
                <FormattedMessage id="signup.form.join" />
              </button>
            </fieldset>
          </form>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default SignupForm;
