import React, { useState } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import urlRegex from 'url-regex';

import { pubAppAPI } from '~/api';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputRadio,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import { IResAppCreate } from '~/models/pub/app';
import { setIsFullLoader } from '~/modules/core';

interface ICreateAppProps {
  handleReqError: (err: any) => void;
}

interface IFormData {
  name: string;
  platform: string;
  storeURL: string;
  filter: string;
}

function CreateApp({ handleReqError }: ICreateAppProps) {
  const [errorData, setErrorData] = useState<any>(null);

  const { register, handleSubmit, errors } = useForm<IFormData>({
    defaultValues: {
      name: `App ${moment().format('YYYY-MM-DD HH:mm')}`,
      platform: 'AOS',
      filter: 'No',
    },
    submitFocusError: false,
  });

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  const onSubmit = async ({ name, platform, storeURL, filter }: IFormData) => {
    try {
      if (window.confirm(f({ id: 'confirm.add' }))) {
        dispatch(setIsFullLoader(true));

        const { data } = await defaultAxios.post<IResAppCreate>(pubAppAPI.add, {
          name,
          platform,
          storeURL,
          filter,
        });

        if (data.error === 'SUCCESS') {
          window.alert(f({ id: 'alert.add' }));
          history.push('/pub-app');
        } else {
          window.alert(f({ id: `errors.${data.error}` }));
        }

        dispatch(setIsFullLoader(false));
      }
    } catch (err) {
      dispatch(setIsFullLoader(false));

      setErrorData(err);
    }
  };

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>새 앱 생성 - 앱</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="pub.app" />
              </h5>
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.app.appName" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder=""
                        ref={register({
                          required: f({ id: 'validate.required' }),
                        })}
                      />
                      <label htmlFor="name">
                        <FormattedMessage id="pub.app.appName" />
                      </label>
                    </FormInputField>
                    {errors.name && (
                      <span className="warning__message s_txt">
                        {errors.name.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.app.platform" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputGroup>
                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="platform"
                          id="android"
                          value="AOS"
                          ref={register}
                        />
                        <label htmlFor="android">Android</label>
                      </FormInputRadio>

                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="platform"
                          id="ios"
                          value="IOS"
                          ref={register}
                        />
                        <label htmlFor="ios">iOS</label>
                      </FormInputRadio>
                    </FormInputGroup>
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.app.storeUrl" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField>
                      <textarea
                        name="storeURL"
                        id="storeURL"
                        cols={40}
                        rows={2}
                        ref={register({
                          required: f({ id: 'validate.required' }),
                          maxLength: {
                            value: 1024,
                            message: f(
                              { id: 'validate.maxLength' },
                              { len: '1024' },
                            ),
                          },
                          validate: (value: string) =>
                            urlRegex({ exact: true }).test(value) ||
                            f({ id: 'validate.url' }),
                        })}
                      />
                      <label htmlFor="storeURL">
                        <FormattedMessage id="pub.app.storeUrl" />
                      </label>
                    </FormInputField>
                    {errors.storeURL && (
                      <span className="warning__message s_txt">
                        {errors.storeURL.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="pub.app.contentLevel" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <span className="m_txt">
                      <FormattedMessage id="pub.app.contentLevelDesc" />
                    </span>
                    <FormInputGroup>
                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="filter"
                          id="filterYes"
                          value="Yes"
                          ref={register}
                        />
                        <label htmlFor="filterYes">Yes</label>
                      </FormInputRadio>

                      <FormInputRadio className="form__input--50">
                        <input
                          type="radio"
                          name="filter"
                          id="filterNo"
                          value="No"
                          ref={register}
                        />
                        <label htmlFor="filterNo">No</label>
                      </FormInputRadio>
                    </FormInputGroup>
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.create" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateApp;
