import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RootState } from '~/modules';
import { setLanguage } from '~/modules/core';

const Wrapper = styled.footer`
  position: relative;
  background-color: rgba(0, 0, 0, 0.02);
  padding-top: 3rem;
  margin-top: auto;

  .footer__container {
    width: 1156px;
    margin: 0 auto;
  }

  .footer__container > ul {
    display: flex;
  }

  .footer__container > ul li {
    padding: 0.5rem 0;
  }

  .footer__top--item {
    width: calc(100% / 6);
  }

  .footer__top--item:last-child {
    margin-left: auto;
  }

  .footer__top--item ul {
    padding-top: 0.5rem;
  }

  .footer__top--item li.footer__top--ask {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ebebeb;
    padding: 0 10px 0 15px;
  }

  .footer__bottom {
    align-items: center;
    border-top: 1px solid #ebebeb;
    padding: 1rem 0 !important;
  }

  .footer__bottom li:last-child {
    margin-left: auto;
  }

  .footer__bottom--logo {
    width: auto;
    min-width: 103.8px;
    height: 18px;
    margin-right: 2rem;
    display: block;
  }

  .footer__bottom--menu {
    display: flex;
    flex-wrap: wrap;
  }

  .footer__bottom--menu li {
    margin-right: 1rem;
  }

  .footer__bottom--lang {
    display: flex;
  }

  .footer__bottom--lang span {
    cursor: pointer;
    display: block;
  }

  .footer__bottom--lang span:first-child {
    margin-right: 1rem;
  }

  .footer__bottom--lang span.active {
    font-weight: 600;
    color: #1b1c23;
  }

  @media screen and (max-width: 1155px) {
    .footer__container {
      width: 100%;
      white-space: nowrap;
      padding: 0 24px;
    }
  }

  @media screen and (max-width: 599px) {
    padding-top: 4rem;
    padding-bottom: 50px;

    .footer__container {
      padding: 0 16px;
    }

    .footer__container > ul {
      flex-wrap: wrap;
    }

    .footer__container > ul.footer__top {
      justify-content: space-between;
    }

    .footer__container > ul li {
      padding: 0.5rem 0;
    }

    .footer__top--item {
      padding: 1rem 0;
      width: calc(50% - 4px);
    }

    .footer__top--item:last-child {
      margin: 0;
    }

    .footer__top--item ul {
      padding: 1rem 0;
    }

    .footer__bottom {
      flex-direction: column;
      align-items: flex-start;
    }

    .footer__bottom > li:last-child {
      margin: 0;
    }

    .footer__bottom > li:first-child {
      padding-top: 1rem;
    }
  }
`;

function Footer() {
  // const { language, setLanguage } = useContext(IntlContext);

  const { formatMessage } = useIntl();

  const language = useSelector((state: RootState) => state.core.language);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div className="footer__container">
        <ul className="footer__top">
          <li className="xs_tit footer__top--item">
            ZENAAD
            <ul className="m_txt">
              <Link to="/">
                <li>
                  <FormattedMessage id="gnb.home.index" />
                </li>
              </Link>
              <Link to="/home-start">
                <li>
                  <FormattedMessage id="gnb.home.start" />
                </li>
              </Link>
              <Link to="/home-resource">
                <li>
                  <FormattedMessage id="gnb.home.resource" />
                </li>
              </Link>
              {/* <Link to="/home-announce">
                <li>공지</li>
              </Link> */}
              <li
                className="cursor__pointer"
                onClick={() => {
                  window.alert(formatMessage({ id: 'alert.ready' }));
                }}
              >
                <FormattedMessage id="gnb.home.announce" />
              </li>
            </ul>
          </li>
          <li className="xs_tit footer__top--item">
            <FormattedMessage id="gnb.sdkAndSupport" />
            <ul className="m_txt">
              <Link to="/home-sdk">
                <li>
                  <FormattedMessage id="gnb.sdkAndSupport.sdk" />
                </li>
              </Link>
              {/* <Link to="/home-support">
                <li>지원</li>
              </Link> */}
              <li
                className="cursor__pointer"
                onClick={() => {
                  window.alert(formatMessage({ id: 'alert.ready' }));
                }}
              >
                <FormattedMessage id="gnb.sdkAndSupport.support" />
              </li>
            </ul>
          </li>
          <li className="xs_tit footer__top--item">
            <FormattedMessage id="gnb.app" />
            <ul className="m_txt">
              <Link to="/home-game">
                <li>
                  <FormattedMessage id="gnb.app.game" />
                </li>
              </Link>
              <Link to="/home-app">
                <li>
                  <FormattedMessage id="gnb.app.app" />
                </li>
              </Link>
            </ul>
          </li>
          <li className="xs_tit footer__top--item">
            <FormattedMessage id="gnb.partner" />
            <ul className="m_txt">
              <Link to="/home-mediation">
                <li>
                  <FormattedMessage id="gnb.partner.mediation" />
                </li>
              </Link>
              <Link to="/home-publisher">
                <li>
                  <FormattedMessage id="gnb.partner.publisher" />
                </li>
              </Link>
            </ul>
          </li>
          <li className="xs_tit footer__top--item">
            <FormattedMessage id="gnb.help" />
            <ul className="m_txt">
              <Link to="/home-help">
                <li className="footer__top--ask">
                  <FormattedMessage id="gnb.help.help" />
                  <i className="icon-chevron-right" />
                </li>
              </Link>
            </ul>
          </li>
        </ul>
        <ul className="footer__bottom m_txt">
          <li>
            <img
              src={require('~/assets/images/footer__logo.svg').default}
              className="footer__bottom--logo"
              width="100%"
              height="100%"
              alt="zenaad logo"
            />
          </li>
          <div className="footer__bottom--menu">
            <a
              href="https://resource.zenaad.com/PrivacyPolicy-ZenaAd.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FormattedMessage id="gnb.privacy" />
              </li>
            </a>
            <a
              href="https://resource.zenaad.com/TermsofUse-ZenaAdAdvertiser.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FormattedMessage id="gnb.adTerms" />
              </li>
            </a>
            <a
              href="https://resource.zenaad.com/TermsofUse-ZenaAdPublisher.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FormattedMessage id="gnb.pubTerms" />
              </li>
            </a>
          </div>
          <li className="footer__bottom--lang">
            <span
              className={language === 'ko' ? 'active' : ''}
              onClick={() => dispatch(setLanguage('ko'))}
            >
              KOR
            </span>
            <span
              className={language === 'en' ? 'active' : ''}
              onClick={() => dispatch(setLanguage('en'))}
            >
              ENG
            </span>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
}

export default Footer;
