import { version } from './helper';

const list = `api/${version.v1}/pub/inventory/`;

const activeMedia = `api/${version.v1}/pub/inventory/active-media`;

const get = `api/${version.v1}/pub/inventory/get`;

const add = `api/${version.v1}/pub/inventory/add`;

const modify = `api/${version.v1}/pub/inventory/modify`;

export default {
  list,
  activeMedia,
  get,
  add,
  modify,
};
