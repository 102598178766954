import React from 'react';

import { Helmet } from 'react-helmet';

import Sdk from '~/components/home/sdk';
import { MobileGnbSdk } from '~/components/layout/header-mobile';
import { GnbSdk } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeSdkPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbSdk />} mobileGnb={<MobileGnbSdk />}>
        <Sdk />
      </Layout>
    </>
  );
}

export default HomeSdkPage;
