import React from 'react';

import { Helmet } from 'react-helmet';

import FindEmail from '~/components/account/find-email';

function FindEmailPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <FindEmail />
    </>
  );
}

export default FindEmailPage;
