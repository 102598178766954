import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { pubReportAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { config } from '~/libs/config';
import { getPosibleSearchDate } from '~/libs/utils';
import {
  IReportByDaily,
  IReportByDailyApp,
  IReportByDailyMedia,
  IResReportByDaily,
  IResReportByDailyApp,
  IResReportByDailyMedia,
  IState,
} from '~/models/pub/report';
import { RootState } from '~/modules';
import { setIsFullLoader } from '~/modules/core';
import {
  setSearchEndDate,
  setSearchSelectDay,
  setSearchStartDate,
} from '~/modules/user';

import ReportDayFilter from './report-day01-filter';
import ReportDayState from './report-day02-state';
import ReportDayChart from './report-day03-chart';
import ReportDayExport from './report-day04-export';
import ReportDayList from './report-day05-list';

function ReportDay() {
  const [state, setState] = useState<IState>({
    impression: 0,
    revenue: 0,
    ecpm: 0,
  });
  const [chartType, setChartType] = useState<string>('ecpm');
  const [chartData, setChartData] = useState<any>([]);
  const [dailyList, setDailyList] = useState<IReportByDaily[] | null>(null);
  const [registTime, setRegistTime] = useState<string>('');
  const [appList, setAppList] = useState<IReportByDailyApp[] | null>(null);
  const [appID, setAppID] = useState<string>('');
  const [medList, setMedList] = useState<IReportByDailyMedia[] | null>(null);
  const [csv, setCSV] = useState<any>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { searchSelectDay, searchStartDate, searchEndDate } = useSelector(
    (s: RootState) => s.user,
  );

  const [{ data: dailyData, loading: dailyLoading, error: dailyError }] =
    useAxios<IResReportByDaily>(
      {
        url: pubReportAPI.reportByDaily,
        method: 'GET',
        params: {
          startDate: searchStartDate,
          endDate: searchEndDate,
        },
      },
      { useCache: false },
    );

  const [{ data: appData, loading: appLoading, error: appError }, execApp] =
    useAxios<IResReportByDailyApp>(
      {
        url: pubReportAPI.reportByDailyApp,
        method: 'GET',
      },
      { useCache: false, manual: true },
    );

  const [{ data: medData, loading: medLoading, error: medError }, execMedia] =
    useAxios<IResReportByDailyMedia>(
      {
        url: pubReportAPI.reportByDailyMedia,
        method: 'GET',
      },
      { useCache: false, manual: true },
    );

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    if (dailyLoading || appLoading || medLoading) {
      dispatch(setIsFullLoader(true));
    } else {
      dispatch(setIsFullLoader(false));
    }
  }, [appLoading, dailyLoading, dispatch, medLoading]);

  useEffect(() => {
    if (dailyData) {
      if (dailyData.list) {
        let totalImpression = 0;
        let totalRevenue = 0;
        let totaleCPM = 0;

        const csvData: any = [
          [
            'Depth',
            formatMessage({ id: 'pub.report.day' }),
            formatMessage({ id: 'pub.report.revenue' }),
            formatMessage({ id: 'pub.report.ecpm' }),
            formatMessage({ id: 'pub.report.impression' }),
            formatMessage({ id: 'pub.report.install' }),
            `${formatMessage({ id: 'pub.report.ctr' })}(%)`,
          ],
        ];

        for (const item of dailyData.list) {
          totalImpression += Number(item.Impressions);
          totalRevenue += Number(item.Revenue);

          csvData.push([
            formatMessage({ id: 'pub.report.day' }),
            item.RegistTime,
            item.Revenue,
            item.eCPM,
            item.Impressions,
            item.Installs,
            item.CTR,
          ]);
        }

        if (totalImpression !== 0 && totalRevenue !== 0) {
          totaleCPM = (totalRevenue / totalImpression) * 1000;
        }

        setState({
          impression: totalImpression,
          revenue: totalRevenue,
          ecpm: totaleCPM,
        });

        setCSV(csvData);

        // const arr = dailyData.list.map((item) => {
        //   return [item.RegistTime, Number(item.eCPM)];
        // });

        // if (arr.length > 0) {
        //   const temp = [
        //     ['Date', formatMessage({ id: 'pub.report.ecpm' })],
        //     ...arr
        //   ];
        //   setChartData(temp);
        // } else {
        //   const temp = [
        //     ['Date', formatMessage({ id: 'pub.report.ecpm' })],
        //     ['', 0]
        //   ];
        //   setChartData(temp);
        // }

        // setChartType('ecpm');

        let temp = [];

        if (chartType === 'impression') {
          const arr = dailyData.list.map((item) => [
            item.RegistTime,
            Number(item.Impressions),
          ]);

          if (arr.length > 0) {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.impression' })],
              ...arr,
            ];
          } else {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.impression' })],
              ['', 0],
            ];
          }
        } else if (chartType === 'revenue') {
          const arr = dailyData.list.map((item) => [
            item.RegistTime,
            Number(item.Revenue),
          ]);

          if (arr.length > 0) {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.revenue' })],
              ...arr,
            ];
          } else {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.revenue' })],
              ['', 0],
            ];
          }
        } else if (chartType === 'ecpm') {
          const arr = dailyData.list.map((item) => [
            item.RegistTime,
            Number(item.eCPM),
          ]);

          if (arr.length > 0) {
            temp = [['Date', formatMessage({ id: 'pub.report.ecpm' })], ...arr];
          } else {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.ecpm' })],
              ['', 0],
            ];
          }
        } else {
          const arr = dailyData.list.map((item) => [
            item.RegistTime,
            Number(item.CTR),
          ]);

          if (arr.length > 0) {
            temp = [['Date', formatMessage({ id: 'pub.report.ctr' })], ...arr];
          } else {
            temp = [
              ['Date', formatMessage({ id: 'pub.report.ctr' })],
              ['', 0],
            ];
          }
        }

        setChartData(temp);

        setDailyList(dailyData.list);
      }
    }
  }, [chartType, dailyData, formatMessage]);

  useEffect(() => {
    if (appData) {
      if (appData.list) {
        setAppList(appData.list);
      }
    }
  }, [appData]);

  useEffect(() => {
    if (medData) {
      if (medData.list) {
        setMedList(medData.list);
      }
    }
  }, [medData]);

  if (dailyError) {
    if (dailyError.response) {
      if (dailyError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw dailyError;
  }

  if (appError) {
    if (appError.response) {
      if (appError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw appError;
  }

  if (medError) {
    if (medError.response) {
      if (medError.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw medError;
  }

  if (!dailyList) {
    return null;
  }

  const handleSearch = (day: number, sdate: any, edate: any) => {
    const valid = getPosibleSearchDate(sdate, edate, config.searchDay);
    if (!valid) {
      window.alert(
        formatMessage({ id: 'validate.searchDay' }, { days: config.searchDay }),
      );
      return;
    }

    setRegistTime('');
    setAppID('');

    dispatch(setSearchSelectDay(day));
    dispatch(setSearchStartDate(sdate));
    dispatch(setSearchEndDate(edate));

    // execDaily({
    //   params: { startDate: sdate, endDate: edate }
    // });
  };

  const handleChangeChartType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setChartType(value);

    let temp = [];

    if (value === 'impression') {
      const arr = dailyList.map((item) => [
        item.RegistTime,
        Number(item.Impressions),
      ]);

      if (arr.length > 0) {
        temp = [
          ['Date', formatMessage({ id: 'pub.report.impression' })],
          ...arr,
        ];
      } else {
        temp = [
          ['Date', formatMessage({ id: 'pub.report.impression' })],
          ['', 0],
        ];
      }
    } else if (value === 'revenue') {
      const arr = dailyList.map((item) => [
        item.RegistTime,
        Number(item.Revenue),
      ]);

      if (arr.length > 0) {
        temp = [['Date', formatMessage({ id: 'pub.report.revenue' })], ...arr];
      } else {
        temp = [
          ['Date', formatMessage({ id: 'pub.report.revenue' })],
          ['', 0],
        ];
      }
    } else if (value === 'ecpm') {
      const arr = dailyList.map((item) => [item.RegistTime, Number(item.eCPM)]);

      if (arr.length > 0) {
        temp = [['Date', formatMessage({ id: 'pub.report.ecpm' })], ...arr];
      } else {
        temp = [
          ['Date', formatMessage({ id: 'pub.report.ecpm' })],
          ['', 0],
        ];
      }
    } else {
      const arr = dailyList.map((item) => [item.RegistTime, Number(item.CTR)]);

      if (arr.length > 0) {
        temp = [['Date', formatMessage({ id: 'pub.report.ctr' })], ...arr];
      } else {
        temp = [
          ['Date', formatMessage({ id: 'pub.report.ctr' })],
          ['', 0],
        ];
      }
    }

    setChartData(temp);
  };

  const handleClickDaily = (value: string) => {
    if (registTime === value) {
      setRegistTime('');
      setAppList(null);
      setMedList(null);
    } else {
      setRegistTime(value);
      setAppList(null);
      setMedList(null);

      execApp({
        params: {
          startDate: value,
          endDate: value,
        },
      });
    }
  };

  const handleClickApp = (value: string) => {
    if (appID === value) {
      setAppID('');
      setMedList(null);
    } else {
      setAppID(value);
      setMedList(null);

      execMedia({
        params: {
          appID: value,
          startDate: registTime,
          endDate: registTime,
        },
      });
    }
  };

  return (
    <>
      <ConsoleContent>
        <ReportDayFilter
          searchSelectDay={searchSelectDay}
          searchStartDate={searchStartDate}
          searchEndDate={searchEndDate}
          handleSearch={handleSearch}
        />
        <ReportDayState state={state} />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        <ReportDayChart
          chartType={chartType}
          chartData={chartData}
          handleChangeChartType={handleChangeChartType}
        />
      </ConsoleContent>

      <DivisionLine />

      <ConsoleContent>
        <ReportDayExport
          csv={csv}
          searchStartDate={searchStartDate}
          searchEndDate={searchEndDate}
        />
        <ReportDayList
          dailyList={dailyList}
          registTime={registTime}
          appList={appList}
          appID={appID}
          medList={medList}
          handleClickDaily={handleClickDaily}
          handleClickApp={handleClickApp}
        />
      </ConsoleContent>
    </>
  );
}

export default ReportDay;
