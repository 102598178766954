import { version } from './helper';

const reportByDaily = `api/${version.v1}/pub/report/report-by-daily`;

const reportByDailyApp = `api/${version.v1}/pub/report/report-by-daily-app`;

const reportByDailyMedia = `api/${version.v1}/pub/report/report-by-daily-media`;

const reportByApp = `api/${version.v1}/pub/report/report-by-app`;

const reportByUser = `api/${version.v1}/pub/report/report-by-user`;

export default {
  reportByDaily,
  reportByDailyApp,
  reportByDailyMedia,
  reportByApp,
  reportByUser,
};
