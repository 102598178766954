import { version } from './helper';

const list = `api/${version.v1}/pub/app/`;

const listAll = `api/${version.v1}/pub/app/list-all`;

const get = `api/${version.v1}/pub/app/get`;

const add = `api/${version.v1}/pub/app/add`;

const modify = `api/${version.v1}/pub/app/modify`;

const requestApiKey = `api/${version.v1}/pub/app/request-api-key`;

export default {
  list,
  listAll,
  get,
  add,
  modify,
  requestApiKey,
};
