import React from 'react';

import { Helmet } from 'react-helmet';

import RevenueDetails from '~/components/account/pub-my/revenue-details';

function PubMyRevenueDetails() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <RevenueDetails />
    </>
  );
}

export default PubMyRevenueDetails;
