import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { StateContainer } from '~/components/elements/state';
import { IState } from '~/models/pub/report';

interface IReportAppStateProps {
  state: IState;
}

function ReportAppState({ state }: IReportAppStateProps) {
  const { impression, revenue, ecpm } = state;

  return (
    <StateContainer>
      <li>
        <p className="m_txt">
          <FormattedMessage id="pub.report.totalImpression" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat
              value={impression}
              displayType="text"
              thousandSeparator
            />
          </b>
        </span>
      </li>
      <li>
        <p className="m_txt">
          <FormattedMessage id="pub.report.totalRevenue" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat
              value={revenue}
              displayType="text"
              thousandSeparator
            />
          </b>
          <span className="ms_tit krw">KRW</span>
        </span>
      </li>
      <li>
        <p className="m_txt">
          <FormattedMessage id="pub.report.ecpm" />
        </p>
        <span className="m_tit">
          <b>
            <NumberFormat
              value={ecpm}
              displayType="text"
              thousandSeparator
              decimalScale={0}
              decimalSeparator="."
            />
          </b>
          <span className="ms_tit krw">KRW</span>
        </span>
      </li>
    </StateContainer>
  );
}

export default ReportAppState;
