import { version } from './helper';

const list = `api/${version.v1}/pub/app-reward/`;

const get = `api/${version.v1}/pub/app-reward/get`;

const add = `api/${version.v1}/pub/app-reward/add`;

const modify = `api/${version.v1}/pub/app-reward/modify`;

const remove = `api/${version.v1}/pub/app-reward/remove`;

export default {
  list,
  get,
  add,
  modify,
  remove,
};
