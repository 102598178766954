import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { pubHomeAPI } from '~/api';
import DivisionLine from '~/components/base/division-line';
import { adtier0 } from '~/enums/adtier0';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { defaultAxios } from '~/libs/axios';
import {
  IApp,
  IMed,
  IResActiveAllMedia,
  IResPubHome,
  IRev,
  IState,
} from '~/models/pub/home';
import { setIsFullLoader } from '~/modules/core';

import HomeNetwork from './home01-network';
import HomeSummary from './home02-summary';

function Home() {
  const [state, setState] = useState<IState>({
    dau: 0,
    revenue: 0,
    ecpm: 0,
    installs: 0,
  });
  const [app, setApp] = useState<IApp>({
    apps: 0,
    approved: 0,
  });
  const [med, setMed] = useState<IMed>({
    meds: 0,
    opened: 0,
    closed: 0,
  });
  const [rev, setRev] = useState<IRev>({
    canRequest: 0,
    revenue: 0,
  });

  const [errorData, setErrorData] = useState<any>(null);

  const [{ data, loading, error }] = useAxios<IResPubHome>(
    {
      url: pubHomeAPI.home,
      method: 'POST',
    },
    { useCache: false },
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
        setState(data.state);
        setApp(data.app);
        setMed(data.med);
        setRev(data.rev);
      }
    }
  }, [data]);

  const handleNewApp = () => {
    history.push('/pub-app-create');
  };

  const handleActiveAllMedia = async (active: number) => {
    if (window.confirm(formatMessage({ id: 'confirm.execute' }))) {
      try {
        dispatch(setIsFullLoader(true));

        const { data: activeData } =
          await defaultAxios.post<IResActiveAllMedia>(
            pubHomeAPI.activeAllMedia,
            {
              active,
            },
          );

        dispatch(setIsFullLoader(false));

        if (activeData.error === adtier0.ERROR[adtier0.ERROR.SUCCESS]) {
          alert(formatMessage({ id: 'alert.execute' }));
          setMed(activeData.med);
        } else {
          alert(formatMessage({ id: 'errors.UNKNOWN' }));
        }
      } catch (err) {
        dispatch(setIsFullLoader(false));

        setErrorData(err);
      }
    }
  };

  const handleRevenuRequest = () => {
    history.push('/pub-my-page-request');
  };

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (errorData) {
    if (errorData.response) {
      if (errorData.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw errorData;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <HomeNetwork state={state} />
      <DivisionLine />
      <HomeSummary
        app={app}
        med={med}
        rev={rev}
        handleNewApp={handleNewApp}
        handleActiveAllMedia={handleActiveAllMedia}
        handleRevenuRequest={handleRevenuRequest}
      />
    </>
  );
}

export default Home;
