import React from 'react';

import NumberFormat from 'react-number-format';

import {
  IReportByDaily,
  IReportByDailyApp,
  IReportByDailyMedia,
} from '~/models/pub/report';

import ReportDayListApp from './report-day07-list-app';

interface IReportDayListDailyProps {
  daily: IReportByDaily;
  registTime: string;
  appList: IReportByDailyApp[] | null;
  appID: string;
  medList: IReportByDailyMedia[] | null;
  handleClickDaily: (value: string) => void;
  handleClickApp: (value: string) => void;
}

function ReportDayListDaily({
  daily,
  registTime,
  appList,
  appID,
  medList,
  handleClickDaily,
  handleClickApp,
}: IReportDayListDailyProps) {
  const { RegistTime, Revenue, eCPM, Impressions, Installs, CTR } = daily;

  return (
    <>
      <tr onClick={() => handleClickDaily(RegistTime)}>
        <th>
          <i
            className={
              registTime === RegistTime ? 'depth__plus open' : 'depth__plus'
            }
          />
        </th>
        <th className="text__left border__right table__title">
          <span>{RegistTime}</span>
        </th>
        <td className="text__right table__important">
          <NumberFormat value={Revenue} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">
          <NumberFormat value={eCPM} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">
          <NumberFormat
            value={Impressions}
            displayType="text"
            thousandSeparator
          />
        </td>
        <td className="text__right">
          <NumberFormat value={Installs} displayType="text" thousandSeparator />
        </td>
        <td className="text__right">{CTR}</td>
      </tr>
      {registTime === RegistTime &&
        appList &&
        appList.map((app, index) => (
          <ReportDayListApp
            key={index}
            app={app}
            appID={appID}
            medList={medList}
            handleClickApp={handleClickApp}
          />
        ))}
    </>
  );
}

export default ReportDayListDaily;
