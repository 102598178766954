import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  OverlayContentTop,
  OverlayTopCreate,
  OverlayTopWrap,
} from '~/components/elements/overlay';

interface ITabProps {
  currentTab: number;
}

function Tab({ currentTab }: ITabProps) {
  const history = useHistory();

  return (
    <OverlayContentTop className="create">
      <OverlayTopWrap>
        <h4 className="overlay__title m_tit">
          <FormattedMessage id="pubmypage.revenueGive" />
        </h4>
        <OverlayTopCreate className="xs_tit">
          <ul>
            <li
              className={
                currentTab === 1 ? 'cursor__pointer active' : 'cursor__pointer'
              }
              onClick={() => {
                history.push('/pub-my-page-revenue-details');
              }}
            >
              <FormattedMessage id="pubmypage.revenueGive.tab.history" />
            </li>
            <li
              className={
                currentTab === 2 ? 'cursor__pointer active' : 'cursor__pointer'
              }
              onClick={() => {
                history.push('/pub-my-page-doc');
              }}
            >
              <FormattedMessage id="pubmypage.revenueGive.tab.doc" />
            </li>
            <li
              className={
                currentTab === 3 ? 'cursor__pointer active' : 'cursor__pointer'
              }
              onClick={() => {
                history.push('/pub-my-page-request');
              }}
            >
              <FormattedMessage id="pubmypage.revenueGive.tab.request" />
            </li>
            <li
              className={
                currentTab === 4 ? 'cursor__pointer active' : 'cursor__pointer'
              }
              onClick={() => {
                history.push('/pub-my-page-request-list');
              }}
            >
              <FormattedMessage id="pubmypage.revenueGive.tab.requestList" />
            </li>
          </ul>
        </OverlayTopCreate>
      </OverlayTopWrap>
    </OverlayContentTop>
  );
}

export default Tab;
