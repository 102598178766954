module.exports = {
  'language.ko': 'Korean',
  'common.prev': 'Previous',
  'common.next': 'Next',
  'common.create': 'Create',
  'common.modify': 'Modify',
  'common.select': 'SELECT',
  'common.fileselect': 'Select File',
  'common.cancel': 'Cancel',
  'common.formatDay': '{day} days',
  'common.all': 'All',
  'common.search': 'Inquiry',
  'common.close': 'Close',
  'common.request': 'Apply',
  'common.registTime': 'Registration Time',
  'common.updateTime': 'Modification Time',
  'common.delete': 'Delete',
  'alert.add': 'Created',
  'alert.modify': 'Changed.',
  'alert.request': 'Applied',
  'alert.stop': 'Stopped.',
  'alert.start': 'Started.',
  'alert.execute': 'Executed.',
  'alert.clipboard': 'Copied to clipboard',
  'alert.ready': 'Preparing.',
  'alert.fileSize': 'File sizes can be up to {size} MB.',
  'alert.confirm': 'Certified',
  'alert.delete': 'Deleted',
  'confirm.signout': 'Log out?',
  'confirm.add': 'Do you want to create?',
  'confirm.modify': 'Do you want to change?',
  'confirm.request': 'Would you like to apply?',
  'confirm.allStop': 'Do you want to stop all?',
  'confirm.allStart': 'Do you want to start all?',
  'confirm.stop': 'Do you want to stop?',
  'confirm.start': 'Do you want to start?',
  'confirm.execute': 'Do you want to run?',
  'confirm.delete': 'Do you want to delete?',
  'validate.required': 'Required',
  'validate.min': 'The minimum value is {min}.',
  'validate.max': 'The maximum value is {max}.',
  'validate.maxLength': 'Maximum length is up to {len}.',
  'validate.url': 'Please enter the correct url',
  'validate.email': 'Please enter a valid email.',
  'validate.pw': 'Please enter a valid password',
  'validate.pwNotMatch': 'Passwords do not match.',
  'validate.minMax': 'Please enter at least {min}',
  'validate.onlyNum': 'Please enter numbers only.',
  'validate.searchDay': 'The searchable date is from 1 to {days}.',
  'errors.UNKNOWN': 'Unknown error. \nPlease contact your administrator.',
  'errors.NEED_SIGNUP': 'Please sign up and use',
  'errors.ALREADY_SIGNUP': 'The mail is already in use.',
  'errors.TOKEN_EXPIRED': 'The token has expired.',
  'errors.DUPLICATE_EMAIL': 'The mail is already in use.',
  'errors.INVALID_PW': 'Please confirm your password.',
  'errors.INVALID_CONFTOKEN': 'Invalid token.',
  'errors.UNKNOWN_TIMEZONE': 'Unknown time zone.',
  'errors.ONLY_BUSINESSMAIL': 'You can only use business mail.',
  'errors.DAYLIMIT_LESSTHAN':
    'The day limit must be less than the campaign budget.',
  'errors.PROLIMIT_LESSTHAN':
    'Your ad limit must be less than your campaign budget.',
  'errors.GREATER_THANZERO': 'Limit must be greater than 0.',
  'errors.INVALID_TRACKURLAOS': 'Invalid Tracking URL (Android)',
  'errors.INVALID_TRACKURLIOS': 'Invalid tracking URL (iOS).',
  'errors.MUSTBE_STOPPED': "You'll need to stop advertising.",
  'errors.INVALID_SESSIONKEY': 'You are logged in elsewhere.',
  'errors.DUPLICATE_REQUEST': 'Duplicate payment request.',
  'errors.NOTENOUGH_REVENUE': 'The minimum payable amount is 100,000.',
  'errors.INVALID_PERIOD': 'It is not a period of application.',
  'errors.NEED_ACCOUNT': 'You will need to submit documents.',
  'errors.INVALID_VCODE': 'Invalid authentication number.',
  'errors.VCODE_EXPIRED': 'Your authentication number has expired.',
  'hint.budget': 'Campaign Budget',
  'hint.budget.desc':
    'When the campaign budget is full, the child ads will not appear.',
  'hint.limit': 'limit',
  'hint.limit.desc':
    'If you exceed the specified limit, this advertisement will not be served.',
  'hint.bid': 'bid',
  'hint.bid.desc':
    'Minimum, Maximum Median The bid will succeed if this award is greater than or equal to.',
  'hint.banner': 'Banner',
  'hint.banner.desc':
    "You can stay on the screen while the user interacts with your app and refresh automatically after a certain amount of time. <br />If you're new to mobile advertising, start with this format.",
  'hint.interstitial': 'Interstitial',
  'hint.interstitial.desc':
    'Your ads will appear naturally when your access is suspended, such as when you move to the next level in your app. <br />When an Interstitial ad appears in your app, you can also tap the ad to navigate to the target, or close the ad and return to the app.',
  'hint.video': 'Video',
  'hint.video.desc':
    "Video ads are ads that embed video content that you can click or tap on. <br />This ad allows users to watch video included in an ad within the app before going to the relevant app store and installing the app they're promoting.",
  'hint.cpm': 'CPM',
  'hint.cpm.desc':
    "CPM stands for 'Cost Per Mille' for cost per 1000 impressions. <br />You pay when your ads run 1000 times and are most effective in enhancing your brand's awareness and engagement.",
  'hint.cpc': 'CPC',
  'hint.cpc.desc':
    "CPC stands for 'Cost Per Click', which means Cost Per Click'. <br />This is the most effective way to improve performance or drive specific behaviors (such as visiting a website).",
  'hint.cpv': 'CPV',
  'hint.cpv.desc':
    'CPV stands for Cost Per View, which means cost per video watch. <br />Easily communicate information by showing users additional information about your app, such as downloads, ratings, screenshots, and more with Video.',
  'hint.ncpi': 'nCPI',
  'hint.ncpi.desc':
    "NCPI stands for 'Non-incentive Cost Per Install' <br />NCPI is an effective way to drive app installs without rewarding users.",
  'hint.ncpa': 'nCPA',
  'hint.ncpa.desc':
    "NCPA stands for 'Non-incentive Cost Per Action' <br />NCPA is effective in driving certain actions within your app (e.g., tutorials, level-up, etc.) without rewarding the user.",
  'hint.addinfo': 'More Info',
  'hint.addinfo.desc':
    'Enter the Store URL of the released app to get additional information from the app and display it as an endscreen.',
  'hint.ctr': 'CTR',
  'hint.ctr.desc': 'CTR = Clicks/Impressions',
  'hint.ecpm': 'eCPM',
  'hint.ecpm.desc':
    "eCPM = (Revenue/Impressions) x 1,000<br />eCPM is an estimate of the publisher's revenue per 1,000 ad impressions.",
  'hint.revenue': 'IMPORTS',
  'hint.revenue.desc': 'Earnings from the app.',
  'hint.install': 'Installations',
  'hint.install.desc': 'The number of installations that came through the app.',
  'week.sunday': 'Sundays',
  'week.monday': 'Monday',
  'week.tuesday': 'Tuesday',
  'week.wednesday': 'Wednesday',
  'week.thursday': 'Thursday',
  'week.friday': 'Fridays',
  'week.saturday': 'Saturday',
  'week.0': 'Sun',
  'week.1': 'Mon',
  'week.2': 'Tue',
  'week.3': 'Wed',
  'week.4': 'Thu',
  'week.5': 'Fri',
  'week.6': 'Sat',
  'logo.advertiser': 'Advertiser',
  'logo.publisher': 'Publisher (Developer)',
  'gnb.home.index': 'Benefits of ZENAAD',
  'gnb.home.start': 'Getting Started',
  'gnb.home.resource': 'Resources',
  'gnb.home.announce': 'NOTICE',
  'gnb.sdkAndSupport': 'Learning and Support',
  'gnb.sdkAndSupport.sdk': 'SDK',
  'gnb.sdkAndSupport.support': 'SUPPORT',
  'gnb.app': 'Apps',
  'gnb.app.game': 'GAME',
  'gnb.app.app': 'Apps',
  'gnb.partner': 'Partner',
  'gnb.partner.mediation': 'Mediation',
  'gnb.partner.publisher': 'Publishers',
  'gnb.help': 'Inquiry',
  'gnb.help.help': 'Contact us',
  'gnb.privacy': 'Privacy Policy',
  'gnb.adTerms': 'Advertiser Terms of Use',
  'gnb.pubTerms': 'Publisher Terms of Use',
  'gnb.ad.home': 'Home',
  'gnb.ad.campaign': 'Campaign',
  'gnb.ad.ad': 'Advertising',
  'gnb.ad.report': 'Metrics',
  'gnb.ad.report.campaign': 'Metrics by Campaign',
  'gnb.ad.report.ad': 'Metrics by Advertising',
  'gnb.pub.home': 'Home',
  'gnb.pub.app': 'Apps',
  'gnb.pub.inventory': 'Inventory',
  'gnb.pub.appReward': 'Rewards',
  'gnb.pub.report': 'Metrics',
  'gnb.pub.report.day': 'Daily Metrics',
  'gnb.pub.report.app': 'Metrics by App',
  'gnb.pub.report.user': 'User Metrics',
  'gnb.signin': 'Login',
  'gnb.signout': 'Logout',
  'gnb.mypage': 'MY PAGE',
  'gnb.manageConsole': 'Admin Console',
  'signin.signin': 'Login',
  'signin.joinMessage': 'Not yet signed up for <b>ZENAAD</b>?',
  'signin.join': 'JOIN',
  'signin.findEmailMessage': 'Forgot your email?',
  'signin.findEmail': 'Find E-mail',
  'signin.findPasswordMessage': 'Forgot your password?',
  'signin.findPassword': 'Forgot Password',
  'findEmail.title': 'Forgot your email?',
  'findEmail.submit': 'OK',
  'findEmail.desc':
    'ZENAAD uses an email as an ID. <br />Enter the email you remember. <br />We will confirm your subscription status.',
  'findEmail.success': 'Email registered as a member.',
  'findEmail.login': 'Login',
  'findEmail.loginMessage': 'Please {login} by email and use ZENAAD.',
  'findEmail.failed': 'An account that is not registered with ZENAAD.',
  'findpw.title': 'Forgot your password?',
  'findpw.submit': 'Send authenticated email',
  'findpw.step':
    'Change the password in a simple procedure. <br />This method will keep your password safe.',
  'findpw.step1': 'Please enter your registered email address.',
  'findpw.step2': 'We will send you a link to reset your password.',
  'findpw.step3': 'Please register a new password within 3 minutes.',
  'signup.email.title':
    'Please check your email and proceed to the next registration process.',
  'signup.email.submit': 'Send authenticated email',
  'signup.email.step': 'The sign-up process is as follows:',
  'signup.email.step1':
    'A verification email will be sent to the entered email address.',
  'signup.email.step2':
    'Please click on the “Verify Email” link in the verification email.',
  'signup.email.step3':
    'Please enter basic member information after agreeing to the Terms.',
  'signup.email.step4': 'Subscribe',
  'signup.email.sendSuccess':
    'A verification email has been sent. \nPlease check your email.',
  'signup.agree.advertiser': 'Advertiser',
  'signup.agree.publisher': 'Publisher<span>(Developer)</span>',
  'sugnup.agree.agree': 'I agree to the terms',
  'sugnup.agree.notAgree': 'Agree to the Terms',
  'signup.form.pwDesc':
    'The password is at least 8 characters long and at least one number, special characters! Must include @#$%^*+=-',
  'signup.form.join': 'JOIN',
  'signup.success': 'You are subscribed.',
  'signup.success.welcome': 'Welcome to ZENAAD!',
  'signup.success.info': "{name}'s id is {email}.",
  'signup.success.start': 'Getting Started',
  'home.xpla.title':
    'The ZENAAD mainnet will change from Ethereum to {link}.{br}Coins earned through the ZENAAD platform can be found on {xPlayz}!',
  'home.xpla.btn': 'View',
  'home.xplaintro.title':
    'Check the profits earned through the ZENAAD platform on xPlayz and<br />earn additional points through various activities within the app to receive XPLA.',
  'home.xplaintro.wallet1': 'A standard wallet that can manage XPLA payments.',
  'home.xplaintro.wallet2':
    'Payments are made automatically when a certain amount of XPLA is accumulated through the ZENAAD platform.',
  'home.xplaintro.reward1': 'Volts are distributed through in-app activities.',
  'home.xplaintro.reward2': 'You can get additional bonuses by using volts.',
  'home.xplaintro.reward3':
    'Volts are points that can only be used within the app.',
  'home.intro.title': 'Experience the strengths of <strong>ZENAAD</strong>',
  'home.intro.subTitle':
    "Use ZENAAD's user metrics and reports to increase app revenue and gain actionable insights.",
  'home.subNav.revenue': 'Stable earnings',
  'home.subNav.format': 'Multiple ad formats',
  'home.subNav.data': 'Useable Data',
  'home.revenue.title': 'Stable revenue is guaranteed',
  'home.revenue.subTitle':
    'Connected to a highly performing advertising network.',
  'home.revenue.network': 'Global Ad Network',
  'home.revenue.networkDesc':
    'ZENAAD works with a global advertising network, so you can show your ads anytime, anywhere.',
  'home.revenue.bid': 'Real-Time Bid',
  'home.revenue.bidDesc':
    'Participate in bids in real time to get advertisers at the best prices at all times.',
  'home.revenue.share': 'User Revenue Sharing',
  'home.revenue.shareDesc':
    'Increase engagement with your app by sharing 10% of the awarded price to your users.',
  'home.revenue.networkResult': 'Network Performance',
  'home.revenue.partner': 'Partner Ad Network',
  'home.revenue.partnerDesc1': 'High exposure rates are guaranteed.',
  'home.revenue.partnerDesc2':
    "It's integrated with global ad networks like Mobvista and delivers real-time ads to ensure high impressions.",
  'home.format.title': 'Take advantage of ad formats optimized for mobile apps',
  'home.format.banner': 'Banner ads',
  'home.format.bannerDesc':
    "You can stay on the screen while users interact with your app and refresh automatically after a certain amount of time. If you're new to mobile advertising, start with this format.",
  'home.format.inter': 'Interstitial advertising',
  'home.format.interDesc':
    'Your ads will appear naturally when your access is suspended, such as when you move to the next level in your app. When an Interstitial ad appears in an app, the user can also tap the ad to go to the target, or close the ad and return to the app.',
  'home.format.video': 'Video Ad',
  'home.format.videoDesc':
    "Video ads are ads that embed video content that you can click or tap. This ad allows users to watch video included in an ad within the app before going to the relevant app store and installing the app they're promoting.",
  'home.format.native': 'Native ads (coming soon)',
  'home.format.nativeDesc':
    'You can serve ads that look like parts of your app naturally in native app code.',
  'home.format.player': 'Playerable ads (coming soon)',
  'home.format.playerDesc':
    'An ad format that provides rewards to users who have interacted with ads. Effective for monetizing users of free games.',
  'home.data.title': 'Get the most out of your insights',
  'home.data.user': 'User Metrics',
  'home.data.userDesc':
    'Use analytics data to identify and respond to factors that may impact your bottom line.',
  'home.data.report': 'report',
  'home.data.reportDesc':
    'It is designed to understand the impact on expected revenue and earnings. Includes charts and tables so you can view overall trends and compare important metrics.',
  'start.title': 'Sign up quickly and earn money',
  'start.email': 'Sign up quickly with email verification',
  'start.email.step01': 'Verify your email and log in to your ZENAAD account.',
  'start.email.step02':
    'Create ads and integrate the ZENAAD SDK into your app.',
  'start.email.step03': 'Use insights to monetize.',
  'start.with': 'Are you with<b>ZENAAD</b>?',
  'start.join': 'JOIN',
  'resource.title':
    'Take your app to the next level with these articles, resources, and best practices',
  'resource.subNav.doc': 'document',
  'resource.subNav.media': 'MEDIA',
  'resource.subNav.success': 'Success Stories',
  'resource.introduce': 'Introduction to ZENAAD',
  'resource.business': 'Business Lessons',
  'resource.businessDesc': 'This is the ZENAAD business introduction.',
  'resource.media': 'Media Intro',
  'resource.mediaDesc': 'This is the introduction to ZENAAD media.',
  'resource.ad': 'Advertiser',
  'resource.adGuide': "Advertiser's Guide",
  'resource.adGuideDesc': 'This is a guide for advertisers.',
  'resource.logo': 'ZENAAD Logo',
  'resource.mainLogo': 'Main Logo',
  'resource.mainLogoDesc': 'This is the main logo of ZENAAD.',
  'resource.walletLinkLogo': 'Wallet Link Logo',
  'resource.walletLinkLogoDesc': 'ZENAAD wallet link logo used in games/apps.',
  'resource.success': 'Success Stories',
  'resource.ready': 'Preparing',
  'resource.update': 'Scheduled to be updated.',
  'sdk.title': 'Integrate the SDK into your app',
  'sdk.titleDesc':
    'To serve and monetize ZENAAD ads, first integrate the SDK into your app. <br />Once the integration is complete, select the ad format and follow the guide to implement the details.',
  'sdk.default': 'Basics',
  'sdk.default.ios': 'Xcode 10+',
  'sdk.default.android': 'Android API Level 14 and above',
  'sdk.default.unity': '5.1 or later',
  'sdk.download': 'Download',
  'sdk.guide.kr': 'Guide (EN)',
  'sdk.release': 'Release Notes',
  'game.app': 'Apps',
  'game.title': 'Experience Optimal Monetization',
  'game.subTitle':
    'Many apps are already experiencing optimal monetization with ZENAAD.',
  'game.game': 'GAME',
  'app.app': 'Apps',
  'app.title': 'Experience Optimal Monetization',
  'app.subTitle':
    'Many apps are already experiencing optimal monetization with ZENAAD.',
  'mediation.mediation': 'Mediation',
  'mediation.title': 'Take advantage of our global advertising network',
  'mediation.subTitle':
    "Expand your business with ZENAAD's global advertising network.",
  'mediation.sp': 'SmartPosting',
  'mediation.sp.title': 'Influencer Marketing Platform',
  'mediation.sp.subTitle':
    'SmartPosting provides digital advertising agencies and influencer marketing solutions. Anyone who uses social media such as Facebook, Cacao, Blog, YouTube, Instagram, can challenge the influencer through SmartPosting and offer a variety of opportunities for content monetization.',
  'mediation.ms': 'Movista',
  'mediation.ms.title': 'Mobile Integrated Marketing Business Platform',
  'mediation.ms.subTitle':
    'Innovative ad creative materials and predictive analytics based on cutting-edge data technologies enable leading companies to grow even more.',
  'mediation.vt': 'Ventumm',
  'mediation.vt.title': 'Digital Marketing Agency',
  'mediation.vt.subTitle':
    'Ventumm is an advertising company with a unique global success experience. Comprising of data analysts and skilled performance marketing experts, we offer a generous set of experience and insights gained directly from the global field. We are committed to helping advertisers lead fun adventures on the world market.',
  'mediation.admax': 'ADMAX',
  'mediation.admax.title': 'Social Influencer Marketing',
  'mediation.admax.subTitle':
    'As a subsidiary of FSN, ADMAX collaborates with over 20 different AD-Agency and AD-Tech affiliates, including ADQua and CAULY. We have one of the largest Social Media Partnerships in Korea and run a variety of performance marketing advertisements tailored to the needs of advertisers.',
  'publisher.publisher': 'Publishers',
  'publisher.title': 'Want to monetize ads?',
  'publisher.subTitle': 'Leading mobile gaming companies choose ZENAAD.',
  'publisher.itam': 'ITAM Games',
  'publisher.itam.title': 'Blockchain Mobile Game Platform',
  'publisher.itam.subTitle':
    'ITAM Games is driving innovation in the gaming ecosystem through blockchain gaming platforms. We are also working to make Play-to-Earn a reality by providing SDKs for developers and providing decentralized digital asset transactions.',
  'publisher.rhaon': 'Rhaon Entertainment',
  'publisher.rhaon.title': 'A venture that specializes in developing games',
  'publisher.rhaon.subTitle':
    'It is a company that specializes in developing PC and mobile games. The iconic Talesrunner is a masterpiece racing game that has been served since 2005 and has a total of 1.3 million members. After the Smilegate Megaport moved to its second heyday, breaking over 605,000 peak users.',
  'publisher.kai': 'Kai Entertainment',
  'publisher.kai.title': 'Developers of mobile game apps and web services',
  'publisher.kai.subTitle':
    'A start-up company specializing in mobile game apps and web services. We recently launched a mobile game called K-Pop Poly, based on a variety of star photos held by Dispatch (Korean online entertainment media).',
  'publisher.three': '3F Factory',
  'publisher.three.title': 'Developers for mobile games',
  'publisher.three.subTitle':
    'It is a company that specializes in mobile games. The iconic game Mystic Apartment-Gosthunter is a popular animated mystical apartment from CJ ENM and is now one of the most popular games with 1 million downloads.',
  'publisher.weneed': 'Wenee',
  'publisher.weneed.title': 'Provide game content and solutions',
  'publisher.weneed.subTitle':
    'Weneed has the highest level of staff to lead the industry and optimized consulting methodologies at a global level. Based on these competencies, we aim for IT leaders who contribute to the competitive advantage of our customers based on standardized methodologies from strategy to solution consulting.',
  'publisher.sinest': 'SINEST',
  'publisher.sinest.title': 'Digital Asset Fun Platform',
  'publisher.sinest.subTitle':
    'SINEST provides Adapters, a blockchain application. With the spirit of fair/share/trust, Adapter creates a fun world connected with blockchain where users, partners and everyone grow value together.',
  'help.help': 'Inquiry',
  'help.title': 'Need help?',
  'help.subTitle':
    'Please fill out the following form to contact a ZENAAD specialist and we will contact you as soon as possible.',
  'help.send.success': 'An email was sent.',
  'mypage.mypage': 'MY PAGE',
  'mypage.default': 'Basic Info',
  'mypage.default.email': 'E-mail',
  'mypage.default.password': 'Password',
  'mypage.default.name': 'Name',
  'mypage.default.company': 'Company Name',
  'mypage.default.timezone': 'Time Zone',
  'mypage.addinfo': 'More Info',
  'mypage.addinfo.mobile': 'Mobile phone number',
  'mypage.pwChange': 'Change Password',
  'mypage.pwChange.currentPassword': 'Current Password',
  'mypage.pwChange.newPassword': 'New Password',
  'mypage.pwChange.passwordConfirm': 'Confirm Password',
  'mypage.pwChange.pwDesc':
    'The password is at least 8 characters long and at least one number, special characters! Must include @#$%^*+=-',
  'admypage.asset': 'Assets',
  'admypage.asset.cash': 'Cash',
  'admypage.asset.payment': 'Payment',
  'admypage.asset.paymentHistory': 'Payment History',
  'admypage.asset.history': 'History',
  'pubmypage.revenueGive': 'income',
  'pubmypage.revenueGive.revenueHistory': 'Revenue History',
  'pubmypage.revenueGive.doc': 'Submit Documents',
  'pubmypage.revenueGive.doc.submit': 'Submit',
  'pubmypage.revenueGive.doc.notSubmit': 'Unsubmitted',
  'pubmypage.revenueGive.giveRequest': 'Apply for Payment',
  'pubmypage.revenueGive.request': 'Apply',
  'pubmypage.revenueGive.giveRequestHistory': 'Payment Application History',
  'pubmypage.revenueGive.history': 'History',
  'pubmypage.revenueGive.tab.history': 'Revenue History',
  'pubmypage.revenueGive.tab.doc': 'paperwork',
  'pubmypage.revenueGive.tab.request': 'Apply for Payment',
  'pubmypage.revenueGive.tab.requestList': 'Payment Application History',
  'pubmypage.revenueGive.revenueDetails.month': 'month',
  'pubmypage.revenueGive.revenueDetails.revenue': 'IMPORTS',
  'pubmypage.revenueGive.revenueDetails.eCPM': 'eCPM',
  'pubmypage.revenueGive.revenueDetails.impressions': 'Impressions',
  'pubmypage.revenueGive.doc.name': 'depositors',
  'pubmypage.revenueGive.doc.bank': 'BANK',
  'pubmypage.revenueGive.doc.bankNum': 'Account number',
  'pubmypage.revenueGive.doc.bankBook': 'Copy of Passbook',
  'pubmypage.revenueGive.doc.bankBookError':
    'Please select a copy of the passbook.',
  'pubmypage.revenueGive.doc.certificate': 'Business license',
  'pubmypage.revenueGive.doc.certificateError':
    'Please select your business registration certificate.',
  'pubmypage.revenueGive.giveRequest.revenue': 'income',
  'pubmypage.revenueGive.giveRequest.sum': 'Amount',
  'pubmypage.revenueGive.giveRequest.guide': 'GUIDE',
  'pubmypage.revenueGive.giveRequest.min': 'Minimum Applicable Earnings',
  'pubmypage.revenueGive.giveRequest.period': 'Application Period',
  'pubmypage.revenueGive.giveRequest.periodDesc': 'Every month 1-5th 23:59',
  'pubmypage.revenueGive.giveRequest.date': 'Date to be paid',
  'pubmypage.revenueGive.giveRequest.dateDesc1':
    'Payment until the end of next month',
  'pubmypage.revenueGive.giveRequest.dateDesc2':
    'Example) proceeds for July, application until August 5, payment by September 30',
  'pubmypage.revenueGive.giveRequestHistory.date': 'time',
  'pubmypage.revenueGive.giveRequestHistory.name': 'depositors',
  'pubmypage.revenueGive.giveRequestHistory.bank': 'BANK',
  'pubmypage.revenueGive.giveRequestHistory.bankNum': 'Account Number',
  'pubmypage.revenueGive.giveRequestHistory.revenue':
    'Application Revenue (KRW)',
  'ad.home.network': 'Network Performance',
  'ad.home.impression': 'Total. Impressions',
  'ad.home.click': 'Total. Clicks',
  'ad.home.ctr': 'CTR',
  'ad.home.summary': 'Summary',
  'ad.home.campaign': 'Campaign',
  'ad.home.newcampaign': 'New Campaign',
  'ad.home.all': 'All',
  'ad.home.advertising': 'Advertising',
  'ad.home.allStop': 'Stop All',
  'ad.home.allStart': 'Start All',
  'ad.home.inprogress': 'ongoing',
  'ad.home.winning': 'Awarded',
  'ad.home.complete': 'Completed',
  'ad.home.asset': 'Assets',
  'ad.home.cash': 'Cash',
  'ad.home.needpay': 'Payment is required.',
  'ad.home.gopay': 'Shortcuts',
  'ad.campaign': 'Campaign',
  'ad.campaign.list': 'Campaign List',
  'ad.campaign.campName': 'Campaign Name',
  'ad.campaign.newCampaign': 'New Campaign',
  'ad.campaign.campType': 'Platforms',
  'ad.campaign.remainBalance': 'Budget Remaining (KRW)',
  'ad.campaign.promotion': 'Advertising',
  'ad.campaign.inProgress': 'ongoing',
  'ad.campaign.complete': 'Completed',
  'ad.campaign.create': 'Create a new campaign',
  'ad.campaign.app': 'Apps',
  'ad.campaign.budget': 'Campaign Budget',
  'ad.campaign.country': 'Country',
  'ad.campaign.targetUrl': 'Target URL',
  'ad.campaign.detail': 'DETAILS',
  'ad.campaign.goAd': 'Shortcut to ads',
  'ad.ad': 'Advertising',
  'ad.ad.list': 'Ad List',
  'ad.ad.proName': 'Name',
  'ad.ad.newPromotion': 'New ads',
  'ad.ad.emptyCampaign': 'Please create a campaign first.',
  'ad.ad.status': 'Status',
  'ad.ad.platform': 'Platforms',
  'ad.ad.product': 'Ad Type',
  'ad.ad.billingType': 'Pricing Model',
  'ad.ad.avgCost': 'Bid',
  'ad.ad.create': 'Create a new ad',
  'ad.ad.material': 'Material',
  'ad.ad.app': 'Apps',
  'ad.ad.info': 'More',
  'ad.ad.schedule': 'Issue Frequency',
  'ad.ad.limit': 'limit',
  'ad.ad.bid': 'bid',
  'ad.ad.banner': 'Banner',
  'ad.ad.interstitial': 'Interstitial',
  'ad.ad.video': 'Video',
  'ad.ad.first': 'Stopped',
  'ad.ad.waiting': 'Stopped',
  'ad.ad.inprogress': 'ongoing',
  'ad.ad.complete': 'Completed',
  'ad.ad.bannerDesc':
    "You can stay on the screen while the user interacts with your app and refresh automatically after a certain amount of time. If you're new to mobile advertising, start with this format.",
  'ad.ad.interstitialDesc':
    'Your ads will appear naturally when your access is suspended, such as when you move to the next level in your app. When an Interstitial ad appears in your app, you can also tap the ad to navigate to the target, or close the ad and return to the app.',
  'ad.ad.videoDesc':
    "Video ads are ads that embed video content that you can click or tap on. This ad allows users to watch video included in an ad within the app before going to the relevant app store and installing the app they're promoting.",
  'ad.ad.cpmDesc':
    "CPM stands for 'Cost Per Mille' for cost per 1000 impressions. You pay when your ads run 1000 times and are most effective in enhancing your brand's awareness and engagement.",
  'ad.ad.cpcDesc':
    "CPC stands for 'Cost Per Click', which means Cost Per Click'. This is the most effective way to improve performance or drive specific behaviors (such as visiting a website).",
  'ad.ad.cpvDesc':
    'CPV stands for Cost Per View, which means cost per video watch. Easily communicate information by showing users additional information about your app, such as downloads, ratings, screenshots, and more with Video.',
  'ad.ad.ncpiDesc':
    "NCPI stands for 'Non-Incentive Cost Per Install'. nCPI is effective in driving app installs without compensation to users.",
  'ad.ad.ncpaDesc':
    "NCPA stands for 'Non-Incentive Cost Per Action'. nCPA is effective in driving specific actions (e.g. tutorials, level-up, etc.) within your app without rewarding the user.",
  'ad.ad.comment': 'Description',
  'ad.ad.image': 'Image',
  'ad.ad.imageBanner':
    'You can choose up to 2 Banner images. <br />The extension can only be jpg, png.',
  'ad.ad.imageInter':
    'Only 1 Interstitial image can be selected. <br />The extension can only be jpg, png.',
  'ad.ad.imageVideo': 'Only files with the extension mp4, mov.',
  'ad.ad.imageError':
    'Only images with the extension jpg, png, or 500 KB or less can be registered.',
  'ad.ad.videoError':
    'Only videos with mp4, mov, or less than 10 MB can be registered.',
  'ad.ad.videoDuration': 'Video time is available from 5 to 35 seconds.',
  'ad.ad.videoRetry': 'Failed to upload video file \nPlease register again.',
  'ad.ad.fileSelect': 'Select File',
  'ad.ad.confirmEncoding': 'This is a time-consuming operation. Encode?',
  'ad.ad.country': 'Country',
  'ad.ad.campType': 'Platforms',
  'ad.ad.targetUrl': 'Target URL',
  'ad.ad.addInfo': 'More Info',
  'ad.ad.notAddInfo':
    'The ad type does not provide additional information functionality.',
  'ad.ad.storeUrl': 'Store URL',
  'ad.ad.invalidStoreURL': 'Please register a valid address.',
  'ad.ad.getAppInfo': 'Get app info',
  'ad.ad.storeAppName': 'Name',
  'ad.ad.grade': 'Rated',
  'ad.ad.downloadCount': 'Downloads',
  'ad.ad.genre': 'Genre',
  'ad.ad.chargeNoCharge': 'Ye/Free',
  'ad.ad.charge': 'Paid',
  'ad.ad.noCharge': 'Free',
  'ad.ad.showGradeDownloadCount': 'Show Rate/Downloads',
  'ad.ad.daily': 'daily',
  'ad.ad.weekday': 'weekdays',
  'ad.ad.weekend': 'Weekend',
  'ad.ad.directSelect': 'Direct Select',
  'ad.ad.dayLimit': 'Day Limit',
  'ad.ad.budgetLimit': 'Ad Limits',
  'ad.ad.bidCost': 'Recent Awards',
  'ad.ad.minCost': 'Lowest',
  'ad.ad.maxCost': 'Top',
  'ad.ad.goCampaign': 'Shortcut to Campaign',
  'ad.ad.detail': 'DETAILS',
  'ad.ad.detailNoti': "If you want to make changes, you'll need to stop ads.",
  'ad.report.campaign': 'Metrics by Campaign',
  'ad.report.totalImpression': 'Total. Impressions',
  'ad.report.totalClick': 'Total. Clicks',
  'ad.report.ctr': 'CTR',
  'ad.report.campName': 'Campaign Name',
  'ad.report.impression': 'Impressions',
  'ad.report.click': 'Clicks',
  'ad.report.ctrper': 'CTR (%)',
  'ad.report.execute': 'Enforcement Costs',
  'ad.report.ad': 'Metrics by Advertising',
  'ad.report.proName': 'Ad Name',
  'pub.home.network': 'Network Performance',
  'pub.home.dau': 'DAU',
  'pub.home.revenue': 'Total. IMPORTS',
  'pub.home.ecpm': 'eCPM',
  'pub.home.install': 'Total. Number of Installations',
  'pub.home.summary': 'Summary',
  'pub.home.app': 'Apps',
  'pub.home.inventory': 'Inventory',
  'pub.home.revenueAmount': 'income',
  'pub.home.all': 'All',
  'pub.home.approval': 'Approved',
  'pub.home.open': 'open',
  'pub.home.close': 'closed',
  'pub.home.newapp': 'Create a new app',
  'pub.home.allclose': 'Close All',
  'pub.home.allopen': 'Open All',
  'pub.home.revenueRequest': 'Apply for Payment',
  'pub.home.revenueRequestDesc': 'You can apply for payment of income.',
  'pub.home.goRequest': 'Shortcuts',
  'pub.app': 'Apps',
  'pub.app.list': 'App List',
  'pub.app.appName': 'App Name',
  'pub.app.newApp': 'Create a new app',
  'pub.app.state': 'Status',
  'pub.app.platform': 'Platforms',
  'pub.app.approval': 'Approved',
  'pub.app.notApproval': 'Under Review',
  'pub.app.create': 'Create a new app',
  'pub.app.storeUrl': 'Store URL',
  'pub.app.apiKey': 'API KEY',
  'pub.app.apiKeyDayLimit': 'Day Limit',
  'pub.app.contentLevel': 'Content level',
  'pub.app.contentLevelDesc':
    'Does it contain content that is harmful to young people, such as sexuality, meandering, violence, etc.?',
  'pub.app.detail': 'DETAILS',
  'pub.app.apiKeyRequest': 'API KEY Request',
  'pub.app.apiKeyRequestWait': 'Waiting for approval',
  'pub.app.goInventory': 'Shortcut to Inventory',
  'pub.inven': 'Inventory',
  'pub.inven.list': 'Inventory List',
  'pub.inven.newInven': 'New Inventory',
  'pub.inven.emptyApp': 'Please create the app first.',
  'pub.inven.state': 'Status',
  'pub.inven.invenName': 'Inventory Name',
  'pub.inven.invenType': 'Inventory Type',
  'pub.inven.open': 'open',
  'pub.inven.close': 'closed',
  'pub.inven.create': 'Create a new inventory',
  'pub.inven.reward': 'Rewards',
  'pub.inven.name': 'Name',
  'pub.inven.id': 'ID',
  'pub.inven.idAuto': 'It will be automatically issued.',
  'pub.inven.rewardCount': 'Qty',
  'pub.inven.detail': 'DETAILS',
  'pub.inven.banner': 'Banner',
  'pub.inven.interstitial': 'Interstitial',
  'pub.inven.video': 'Video',
  'pub.inven.goApp': 'Shortcut to an app',
  'pub.inven.alertRewards': 'Please enter your reimbursement quantity',
  'pub.appReward': 'Reward',
  'pub.appReward.list': 'Reward List',
  'pub.appReward.newAppReward': 'New Reward',
  'pub.appReward.emptyApp': '앱을 먼저 생성해주십시오.',
  'pub.appReward.create': '새 보상 생성',
  'pub.appReward.rewardName': '보상 이름',
  'pub.appReward.reward': '보상 가격(원)',
  'pub.appReward.dayLimit': '일일 제한(원)',
  'pub.appReward.goApp': '앱으로 바로 가기',
  'pub.report.dailyReport': 'Daily Metrics',
  'pub.report.searchDesc': 'Tracking is available up to 180 days.',
  'pub.report.totalImpression': 'Total. Impressions',
  'pub.report.totalRevenue': 'Total. IMPORTS',
  'pub.report.ecpm': 'eCPM',
  'pub.report.daily': 'Day by day',
  'pub.report.impression': 'Impressions',
  'pub.report.revenue': 'IMPORTS',
  'pub.report.ctr': 'CTR',
  'pub.report.nru': 'NRU',
  'pub.report.install': 'Number of Installations',
  'pub.report.day': 'days',
  'pub.report.appReport': 'Metrics by App',
  'pub.report.appName': 'App Name',
  'pub.report.userReport': 'User Metrics',
  'pub.report.dau': 'DAU',
  'pub.report.totalNru': 'Total. NRU',
  'vcode.email': 'E-mail',
  'vcode.code': 'Verification Code',
  'vcode.sendSuccess':
    'A verification code has been sent to the email address you entered. \nPlease check within 3 minutes.',
  'test.zenaad': 'ZENAAD',
  'test.hwkim': 'Kim Hyunwoo',
};
