import React, { useState } from 'react';

import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { commonAPI } from '~/api';
import LineChart from '~/components/chart/line-chart';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { defaultAxios } from '~/libs/axios';
import { dataURItoBlob } from '~/libs/utils';

interface IReportDayChartProps {
  chartType: string;
  chartData: any;
  handleChangeChartType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const DayChartFilter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;

  ${FormInput} {
    display: flex;
    width: auto;
    min-width: 180px;
    margin-right: 1rem;
  }

  ${FormInputTitleWrap} {
    padding-right: 1rem;
    padding-bottom: 0;
  }
`;

const CurveChart = styled.div`
  width: 100%;
  height: 500px;
`;

const options = {
  chartArea: { width: '95%', height: '90%' },
  curveType: 'none',
  colors: ['#3F38D6'],
  legend: 'none',
  pointSize: 8,
  hAxis: {
    textStyle: {
      fontSize: `14`,
      color: '#666666',
    },
  },
  vAxis: {
    minValue: 0,
    textStyle: {
      fontSize: `14`,
      color: '#666666',
    },
    gridlines: {
      color: '#ebebeb',
    },
  },
};

function ReportDayChart({
  chartType,
  chartData,
  handleChangeChartType,
}: IReportDayChartProps) {
  const [imageURI, setImageURI] = useState<any>();

  const handleDownload = async () => {
    const blob = dataURItoBlob(imageURI);
    const filename = 'daily-report.png';

    const { data } = await defaultAxios.post(commonAPI.s3getSignedUrl);
    await defaultAxios.put(data.uploadURL, blob, {
      headers: {
        'Content-Type': 'image/png',
      },
    });

    const image = data.uploadURL.split('?')[0];

    saveAs(image, filename);
  };

  const handleImageURI = (uri: string) => {
    setImageURI(uri);
  };

  return (
    <>
      <DayChartFilter>
        <NoFloating>
          <FormInputContainer>
            <FormInput>
              <FormInputTitleWrap>
                <FormInputTitleName className="m_txt">
                  <FormattedMessage id="pub.report.daily" />
                </FormInputTitleName>
              </FormInputTitleWrap>
              <FormInputField>
                <select value={chartType} onChange={handleChangeChartType}>
                  <FormattedMessage id="pub.report.impression">
                    {(message) => <option value="impression">{message}</option>}
                  </FormattedMessage>

                  <FormattedMessage id="pub.report.revenue">
                    {(message) => <option value="revenue">{message}</option>}
                  </FormattedMessage>

                  <FormattedMessage id="pub.report.ecpm">
                    {(message) => <option value="ecpm">{message}</option>}
                  </FormattedMessage>

                  <FormattedMessage id="pub.report.ctr">
                    {(message) => <option value="ctr">{message}</option>}
                  </FormattedMessage>
                </select>
                <i className="icon-chevron-down" />
              </FormInputField>
            </FormInput>
          </FormInputContainer>
        </NoFloating>
        <button className="btn contentBtn btnLine" onClick={handleDownload}>
          <i className="icon-save_alt" />
          <div className="btn__txt">Download</div>
        </button>
      </DayChartFilter>

      <CurveChart>
        <LineChart
          chartData={chartData}
          options={options}
          handleImageURI={handleImageURI}
        />
      </CurveChart>
    </>
  );
}

export default ReportDayChart;
