import React from 'react';

import DefaultSlider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface ICarouselProps {
  matURLs: string[];
}

const Wrapper = styled.div`
  margin-top: 20px;
  max-width: 820px;

  .slick-slider {
    max-width: 820px;
  }

  @media screen and (max-width: 1155px) {
    max-width: calc(100vw - 268px);
  }

  @media screen and (max-width: 716px) {
    max-width: 100%;
  }

  img {
    width: 100%;
    padding: 0 0.5rem;
  }

  .slick-prev:before {
    display: none;
  }

  [dir='rtl'] .slick-prev:before {
    display: none;
  }

  .slick-next:before {
    display: none;
  }

  [dir='rtl'] .slick-next:before {
    display: none;
  }

  .slick-prev i,
  .slick-next i {
    opacity: 0.5;
    transition: 0.3s ease;
  }

  .slick-prev:hover i,
  .slick-prev:focus i,
  .slick-next:hover i,
  .slick-next:focus i {
    opacity: 1;
    transition: 0.3s ease;
  }
  .slick-prev.slick-disabled i,
  .slick-next.slick-disabled i {
    opacity: 0.25;
    transition: 0.3s ease;
  }
`;

const PrevArrowButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: translate(100%, -50%);

  i {
    color: #666;
    postion: relative;
  }
`;

const NextArrowButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: translate(-100%, -50%);

  i {
    color: #666;
    postion: relative;
  }
`;

const Slider = styled(DefaultSlider)``;

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <PrevArrowButton
      className={className}
      style={{
        width: '30px',
        height: '30px',
        backgroundColor: '#fff',
        borderRadius: '30px',
        boxShadow: '0px 3px 6px 0 rgba(0, 0, 0, 0.2)',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        transform: 'translate(100%, -50%)',
        color: '#666',
      }}
      onClick={onClick}
    >
      <i className="icon-chevron-left" />
    </PrevArrowButton>
  );
}

function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <NextArrowButton
      className={className}
      style={{
        width: '30px',
        height: '30px',
        backgroundColor: '#fff',
        borderRadius: '30px',
        boxShadow: '0px 3px 6px 0 rgba(0, 0, 0, 0.2)',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        transform: 'translate(-100%, -50%)',
        color: '#666',
      }}
      onClick={onClick}
    >
      <i className="icon-chevron-right" />
    </NextArrowButton>
  );
}

function Carousel({ matURLs }: ICarouselProps) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1155,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 716,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {matURLs &&
          matURLs.map((src, index) => (
            <div key={index}>
              <img src={src} alt="" />
            </div>
          ))}
      </Slider>
    </Wrapper>
  );
}

export default Carousel;
