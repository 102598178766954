import React, { useEffect, useRef } from 'react';

import { AxiosPromise } from 'axios';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import Hint from '~/components/common/hint';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayFooter,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';
import { adtier0 } from '~/enums/adtier0';
import { IAdCreate } from '~/models/ad/ad';

interface ICreateBidProps {
  campID: number | undefined;
  createData: IAdCreate;
  handleCurrentTab: (value: number) => void;
  handleCreateData: (values: object) => void;
  bidData: any;
  getBidCost: () => AxiosPromise<any>;
  handleAdd: (minCost: number, maxConst: number) => Promise<void>;
}

interface IFormData {
  minCost: number;
  maxCost: number;
}

function CreateBid({
  campID,
  createData,
  handleCurrentTab,
  handleCreateData,
  bidData,
  getBidCost,
  handleAdd,
}: ICreateBidProps) {
  const minValue = useRef<number>(1);
  const maxValue = useRef<number>(100000);

  const { formatMessage } = useIntl();

  const { register, handleSubmit, getValues, setValue, errors } =
    useForm<IFormData>({
      submitFocusError: false,
    });

  useEffect(() => {
    if (
      createData.billingType === adtier0.BILLING_TYPE[adtier0.BILLING_TYPE.CPM]
    ) {
      minValue.current = 1000;
    }
  }, [createData.billingType]);

  useEffect(() => {
    setValue('minCost', createData.minCost);
    setValue('maxCost', createData.maxCost);
  }, [createData.maxCost, createData.minCost, setValue]);

  useEffect(() => {
    getBidCost();
  }, [getBidCost]);

  useEffect(() => {
    const interval = setInterval(async () => {
      getBidCost();
    }, 5000);
    return () => clearInterval(interval);
  }, [
    createData.billingType,
    createData.campType,
    createData.product,
    getBidCost,
  ]);

  const handlePrevTab = () => {
    const { minCost, maxCost } = getValues();
    handleCreateData({ minCost, maxCost });
    handleCurrentTab(7);
  };

  const onSubmit = async ({ minCost, maxCost }: IFormData): Promise<void> => {
    handleAdd(minCost, maxCost);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>상세정보 - 광고(배너) : 입찰</legend>
        <OverlayContentBottom>
          <OverlayItemWrap>
            <OverlayItemWrapTitle>
              <h5 className="s_tit">
                <FormattedMessage id="ad.ad.bid" />
              </h5>
              <Hint
                title={formatMessage({ id: 'hint.bid' })}
                content={formatMessage({ id: 'hint.bid.desc' })}
              />
            </OverlayItemWrapTitle>
            <OverlayItem>
              <NoFloating>
                <FormInputContainer>
                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.bidCost" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <span className="m_txt">
                      <NumberFormat
                        value={bidData ? bidData.value : 0}
                        displayType="text"
                        thousandSeparator
                      />
                      <i className="unit xs_txt">KRW</i>
                    </span>
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.minCost" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField className="unit">
                      <input
                        type="number"
                        name="minCost"
                        id="minCost"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                          min: {
                            value: minValue.current,
                            message: formatMessage(
                              { id: 'validate.min' },
                              { min: minValue.current },
                            ),
                          },
                          max: {
                            value: maxValue.current,
                            message: formatMessage(
                              { id: 'validate.max' },
                              { max: maxValue.current },
                            ),
                          },
                          validate: {
                            onlyNum: (value) =>
                              !isNaN(Number(value)) ||
                              formatMessage({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="minCost">
                        <FormattedMessage id="ad.ad.minCost" />
                      </label>
                      <i className="unit xs_txt">KRW</i>
                    </FormInputField>
                    {errors.minCost && (
                      <span className="warning__message s_txt">
                        {errors.minCost.message}
                      </span>
                    )}
                  </FormInput>

                  <FormInput>
                    <FormInputTitleWrap>
                      <FormInputTitleName className="m_txt">
                        <FormattedMessage id="ad.ad.maxCost" />
                        <i className="required" />
                      </FormInputTitleName>
                    </FormInputTitleWrap>
                    <FormInputField className="unit">
                      <input
                        type="number"
                        name="maxCost"
                        id="maxCost"
                        placeholder=""
                        ref={register({
                          required: formatMessage({ id: 'validate.required' }),
                          min: {
                            value: minValue.current,
                            message: formatMessage(
                              { id: 'validate.min' },
                              { min: minValue.current },
                            ),
                          },
                          max: {
                            value: maxValue.current,
                            message: formatMessage(
                              { id: 'validate.max' },
                              { max: maxValue.current },
                            ),
                          },
                          validate: {
                            minMax: (value) =>
                              value >= getValues().minCost ||
                              formatMessage(
                                { id: 'validate.minMax' },
                                { min: getValues().minCost },
                              ),
                            onlyNum: (value) =>
                              !isNaN(Number(value)) ||
                              formatMessage({ id: 'validate.onlyNum' }),
                          },
                        })}
                      />
                      <label htmlFor="maxCost">
                        <FormattedMessage id="ad.ad.maxCost" />
                      </label>
                      <i className="unit xs_txt">KRW</i>
                    </FormInputField>
                    {errors.maxCost && (
                      <span className="warning__message s_txt">
                        {errors.maxCost.message}
                      </span>
                    )}
                  </FormInput>
                </FormInputContainer>
              </NoFloating>
            </OverlayItem>
          </OverlayItemWrap>
          <OverlayFooter>
            <div className="btn__group">
              <button
                className="btn contentBtn btnClear"
                onClick={handlePrevTab}
              >
                <div className="btn__txt">
                  <FormattedMessage id="common.prev" />
                </div>
              </button>
              <button className="btn contentBtn">
                <div className="btn__txt">
                  <FormattedMessage id="common.create" />
                </div>
              </button>
            </div>
          </OverlayFooter>
        </OverlayContentBottom>
      </fieldset>
    </form>
  );
}

export default CreateBid;
