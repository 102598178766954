import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '~/modules';

export function usePrevLocation() {
  const user = useSelector((state: RootState) => state.user.user);
  const location = useLocation();

  const returnType = user ? `type=${user.Type}` : 'type=';
  const returnURL = `returnURL=${location.pathname}${location.search}`;
  const returnQuery = `?${returnType}&${returnURL}`;

  return { returnQuery };
}
