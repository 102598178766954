import React from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  FormInput,
  FormInputContainer,
  FormInputGroup,
  FormInputRadio,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayItem,
  OverlayItemFrequency,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IDetailScheduleProps {
  schedule: string;
  week: number[];
  weekError: number;
  handleSchedule: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleWeek: (value: number, index: number) => void;
}

function DetailSchedule({
  schedule,
  week,
  weekError,
  handleSchedule,
  handleWeek,
}: IDetailScheduleProps) {
  return (
    <OverlayItemWrap>
      <OverlayItemWrapTitle>
        <h5 className="s_tit">
          <FormattedMessage id="ad.ad.schedule" />
        </h5>
      </OverlayItemWrapTitle>

      <OverlayItem>
        <form>
          <fieldset>
            <legend>상세정보 - 광고(배너) : 게재 빈도</legend>
            <NoFloating>
              <FormInputContainer>
                <FormInput>
                  <FormInputGroup>
                    <FormInputRadio>
                      <input
                        type="radio"
                        name="schedule"
                        id="daily"
                        value="daily"
                        checked={schedule === 'daily'}
                        onChange={handleSchedule}
                      />
                      <label htmlFor="daily">
                        <FormattedMessage id="ad.ad.daily" />
                      </label>
                    </FormInputRadio>

                    <FormInputRadio>
                      <input
                        type="radio"
                        name="schedule"
                        id="weekday"
                        value="weekday"
                        checked={schedule === 'weekday'}
                        onChange={handleSchedule}
                      />
                      <label htmlFor="weekday">
                        <FormattedMessage id="ad.ad.weekday" />
                      </label>
                    </FormInputRadio>

                    <FormInputRadio>
                      <input
                        type="radio"
                        name="schedule"
                        id="weekend"
                        value="weekend"
                        checked={schedule === 'weekend'}
                        onChange={handleSchedule}
                      />
                      <label htmlFor="weekend">
                        <FormattedMessage id="ad.ad.weekend" />
                      </label>
                    </FormInputRadio>

                    <FormInputRadio>
                      <input
                        type="radio"
                        name="schedule"
                        id="self"
                        value="self"
                        checked={schedule === 'self'}
                        onChange={handleSchedule}
                      />
                      <label htmlFor="self">
                        <FormattedMessage id="ad.ad.directSelect" />
                      </label>
                    </FormInputRadio>

                    <OverlayItemFrequency className="m_txt">
                      {week.map((w, index) => (
                        <li
                          key={index}
                          className={cx('btnLight', {
                            active: w === 16777215,
                          })}
                          onClick={() => handleWeek(w, index)}
                        >
                          <FormattedMessage id={`week.${index}`} />
                        </li>
                      ))}
                    </OverlayItemFrequency>
                  </FormInputGroup>
                  {weekError === 1 && (
                    <span className="warning__message s_txt">
                      <FormattedMessage id="validate.required" />
                    </span>
                  )}
                </FormInput>
              </FormInputContainer>
            </NoFloating>
          </fieldset>
        </form>
      </OverlayItem>
    </OverlayItemWrap>
  );
}

export default DetailSchedule;
