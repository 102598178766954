import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import Hint from '~/components/common/hint';
import {
  OverlayContentTop,
  OverlayTitleKeyword,
  OverlayTitleWrap,
  OverlayTopCreate,
  OverlayTopWrap,
} from '~/components/elements/overlay';

interface ICreateTabProps {
  currentTab: number;
  product: string;
  billingType: string;
}

function CreateTab({ currentTab, product, billingType }: ICreateTabProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayContentTop className="create">
      <OverlayTopWrap>
        <OverlayTitleWrap>
          <h4 className="overlay__title m_tit">
            <FormattedMessage id="ad.ad.create" />
          </h4>
          {product && (
            <OverlayTitleKeyword>
              <FormattedMessage id={`ad.ad.${product.toLowerCase()}`} />
            </OverlayTitleKeyword>
          )}
          {billingType && (
            <OverlayTitleKeyword>{billingType}</OverlayTitleKeyword>
          )}
        </OverlayTitleWrap>
        <OverlayTopCreate className="xs_tit">
          <ul>
            <li className={currentTab === 1 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.product" />
            </li>
            <li className={currentTab === 2 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.billingType" />
            </li>
            <li className={currentTab === 3 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.material" />
            </li>
            <li className={currentTab === 4 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.app" />
            </li>
            <li className={currentTab === 5 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.info" />
              <Hint
                title={formatMessage({ id: 'hint.addinfo' })}
                content={formatMessage({ id: 'hint.addinfo.desc' })}
              />
            </li>
            <li className={currentTab === 6 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.schedule" />
            </li>
            <li className={currentTab === 7 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.limit" />
              <Hint
                title={formatMessage({ id: 'hint.limit' })}
                content={formatMessage({ id: 'hint.limit.desc' })}
              />
            </li>
            <li className={currentTab === 8 ? 'active' : ''}>
              <FormattedMessage id="ad.ad.bid" />
              <Hint
                title={formatMessage({ id: 'hint.bid' })}
                content={formatMessage({ id: 'hint.bid.desc' })}
              />
            </li>
          </ul>
        </OverlayTopCreate>
      </OverlayTopWrap>
    </OverlayContentTop>
  );
}

export default CreateTab;
