import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Content } from '~/components/elements/content';
import {
  ListSection,
  ListSectionContainer,
  ListSectionFile,
  ListSectionItem,
  ListSectionItemFile,
  ListSectionItemImg,
  ListSectionItemText,
} from '~/components/elements/list-section';
import { downloadFromURL } from '~/libs/utils';

function ResourceMedia() {
  return (
    <Content id="media">
      <ListSection>
        <h3 className="m_tit">
          <FormattedMessage id="resource.logo" />
        </h3>
        <ListSectionContainer>
          <ListSectionItem>
            <ListSectionItemImg>
              <img src={require('~/assets/images/p3_4.png').default} alt="" />
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="resource.mainLogo" />
              </h4>
              <p className="s_txt">
                <FormattedMessage id="resource.mainLogoDesc" />
              </p>
            </ListSectionItemText>
            <ListSectionItemFile>
              <ListSectionFile>
                <h5 className="xs_tit">AI</h5>
                <i
                  className="icon-save_alt"
                  onClick={() =>
                    downloadFromURL(
                      'https://resource.zenaad.com/Logo-ZenaAd-Main.ai',
                      'Logo-ZenaAd-Main.ai',
                    )
                  }
                />
              </ListSectionFile>
              <ListSectionFile>
                <h5 className="xs_tit">PNG</h5>
                <i
                  className="icon-save_alt"
                  onClick={() =>
                    downloadFromURL(
                      'https://resource.zenaad.com/Logo-ZenaAd-Main.png',
                      'Logo-ZenaAd-Main.png',
                    )
                  }
                />
              </ListSectionFile>
            </ListSectionItemFile>
          </ListSectionItem>

          <ListSectionItem>
            <ListSectionItemImg>
              <img src={require('~/assets/images/p3_5.png').default} alt="" />
            </ListSectionItemImg>
            <ListSectionItemText>
              <h4 className="s_tit">
                <FormattedMessage id="resource.walletLinkLogo" />
              </h4>
              <p className="s_txt">
                <FormattedMessage id="resource.walletLinkLogoDesc" />
              </p>
            </ListSectionItemText>
            <ListSectionItemFile>
              <ListSectionFile>
                <h5 className="xs_tit">AI</h5>
                <i
                  className="icon-save_alt"
                  onClick={() =>
                    downloadFromURL(
                      'https://resource.zenaad.com/Logo-ZenaAd-Wallet.ai',
                      'Logo-ZenaAd-Wallet.ai',
                    )
                  }
                />
              </ListSectionFile>
              <ListSectionFile>
                <h5 className="xs_tit">PNG</h5>
                <i
                  className="icon-save_alt"
                  onClick={() =>
                    downloadFromURL(
                      'https://resource.zenaad.com/Logo-ZenaAd-Wallet.png',
                      'Logo-ZenaAd-Wallet.png',
                    )
                  }
                />
              </ListSectionFile>
            </ListSectionItemFile>
          </ListSectionItem>
        </ListSectionContainer>
      </ListSection>
    </Content>
  );
}

export default ResourceMedia;
