import React from 'react';

import { useIntl } from 'react-intl';

import BasicInput from '~/components/base/basic-input';
import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import { SearchContainer } from '~/components/elements/search';

interface IListSearchProps {
  name: string;
  handleName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPressName: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearch: (value: string) => void;
  handleNewCampaign: () => void;
}

function ListSearch({
  name,
  handleName,
  handleKeyPressName,
  handleSearch,
  handleNewCampaign,
}: IListSearchProps) {
  const { formatMessage: f } = useIntl();

  return (
    <SearchContainer>
      <NoFloating>
        <FormInputContainer>
          <BasicInput>
            <input
              type="text"
              name="name"
              id="name"
              className="input__search"
              placeholder={f({ id: 'ad.campaign.campName' })}
              value={name}
              onChange={handleName}
              onKeyPress={handleKeyPressName}
            />
            <label htmlFor="name">{f({ id: 'ad.campaign.campName' })}</label>
            <i className="icon-search" onClick={() => handleSearch(name)} />
          </BasicInput>
        </FormInputContainer>
      </NoFloating>

      <div className="btn contentBtn" onClick={handleNewCampaign}>
        <i className="icon-plus" />
        <div className="btn__txt">{f({ id: 'ad.campaign.newCampaign' })}</div>
      </div>
    </SearchContainer>
  );
}

export default ListSearch;
