import React from 'react';

import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { Content } from '~/components/elements';

import { Sign, SignContainer, SignFinContainer, SignLog } from '../elements';

function SignupSuccess() {
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search);
  const { name, email } = query;

  const handleStart = () => {
    history.push('/signin');
  };

  return (
    <Sign>
      <Content className="first">
        <SignContainer>
          <SignLog to="/">
            <img
              src={require('~/assets/images/header__logo.svg').default}
              alt=""
              width="100%"
              height="100%"
            />
          </SignLog>
          <SignFinContainer>
            <h5>
              <FormattedMessage id="signup.success.welcome" />
            </h5>
            <p className="m_txt">
              <FormattedMessage
                id="signup.success.info"
                values={{
                  name: <span>{name}</span>,
                  email: <span>{email}</span>,
                }}
              />
            </p>
          </SignFinContainer>
          <button className="btn" onClick={handleStart}>
            <FormattedMessage id="signup.success.start" />
          </button>
        </SignContainer>
      </Content>
    </Sign>
  );
}

export default SignupSuccess;
