import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';

import {
  DateFilter,
  DateFilterWrap,
  FilterContainer,
} from '~/components/elements/filter';
import {
  FormInput,
  FormInputContainer,
  FormInputField,
} from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';

import 'react-datepicker/dist/react-datepicker.css';

interface IReportAppFilter {
  searchSelectDay: number;
  searchStartDate: Date;
  searchEndDate: Date;
  handleSearch: (day: number, sdate: any, edate: any) => void;
}

function ReportAppFilter({
  searchSelectDay,
  searchStartDate,
  searchEndDate,
  handleSearch,
}: IReportAppFilter) {
  const [selectDay, setSelectDay] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  useEffect(() => {
    setSelectDay(searchSelectDay);
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
  }, [searchStartDate, searchEndDate, searchSelectDay]);

  const handleChangeSelectDay = (day: number) => {
    const sdate = moment()
      .subtract(day - 1, 'days')
      .toDate();

    const edate = moment().toDate();

    setSelectDay(day);
    setStartDate(sdate);
    setEndDate(edate);
  };

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: any) => {
    setEndDate(date);
  };

  return (
    <FilterContainer>
      <h1 className="m_tit">
        <FormattedMessage id="pub.report.appReport" />
      </h1>
      <DateFilter>
        <DateFilterWrap>
          <ul className="m_txt">
            <li
              className={selectDay === 7 ? 'active' : ''}
              onClick={() => handleChangeSelectDay(7)}
            >
              <FormattedMessage id="common.formatDay" values={{ day: '7' }} />
            </li>
            <li
              className={selectDay === 15 ? 'active' : ''}
              onClick={() => handleChangeSelectDay(15)}
            >
              <FormattedMessage id="common.formatDay" values={{ day: '15' }} />
            </li>
            <li
              className={selectDay === 30 ? 'active' : ''}
              onClick={() => handleChangeSelectDay(30)}
            >
              <FormattedMessage id="common.formatDay" values={{ day: '30' }} />
            </li>
          </ul>

          <NoFloating>
            <FormInputContainer>
              <FormInput>
                <FormInputField>
                  <i className="icon-calendar_today" />
                  {/* <input type="text" /> */}
                  <DatePicker
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    dateFormat="yyyy-MM-dd"
                    popperPlacement="bottom-end"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                    withPortal={window.matchMedia('(max-width: 599px)').matches}
                  />
                  <span className="m_txt date__bar">-</span>
                  <i className="icon-calendar_today" />
                  {/* <input type="date" /> */}
                  <DatePicker
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    dateFormat="yyyy-MM-dd"
                    popperPlacement="bottom-end"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                    withPortal={window.matchMedia('(max-width: 599px)').matches}
                  />
                </FormInputField>
              </FormInput>
            </FormInputContainer>
          </NoFloating>
          <button
            className="btn contentBtn"
            onClick={() => handleSearch(selectDay, startDate, endDate)}
          >
            <FormattedMessage id="common.search" />
          </button>
        </DateFilterWrap>
        <span className="xs_txt">
          <FormattedMessage id="pub.report.searchDesc" />
        </span>
      </DateFilter>
    </FilterContainer>
  );
}

export default ReportAppFilter;
