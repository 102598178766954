import React, { useEffect, useState } from 'react';

import AOS from 'aos';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import HomeXpla from './home00-xpla';
import HomeXplaIntro from './home00-xplaintro';
import HomeIntro from './home01-intro';
import HomeSubNav from './home02-sub-nav';
import HomeRevenue1 from './home03-revenue1';
import HomeRevenue2 from './home03-revenue2';
import HomeRevenue3 from './home03-revenue3';
import HomeAdForm from './home04-ad-form';
import HomeData from './home05-data';

import 'aos/dist/aos.css';

function Home() {
  const [adType, setAdType] = useState<string>('banner');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search);

    const { type } = query;
    if (type) {
      if (type === 'signup') {
        const { email, token } = query;
        if (email && token) {
          history.push(`/signup?email=${email}&token=${token}`);
        }
      }

      if (type === 'findpw') {
        const { token } = query;

        if (token) {
          history.push(`/reset-password?token=${token}`);
        }
      }
    }
  }, [history, location.search]);

  const handleAdType = (type: string) => {
    setAdType(type);
  };

  return (
    <>
      <HomeXpla />
      <HomeXplaIntro />
      <HomeIntro />
      <HomeSubNav />
      <div id="revenue">
        <HomeRevenue1 />
        <HomeRevenue2 />
        <HomeRevenue3 />
      </div>
      <HomeAdForm adType={adType} handleAdType={handleAdType} />
      <HomeData />
    </>
  );
}

export default Home;
