/* eslint-disable */
enum DEVENV {
  INVALID = -2,
  FIRST = -1,
  DEV = 0,
  STAGE = 1,
  PRODUCT = 2,
  TEST = 3,
  LAST = 4,
}

enum PLATFORM {
  INVALID = -2,
  FIRST = -1,
  IOS = 0,
  AOS = 1,
  LAST = 2,
}

enum CUSTOMER_TYPE {
  INVALID = -2,
  FIRST = -1,
  ADVERTISER = 0,
  PUBLISHER = 1,
  LAST = 2,
}

enum CAMPAIGN_TYPE {
  INVALID = -2,
  FIRST = -1,
  IOS = 0,
  AOS = 1,
  BOTH = 2,
  WEB = 3,
  LAST = 4,
}

enum PRODUCT {
  INVALID = -2,
  FIRST = -1,
  BANNER = 0,
  INTERSTITIAL = 1,
  VIDEO = 2,
  LAST = 3,
}

enum BILLING_TYPE {
  INVALID = -2,
  FIRST = -1,
  CPC = 0,
  CPM = 1,
  CPV = 2,
  CPI = 3,
  CPA = 4,
  eCPC = 5,
  nCPI = 6,
  nCPA = 7,
  LAST = 8,
}

enum PROMOTION_STATUS {
  INVALID = -2,
  FIRST = -1,
  WAITING = 0,
  INPROGRESS = 1,
  COMPLETE = 2,
  LAST = 3,
}

enum CRITERIA {
  INVALID = -2,
  FIRST = -1,
  AMOUNT = 0,
  DAY = 1,
  HOUR = 2,
  LAST = 3,
}

enum TARGETING {
  INVALID = -2,
  FIRST = -1,
  GAME = 0,
  AUDIENCE = 1,
  LAST = 2,
}

enum GENRE {
  RPG = 1,
  ACTION = 2,
  CARD = 4,
  ADVANTURE = 8,
}

enum TEST {
  TEST = 1,
}

enum TIMEFLAG {
  H0 = 1,
  H1 = 2,
  H2 = 4,
  H3 = 8,
  H4 = 16,
  H5 = 32,
  H6 = 64,
  H7 = 128,
  H8 = 256,
  H9 = 512,
  H10 = 1024,
  H11 = 2048,
  H12 = 4096,
  H13 = 8192,
  H14 = 16384,
  H15 = 32768,
  H16 = 65536,
  H17 = 131072,
  H18 = 262144,
  H19 = 524288,
  H20 = 1048576,
  H21 = 2097152,
  H22 = 4194304,
  H23 = 8388608,
}

enum ACTION_TYPE {
  INVALID = -2,
  FIRST = -1,
  IMPRESSION = 0,
  WATCH = 1,
  INSTALL = 2,
  CLICK = 3,
  ACTION = 4,
  LAST = 5,
}

enum ADMIN_GROUP {
  INVALID = -2,
  FIRST = -1,
  ADMINISTRATORS = 0,
  USERS = 1,
  LAST = 2,
}

enum ETH_ASKRESPONSE {
  INVALID = -2,
  FIRST = -1,
  WAITING = 0,
  COMPLETE = 1,
  HOLD = 2,
  LAST = 3,
}

enum ERROR {
  INVALID = -2,
  FIRST = -1,
  SUCCESS = 0,
  UNKNOWN = 1,
  LAST_COMMON = 128,
  AD_NOTFOUND = 129,
  AD_DISABLED = 130,
  EXCEEDED_IMPRESSION = 131,
  NEED_ADDR = 132,
  INVALID_ADDR = 133,
  IS_BLOCKED = 134,
  NOTENOUGH_POINT = 135,
  WITHDRAW_TIMEOUT = 136,
  INSUFFICIENT_FUNDS = 137,
  ONLY_ONCEDAY = 138,
  UNKNOWN_MEDID = 139,
  CANNOT_EDITADDR = 140,
  EXCEEDED_ADDR = 141,
  AD_EXPIRED = 142,
  ALREADY_ADREQ = 143,
  TRYAGAIN_LATER = 144,
  LAST_SDK = 512,
  ADSERVER_TEST = 513,
  LAST_ADSERVER = 1024,
  NEED_SIGNUP = 1025,
  ALREADY_SIGNUP = 1026,
  TOKEN_EXPIRED = 1027,
  DUPLICATE_EMAIL = 1028,
  DUPLICATE_PACKAGE = 1029,
  INVALID_SESSIONKEY = 1030,
  INVALID_CONFTOKEN = 1031,
  NEED_SIGNIN = 1032,
  NEED_REFRESHTOKEN = 1033,
  API_FORBIDDEN = 1034,
  API_NOTFOUND = 1035,
  INVALID_PW = 1036,
  UNKNOWN_TIMEZONE = 1037,
  DAYLIMIT_LESSTHAN = 1038,
  PROLIMIT_LESSTHAN = 1039,
  GREATER_THANZERO = 1040,
  EXCEEDED_SCHEDULE = 1041,
  CANNOT_START = 1042,
  CANNOT_STOP = 1043,
  CANNOT_EDIT = 1044,
  EXCEEDED_CAMPBUDGET = 1045,
  EXCEEDED_DAYLIMIT = 1046,
  EXCEEDED_PROLIMIT = 1047,
  ACCEPT_WAITING = 1048,
  DUPLICATE_ID = 1049,
  NO_PERMISSION = 1050,
  INVALID_PERIOD = 1051,
  DUPLICATE_REQUEST = 1052,
  MUSTBE_STOPPED = 1053,
  ONLY_BUSINESSMAIL = 1054,
  NEED_ACCOUNT = 1055,
  NOTENOUGH_REVENUE = 1056,
  INVALID_TRACKURLAOS = 1057,
  INVALID_TRACKURLIOS = 1058,
  INVALID_VCODE = 1059,
  VCODE_EXPIRED = 1060,
  DUPLICATE_ADDRESS = 1061,
  INVALID_ADDRESS = 1062,
  LAST = 1063,
}

export const adtier0 = {
  DEVENV,
  PLATFORM,
  CUSTOMER_TYPE,
  CAMPAIGN_TYPE,
  PRODUCT,
  BILLING_TYPE,
  PROMOTION_STATUS,
  CRITERIA,
  TARGETING,
  GENRE,
  TEST,
  TIMEFLAG,
  ACTION_TYPE,
  ADMIN_GROUP,
  ETH_ASKRESPONSE,
  ERROR,
};
