import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FormInput,
  FormInputContentWrap,
  FormInputTitleName,
  FormInputTitleWrap,
} from '~/components/elements/form';

function DefaultInfoPassword() {
  const history = useHistory();

  const handleChangePassword = () => {
    history.push('/change-password');
  };

  return (
    <FormInput>
      <FormInputTitleWrap>
        <FormInputTitleName className="m_txt">
          <FormattedMessage id="mypage.default.password" />
        </FormInputTitleName>
      </FormInputTitleWrap>
      <FormInputContentWrap>
        <span className="m_txt">
          *******
          <i className="icon-chevron-right" />
        </span>
        <button className="btn contentBtn" onClick={handleChangePassword}>
          <FormattedMessage id="common.modify" />
        </button>
      </FormInputContentWrap>
    </FormInput>
  );
}

export default DefaultInfoPassword;
