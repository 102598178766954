import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import TitleStatic from '~/components/base/title-static';
import { FormInputContainer } from '~/components/elements/form';
import { NoFloating } from '~/components/elements/no-floating';
import {
  OverlayContentBottom,
  OverlayItem,
  OverlayItemWrap,
  OverlayItemWrapTitle,
} from '~/components/elements/overlay';

interface IRevenueRequestFormProps {
  revenue: number;
  handleRequest: () => Promise<void>;
}

function RevenueRequestForm({
  revenue,
  handleRequest,
}: IRevenueRequestFormProps) {
  const { formatMessage } = useIntl();

  return (
    <OverlayContentBottom>
      <OverlayItemWrap>
        <OverlayItemWrapTitle>
          <h5 className="s_tit">
            <FormattedMessage id="pubmypage.revenueGive.giveRequest.revenue" />
          </h5>
        </OverlayItemWrapTitle>
        <OverlayItem>
          <NoFloating>
            <FormInputContainer>
              <TitleStatic
                title={formatMessage({
                  id: 'pubmypage.revenueGive.giveRequest.sum',
                })}
              >
                <span className="m_txt">
                  <NumberFormat
                    value={revenue}
                    displayType="text"
                    thousandSeparator
                  />
                  <i className="unit xs_tit">KRW</i>
                </span>
              </TitleStatic>
            </FormInputContainer>
          </NoFloating>
        </OverlayItem>
      </OverlayItemWrap>
      <button className="btn contentBtn" onClick={handleRequest}>
        <FormattedMessage id="common.request" />
      </button>

      <OverlayItemWrap className="clear">
        <OverlayItemWrapTitle>
          <h5 className="s_tit">
            <FormattedMessage id="pubmypage.revenueGive.giveRequest.guide" />
          </h5>
        </OverlayItemWrapTitle>

        <OverlayItem>
          <NoFloating>
            <FormInputContainer>
              <TitleStatic
                title={formatMessage({
                  id: 'pubmypage.revenueGive.giveRequest.min',
                })}
              >
                <span className="m_txt">
                  1,000,000
                  <i className="unit xs_tit">KRW</i>
                </span>
              </TitleStatic>

              <TitleStatic
                title={formatMessage({
                  id: 'pubmypage.revenueGive.giveRequest.period',
                })}
              >
                <span className="m_txt">
                  <FormattedMessage id="pubmypage.revenueGive.giveRequest.periodDesc" />
                </span>
              </TitleStatic>

              <TitleStatic
                title={formatMessage({
                  id: 'pubmypage.revenueGive.giveRequest.date',
                })}
              >
                <span className="m_txt">
                  <FormattedMessage id="pubmypage.revenueGive.giveRequest.dateDesc1" />
                </span>
                <span className="m_txt">
                  <FormattedMessage id="pubmypage.revenueGive.giveRequest.dateDesc2" />
                </span>
              </TitleStatic>
            </FormInputContainer>
          </NoFloating>
        </OverlayItem>
      </OverlayItemWrap>
    </OverlayContentBottom>
  );
}

export default RevenueRequestForm;
