import React from 'react';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';

import { Content } from '~/components/elements/content';

const Wrapper = styled.section`
  width: 100%;
  height: auto;
  background: linear-gradient(
    180deg,
    rgba(249, 249, 251, 0) 28.74%,
    #eceff3 100%
  );
`;

const Styles = styled(Content as any)`
  .xPlayz__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .xPlayz__header > img {
    width: 190px;
  }

  .xPlayz__header p {
    font-size: 18px;
    font-weight: 300;
    color: #666;
    text-align: center;
    margin-top: 24px;
  }

  .xPlayz__store {
    display: flex;
    align-items: center;
    gap: 12px;
    row-gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }

  .xPlayz__store--badge {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 160px;
    background: hsl(240, 4%, 95%);
    padding: 0 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 300ms;
  }
  .xPlayz__store--badge:hover {
    background: hsl(240, 4%, 90%);
  }

  .xPlayz__store--badge.disable {
    opacity: 0.4;
  }

  .xPlayz__store--badge img {
    width: auto;
    height: 28px;
  }

  .xPlayz__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-templete-rows: 1fr 1fr 1fr;
    column-gap: 20px;
    margin-top: 120px;
  }

  .xPlayz__main img {
    display: flex;
    width: 400px;
  }

  .xPlayz__main img:nth-of-type(1) {
    grid-column: 1;
    grid-row: span 2;
    margin-left: auto;
  }

  .xPlayz__main img:nth-of-type(2) {
    grid-column: 2;
    grid-row: 2 / span 3;
    margin-top: auto;
  }

  .xPlayz__explain {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .xPlayz__explain:last-child {
    justify-content: end;
    text-align: right;
    padding-right: 60px;
    padding-bottom: 80px;
  }

  .xPlayz__explain h1 {
    font-size: 21px;
    font-weight: 700;
    color: #1b1c23;
    margin-bottom: 16px;
  }

  .xPlayz__explain p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    color: #666;
  }

  .xPlayz__explain span {
    font-size: 13px;
    font-weight: 300;
    color: #666;
    margin-top: 8px;
  }

  @media screen and (max-width: 1155px) {
    .xPlayz__main {
      column-gap: 0px;
    }
    .xPlayz__main img {
      max-width: 370px;
      width: 40vw;
    }

    .xPlayz__explain h1 {
      font-size: 14px;
    }

    .xPlayz__explain p {
      font-size: 13px;
    }
    .xPlayz__explain span {
      font-size: 11px;
    }
  }

  @media screen and (max-width: 599px) {
    .xPlayz__header > img {
      width: 140px;
    }

    .xPlayz__header p {
      font-size: 14px;
      font-weight: 300;
      color: #666;
      text-align: center;
      margin-top: 24px;
    }
    .xPlayz__main {
      grid-template-columns: 1fr;
      grid-template-rows: 4fr;
      margin-top: 80px;
    }

    .xPlayz__explain {
      text-align: center;
    }

    .xPlayz__explain:last-child {
      text-align: center;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .xPlayz__main img {
      position: relative;
      left: 2.6vw;
      width: 50vw;
      margin: 0 auto;
    }

    .xPlayz__main img:nth-of-type(1) {
      grid-column: 1;
      grid-row: 2;
    }

    .xPlayz__main img:nth-of-type(2) {
      grid-column: 1;
      grid-row: 4;
    }
  }
`;

function HomeXpla() {
  return (
    <Wrapper id="p1_xPlayz">
      <Styles>
        <div className="content">
          <div className="xPlayz__header">
            <img
              src={require('~/assets/images/p1_xPlayzIcon.png').default}
              alt=""
            />
            <p>
              <FormattedHTMLMessage id="home.xplaintro.title" />
            </p>
            <div className="xPlayz__store">
              <a
                className="xPlayz__store--badge"
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/app/id1667742112"
              >
                <img
                  src={require('~/assets/images/p1_badge-appStore.png').default}
                  alt=""
                />
              </a>
              <a
                className="xPlayz__store--badge"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.zenaad.xplayz"
              >
                <img
                  src={
                    require('~/assets/images/p1_badge-playStore.png').default
                  }
                  alt=""
                />
              </a>
              <a
                className="xPlayz__store--badge"
                href="https://xplayz.zenaad.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require('~/assets/images/p1_badge-webSite.png').default}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="xPlayz__main">
            <img
              src={require('~/assets/images/p1_xPlayzScreen1.png').default}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
            />
            <div className="xPlayz__explain">
              <h1>Wallet</h1>
              <p>
                <FormattedMessage id="home.xplaintro.wallet1" />
                <br />
                <FormattedMessage id="home.xplaintro.wallet2" />
              </p>
            </div>
            <img
              src={require('~/assets/images/p1_xPlayzScreen2.png').default}
              alt=""
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="300"
            />
            <div className="xPlayz__explain">
              <h1>Reward gauge</h1>
              <p>
                <FormattedMessage id="home.xplaintro.reward1" />
                <br />
                <FormattedMessage id="home.xplaintro.reward2" />
              </p>
              <span>
                <FormattedMessage id="home.xplaintro.reward3" />
              </span>
            </div>
          </div>
        </div>
      </Styles>
    </Wrapper>
  );
}

export default HomeXpla;
