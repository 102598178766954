import React from 'react';

import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import Hint from '~/components/common/hint';
import { TableContainer } from '~/components/elements/table';
import { IReportCampaignListItem } from '~/models/ad/report';

interface IReportCampaignListProps {
  campaigns: IReportCampaignListItem[] | null;
}

interface IReportCampaignListItemProps {
  campaign: IReportCampaignListItem;
}

function ReportCampaignList({ campaigns }: IReportCampaignListProps) {
  return (
    <TableContainer>
      <table className="xs_txt">
        <thead>
          <tr>
            <th className="text__left border__right">
              <FormattedMessage id="ad.report.campName" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.impression" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.click" />
              <i className="icon-drop-down" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.ctrper" />
              <Hint title="hint.ctr" content="hint.ctr.desc" />
            </th>
            <th className="text__right">
              <FormattedMessage id="ad.report.execute" />
              (KRW)
              <i className="icon-drop-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns &&
            campaigns.map((campaign, index) => (
              <ReportCampaignListItem key={index} campaign={campaign} />
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

function ReportCampaignListItem({ campaign }: IReportCampaignListItemProps) {
  const { Name, Impression, Click, CTR, Execute } = campaign;

  return (
    <tr>
      <th className="text__left border__right table__title">
        <span>{Name}</span>
      </th>
      <td className="text__right">
        <NumberFormat value={Impression} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">
        <NumberFormat value={Click} displayType="text" thousandSeparator />
      </td>
      <td className="text__right">{CTR}</td>
      <td className="text__right table__important">
        <NumberFormat value={Execute} displayType="text" thousandSeparator />
      </td>
    </tr>
  );
}

export default ReportCampaignList;
