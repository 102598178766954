import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { accountAPI } from '~/api';
import AddInfo from '~/components/account/my/add-info';
import DefaultInfo from '~/components/account/my/default-info';
import { ConsoleContent } from '~/components/elements/console-content';
import { usePrevLocation } from '~/hooks/use-prev-location';
import { ICustomer, IResPubMyPage } from '~/models/account/my-page';
import { setIsFullLoader } from '~/modules/core';

import Revenue from './revenue';

const MyPageWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #ebebeb;
`;

function MyPage() {
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [isAccount, setIsAccount] = useState<number>(0);
  const [revenue, setRevenue] = useState<number>(0);

  const [{ data, loading, error }] = useAxios<IResPubMyPage>(
    {
      url: accountAPI.pubMyPage,
      method: 'POST',
    },
    { useCache: false },
  );

  const dispatch = useDispatch();

  const { returnQuery } = usePrevLocation();

  useEffect(() => {
    dispatch(setIsFullLoader(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      if (data.customer) {
        setCustomer(data.customer);
        setIsAccount(data.custID);
        setRevenue(data.revenue);
      }
    }
  }, [data]);

  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return <Redirect to={`/signin${returnQuery}`} />;
      }
    }

    throw error;
  }

  if (!data || !customer) {
    return null;
  }

  return (
    <MyPageWrap>
      <ConsoleContent>
        <h1 className="m_tit">
          <FormattedMessage id="mypage.mypage" />
        </h1>

        <DefaultInfo customer={customer} />

        <AddInfo customer={customer} />

        <Revenue isAccount={isAccount} revenue={revenue} />
      </ConsoleContent>
    </MyPageWrap>
  );
}

export default MyPage;
