import React from 'react';

import PublisherIntro from './publisher01-intro';
import PublisherContent from './publisher02-content';

function Game() {
  return (
    <>
      <PublisherIntro />
      <PublisherContent />
    </>
  );
}

export default Game;
