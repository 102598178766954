import React from 'react';

import { Helmet } from 'react-helmet';

import Mediation from '~/components/home/mediation';
import { MobileGnbPartner } from '~/components/layout/header-mobile';
import { GnbPartner } from '~/components/layout/header-web';
import Layout from '~/components/layout/layout';

function HomeMediationPage() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <Layout webGnb={<GnbPartner />} mobileGnb={<MobileGnbPartner />}>
        <Mediation />
      </Layout>
    </>
  );
}

export default HomeMediationPage;
