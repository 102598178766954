import { version } from './helper';

const campaign = `api/${version.v1}/ad/report/campaign`;

const ad = `api/${version.v1}/ad/report/ad`;

const adDaily = `api/${version.v1}/ad/report/ad-daily`;

export default {
  campaign,
  ad,
  adDaily,
};
