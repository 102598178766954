import React, { Component } from 'react';

import log from 'loglevel';
import { useSelector } from 'react-redux';

import { errorAPI } from '~/api';
import { defaultAxios } from '~/libs/axios';
import { RootState } from '~/modules';

import Error404 from './error-404';
import Error500 from './error-500';

interface IErrorBoundaryState {
  hasError: boolean;
}

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorParams {
  isAxiosError: boolean;
  config: {
    url: string;
    method: string;
    data: any;
    params: any;
  } | null;
  response: {
    data: any;
    status: number;
    statusText: string;
  } | null;
  message: string;
  stack: any;
  componentStack: any;
}

log.enableAll();

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    log.error({ error, errorInfo });

    const isAxiosError = !!error.isAxiosError;
    let config: any = null;
    let response: any = null;
    if (isAxiosError) {
      const c = error.config ? error.config : null;
      const r = error.response ? error.response : null;

      if (c) {
        config = {
          url: c.url,
          method: c.method,
          data: c.data,
          params: c.params,
        };
      }

      if (r) {
        response = {
          data: r.data,
          status: r.status,
          statusText: r.statusText,
        };
      }
    }

    const { message } = error;
    const { stack } = error;
    const { componentStack } = errorInfo;

    const errorParams: IErrorParams = {
      isAxiosError,
      config,
      response,
      message,
      stack,
      componentStack,
    };

    defaultAxios
      .post(errorAPI.logger, errorParams)
      .then((res) => {
        if (res.data.error === 'SUCCESS') {
          log.debug('error log send success');
        }
      })
      .catch((err) => {});
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Error500 />;
    }

    return <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>;
  }
}

interface IErrorBoundaryWrapperProps {
  children: React.ReactNode;
}

function ErrorBoundaryWrapper(props: IErrorBoundaryWrapperProps) {
  const isNotFound = useSelector(
    (state: RootState) => state.error.errorType === 'NOT_FOUND',
  );

  if (isNotFound) {
    return <Error404 />;
  }

  const { children } = props;
  return <>{children}</>;
}

export default ErrorBoundary;
